<template>
  <v-dialog 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  scrollable @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  transition="dialog-bottom-transition"
  >
    <v-card rounded class="d-flex flex-column dialog-card">
      <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
        <div>{{ $t('data_insights') }}</div>
        <div>
          <v-tooltip top class="">
            <template v-slot:activator="{ on }">
              <v-btn
                @click.stop="copyText()" 
                v-on="on"
                fab 
                small 
                text
                color="grey"
                class="no-caps border-radius-circle"
              >
                <v-icon size="20">mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('copy') }}</span>
          </v-tooltip>

          <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
            <v-icon size="24">mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text rounded :class="'ma-0 pt-5 pb-5 pl-5 ' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">


        <v-card rounded outlined class="mb-5 pl-5 pr-5 pt-1 pb-1" >
          <v-row class="d-flex justify-end" >
              <v-col class="text-left ma-auto" cols="12" sm="8" md="8" lg="8">
                <div class="">
                  <div :class="'mb-3 '+($vuetify.breakpoint.width < 600 ? 'mt-4' : '')"><h2><b>{{ $t(item_playground.type_function) }}</b></h2></div>
                  <div>{{ $t('msg_'+ item_playground.type_function) }}</div>
                </div>
              </v-col>
              <v-col class="d-flex justify-center ma-auto " cols="12" sm="4" md="4" lg="4">
                <x-autocomplete
                  v-model="type_playground"
                  :items="settings"
                  :item-text="getItemText"
                  item-value="type_function"
                  :label="$t('type')"
                  ref="type"
                  class="select_type_function"
                  filled
                  clearable
                  @change="setPlayground"
                >
                </x-autocomplete>
              </v-col>
          </v-row>
        </v-card>

         <!-- <v-tabs 
          v-model="tab_type_function"
          :color="($store.state.app.dark) ? 'white' : 'black'"
          class="content-tabs mb-2"
          background-color="transparent"
          fixed-tabs
          show-arrows
        >
          <v-tab 
          v-for="(item,index) in settings"
          :key="index"
          @click="setData(item)"
          >
             {{ $t(item.type_function) }}
          </v-tab>
        </v-tabs> -->

        <v-tabs 
          v-if="!loading && Object.keys(item_language_locale).length > 1 && type == 'multiple'"
          v-model="tab_language_locale"
          :color="($store.state.app.dark) ? 'white' : 'black'"
          class="content-tabs mb-2"
          background-color="transparent"
          fixed-tabs
          show-arrows
        >
          <v-tab 
          v-for="(item,index) in item_language_locale"
          :key="index"
          @click="getMultipleData(item)"
          >
             {{ item[0].language_name }} {{ item[0].locale_name }}
          </v-tab>
        </v-tabs> 

        <!-- <v-skeleton-loader
          v-if="loading"
          class="mx-auto table_loading"
          type="image"
        ></v-skeleton-loader> -->
        <div class="mt-2">
          <v-skeleton-loader
            v-if="loading"
            class="mx-auto table_loading"
            type="table-thead,table-tbody"
          ></v-skeleton-loader>
        </div>
        <div v-if="!loading && !desserts_table.length" class="pa-4 d-flex justify-center" style="height: 65vh;">
          <h2 class="ma-auto">{{ $t('no_data') }}</h2>
        </div>

        <div v-if="!loading && desserts_table.length" class="mt-2">
          <!-- <div class="d-flex justify-end mt-2 mb-1">
            <x-text-field
              class="input-search"
              v-model="search_table"
              append-icon="mdi-magnify"
              label="Search"
              :loading="table_loading"
              single-line
              hide-details
              clearable
            ></x-text-field>
          </div> -->
          <v-data-table
            rounded
            :mobile-breakpoint="0"
            class="elevation-1 table-insight"
            :headers="headers_table"
            :items="desserts_table"
            :items-per-page="-1"  
            :search="search_table"
            :disable-pagination="true"
            @update:sort-by="updateSortBy"
            @update:sort-desc="updateSortDesc"
            :header-props="{
                'sort-icon': (sortedDesc ? 'mdi-arrow-up' : 'mdi-arrow-down')
            }"
            :footer-props="{
              itemsPerPageText: $t('rows_per_page'),
              itemsPerPageOptions: [10, 25, 50, 100, 250, { text: $t('all'), value: -1 }],  
              itemsPerPageAllText: $t('all'),
              pageText: $t('page_of'),
              'show-current-page': false,
              'show-first-last-page': false,
              'disable-items-per-page': true,
              'disable-pagination': true,
              firstIcon: 'mdi-chevron-double-left',
              lastIcon: 'mdi-chevron-double-right',
              prevIcon: 'mdi-chevron-left',
              nextIcon: 'mdi-chevron-right'
            }"
          >
             
          <template 
          :slot="'header.'+ item.value" 
          slot-scope="props" 
          v-for="(item, index) in headers_table"
          >
            <div 
              v-if="item" 
              :key="index"
              :class="'text-center ' + [(!sortedBy || sortedBy != item.value ? 'hide-icon' : '')]"
            >   
              <span class="">
                <span>
                  {{ item.text }}
                </span>
                <v-tooltip top v-if="item.copy">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click.stop="copyHeader(item)" 
                      v-on="on"
                      icon
                      small
                      color="primary"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('copy_all_column') }}</span>
                </v-tooltip>
              </span>
            </div>
          </template>

          <template :slot="'item.' + item.value" slot-scope="props" v-for="(item, index) in headers_table">
            <div 
              v-if="item" 
              :key="'col_' + index"
              :class="'body-td-insight ' +((item.copy || item.copy_row) && props.item[item.value] && item.type != 'table_array' ? 'td-insight' : '')+' ' +( item.type == 'chip' || item.type == 'description' || item.type == 'table' ? 'td-insight-chip' : '') + ' ' + (props.item.no < 10 ? 'td-insight-top' : '') + ' ' + (item.type == 'number' ? 'td-insight-number' : '')"
            >
              <div v-if="item.type == 'url' && props.item[item.value]" 
                :class="'text-center url-in-table'">
                <a class="pr-7" :href="props.item[item.value]" target="_blank">{{ encodeDecodeURL(props.item[item.value]) }}</a>
                <v-tooltip top v-if="item.copy" class="">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click.stop="copyData(props.item[item.value])" 
                      v-on="on"
                      icon
                      small
                      class="btn-copy"
                      color="primary"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('copy') }}</span>
                </v-tooltip>
              </div>
              <div v-else-if="item.type == 'date' && props.item[item.value]" class="text-center">
                <span class="">
                  {{ moment(props.item[item.value]).tz($auth.user().timezone).format('DD/MM/YYYY') }}
                </span>
                <v-tooltip top v-if="item.copy" class="">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click.stop="copyData(props.item[item.value])" 
                      v-on="on"
                      icon
                      small
                      class="btn-copy"
                      color="primary"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('copy') }}</span>
                </v-tooltip>
              </div>
              <div v-else-if="item.type == 'number' && props.item[item.value]" class="text-center">
               
                <span class="">
                  {{ props.item[item.value] ? formatNumber(props.item[item.value]) : 0  }}
                </span>
                <v-tooltip top v-if="item.copy" class="">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click.stop="copyData(props.item[item.value])" 
                      v-on="on"
                      icon
                      small
                      class="btn-copy"
                      color="primary"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('copy') }}</span>
                </v-tooltip>
                <v-tooltip top v-if="item.copy_row" class="">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click.stop="copyRow(props.item)" 
                      v-on="on"
                      icon
                      small
                      class="btn-copy"
                      color="primary"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('copy_row') }}</span>
                </v-tooltip>
              </div>
              <div v-else-if="item.type == 'chip' && props.item[item.value]" class="text-center" style="max-width: 500px;">
                <div class="d-flex flex-wrap"> 
                  <v-chip
                    v-for="(chip, index_chip) in props.item[item.value]" :key="'chip_' + index_chip"
                    class="ma-2 custom-chip"
                    color="primary"
                  >
                    {{ chip }}
                  </v-chip>
                </div> 
                <v-tooltip top v-if="item.copy" class="">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click.stop="copyData(props.item[item.value])" 
                      v-on="on"
                      icon
                      small
                      class="btn-copy"
                      color="primary"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('copy') }}</span>
                </v-tooltip>
              </div>
              <div v-else-if="item.type == 'monthly_searches' && props.item[item.value]" class="text-center">
                <div class="pl-2" style="width: 200px;">
                  <sparkline :seriesData="SetDataMonthlySearches(props.item[item.value])" />
                </div>
                <v-tooltip top v-if="item.copy" class="">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click.stop="copyData(props.item[item.value])" 
                      v-on="on"
                      icon
                      small
                      class="btn-copy"
                      color="primary"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('copy') }}</span>
                </v-tooltip>
              </div>
              <div v-else-if="item.type == 'table' && props.item[item.value]" class="text-center">
                <v-simple-table class="pr-7" style="max-width: 360px;">
                <template v-slot:default>
                    <!-- <thead>
                      <tr>
                        <th v-for="(item_td_tble, index_td_tble) in Object.keys(props.item[item.value])" :key="index_td_tble" class="text-center">
                          {{ item_td_tble }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td v-for="(key, colIndex) in Object.keys(props.item[item.value])" :key="colIndex" class="text-center">{{ props.item[item.value][key] }}</td>
                      </tr>
                    </tbody> -->
                    <tbody>
                      <tr v-for="(item_td_tble, index_td_tble) in Object.keys(props.item[item.value])" :key="index_td_tble">
                        <td class="text-center" style="width: 150px;"><b>{{ item_td_tble }}</b></td>
                        <td class="text-center" style="width: 200px;">{{ props.item[item.value][item_td_tble] }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-tooltip top v-if="item.copy" class="">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click.stop="copyDataObject(props.item[item.value])" 
                      v-on="on"
                      icon
                      small
                      class="btn-copy"
                      color="primary"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('copy') }}</span>
                </v-tooltip>

                <v-tooltip top class="">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click.stop="expandTableObject(props.item[item.value])" 
                      v-on="on"
                      icon
                      small
                      class="btn-expand"
                      color="primary"
                    >
                      <v-icon>mdi-table</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('open_more_data') }}</span>
                </v-tooltip>

              </div>
              <div v-else-if="item.type == 'table_array' && props.item[item.value]" class="text-center">
                
                <v-tooltip top v-if="item.copy" class="">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click.stop="expandTable(props.item[item.value])" 
                      v-on="on"
                      icon
                      small
                      class=""
                      color="primary"
                    >
                      <v-icon>mdi-table</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('open_more_data') }}</span>
                </v-tooltip>

                <!-- <v-simple-table v-if="props.item[item.value].length" class="pr-7" style="max-width: 360px;">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-for="(item_td_tble, index_td_tble) in Object.keys(props.item[item.value][0])" :key="index_td_tble" class="text-center">
                        {{ item_td_tble }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, rowIndex) in props.item[item.value]" :key="rowIndex">
                      <td v-for="(key, colIndex) in Object.keys(row)" :key="colIndex" class="text-center">{{ row[key] }}</td>
                    </tr>
                  </tbody>
                  </template>
                </v-simple-table>
                <v-tooltip top v-if="item.copy" class="">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click.stop="copyData(props.item[item.value])" 
                      v-on="on"
                      icon
                      small
                      class="btn-copy"
                      color="primary"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('copy') }}</span>
                </v-tooltip> -->
              </div>
              <div v-else-if="item.type == 'description' && props.item[item.value]" class="text-left" >
                <span class="pre-line pr-7" style="max-width: 500px;">{{ props.item[item.value] }}</span>
                <v-tooltip top v-if="item.copy" class="">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click.stop="copyData(props.item[item.value])" 
                      v-on="on"
                      icon
                      small
                      class="btn-copy"
                      color="primary"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('copy') }}</span>
                </v-tooltip>
              </div>
              <div v-else-if="item.type == 'boolean' && props.item[item.value]" class="text-center">
                <div v-if="props.item[item.value]">
                  <v-icon color="green" small>mdi-check</v-icon>
                </div>
                <div v-else>
                  <v-icon color="red" small>mdi-close</v-icon>
                </div>
                <v-tooltip top v-if="item.copy" class="">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click.stop="copyData(props.item[item.value])" 
                      v-on="on"
                      icon
                      small
                      class="btn-copy"
                      color="primary"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('copy') }}</span>
                </v-tooltip>
              </div>
              <div v-else class="text-center">
                <span class="" v-if="(typeof props.item[item.value] == 'undefined' || props.item[item.value] == null)">-</span>
                <span :class="(item.copy && props.item[item.value] ? 'pr-7' : '')" v-else>{{ props.item[item.value] }}</span>
                <v-tooltip top v-if="item.copy && props.item[item.value]" class="">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click.stop="copyData(props.item[item.value])" 
                      v-on="on"
                      icon
                      small
                      class="btn-copy"
                      color="primary"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('copy') }}</span>
                </v-tooltip>
              </div>

              
            </div>
          </template>
          </v-data-table>
        </div>
      
      </v-card-text>
      <!-- <v-card-actions class="pl-5 pr-5 pb-5 pt-0 ma-0 ">
        <v-spacer></v-spacer>
        
        <v-btn color="grey" text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>
<script>
  
  export default {
    data: () => ({
      loading: true,
      dialog: false,
      resolve: null,
      reject: null,
      tab_type_function: '',
      tab_language_locale: '',
      uuid: null,
      array_uuid: null,
      item_playground:{},
      settings: [],
      head_table: [],
      item_table: [],
      item_language_locale: [],
      has_scrollbar: false,
      table_loading: false,
      search_table: '',
      headers_table: [],
      desserts_table: [],
      sortedBy: null,
      sortedDesc: false,
      type_playground: null,
      type: 'single',
      options: {
        color: 'primary',
        width: 1240,
        zIndex: 200
      }
    }),
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
        }
      },
      formatNumber (value) {
        const num = Number(value);
        if (num && num !== 0 && Math.abs(num) < 0.01) {
            // ระบุตำแหน่งของตัวเลขที่สำคัญแรกโดยตรวจสอบจาก logarithm
            const significantDigits = Math.ceil(-Math.log10(Math.abs(num)));
            // ถ้าค่าที่ได้มากกว่า 2 ให้เรากำหนดความแม่นยำตามค่านั้น
            return num.toFixed(significantDigits > 2 ? significantDigits : 2);
        } else {
            // ค่ามากกว่าหรือเท่ากับ 0.01 ใช้ toFixed 2
            return new Intl.NumberFormat('th').format(num)
            //return num ? num.toFixed(2) : 0;
        }
      },
      hexToSymbols(hexString) {
        // แยกสตริงออกเป็นส่วนๆ โดยใช้เครื่องหมายเซมิโคลอน (;)
        const hexParts = hexString.split(';');
        
        // แปลงแต่ละส่วนจากเลขฐานสิบหกเป็นตัวอักษร และรวมกันเป็นสตริงเดียว
        const symbols = hexParts.map(part => String.fromCharCode(parseInt(part, 16))).join('');
        
        return symbols;
      },
      encodeDecodeURL(url) {
        if(!url){
          return '';
        }
        //console.log(url)
        const parts = url.split('/');
        const decodedParts = parts.map(part => {
          try {
            // พยายามดีโค้ดแต่ละส่วนของ URL
            return decodeURIComponent(part);
          } catch {
            // หากเกิดข้อผิดพลาด, ส่งคืนส่วนนั้นๆ ออกมาเป็นเอนโค้ด
            return part;
          }
        });
        // รวมกลับเป็น URL ที่ดีโค้ดได้บางส่วนและเอนโค้ดบางส่วน
        return decodedParts.join('/');
      },
      copyText() {
        let array = []
        let text_head = ''
        for(let e of this.headers_table){
          text_head += e.value+'\t'
        } 
        array.push(text_head)
        for(let body of this.desserts_table){
          let td = ''
          for(let head of this.headers_table){
            let data = body[head.value]
            if(!data){
               td += '-\t'
            }
            else if(this.isArrayofObjects(data)){
              td += (JSON.stringify(data) + '\t')
            }
            else if(Array.isArray(data)){
              td += (data + '\t')
            }
            else if(this.isPlainObject(data)){
              td += (JSON.stringify(data) + '\t')
            }
            else{
              td += (data + '\t')
            }
          }
          array.push(td)
        } 
        navigator.clipboard.writeText(array.join('\n')); 
        this.$root.$snackbar(this.$t('copied_to_clipboard'))
      },
      isArrayofObjects(data) {
        return Array.isArray(data) && data.every(item => 
          typeof item === 'object' && item !== null && !Array.isArray(item)
        );
      },
      isPlainObject(obj) {
          return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
      },
      getData(){
        //console.log(this.item_playground)
        this.loading = true
        let post = {
          type: this.type,
          search_engine: this.item_playground.search_engine,
          type_function: this.item_playground.type_function,
          type_playground: this.item_playground.type_playground,
          enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
        }
        if(this.type == 'multiple'){
          post['array_uuid'] = JSON.stringify(this.array_uuid)
        }
        else{
          post['uuid'] = this.uuid
        }

        this.axios.post('/tracking/keyword/get-data-insights', post)
        .then(res => {
          if (res.data.status === 'success') {
            this.$root.$refs.master.getCredit()
            this.initTable(res.data.head,res.data.body)
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            if(err.response.data.type === 'credit_error'){
              if(this.$route.query && this.$route.query.enterprise_uuid){
                this.$root.$snackbar(err.response.data.msg)
              }
              else{
                this.$root.$snackbar(err.response.data.msg)
                this.$router.push({
                  name: 'billing-payment', 
                  query: this.$route.query, 
                  params: { 
                    credit: err.response.data.credit
                  }
                })
              }
            }
            else{
              this.$root.$snackbar(err.response.data.msg)
            }
            //this.cancel()
          }
        })
        .finally(() => this.loading = false)
      },
      initTable(head,body){
        //console.log(head)
        //console.log(body)
        //this.head_table = head
        //this.item_table = body
        // for(let e of head){
        //   let item = {}
          
        // }
        // let headers = [{
        //   text: this.$t('no'),
        //     value: 'no',
        //     path: 'no',
        //     type: 'number',
        //     align: 'start',
        //     copy: false,
        //     copy_row: true,
        //     sortable: true,
        // }]
        let headers = []
        for(let e of head){
          headers.push({
            text: this.$t(e.name),
            value: e.name,
            path: e.path,
            type: e.type,
            copy: e.copy,
            copy_row: e.copy_row,
            sortable: true,
          }) 
        }
        //console.log(headers)

        let desserts = []
        let count = 1
        for(let e of body){
          let data = {}
          for(let h of head){
            if(h.name == 'no'){
              data[h.name] = count
            }
            else{
              data[h.name] = this.getJsonData(e,h.path)
            }
            
          }
          desserts.push(data) 
          count++
        }
        //console.log(desserts)
        this.headers_table = headers
        this.desserts_table = desserts
        let that = this
        setTimeout(function(){
          that.$nextTick(() => {
            that.checkForScrollbar();
          });
        },100)
      },
      updateSortBy(newSortBy) {
        //console.log(newSortBy)
        this.sortedBy = newSortBy;
      },
      updateSortDesc(newSortDesc) {
        //console.log(newSortDesc)
        this.sortedDesc = newSortDesc;
      },
      getJsonData(data, keyString) {
        // Check if the input jsonString is not null or undefined
        if (!data) {
            throw new Error("Invalid JSON string");
        }

        // Check if the keyString is not null or undefined
        if (!keyString) {
            throw new Error("Invalid key string");
        }

        // Split the key string to access nested keys and indices
        const keys = keyString.split('.');

        let result = data;
        for (let key of keys) {
            // Check if the current result is null
            if (result === null) {
                return null; // Prevent further access if the result is null
            }

            // Check if the key contains an index (e.g., result[0])
            const match = key.match(/(.*)\[(\d+)\]/);
            if (match) {
                key = match[1];
                const index = parseInt(match[2], 10);

                if (result[key] && result[key][index] !== undefined) {
                    result = result[key][index];
                } else {
                    return null; // Key or index not found
                }
            } else {
                if (result[key] !== undefined) {
                    result = result[key];
                } else {
                    return null; // Key not found
                }
            }
        }

        return result;
      },
      SetDataMonthlySearches(monthly_searches){
        if(!monthly_searches || !monthly_searches.length){
          return []
        }
        let new_monthly_searches = JSON.parse(JSON.stringify(monthly_searches)).reverse()
        let res_monthly_searches = []
        for(let monthly_searches of new_monthly_searches){
          res_monthly_searches.push([monthly_searches.month + '/' + monthly_searches.year,monthly_searches.search_volume])
        }
        return res_monthly_searches
      },
      initLanguageLocale(array_uuid){
        this.loading = true
        this.axios.post('/tracking/keyword/split-data-array-uuid', {
          array_uuid: JSON.stringify(array_uuid),
          enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
        })
        .then(res => {
          if (res.data.status === 'success') {
            //console.log(res.data)
            this.item_language_locale = res.data.keywords
            this.tab_language_locale = 0
            this.getMultipleData(Object.values(this.item_language_locale)[0])
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
              this.cancel()
          }
        })
      },
      getMultipleData(item){
        //console.log(item)
        let array_uuid = []
        for(let e of item){
          array_uuid.push(e.uuid)
        }
        this.array_uuid = array_uuid
        this.getData()
      },
      copyHeader(item){
        //console.log(item)
        let array = []
        array.push(item.value+'\t')        

        for(let body of this.desserts_table){
          let data = body[item.value]
          let td = ''
          if(!data){
              td += '-\t'
          }
          else if(this.isArrayofObjects(data)){
            td += (JSON.stringify(data) + '\t')
          }
          else if(Array.isArray(data)){
            td += (data + '\t')
          }
          else if(this.isPlainObject(data)){
            td += (JSON.stringify(data) + '\t')
          }
          else{
            td += (data + '\t')
          }
          array.push(td)
        } 
        navigator.clipboard.writeText(array.join('\n')); 
        this.$root.$snackbar(this.$t('copied_to_clipboard'))
      },
      copyRow(row){
        //console.log(data)
        let td = ''
        for(let e of this.headers_table){
          td += e.value+'\t'
        } 
        td += '\n'
        for(let head of this.headers_table){
          let data = row[head.value]
          if(!data){
              td += '-\t'
          }
          else if(this.isArrayofObjects(data)){
            td += (JSON.stringify(data) + '\t')
          }
          else if(Array.isArray(data)){
            td += (data + '\t')
          }
          else if(this.isPlainObject(data)){
            td += (JSON.stringify(data) + '\t')
          }
          else{
            td += (data + '\t')
          }
        }
        navigator.clipboard.writeText(td); 
        this.$root.$snackbar(this.$t('copied_to_clipboard'))
      },
      expandTable(data){
        //console.log(data)
        this.$root.$dialog_expand_table(data)
      },
      expandTableObject(data){
        //console.log(data) 
        this.$root.$dialog_expand_table_object(data)
      },
      copyDataObject(object){
        let array = []

        for(let key of Object.keys(object)){
          // console.log(key)
          let td = key+'\t'
          
          let data = object[key]
          //console.log(data)
          if(!data){
              td += '-\t'
          }
          else if(this.isArrayofObjects(data)){
            td += (JSON.stringify(data) + '\t')
          }
          else if(Array.isArray(data)){
            td += (data + '\t')
          }
          else if(this.isPlainObject(data)){
            td += (JSON.stringify(data) + '\t')
          }
          else{
            td += (data + '\t')
          }
          
          array.push(td)
        } 
        navigator.clipboard.writeText(array.join('\n')); 
        this.$root.$snackbar(this.$t('copied_to_clipboard'))
      },
      copyData(data){
        //console.log(data)
        let text = ''
        if(!data){
            text = '-\t'
        }
        else if(this.isArrayofObjects(data)){
          text = (JSON.stringify(data) + '\t')
        }
        else if(Array.isArray(data)){
          text = (data + '\t')
        }
        else if(this.isPlainObject(data)){
          text = (JSON.stringify(data) + '\t')
        }
        else{
          text = (data + '\t')
        }
        navigator.clipboard.writeText(text); 
        this.$root.$snackbar(this.$t('copied_to_clipboard'))
      },
      setData(item){
        this.item_playground = item
        this.getData()
      },
      getItemText(item){  
        let price = ''
        if(item.price){
          let currency = ''
          if(this.$root.$refs.master.currency != 'USD'){
            currency = this.formatNumber(parseFloat(item.price)*this.$root.$refs.master.currency_rate)
          }
          else{
            currency = parseFloat(item.price)
          }
          let symbol = ''
          if(this.$root.$refs.master && this.$root.$refs.master.data_currency && this.$root.$refs.master.data_currency.symbol_hex){
            symbol = this.hexToSymbols(this.$root.$refs.master.data_currency.symbol_hex)
          }
          else{
            symbol = this.$root.$refs.master.currency
          }
          price = ' ['+(currency)+' '+symbol+']'
        }
        return this.$t(item.type_function) + price
      },
      setPlayground(item){
        let selectedItem = this.settings.find(setting => item == setting.type_function);
        if(selectedItem){
          this.item_playground = selectedItem
          this.getData()
        }
        //console.log(selectedItem);
      },
      open(uuid,settings,type) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.type = type
        this.settings = settings
        this.item_playground = this.settings[0]
        this.type_playground = this.settings[0].type_function
        this.tab_type_function = 0
        if(type == 'multiple'){
          //this.array_uuid = uuid
          this.initLanguageLocale(uuid)
        }
        else{
          this.uuid = uuid
          this.getData()
        }
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false
        })
        this.dialog = false
      }
    }
  }
</script>
<style>
.url-in-table{
  max-width: 360px;
  overflow-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.url-text-in-table{
  max-width: 700px;
  overflow-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.table_loading .v-skeleton-loader__image{
  height: 65vh;
}
.highcharts-tooltip-container{
  z-index: 1000!important;
}
.custom-chip {
  white-space: normal;
  max-width: 400px;
  text-align: center;
}
.pre-line{
  white-space: pre-line!important;
}
.v-data-table.table-insight{
  box-shadow: none !important;
}
.table-insight .v-data-footer{
  display: none;
}
.table-insight .td-insight-chip {
  width: 400px!important;
}
/* .table-insight .body-td-insight{
  width: 200px;
} */
.table-insight .td-insight{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 200px;
    height: 50px;
    padding: 5px;
    text-align: left;
    vertical-align: middle;
    -webkit-box-align: center;
    -ms-flex-align: center;
            align-items: flex-start;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	        justify-content: start;
    overflow: hidden;
    border-top: 0;
    border-left: 0;
	line-height: 44px;
  position: relative;
}

.table-insight .td-insight.td-insight-number{
  justify-content: center!important;
}

.table-insight .td-insight:not(:empty):hover {
	word-break: break-all;
  white-space: normal;
  overflow: initial;
  line-height: normal;
  padding: 0;
  border: 0;
}
.table-insight .td-insight:not(:empty):first-child:hover {
	-webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  z-index: 3000;
}

.table-insight .td-insight-top:not(:empty):first-child:hover  {
  -webkit-box-align: start!important;
  -ms-flex-align: start!important;
  align-items: start!important;
  z-index: 3000;
}

.table-insight .td-insight:not(:empty):hover > div {
  border: 2px solid #000000;
  border-radius: 10px;
	text-decoration: none;
    background-color: #ffffff;
    -webkit-transform: translate3d(4px, -4px, 0);
    transform: translate3d(4px, -4px, 0);
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
            transition-delay: 0s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
            transition-duration: 0.4s;
    -webkit-transition-property: all;
    -o-transition-property: all;
    transition-property: all;
    word-break: break-word;
    white-space: normal;
    height: auto;
	  width: 100%;
    min-height: 40px;
    overflow: hidden;
    line-height: normal;
    padding: 5px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
            justify-content: center;
}

.app.theme--dark .table-insight .td-insight:not(:empty):hover > div{
  background-color: #232425;
  border: 2px solid #ffffff;
}

.loader-table{
  border-radius: 10px;
  margin: 5px;
}

.btn-copy{
  display: none;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 3000;
}
.table-insight .td-insight:not(:empty):hover > div {
  .btn-copy{
    display: block;
  }
  .btn-expand{
    display: block;
  }
}
.select_type_function{
  width: 100%;
}

.table-insight .v-data-table__wrapper{
  max-height: calc(100vh - 370px);
  overflow: auto;
}
@media (max-width: 600px) {
  .table-insight .v-data-table__wrapper{
    max-height: calc(100vh - 365px);
  }
}

.btn-expand{
  display: none;
  position: absolute;
  top: 40px;
  right: 5px;
  z-index: 3000;
}

.table-insight thead tr th span{
  padding: 0;
}
</style>

