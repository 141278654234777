<template>
  <v-card rounded outlined class="mb-5 pa-5" >
    <v-row class="d-flex justify-end" >
        <v-col class="text-left" cols="12" sm="8" md="8" lg="8">
          <div class="">
            <div><h2><b>{{ title }}</b></h2></div>
            <div>{{ msg }}</div>
          </div>
        </v-col>
        <v-col class="d-flex justify-center ma-auto " cols="12" sm="4" md="4" lg="4">
            <v-btn
              @click="$router.push({ 
                name: redirect,
                query: $route.query
              })" 
              outlined
              depressed  
              large 
              class="" 
              color="ctaBg ctaFg--text"
            >
              <v-icon left>mdi-plus</v-icon> {{ create_item }}
            </v-btn> 
        </v-col>
    </v-row>
  </v-card>
</template>
<script>
  export default {
    data: () => ({
      
    }),
    props: {
      title: {
        default: '',
        required: false,
        type: String
      },
      msg: {
        default: '',
        required: false,
        type: String
      },
      create_item: {
        default: '',
        required: false,
        type: String
      },
      redirect: {
        default: '',
        required: false,
        type: String
      },
    },
    created () {
      
    }
  }
</script>
<style>
.display_inline{
  display: inline-block;
}
</style>