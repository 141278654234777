<template>
  <v-dialog 
  scrollable 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }" 
  transition="dialog-bottom-transition"
  >
    <v-card class="dialog-card">
      <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
        <div>
        {{ $t('confirm') }}
        </div>
        <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-5 ma-0">
        <div class="text-body-1">{{ $t(title) }}</div>
        <v-text-field
            v-model="text"
            :label="$t('msg_confirm_password',{password: password})"
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-5 ma-0">
        <v-spacer></v-spacer>
        <!-- <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn> -->
        <v-btn color="primary" rounded depressed @click.native="agree" class="no-caps">{{ $t('save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      label: null,
      text:  null,
      title: null,
      password: null,
      options: {
        color: 'primary',
        width: 360,
        zIndex: 200
      }
    }),
    methods: {
      open(title,password) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.text = null
        this.title = title
        this.password = password
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: true,
          reason: this.reason,
          password: this.text
        })
        this.dialog = false
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>