<template>
  <v-dialog 
  scrollable 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  transition="dialog-bottom-transition"
  >
    <v-card rounded class="dialog-card">    
      <v-card-title class="pl-5 pr-5 pt-3 pb-3 ma-0 d-flex justify-space-between">
        <div>
          {{ $t('view_package') }}
        </div>
        
        <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="data_package" rounded :class="'ma-0 pt-3 pl-5 pb-3 ' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">

        <div class="view_div ml-0 mr-0 pt-0 pb-0 mt-0 d-flex justify-space-between">
          <div class="text-body-1 mt-auto mb-auto">{{$t('search_engine')}}</div>
          <div class="text-h6 mt-auto mb-auto">{{ data_package.serp_provider_name }}</div>   
        </div>

        <div class="view_div ml-0 mr-0 pt-3 pb-0 d-flex justify-space-between">
          <div class="text-body-1 mt-auto mb-auto">{{$t('date_update_ranking')}}</div>
          <div class="text-h6 mt-auto mb-auto">{{ data_package.frequency_date }} {{ $t(data_package.frequency_date_format) }}</div>   
        </div>

        <div class="view_div ml-0 mr-0 pt-3 pb-0 d-flex justify-space-between">
          <div class="text-body-1 mt-auto mb-auto">{{$t('package_date')}}</div>
          <div class="text-h6 mt-auto mb-auto">{{ data_package.package_date }} {{ $t(data_package.package_date_format) }}</div>   
        </div>

        <div class="view_div ml-0 mr-0 pt-3 pb-0 d-flex justify-space-between">
          <div class="text-body-1 mt-auto mb-auto">{{$t('depth')}}</div>
          <div class="text-h6 mt-auto mb-auto">{{ data_package.serp_depth_provider_depth }}</div>   
        </div>

        <div class="view_div ml-0 mr-0 pt-3 pb-0 d-flex justify-space-between">
          <div class="text-body-1 mt-auto mb-auto">{{$t('limit_active_keyword')}}</div>
          <div class="text-h6 mt-auto mb-auto">{{ data_package.limit_active_keyword }}</div>   
        </div>
        
        <div class="view_div ml-0 mr-0 pt-3 pb-0 d-flex justify-space-between" v-if="!$auth.user().status_enterprise">   
          <div class="text-body-1 mt-auto mb-auto">{{$t('price')}}</div>
          <div class="text-h6 mt-auto mb-auto">
            <amount_currency :amount="parseFloat(data_package.price)" top></amount_currency> 
          </div>   
        </div>

        <div class="view_div ml-0 mr-0 pt-3 pb-0 d-flex justify-space-between">
          <div class="text-body-1 mt-auto mb-auto">{{$t('start_date')}}</div>
          <div class="text-h6 mt-auto mb-auto">{{ moment(data_package.created_at).tz($auth.user().timezone).format('DD/MM/YYYY H:mm') }}</div>   
        </div>

        <div class="view_div ml-0 mr-0 pt-3 pb-0 d-flex justify-space-between">
          <div class="text-body-1 mt-auto mb-auto">{{$t('end_date')}}</div>
          <div class="text-h6 mt-auto mb-auto">{{ moment(data_package.package_date_expired).tz($auth.user().timezone).format('DD/MM/YYYY H:mm') }}</div>   
        </div>

        <div class="view_div ml-0 mr-0 pt-3 pb-0 d-flex justify-space-between">    
          <div class="text-body-1 mt-auto mb-auto">{{$t('status')}}</div>
          <div :class="'text-h6 mt-auto mb-auto' +(data_package.active ? 'green--text':'red--text')">{{ data_package.active ? $t('active') : $t('expires') }}</div>   
        </div>

      </v-card-text>
      <!-- <v-card-actions class="pl-5 pr-5 pb-5 pt-0 ma-0 ">
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify DialogViewPackage Dialog component
   *
   * Insert component where you want to use it:
   * <DialogViewPackage ref="DialogViewPackage"></DialogViewPackage>
   *
   * Call it:
   * this.$refs.DialogViewPackage.open('Delete', 'Are you sure?', { color: 'red' }).then((DialogViewPackage) => {})
   * Or use await:
   * if (await this.$refs.DialogViewPackage.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$DialogViewPackage
   * <template>
   *   <v-app>
   *     ...
   *     <DialogViewPackage ref="DialogViewPackage"></DialogViewPackage>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$DialogViewPackage = this.$refs.DialogViewPackage.open
   * }
   */
  export default {
    data: () => ({
      loading: false,
      dialog: false,
      resolve: null,
      reject: null,
      data_package: null,
      has_scrollbar: false,
      options: {
        color: 'primary',
        width: 720,
        zIndex: 200
      }
    }),
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
        }
      },
      formatNumber (number) {
        const num = Number(number);
        if (num && num !== 0 && Math.abs(num) < 0.01) {
            // ระบุตำแหน่งของตัวเลขที่สำคัญแรกโดยตรวจสอบจาก logarithm
            const significantDigits = Math.ceil(-Math.log10(Math.abs(num)));
            // ถ้าค่าที่ได้มากกว่า 2 ให้เรากำหนดความแม่นยำตามค่านั้น
            return num.toFixed(significantDigits > 2 ? significantDigits : 2);
        } else {
            // ค่ามากกว่าหรือเท่ากับ 0.01 ใช้ toFixed 2
            return num ? num.toFixed(2) : 0;
        }
        //return new Intl.NumberFormat('th').format(number)
      },
      open(data_package) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.data_package = data_package
        let that = this
        setTimeout(function(){
          that.$nextTick(() => {
            that.checkForScrollbar();
          });
        },100)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false
        })
        this.dialog = false
      }
    }
  }
</script>
<style scoped>
.v-dialog .v-card{
  border-radius: 10px !important;
}
</style>