 
import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import VueAnalytics from 'vue-analytics'
import MasterLayout from '../views/layouts/Master.vue'
// import SSOLayout from '../views/layouts/SSO.vue'
import CleanLayout from '../views/layouts/Clean.vue'
import Home from '../views/Home.vue'

import i18n from '@/plugins/i18n'
import { loadLanguageAsync } from '@/plugins/i18n'
const routes =[
      // {
      //   path: '/',
      //   component: MasterLayout,
      //   children: [{
      //     path: '/',
      //     name: '',
      //     components: {
      //       primary: Home,
      //     }
      //   }],
      //   meta: {
      //     auth: null
      //   }
      // },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '',
          name: 'home',
          components: {
            primary: Home
          }
        }],
        meta: {
          auth: null
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/login',
          name: 'login',
          components: {
            primary: () => import(/* webpackChunkName: "x80e2" */ '../views/auth/Login.vue'),
          }
        }],
        meta: {
          auth: false
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/register',
          name: 'register',
          components: {
            primary: () => import(/* webpackChunkName: "xd26e" */ '../views/auth/Register.vue'),
          }
        }],
        meta: {
          auth: false
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/password/reset',
          name: 'password.email',
          components: {
            primary: () => import(/* webpackChunkName: "xad3a" */ '../views/auth/password/Email.vue'),
          }
        }],
        meta: {
          auth: false
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/password/reset/:token',
          name: 'password.reset',
          components: {
            primary: () => import(/* webpackChunkName: "x13b1" */ '../views/auth/password/Reset.vue'),
          }
        }],
        meta: {
          auth: false
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/settings/profile',
          name: 'settings.profile',
          components: {
            primary: () => import(/* webpackChunkName: "x4d21" */ '../views/settings/Profile.vue'),
          }
        }],
        meta: {
          auth: { roles: [1, 2] }
        }
      },
      // {
      //   path: '/',
      //   component: MasterLayout,
      //   children: [{
      //     path: '/payment',
      //     name: 'payment',
      //     components: {
      //       primary: () => import(/* webpackChunkName: "x4d21" */ '../views/customer/Payment.vue'),
      //     }
      //   }],
      //   meta: {
      //     auth: { roles: [2] }
      //   }
      // },
      
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/billing/history',
          name: 'admin.billing-history',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/admin/Accounting.vue'),
          }
        }],
        meta: {
          auth: [1]
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/lighthouse/history',
          name: 'admin.lighthouse-history',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/admin/Lighthouse.vue'),
          }
        }],
        meta: {
          auth: [1]
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/backlink/history',
          name: 'admin.backlink-history',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/admin/Orders.vue'),
          }
        }],
        meta: {
          auth: [1]
        }
      },
      // {
      //   path: '/',
      //   component: MasterLayout,
      //   children: [{
      //     path: '/admin/payment',
      //     name: 'admin.payment',
      //     components: {
      //       primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/admin/Payment.vue'),
      //     }
      //   }],
      //   meta: {
      //     auth: [1]
      //   }
      // },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/settings/providers',
          name: 'admin.providers',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/admin/Providers.vue'),
          }
        }],
        meta: {
          auth: [1]
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/backlink/services',
          name: 'admin.services',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/admin/Services.vue'),
          }
        }],
        meta: {
          auth: [1]
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/backlink/extras',
          name: 'admin.extras',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/admin/Extras.vue'),
          }
        }],
        meta: {
          auth: [1]
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/tracking/keyword',
          name: 'admin.tracking-keyword',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/admin/Keyword.vue'),
          }
        }],
        meta: {
          auth: [1]
        }
      },
      // {
      //   path: '/',
      //   component: MasterLayout,
      //   children: [{
      //     path: '/tracking/keyword-enterprise',
      //     name: 'tracking-keyword-enterprise',
      //     components: {
      //       primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/admin/KeywordEnterprise.vue'),
      //     }
      //   }],
      //   meta: {
      //     auth: [1,3]
      //   }
      // },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/tracking/serp-provider',
          name: 'admin.serp-provider',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/admin/SerpProvider.vue'),
          }
        }],
        meta: {
          auth: [1]
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/settings/users',
          name: 'admin.users',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/admin/Users.vue'),
          }
        }],
        meta: {
          auth: [1]
        }
      },
      // {
      //   path: '/',
      //   component: MasterLayout,
      //   children: [{
      //     path: '/admin/settings',
      //     name: 'admin.settings',
      //     components: {
      //       primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/admin/Setting.vue'),
      //     }
      //   }],
      //   meta: {
      //     auth: [1]
      //   }
      // },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/tracking/package',
          name: 'admin.tracking-package',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/admin/PackageKeyword.vue'),
          }
        }],
        meta: {
          auth: [1]
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/tracking/manage-package',
          name: 'admin.tracking-manage-package',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/admin/ManagePackage.vue'),
          }
        }],
        meta: {
          auth: [1]
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/tracking/manage-package-date',
          name: 'admin.tracking-manage-package-date',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/admin/ManagePackageDate.vue'),
          }
        }],
        meta: {
          auth: [1]
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/full-data',
          name: 'admin.full-data',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/admin/FullData.vue'),
          }
        }],
        meta: {
          auth: [1]
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/tracking/keyword',
          name: 'tracking-keyword',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/customer/Keyword.vue'),
          }
        }],
        meta: {
          auth: [1,2,3]
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/tracking/add-keyword',
          name: 'tracking-add-keyword',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/customer/CreateKeyword.vue'),
          }
        }],
        meta: {
          auth: [1,2,3]
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/tracking/active-keyword',
          name: 'tracking-active-keyword',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/customer/ActiveBulkKeyword.vue'),
          }
        }],
        meta: {
          auth: [1,2,3]
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/tracking/unactive-keyword',
          name: 'tracking-unactive-keyword',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/customer/UnactiveBulkKeyword.vue'),
          }
        }],
        meta: {
          auth: [1,2,3]
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/tracking/dashboard',
          name: 'tracking-dashboard',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/customer/Dashboard.vue'),
          }
        }],
        meta: {
          auth: [2]
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/tracking/package',
          name: 'tracking-package',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/customer/Package.vue'),
          }
        }],
        meta: {
          auth: [1,2,3]
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/tracking/package/keyword/add',
          name: 'tracking-package-keyword-add',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/customer/CreatePackageKeyword.vue'),
          }
        }],
        meta: {
          auth: [1,2,3]
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/tracking/package/boost-keyword/add',
          name: 'tracking-package-boost-keyword-add',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/customer/CreatePackageBoostKeyword.vue'),
          }
        }],
        meta: {
          auth: [1,2,3]
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/billing/history',
          name: 'billing-history',
          components: {
            primary: () => import(/* webpackChunkName: "x4d21" */ '../views/customer/Accounting.vue'),
          }
        }],
        meta: {
          auth: { roles: [2] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/billing/payment',
          name: 'billing-payment',
          components: {
            primary: () => import(/* webpackChunkName: "x4d21" */ '../views/customer/CreatePayment.vue'),
          }
        }],
        meta: {
          auth: { roles: [2] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/backlink/history',
          name: 'backlink-history',
          components: {
            primary: () => import(/* webpackChunkName: "x4d21" */ '../views/customer/Orders.vue'),
          }
        }],
        meta: {
          auth: { roles: [1,2,3] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/backlink/add-order',
          name: 'backlink-add-order',
          components: {
            primary: () => import(/* webpackChunkName: "x4d21" */ '../views/customer/CreateOrder.vue'),
          }
        }],
        meta: {
          auth: { roles: [1,2,3] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/lighthouse/history',
          name: 'lighthouse-history',
          components: {
            primary: () => import(/* webpackChunkName: "x4d21" */ '../views/customer/Lighthouse.vue'),
          }
        }],
        meta: {
          auth: { roles: [1,2,3] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/lighthouse/add',
          name: 'lighthouse-add',
          components: {
            primary: () => import(/* webpackChunkName: "x4d21" */ '../views/customer/CreateLighthouse.vue'),
          }
        }],
        meta: {
          auth: { roles: [1,2,3] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/sales/billing',
          name: 'sales.billing',
          components: {
            primary: () => import(/* webpackChunkName: "x4d21" */ '../views/sales/Accounting.vue'),
          }
        }],
        meta: {
          auth: { roles: [3] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/sales/users',
          name: 'sales.users',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/sales/Users.vue'),
          }
        }],
        meta: {
          auth: [3]
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/terms-and-conditions',
          name: 'terms',
          components: {
            primary: () => import(/* webpackChunkName: "x4b69" */ '../views/legal/Terms.vue'),
          }
        }],
        meta: {
          auth: null
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/privacy-policy',
          name: 'privacy',
          components: {       
            primary: () => import(/* webpackChunkName: "xaee4" */ '../views/legal/Privacy.vue'),
          }
        }],
        meta: {
          auth: null
        }
      },
      // {
      //   path: '/sso',
      //   component: SSOLayout,
      //   meta: {
      //     auth: null
      //   }
      // },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/404',
          name: '404',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/error/404.vue'),
          }
        }],
        meta: {
          auth: null
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/403',
          name: '403',
          components: {
            primary: () => import(/* webpackChunkName: "x40cc" */ '../views/error/403.vue'),
          }
        }],
        meta: {
          auth: true
        }
      },
      {
        path: '/',
        component: CleanLayout,
        children: [{
          path: '/install',
          name: 'install',
          components: {
            primary: () => import(/* webpackChunkName: "x4fc5" */ '../views/install/Install.vue'),
          }
        }],
        meta: {
          auth: null
        }
      },
    { path: '*', redirect: '/404', hidden: true } // Catch unkown routes
]
const router = new VueRouter({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: routes
  })

  if (typeof window.init.config.ga !== 'undefined' && window.init.config.ga != '') {
    Vue.use(VueAnalytics, {
      id: window.init.config.ga,
      router
    })
  }
  
  NProgress.configure({ showSpinner: false });
  
  // This callback runs before every route change, including on page load.
  router.beforeEach((to, from, next) => {
    loadLanguageAsync(i18n.locale).then(() => next())
  })
  
  router.beforeResolve((to, from, next) => {
    if (to.name != from.name) {
      NProgress.start()
      document.title = window.init.app.name
    }
    next()
  })
  
  router.afterEach((to) => {
    if (to.name) {
      NProgress.done()
    }
  })
  
 
export default router