<template>
  <v-dialog 
  scrollable 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }" 
  transition="dialog-bottom-transition"
  >
    <v-card class="dialog-card">
      <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
        <div>
        {{ title }}
        </div>
        <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-5 ma-0">
        <div class="text-body-1" v-show="!!message">{{ message }}</div>
        <x-text-field
            v-if="input"
            :type="input_type"
            v-model="text"
            :label="$t(input_name)"
        ></x-text-field>
        <x-textarea
          v-if="note"
          v-model="reason"
          :label="$t('reason')"
          filled
          rounded
          persistent-placeholder
          persistent-hint
          label-placement="on-top"
          class="custom-text-field mt-4"
        ></x-textarea>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-5 ma-0 ">
        <v-spacer></v-spacer>
        <!-- <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn> -->
        <v-btn color="primary" rounded depressed @click.native="agree" class="no-caps">{{ $t('confirm') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify Confirm Dialog component
   *
   * Insert component where you want to use it:
   * <confirm ref="confirm"></confirm>
   *
   * Call it:
   * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
   * Or use await:
   * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
   * <template>
   *   <v-app>
   *     ...
   *     <confirm ref="confirm"></confirm>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$confirm = this.$refs.confirm.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      note: false,
      input: false,
      input_name: 'reason',
      input_type: 'reason',
      reason: '',
      text:  '',
      title: null,
      options: {
        color: 'primary',
        width: 360,
        zIndex: 200
      }
    }),
    methods: {
      open(title, message, options, note = false, input = false, input_name = '', value = '', input_type = 'text') {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.title = title
        this.message = message
        this.note = note
        this.input = input
        this.input_name = input_name
        this.input_type = input_type
        this.reason = ''
        this.text = value
        this.options = Object.assign(this.options, options)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: true,
          reason: this.reason,
          text: this.text
        })
        this.dialog = false
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>