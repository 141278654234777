<template>
  <v-dialog 
  scrollable 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  :fullscreen="$vuetify.breakpoint.xsOnly"
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }" 
  transition="dialog-bottom-transition"
  >
    <v-card class="dialog-card">
      <v-card-title>{{ $t('qrcode_promptpay') }}</v-card-title>
      <v-card-text>
        <div class="view_div">
          <v-img :src="qrcode" class="" contain :style="{'width': '100%'}" ></v-img>
        </div>
        <div class="text-center">
          <v-btn 
            rounded
            text
            color="ctaBg ctaFg--text" 
            :href="qrcode" 
            target="_blank"
          >
            <v-icon left>mdi-download</v-icon> {{ $t('download') }}
          </v-btn>
        </div>
        
      </v-card-text>
      
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify QrcodePromptpay Dialog component
   *
   * Insert component where you want to use it:
   * <QrcodePromptpay ref="QrcodePromptpay"></QrcodePromptpay>
   *
   * Call it:
   * this.$refs.QrcodePromptpay.open('Delete', 'Are you sure?', { color: 'red' }).then((QrcodePromptpay) => {})
   * Or use await:
   * if (await this.$refs.QrcodePromptpay.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$QrcodePromptpay
   * <template>
   *   <v-app>
   *     ...
   *     <QrcodePromptpay ref="QrcodePromptpay"></QrcodePromptpay>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$QrcodePromptpay = this.$refs.QrcodePromptpay.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      qrcode:  null,
      options: {
        color: 'primary',
        width: 500,
        zIndex: 200
      }
    }),
    methods: {
      open(qrcode) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.qrcode = qrcode
        
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      formatCurrency (number,currency) {
        return currency ? new Intl.NumberFormat('th', {style: 'currency', currency: currency}).format(number) : '-'
      },
      agree() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: true,
          amount: this.amount
        })
        this.dialog = false
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>