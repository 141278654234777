<template>
  <v-dialog 
  scrollable 
  :fullscreen="$vuetify.breakpoint.xsOnly"
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  transition="dialog-bottom-transition"
  >
    <v-card rounded class="dialog-card">
      <v-card-title class="pl-5 pr-5 pt-3 pb-3 ma-0 d-flex justify-space-between">
        <div>
        {{ $t('view_order') }}
        </div>
        <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text rounded :class="'ma-0 pt-5 pl-5 pb-5 ' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">

        <div class="view_div ml-0 mr-0 pt-0 pb-0 mt-0">
          <div class="text-body-1">{{$t('url')}}</div>
          <div v-if="order && order.links"
          >
            <v-chip
              v-for="(chip, index_chip) in JSON.parse(order.links)" :key="'chip_' + index_chip"
              class="mt-2 mr-2"
              color="primary"
            >
              {{ encodeDecodeURL(chip) }}
            </v-chip>
          </div>       
        </div>

        <div class="view_div ml-0 mr-0 pt-3 pb-3">
          <div class="text-body-1">{{$t('keyword')}}</div>
          <div v-if="order && order.keywords">
            <v-chip
              v-for="(chip, index_chip) in JSON.parse(order.keywords)" :key="'chip_' + index_chip"
              class="mt-2 mr-2"
              color="primary"
            >
              {{ chip }}
            </v-chip>
          </div>       
        </div>

        <v-card outlined class="view_div ml-0 mr-0 pt-0 pb-0">
          <v-simple-table class="">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{$t('type')}}
                  </th>
                  <th class="text-left">
                    {{$t('description')}}
                  </th>
                  <th class="text-left" v-if="!$auth.user().status_enterprise">
                    {{$t('price')}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in order_item"
                  :key="item.code"
                >
                  <td>{{ $t(item.type) }}</td>
                  <td>{{ item.description }}</td>
                  <td v-if="!$auth.user().status_enterprise">
                    <amount_currency :amount="parseFloat(item.price_usd)" top></amount_currency>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <div class="view_div ml-0 mr-0 pt-3 pb-0 d-flex justify-space-between">
          <div class="text-body-1 mt-auto mb-auto">{{$t('quantity')}}</div>
          <div class="text-h6 mt-auto mb-auto">{{ formatNumber(order.quantity) }}</div>   
        </div>
        
        <div class="view_div ml-0 mr-0 pt-3 pb-0 d-flex justify-space-between" v-if="!$auth.user().status_enterprise">   
          <div class="text-body-1 mt-auto mb-auto">{{$t('total_price')}}</div>
          <div class="text-h6 mt-auto mb-auto">
            <amount_currency :amount="parseFloat(order.price_usd)" top></amount_currency>
          </div>   
        </div>

        <div class="view_div ml-0 mr-0 pt-3 pb-0 d-flex justify-space-between">   
          <div class="text-body-1 mt-auto mb-auto">{{$t('status')}}</div>
          <div class="text-h6 mt-auto mb-auto">{{ $t(order.status) }}</div>   
        </div>

      </v-card-text>
      <!-- <v-card-actions class="pl-5 pr-5 pb-5 pt-0 ma-0 ">
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify DialogViewOrder Dialog component
   *
   * Insert component where you want to use it:
   * <DialogViewOrder ref="DialogViewOrder"></DialogViewOrder>
   *
   * Call it:
   * this.$refs.DialogViewOrder.open('Delete', 'Are you sure?', { color: 'red' }).then((DialogViewOrder) => {})
   * Or use await:
   * if (await this.$refs.DialogViewOrder.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$DialogViewOrder
   * <template>
   *   <v-app>
   *     ...
   *     <DialogViewOrder ref="DialogViewOrder"></DialogViewOrder>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$DialogViewOrder = this.$refs.DialogViewOrder.open
   * }
   */
  export default {
    data: () => ({
      loading: false,
      dialog: false,
      resolve: null,
      reject: null,
      order: {

      },
      has_scrollbar: false,
      order_item: [],
      options: {
        color: 'primary',
        width: 720,
        zIndex: 200
      }
    }),
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
        }
      },
      formatNumber (number) {
        const num = Number(number);
        if (num && num !== 0 && Math.abs(num) < 0.01) {
            // ระบุตำแหน่งของตัวเลขที่สำคัญแรกโดยตรวจสอบจาก logarithm
            const significantDigits = Math.ceil(-Math.log10(Math.abs(num)));
            // ถ้าค่าที่ได้มากกว่า 2 ให้เรากำหนดความแม่นยำตามค่านั้น
            return num.toFixed(significantDigits > 2 ? significantDigits : 2);
        } else {
            // ค่ามากกว่าหรือเท่ากับ 0.01 ใช้ toFixed 2
            return num ? num.toFixed(2) : 0;
        }
        //return new Intl.NumberFormat('th').format(number)
      },
      encodeDecodeURL(url) {
        if(!url){
          return ''
        }
        const parts = url.split('/');
        const decodedParts = parts.map(part => {
          try {
            // พยายามดีโค้ดแต่ละส่วนของ URL
            return decodeURIComponent(part);
          } catch {
            // หากเกิดข้อผิดพลาด, ส่งคืนส่วนนั้นๆ ออกมาเป็นเอนโค้ด
            return part;
          }
        });
        // รวมกลับเป็น URL ที่ดีโค้ดได้บางส่วนและเอนโค้ดบางส่วน
        return decodedParts.join('/');
      },
      open(order,order_item_service,order_item_extras) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.order = order
        let order_item = []
        order_item.push(order_item_service)
        order_item = order_item.concat(order_item_extras)
        this.order_item = order_item
        let that = this
        setTimeout(function(){
          that.$nextTick(() => {
            that.checkForScrollbar();
          });
        },100)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false
        })
        this.dialog = false
      }
    }
  }
</script>