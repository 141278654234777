<template>
  <v-dialog 
  scrollable 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  transition="dialog-bottom-transition" 
  @click:outside="cancel" v-model="dialog" 
  :max-width="1120" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  >
      <v-card rounded class="dialog-card">
        <v-card-title class="header_title pt-3 pl-5 pr-5 pb-3 ma-0">
          <div class="d-flex justify-space-between">
            <v-skeleton-loader
              v-if="loading"
              class="loading_select_total" 
              type="button"
            ></v-skeleton-loader>
            <div v-if="!loading">
              {{ keyword }}
              <!-- <v-btn @click="loading ? loading=false : loading=true">test</v-btn> -->
            </div>
            <div>
              <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle">
                <v-icon size="24">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-0 pl-5 pr-5 pb-2 pt-5 ma-0 overflow-hidden">

          <v-row class="" >
            <v-col cols="12" md="10" sm="9" class="pt-0 pb-0 mt-0 mb-0">
              <div class="d-flex">
                <div class="d-flex overflow-x-auto mt-auto mb-auto">
                  <div v-for="(domain , index) in array_domain" :key="index">
                      <v-chip 
                      close
                      @click:close="removeDomain(index)"
                      class="mr-1"
                      >
                        {{encodeDecodeURL(domain)}}
                      </v-chip>  
                  </div>
                </div>
                <div :class="'d-flex '+ (has_add_domain ? 'div_input_search': '')">
                  <x-text-field
                    v-if="has_add_domain"
                    class="input_search"
                    :placeholder="$t('rival')"
                    v-model="textBox"
                  >
                  </x-text-field>
                  <div class="div-open d-flex ma-auto">
                    <div v-if="!has_add_domain" class="line-height-0 text-right ma-auto">
                      <v-btn small fab dark class="border-radius-circle" color="primary" @click="has_add_domain = true">
                        <v-icon >mdi-plus</v-icon>
                      </v-btn>
                    </div>  
                    <div v-if="has_add_domain" class="line-height-0 d-flex ma-auto">
                      <v-btn outlined
                        fab
                        small
                        class="border-radius-circle ml-1 mr-1" 
                        color="ctaBg" 
                        @click="textBox = '';has_add_domain = false;">
                        <v-icon >mdi-close</v-icon>
                      </v-btn>
                      <v-btn small fab dark class="border-radius-circle" color="primary" @click="addDomain();has_add_domain = false;">
                        <v-icon >mdi-check</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>   
            </v-col>
            <v-col cols="12" md="2" sm="3" class="pt-0 pb-0 mt-0 mb-0">
              <div class="d-flex justify-end ">
                <!-- <div v-if="loading" class="ma-auto">
                  <v-skeleton-loader
                    class="loading_select_total"
                    type="button"
                  ></v-skeleton-loader>
                </div> -->
                <div class="" style="width: 100%;">
                  <h6 class="ma-auto mr-2">{{ $t('total_quantity') }}</h6>
                    <x-select
                      @change="getData"
                      :items="item_total_quantity"
                      v-model="total_quantity"
                      class="select_total justify-center"
                      item-text="name"
                      item-value="value"
                      dense
                      filled
                    ></x-select>
                  
                </div>
                
              </div>
            </v-col> 

            <!-- <v-text-field
              class="tag-input mt-0 pt-0 input_search"
              placeholder="URL"
              v-model="textBox"
              @keydown.enter.prevent="addDomain"
            >
              <template v-slot:append> 
                <v-btn class="ma-2" depressed rounded @click="compareDomain">
                  {{ $t('compare') }}
                  <v-icon right>mdi-magnify</v-icon>
                  
                </v-btn>
              </template>
            </v-text-field> -->
            
          </v-row>

          <v-skeleton-loader
              v-if="loading"
              class="loading_card mt-2"
              type="image"
          ></v-skeleton-loader>
          <v-row class="pt-2 mt-0 pb-4">
            <div v-if="!loading" id="cache_content" class="overflow-hidden">
                <div id="cache_detail" class="form-group col-xs-12 col-md-12">
                  <div class="fix-table total-wrapper">
                    <div class="fix-column">
                      <div class="thead">
                        <div class="throw">
                          <span>{{ $t('date') }}</span>
                          <span>{{ $t('ranking') }}</span>
                        </div>
                      </div>
                      <div id="table_main_body" class="tbody">
                        <div class="trow">
                          <span>{{ $t('url') }}</span>
                        </div>
                        <!-- <div class="trow">
                          <span>{{ $t('title') }}</span>
                        </div>
                        <div class="trow">
                          <span>{{ $t('keyword') }}</span>
                        </div>
                        <div class="trow">
                          <span>{{ $t('description') }}</span>
                        </div> -->
                        <div class="trow" v-for="index_row in 500" :key="index_row">
                          <span>#{{ index_row }}</span>
                        </div>

                        <!-- <div class="trow"><span>#2</span></div>
                        <div class="trow"><span>#3</span></div>
                        <div class="trow"><span>#4</span></div>
                        <div class="trow"><span>#5</span></div>
                        <div class="trow"><span>#6</span></div>
                        <div class="trow"><span>#7</span></div>
                        <div class="trow"><span>#8</span></div>
                        <div class="trow"><span>#9</span></div>
                        <div class="trow"><span>#10</span></div>
                        <div class="trow"><span>#11</span></div>
                        <div class="trow"><span>#12</span></div>
                        <div class="trow"><span>#13</span></div>
                        <div class="trow"><span>#14</span></div>
                        <div class="trow"><span>#15</span></div>
                        <div class="trow"><span>#16</span></div>
                        <div class="trow"><span>#17</span></div>
                        <div class="trow"><span>#18</span></div>
                        <div class="trow"><span>#19</span></div>
                        <div class="trow"><span>#20</span></div>
                        <div class="trow"><span>#21</span></div>
                        <div class="trow"><span>#22</span></div>
                        <div class="trow"><span>#23</span></div>
                        <div class="trow"><span>#24</span></div>
                        <div class="trow"><span>#25</span></div>
                        <div class="trow"><span>#26</span></div>
                        <div class="trow"><span>#27</span></div>
                        <div class="trow"><span>#28</span></div>
                        <div class="trow"><span>#29</span></div>
                        <div class="trow"><span>#30</span></div>
                        <div class="trow"><span>#31</span></div>
                        <div class="trow"><span>#32</span></div>
                        <div class="trow"><span>#33</span></div>
                        <div class="trow"><span>#34</span></div>
                        <div class="trow"><span>#35</span></div>
                        <div class="trow"><span>#36</span></div>
                        <div class="trow"><span>#37</span></div>
                        <div class="trow"><span>#38</span></div>
                        <div class="trow"><span>#39</span></div>
                        <div class="trow"><span>#40</span></div>
                        <div class="trow"><span>#41</span></div>
                        <div class="trow"><span>#42</span></div>
                        <div class="trow"><span>#43</span></div>
                        <div class="trow"><span>#44</span></div>
                        <div class="trow"><span>#45</span></div>
                        <div class="trow"><span>#46</span></div>
                        <div class="trow"><span>#47</span></div>
                        <div class="trow"><span>#48</span></div>
                        <div class="trow"><span>#49</span></div>
                        <div class="trow"><span>#50</span></div>
                        <div class="trow"><span>#51</span></div>
                        <div class="trow"><span>#52</span></div>
                        <div class="trow"><span>#53</span></div>
                        <div class="trow"><span>#54</span></div>
                        <div class="trow"><span>#55</span></div>
                        <div class="trow"><span>#56</span></div>
                        <div class="trow"><span>#57</span></div>
                        <div class="trow"><span>#58</span></div>
                        <div class="trow"><span>#59</span></div>
                        <div class="trow"><span>#60</span></div>
                        <div class="trow"><span>#61</span></div>
                        <div class="trow"><span>#62</span></div>
                        <div class="trow"><span>#63</span></div>
                        <div class="trow"><span>#64</span></div>
                        <div class="trow"><span>#65</span></div>
                        <div class="trow"><span>#66</span></div>
                        <div class="trow"><span>#67</span></div>
                        <div class="trow"><span>#68</span></div>
                        <div class="trow"><span>#69</span></div>
                        <div class="trow"><span>#70</span></div>
                        <div class="trow"><span>#71</span></div>
                        <div class="trow"><span>#72</span></div>
                        <div class="trow"><span>#73</span></div>
                        <div class="trow"><span>#74</span></div>
                        <div class="trow"><span>#75</span></div>
                        <div class="trow"><span>#76</span></div>
                        <div class="trow"><span>#77</span></div>
                        <div class="trow"><span>#78</span></div>
                        <div class="trow"><span>#79</span></div>
                        <div class="trow"><span>#80</span></div>
                        <div class="trow"><span>#81</span></div>
                        <div class="trow"><span>#82</span></div>
                        <div class="trow"><span>#83</span></div>
                        <div class="trow"><span>#84</span></div>
                        <div class="trow"><span>#85</span></div>
                        <div class="trow"><span>#86</span></div>
                        <div class="trow"><span>#87</span></div>
                        <div class="trow"><span>#88</span></div>
                        <div class="trow"><span>#89</span></div>
                        <div class="trow"><span>#90</span></div>
                        <div class="trow"><span>#91</span></div>
                        <div class="trow"><span>#92</span></div>
                        <div class="trow"><span>#93</span></div>
                        <div class="trow"><span>#94</span></div>
                        <div class="trow"><span>#95</span></div>
                        <div class="trow"><span>#96</span></div>
                        <div class="trow"><span>#97</span></div>
                        <div class="trow"><span>#98</span></div>
                        <div class="trow"><span>#99</span></div>
                        <div class="trow"><span>#100</span></div> -->
                      </div>
                    </div>
                    <div class="rest-columns">
                      <div id="table_head" class="thead">
                        <span v-for="(item, index) in table_head" :key="index">
                          <div class="trow">
                            <span>{{ item.date }}</span>
                            <span>{{ item.ranking }}</span>
                          </div>
                        </span>
                      </div>
                      <div id="table_body" class="tbody" @scroll="handleScroll">
                        <div class="tr" v-for="(item, index) in table_body" :key="index">
                          <div class="trow">
                            <span>
                              <p>
                                <a v-if="item.url" :href="item.url" target="_blank">{{ item.url_name }}</a>
                                <span v-else>-</span>
                              </p>
                            </span>
                            <!-- <span><p>{{ item.title }}</p></span>
                            <span><p>{{ item.keyword }}</p></span>
                            <span><p>{{ item.des }}</p></span> -->
                            
                            <span v-for="(item2, index2) in item.array_url" :key="index2" :class="item2.class_snapshot">
                              <p >
                                <a v-if="item2.url" :href="item2.url" target="_blank">{{ item2.url_name }}</a>
                                <span v-else>-</span>
                              </p>
                            </span>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div id="div_reload_cache" v-if="reload">
                    <v-btn 
                      color="ctaBg ctaFg--text"
                      class="reload_cache"
                      :loading="loading_load_more" :disabled="loading_load_more"
                      @click="load_more">
                      {{ $t('load_more') }}
                    </v-btn>
                  </div> -->
                </div>
            </div>
          </v-row>
        </v-card-text>
        
      </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify ReportSEORival Dialog component
   *
   * Insert component where you want to use it:
   * <ReportSEORival ref="ReportSEORival"></ReportSEORival>
   *
   * Call it:
   * this.$refs.ReportSEORival.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
   * Or use await:
   * if (await this.$refs.ReportSEORival.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
   * <template>
   *   <v-app>
   *     ...
   *     <ReportSEORival ref="ReportSEORival"></ReportSEORival>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$ReportSEORival = this.$refs.ReportSEORival.open
   * }
   */
  import Cookies from 'js-cookie'
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      loading: false,
      has_add_domain: false,
      table_head: [],
      table_body: [],
      options: {
        color: 'primary',
        width: '100%',
        zIndex: 200
      },
      uuid: null,
      keyword: null,
      url: null,
      search_engine: null,
      id_youtube: null,
      id_channel_youtube: null,
      all_data: [],
      rival_json: {},
      array_domain: [],
      textBox: "",
      page: 1,
      reload: true,
      loading_load_more: false,
      item_total_quantity: [],
      total_quantity: 10,
    }),
    methods: {
      addDomain() {
        if (this.textBox.length) {
          this.array_domain.push(this.textBox)
          this.textBox = ""
          Cookies.set('rival_url_'+this.uuid, JSON.stringify(this.array_domain))
          this.compareDomain()
        }
      },
      removeDomain(index) {
        this.array_domain.splice(index, 1)
        Cookies.set('rival_url_'+this.uuid, JSON.stringify(this.array_domain))
        this.compareDomain()
      },
      compareDomain(){
        //console.log(this.array_domain)
        this.createTable(this.all_data,this.encodeDecodeURL(this.url))
      },
      handleScroll (event) {
        let table_body = document.getElementById("table_body");
        let table_head = document.getElementById("table_head");
        let table_main_body = document.getElementById("table_main_body");
        let top = table_body.scrollTop;
        let left = table_body.scrollLeft;
        // console.log(table_head.scrollLeft)
        // console.log(left)
        table_main_body.scrollTop = top
        table_head.scrollLeft = left 
      },
      open(uuid) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.array_domain = []
        this.keyword = '-'
        this.total_quantity = 10
        this.textBox = ""
        this.has_add_domain = false
        if(Cookies.get('rival_url_'+uuid)){
          this.array_domain = JSON.parse(Cookies.get('rival_url_'+uuid))
        }
        // if(rival.length >= 10){
        //   this.reload = true
        // }
        // else{
        //   this.reload = false
        // }

        // this.page = 1
        this.uuid = uuid
        this.all_data = []
        

        // this.keyword = keyword
        // this.url = domain
        // this.all_data = rival
        // this.createTable(this.all_data,this.encodeDecodeURL(this.url))
        
        this.getData()
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: true
        })
        this.dialog = false
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
          text: this.text
        })
        this.dialog = false
      },
      getData() {
        this.loading = true
        this.axios.post('/tracking/keyword/rival', {
            uuid: this.uuid,
            limit: this.total_quantity,
        })
        .then(res => {
          if (res.data.status === 'success') {
            //console.log(res.data)
            // for(let e of res.data.rival){
            //   this.all_data.push(e)
            // }
            // if(res.data.rival.length < 10){
            //   this.reload = false
            // }
            this.item_total_quantity = [
              {
                name: 10,
                value: 10
              },
            ]

            if(res.data.total > 10 && res.data.total <= 20){
              this.item_total_quantity.push({
                name: res.data.total,
                value: res.data.total
              })
            }
            if(res.data.total > 20 && res.data.total <= 50){
              this.item_total_quantity.push({
                name: 20,
                value: 20
              })
              this.item_total_quantity.push({
                name: res.data.total,
                value: res.data.total
              })
            }
            if(res.data.total > 50 && res.data.total <= 100){
              this.item_total_quantity.push({
                name: 20,
                value: 20
              })
              this.item_total_quantity.push({
                name: 50,
                value: 50
              })
              this.item_total_quantity.push({
                name: res.data.total,
                value: res.data.total
              })
            }
            if(res.data.total > 100){
              this.item_total_quantity.push({
                name: 20,
                value: 20
              })
              this.item_total_quantity.push({
                name: 50,
                value: 50
              })
              this.item_total_quantity.push({
                name: 100,
                value: 100
              })
              this.item_total_quantity.push({
                name: this.$t('total'),
                value: res.data.total
              })
            }
            //console.log(this.item_total_quantity)
            this.url = res.data.url
            this.search_engine = res.data.search_engine
            if(this.search_engine == 'youtube'){
              this.id_youtube = res.data.id_youtube
              this.id_channel_youtube = res.data.id_channel_youtube
            }
            this.keyword = res.data.keyword
            this.rival_json = res.data.rival_json
            this.all_data = res.data.rival.reverse()

            this.createTable(this.all_data,this.encodeDecodeURL(this.url))
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading = false)
      },
      createTable(rival,domain) {
        this.table_head = []
        this.table_body = []
        
        // for(let array_domain of this.array_domain){
        //   console.log(this.extractChannelName(array_domain))
        // }

        for(let e of rival){
          // if(e.array_rank.length == 0){
          //   continue
          // }
          if(!this.rival_json[e.dataforseo_task_id]){
            continue
          }
          this.table_head.push({
            date: this.moment(e.date_created).tz(this.$auth.user().timezone).format('DD/MM/YYYY H:mm'),
            ranking: e.ranking ? e.ranking : '-'
            //googleresult: parseFloat(e.googleresult).toFixed(0)
          })
          let array_url = []
          //console.log(domain.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ""))
          //console.log(this.extractUrl(domain))
          for(let result of this.rival_json[e.dataforseo_task_id]){
            if(result.url){
              
              let url = this.encodeDecodeURL(result.url)
              //console.log(url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ""))
              
              let class_snapshot = 'snapshot'
              if(this.search_engine == 'youtube'){  
                let data_rival = this.array_domain.find(item => this.transformYoutubeUrl(item) == this.transformYoutubeUrl(url))
                if(!data_rival){
                  if(result.channel_url){
                    data_rival = this.array_domain.find(item => this.extractChannelName(item) == this.extractChannelName(result.channel_url))
                    if(!data_rival){
                      data_rival = this.array_domain.find(item => item == this.extractChannelName(result.channel_url))
                      if(!data_rival){
                        data_rival = this.array_domain.find(item => item == result.channel_id)
                      }
                    }
                  } 
                }
                if(result.type == 'youtube_video_paid'){ 
                  class_snapshot = 'ads_correct'  
                } 
                else if(result.video_id && this.id_youtube == result.video_id){
                  class_snapshot = 'url_correct'  
                }
                else if(result.channel_id && this.id_channel_youtube == result.channel_id){
                  class_snapshot = 'domain_correct'  
                }
                else if(data_rival){
                  class_snapshot = 'url_rival' 
                }     
              }
              else{
                let data_rival = this.array_domain.find(item => this.extractRootDomain(item) == this.extractRootDomain(url))
                let domain_match = false
                if(e.domain_match && e.domain_match.is_match && e.domain_match.data){
                  let data_domain_match = e.domain_match.data.find(item => item.rank_group == result.rank_group && item.rank_absolute == result.rank_absolute)
                  if(data_domain_match){
                    domain_match = true
                  }
                }

                let subdomain_match = false
                if(e.subdomain_match && e.subdomain_match.is_match && e.subdomain_match.data){
                  let data_subdomain_match = e.subdomain_match.data.find(item => item.rank_group == result.rank_group && item.rank_absolute == result.rank_absolute)
                  if(data_subdomain_match){
                    subdomain_match = true
                  }
                }

                if(result.type == 'paid'){ 
                  class_snapshot = 'ads_correct'  
                } 
                else if(
                  e.url_match && 
                  e.url_match.is_match &&
                  e.url_match.data &&
                  e.url_match.data.rank_group == result.rank_group &&
                  e.url_match.data.rank_absolute == result.rank_absolute
                ){
                  class_snapshot = 'url_correct'  
                }
                else if(subdomain_match){
                  class_snapshot = 'subdomain_correct' 
                }
                else if(domain_match){
                  class_snapshot = 'domain_correct' 
                }
                // else if(this.simplifyURL(domain) == this.simplifyURL(url)){
                //   class_snapshot = 'url_correct'  
                // }
                // else if(this.extractRootDomain(domain) == this.extractRootDomain(url)){
                //   class_snapshot = 'domain_correct'  
                // }
                else if(data_rival){
                  class_snapshot = 'url_rival' 
                }  
              } 
              
              array_url.push({
                url: url,
                url_name: (url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")),
                class_snapshot: class_snapshot
              })
            }
            else{
              let domain = result.domain ? result.domain : ''
              array_url.push({
                url: domain,
                url_name: domain,
                class_snapshot: 'snapshot'
              }) 
            }
          }
           
          this.table_body.push({
            url: e.url,
            url_name: e.url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ""),
            array_url: array_url
          })
            
          
        }
      },
      encodeDecodeURL(url) {
        if(!url){
          return ''
        }
        const parts = url.split('/');
        const decodedParts = parts.map(part => {
          try {
            // พยายามดีโค้ดแต่ละส่วนของ URL
            return decodeURIComponent(part);
          } catch {
            // หากเกิดข้อผิดพลาด, ส่งคืนส่วนนั้นๆ ออกมาเป็นเอนโค้ด
            return part;
          }
        });
        // รวมกลับเป็น URL ที่ดีโค้ดได้บางส่วนและเอนโค้ดบางส่วน
        return decodedParts.join('/');
      },
      simplifyURL(url) {
          // Remove the protocol (http, https) and "www"
          let simplified = url.replace(/^(https?:\/\/)?(www\.)?/, '');

          // Remove any query parameters
          simplified = simplified.split('?')[0];

          // Remove trailing slashes
          simplified = simplified.replace(/\/$/, '');

          return simplified;
      },
      extractChannelName(url) {
        let youtubePrefix = 'https://www.youtube.com/@';
        // ตรวจสอบว่า URL เริ่มต้นด้วย 'https://www.youtube.com/@'
        if (url.startsWith(youtubePrefix)) {
            let channelName = url.substring(youtubePrefix.length);
            return channelName.toLowerCase();
        } else {
            // ถ้าไม่ใช่รูปแบบที่กำหนด ให้ return ค่าว่าง
            return '';
        }
      },
      transformYoutubeUrl(url) {
        try {
            let urlObj = new URL(url);
            let videoId = urlObj.searchParams.get('v');
            // ตรวจสอบว่ามีพารามิเตอร์ 'v' หรือไม่
            if (videoId) {
                return `https://www.youtube.com/watch?v=${videoId}`;
            } else {
                return ''; // หรือ return ข้อความแสดงข้อผิดพลาดตามที่ต้องการ
            }
        } catch (e) {
            // หาก URL ไม่ถูกต้อง
            return ''; // หรือ return ข้อความแสดงข้อผิดพลาดตามที่ต้องการ
        }
      },
      extractRootDomain(url) {
        let domain = this.extractHostname(url),
          splitArr = domain.split('.'),
          arrLen = splitArr.length;

        //extracting the root domain here
        //if there is a subdomain 
        if (arrLen > 2) {
          domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1];
          //check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
          if (splitArr[arrLen - 2].length == 2 && splitArr[arrLen - 1].length == 2) {
            //this is using a ccTLD
            domain = splitArr[arrLen - 3] + '.' + domain;
          }
        }
        return domain;
      },
      extractHostname(url) {
        var hostname;
        //find & remove protocol (http, ftp, etc.) and get hostname

        if (url.indexOf("://") > -1) {
          hostname = url.split('/')[2];
        }
        else {
          hostname = url.split('/')[0];
        }

        //find & remove port number
        hostname = hostname.split(':')[0];
        //find & remove "?"
        hostname = hostname.split('?')[0];

        return hostname;
      },
      extractUrl(url) {
        var hostname;
        //find & remove protocol (http, ftp, etc.) and get hostname

        if (url.indexOf("://") > -1) {
          hostname = url.split('://')[1];
        }
        else {
          hostname = url.split('/')[0];
        }

        //find & remove port number
        hostname = hostname.split(':')[0];
        //find & remove "?"
        hostname = hostname.split('?')[0];

        return hostname;
      }
    }
  }
</script>
<style >

.justify-content-between{
  display: flex;
  justify-content: space-between;
}

/* === Fixed Table === */
/* .fix-table .fix-column {
    float: left;
} */
.fix-table .thead {
    height: 80px;
    white-space: nowrap;
	display: flex;
    /* justify-content: space-between; */
}
.fix-table .fix-column .thead {
    max-width: 100px;
}
.fix-table .fix-column .thead > span  {
     width: 100px;
	 text-align: left;
}
.fix-table .fix-column > .tbody > .trow > span {
	width: 100px;
    text-align: left;
    height: 40px;
    align-items: center;
    border: 2px solid #f1f1f1;
	border-top: 0;
	border-left: 0;
    padding: 8px;
    font-weight: bold;
	justify-content: flex-start;
	min-width: auto;
}
.fix-table .thead > span {
	display: inline-block;
    width: 200px;
    text-align: center;
    height: 40px;
    line-height: 44px;
    align-items: center;
    border: 2px solid #f1f1f1;
    padding: 8px;
    font-weight: bold;
}
.fix-table .trow {
    white-space: nowrap;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
            flex-direction: column;
	    width: 100%;
}
.fix-table .throw {
	white-space: nowrap;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
            flex-direction: column;
}
.fix-table .throw > span {
	width: 200px;
    text-align: left;
    height: 40px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid #f1f1f1;
    border-top: 0;
    border-left: 0;
    padding: 8px;
    font-weight: bold;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
            justify-content: flex-start;
}
.fix-table .thead .throw > span {
  width: 100px;
}
.fix-table .throw > span:first-child {
    /*border-top: 2px solid #f1f1f1;*/
}
.fix-table .trow > span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 200px;
    height: 40px;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
    -webkit-box-align: center;
    -ms-flex-align: center;
            align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	        justify-content: center;
    overflow: hidden;
    border: 2px solid #f1f1f1;
    border-top: 0;
    border-left: 0;
	line-height: 44px;
}
.fix-table .rest-columns .tbody .trow > span:not(:empty):hover {
	word-break: break-all;
    white-space: normal;
    overflow: initial;
    line-height: normal;
    padding: 0;
    border: 0;
}
.fix-table .rest-columns .tbody .trow > span:not(:empty):first-child:hover {
	-webkit-box-align: end;-ms-flex-align: end;align-items: end;
}
.fix-table .rest-columns .tbody .trow > span:not(:empty):hover > a,
.fix-table .rest-columns .tbody .trow > span:not(:empty):hover > p {
	text-decoration: none;
    background-color: #ffffff;
    box-shadow: #f1f1f1 -1px 1px, #f1f1f1 -2px 2px, #f1f1f1 -3px 3px, #f1f1f1 -4px 4px, #f1f1f1 -4px -2px, #f1f1f1 0px -2px, #f1f1f1 2px -2px, #f1f1f1 2px 2px;
    -webkit-transform: translate3d(4px, -4px, 0);
    transform: translate3d(4px, -4px, 0);
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
            transition-delay: 0s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
            transition-duration: 0.4s;
    -webkit-transition-property: all;
    -o-transition-property: all;
    transition-property: all;
    word-break: break-word;
    white-space: normal;
    height: auto;
	width: 100%;
    min-height: 40px;
    overflow: hidden;
    line-height: normal;
    padding: 8px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
            justify-content: center;
}
.app.theme--dark .fix-table .rest-columns .tbody .trow > span:not(:empty):hover > a,
.app.theme--dark .fix-table .rest-columns .tbody .trow > span:not(:empty):hover > p{
  background-color: #232425;
}

.app.theme--dark .fix-table .rest-columns .tbody .trow > span.url_correct:not(:empty):hover > p{
  background-color: #b0ffb7!important;
}

.app.theme--dark .fix-table .rest-columns .tbody .trow > span.domain_correct:not(:empty):hover > p{
  background-color: #babdff!important;
}

.app.theme--dark .fix-table .rest-columns .tbody .trow > span.subdomain_correct:not(:empty):hover > p{
  background-color: #99daff!important;
}

.app.theme--dark .fix-table .rest-columns .tbody .trow > span.ads_correct:not(:empty):hover > p{
  background-color: #fff8ae!important;
}

.app.theme--dark .fix-table .rest-columns .tbody .trow > span.url_rival:not(:empty):hover > p{
  background-color: #ffb3b3!important;
}

.fix-table .thead .trow > span:first-child {
    /*border-top: 2px solid #f1f1f1;*/
}
.fix-table .trow > span > a,
.fix-table .trow > span > p {
    text-overflow: ellipsis;
    overflow: hidden;
	margin: 0;
	font-size: inherit;
    line-height: inherit;
	white-space: nowrap;
    word-break: inherit;
}
.fix-table .tbody {
    max-height: -webkit-calc(100vh - 380px);
    max-height: -moz-calc(100vh - 380px);
    max-height: calc(100vh - 380px);
    overflow: auto;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.fix-table .fix-column > .tbody {
    overflow: hidden;
	overflow-x: scroll;
    overflow-y: hidden;
    /*padding-bottom: 50px;*/
}
/*.fix-column > .tbody > .trow:last-child {margin-bottom: 50px;}*/

.fix-table .fix-column > .tbody > .tr {
    margin-top: -50px;
    margin-bottom: 50px;
}
.fix-table .tbody > .tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
      width: 200px;
}
.fix-table .fix-column > .tbody > .trow:first-child {
    margin-top: 0px;
}

.fix-table .rest-columns {
    /*width: 350px;*/
    flex: 1;
    overflow: auto;
}
.fix-table .rest-columns .tbody {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow-y: overlay;
    -webkit-box-align: start;
    -ms-flex-align: start;
            align-items: flex-start;
    /* justify-content: space-between; */
}
/* .fix-table > .thead {
    margin-right: 50px!important;
} */
.fix-table .rest-columns > .thead {
    padding-right: 15px!important;
    overflow: hidden;
}
/*.rest-columns > .thead > :last-child {margin-right: 50px;}*/
.fix-table .rest-columns > .thead > span {
	/*
    margin-right: 50px;
    margin-left: -50px;
	*/
	border-left: 0;
	padding: 0;
    border: 0;
}
.fix-table .rest-columns > .thead > span:last-child span {
	border-right: 0;
}

.fix-table.total-wrapper {
  display: flex;
}
.fix-table .rest-columns > .thead > :first-child {margin-left: 0px;}
@media only screen and (max-width: 767px) {
	.fix-table.total-wrapper {
		font-size: 14px;
	}
	.fix-table .trow > span {
		height: 40px;
		min-width: 200px;
	}
	.fix-table .fix-column .thead {
		max-width: 100px;
	}
	.fix-table .thead {
		height: 80px;
	}
	.fix-table .throw > span {
		width: 100px;
		height: 40px;
	}
	.fix-table .tbody > .tr {
		min-width: 200px;
	}
	.fix-table .thead > span {
		min-width: 200px;
		height: 40px;
	}
	.fix-table .fix-column > .tbody > .trow > span {
		width: 100px;
		height: 40px;
	}
	.fix-table .tbody {
		max-height: -webkit-calc(100vh - 380px);
		max-height: -moz-calc(100vh - 380px);
		max-height: calc(100vh - 380px);
	}
  @media (max-width: 600px) {
    .fix-table .tbody {
      max-height: -webkit-calc(100vh - 315px);
      max-height: -moz-calc(100vh - 315px);
      max-height: calc(100vh - 315px);
    }
  }

	.reload_cache {
		right: 15px;
		bottom: 15px;
	}
}

.reload_cache {
	position: fixed;
  right: 30px;
  bottom: 30px;
  display: flex;
  align-items: center;
  opacity: 1;
}

.url_correct{
	background-color: #b0ffb7!important;
}
.url_correct a{
	color: #05720b!important;
}

.domain_correct{
	background-color: #babdff!important;
}
.domain_correct a{
	color: #111dff!important;
}

.subdomain_correct{
	background-color: #99daff!important;
}
.subdomain_correct a{
	color: #0a7ec2!important;
}

.ads_correct{
	background-color: #fff8ae!important;
}
.ads_correct a{
	color: #b27307!important;
}

.url_rival{
	background-color: #ffb3b3!important;
}
.url_rival a{
	color: #ab1111!important;
}
/* === Fixed Table ===*/


.text-alert-correct{
  max-width: 360px;
  overflow-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media only screen and (max-width: 480px) {
  .text-alert-correct{
    font-size: 0.8em;
  }
}
@media only screen and (max-width: 378px) {
  .text-alert-correct{
    font-size: 0.7em;
  }
}

.header_title{
  position: relative;
  display: inline;
}
.div-open{
  height: 60px;
}
.input_search{
  height: 60px;
  width: 200px;
}
@media only screen and (max-width: 500px) {
  .div_input_search{
    width: 100%;
  }
  .input_search{
    width: 100vw;
  }
}
.line-height-0{
  line-height: 0;
}
.select_total{
  height: 50px;
  width: 110px;
}

.v-select__selection--comma{
  text-overflow: inherit;
}

.loading_select_total .v-skeleton-loader__button{
  width: 200px;
  height: 40px;
}

.loading_card .v-skeleton-loader__image{
  max-height: -webkit-calc(100vh - 300px);
  max-height: -moz-calc(100vh - 300px);
  max-height: calc(100vh - 300px);
  height: 100vh;
}
@media only screen and (max-width: 600px) {
  .loading_card .v-skeleton-loader__image{
    max-height: -webkit-calc(100vh - 220px);
    max-height: -moz-calc(100vh - 220px);
    max-height: calc(100vh - 220px);
    height: 100vh;
  }
}

</style>      

