<template>
  <v-dialog 
  scrollable 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  transition="dialog-bottom-transition"
  >
    <v-card rounded class="d-flex flex-column dialog-card">
      <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
        <div>
          {{ $t('table_lighthouse') }}
        </div>

        <div>
          <v-tooltip top class="">
            <template v-slot:activator="{ on }">
              <v-btn
                @click.stop="refreshData()" 
                v-on="on"
                fab 
                small 
                text
                color="grey"
                class="no-caps border-radius-circle"
                :loading="loading_refresh" 
                :disabled="loading_refresh"
              >
                <v-icon size="20">mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('refresh') }}</span>
          </v-tooltip>

          <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
            <v-icon size="24">mdi-close</v-icon>
          </v-btn>
        </div>

      </v-card-title>
      <v-divider></v-divider>
      <v-card-text rounded :class="'ma-0 pl-5 pt-0 pb-5 ' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">

        <div class="mt-2">
          <v-skeleton-loader
            v-if="loading"
            class="mx-auto table_loading"
            type="table-thead,table-tbody"
          ></v-skeleton-loader>
        </div>
        <div v-if="!loading && !desserts_table.length" class="pa-4 d-flex justify-center" style="height: 65vh;">
          <h2 class="ma-auto">{{ $t('no_data') }}</h2>
        </div>
        <div v-if="!loading && desserts_table.length" class="mt-2">
          <v-data-table
            rounded
            :mobile-breakpoint="0"
            class="elevation-1 table-lighthouse"
            :headers="headers_table"
            :items="desserts_table"
            :items-per-page="-1"  
            :disable-pagination="true"
            @update:sort-by="updateSortBy"
            @update:sort-desc="updateSortDesc"
            :header-props="{
                'sort-icon': (sortedDesc ? 'mdi-arrow-up' : 'mdi-arrow-down')
            }"
            :footer-props="{
              itemsPerPageText: $t('rows_per_page'),
              itemsPerPageOptions: [10, 25, 50, 100, 250, { text: $t('all'), value: -1 }],  
              itemsPerPageAllText: $t('all'),
              pageText: $t('page_of'),
              'show-current-page': false,
              'show-first-last-page': false,
              'disable-items-per-page': true,
              'disable-pagination': true,
              firstIcon: 'mdi-chevron-double-left',
              lastIcon: 'mdi-chevron-double-right',
              prevIcon: 'mdi-chevron-left',
              nextIcon: 'mdi-chevron-right'
            }"
          >

          <template 
          :slot="'header.'+ item.value" 
          slot-scope="props" 
          v-for="(item, index) in headers_table"
          >
            <div 
              v-if="item" 
              :key="index"
              :class="'text-center ' + [(!sortedBy || sortedBy != item.value ? 'hide-icon' : '')]"
            >   
              <span class="">
                <span>
                  {{ item.text }}
                </span>
              </span>
            </div>
          </template>

          <template :slot="'item.' + item.value" slot-scope="props" v-for="(item, index) in headers_table">
            <div 
              v-if="item" 
              :key="'col_' + index"
            >
              <div v-if="item.type == 'date' && props.item[item.value]" class="text-left">
                {{ moment(props.item[item.value]).tz($auth.user().timezone).format('DD/MM/YYYY H:mm') }}
              </div>
              <div v-else-if="item.type == 'status' && props.item[item.value]" class="text-center">
                <span>
                {{ $t(props.item[item.value]) }} 
                </span>
              </div>
              <div v-else-if="item.type == 'number' && props.item[item.value]" class="text-center">
               <span class="">
                 {{ props.item[item.value] ? formatNumber(props.item[item.value]) : 0  }}
               </span>
              </div>
              <div v-else-if="item.type == 'summary' && props.item[item.value]" class="text-center">
                <v-tooltip top class="">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click.stop="openIframe(props.item[item.value])" 
                      v-on="on"
                      icon
                      small
                      class=""
                      color="primary"
                    >
                      <v-icon>mdi-web</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('report') }}</span>
                </v-tooltip>
              </div>
              <div v-else-if="item.type == 'paths' && props.item[item.value]" class="text-left" style="width: 300px;">
                <div class="d-flex flex-wrap"> 
                  <v-chip
                    v-for="(chip, index_chip) in JSON.parse(props.item[item.value])" :key="'chip_' + index_chip"
                    class="ma-2 custom-chip"
                    color="primary"
                    @click.stop="openIframe(chip.summary)" 
                  >
                    {{ chip.path ? chip.path.trim() : 'home' }}
                  </v-chip>
                </div> 
              </div>
              <div v-else class="text-center">
                {{ props.item[item.value] ? props.item[item.value] : '-' }}
              </div>
            </div>
          </template>
          </v-data-table>
        </div>
      </v-card-text>
      
    </v-card>
  </v-dialog>
</template>
<script>
 
  export default {
    data: () => ({
      loading: false,
      loading_refresh: false,
      dialog: false,
      resolve: null,
      reject: null,
      uuid: null,
      type: null,
      site: null,
      headers_table: [],
      desserts_table: [],
      sortedBy: null,
      sortedDesc: false,
      has_scrollbar: false,
      options: {
        color: 'primary',
        width: 1024,
        zIndex: 200
      }
    }),
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
        }
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      encodeDecodeURL(url) {
        if(!url){
          return ''
        }
        const parts = url.split('/');
        const decodedParts = parts.map(part => {
          try {
            // พยายามดีโค้ดแต่ละส่วนของ URL
            return decodeURIComponent(part);
          } catch {
            // หากเกิดข้อผิดพลาด, ส่งคืนส่วนนั้นๆ ออกมาเป็นเอนโค้ด
            return part;
          }
        });
        // รวมกลับเป็น URL ที่ดีโค้ดได้บางส่วนและเอนโค้ดบางส่วน
        return decodedParts.join('/');
      },
      updateSortBy(newSortBy) {
        this.sortedBy = newSortBy;
      },
      updateSortDesc(newSortDesc) {
        this.sortedDesc = newSortDesc;
      },
      initTable(data){
        let headers = [
        {
            text: this.$t('id'),
            value: 'request_id',
            type: 'text'
          },
          {
            text: this.$t('status'),
            value: 'status',
            type: 'status'
          },
          {
            text: this.$t('percentage'),
            value: 'percentage',
            type: 'number'
          },
          {
            text: this.$t('done_inspect_tasks'),
            value: 'done_inspect_tasks',
            type: 'number'
          },
          {
            text: this.$t('done_lighthouse_tasks'),
            value: 'done_lighthouse_tasks',
            type: 'number'
          },
          {
            text: this.$t('total_files'),
            value: 'total_files',
            type: 'number'
          },
          {
            text: this.$t('total_inspect_tasks'),
            value: 'total_inspect_tasks',
            type: 'number'
          },
          {
            text: this.$t('uploaded_files'),
            value: 'uploaded_files',
            type: 'number'
          },
        ]

        if(this.type == 'url'){
          headers.push({
              text: this.$t('paths'),
              value: 'paths',
              type: 'paths'
          })
        }
        else{
          headers.push({
              text: this.$t('summary'),
              value: 'summary',
              type: 'summary'
          })
        }
        headers.push({
            text: this.$t('created_at'),
            value: 'created_at',
            type: 'date'
        })
       
        this.headers_table = headers
        this.desserts_table = data
        
        let that = this
        setTimeout(function(){
          that.$nextTick(() => {
            that.checkForScrollbar();
          });
        },100)
      },
      getData(){
        this.loading = true
        this.axios.post('/lighthouse/log', {
          enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
          uuid: this.uuid,
        })
        .then(res => {
          if (res.data.status === 'success') {
            this.site = res.data.site
            this.type = res.data.type
            this.initTable(res.data.data)
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading = false)
      },
      refreshData(){
        this.loading_refresh = true
        this.axios.post('/lighthouse/sitemap', {
          enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
          uuid: this.uuid,
        })
        .then(res => {
          if (res.data.status === 'success') {
            this.$root.$snackbar(this.$t('data_up_to_date'))
            this.getData()
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading_refresh = false)
      },
      openIframe(url){
        //console.log(url)
        this.$root.$dialog_iframe(url,this.site)
      },
      open(uuid) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.uuid = uuid
        this.getData()
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false
        })
        this.dialog = false
      }
    }
  }
</script>
<style>
.url-in-table{
  max-width: 360px;
  overflow-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.td-chip{
  max-width: 360px;
}
.v-data-table.table-lighthouse{
  box-shadow: none !important;
}
.table-lighthouse .v-data-footer{
  display: none;
}
.table-lighthouse thead tr th span{
  padding: 0;
}
</style>

