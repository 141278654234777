<template>
  <v-dialog 
  scrollable 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  transition="dialog-bottom-transition"
  >
    <v-card class="dialog-card">
      <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
        <div>
        {{ $t('show_hide_action') }}
        </div>
        <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text rounded :class="'ma-0 pb-5 pt-5 pl-5 ' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">
        <v-tabs 
            v-model="tab"
            :color="($store.state.app.dark) ? 'white' : 'black'"
            class="content-tabs mb-3"
            background-color="transparent"
            fixed-tabs
            show-arrows
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab class="no-caps" :href="'#keyword'" :ripple="false">
              {{ $t('keyword') }}
            </v-tab>
            <v-tab class="no-caps" :href="'#order'" :ripple="false">
              {{ $t('order') }}
            </v-tab>
            <v-tab class="no-caps" :href="'#lighthouse'" :ripple="false">
              {{ $t('lighthouse') }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" :touchless="false">
            <v-tab-item :transition="false" :reverse-transition="false" :value="'keyword'">
              <v-card v-for="(item_action,index) in action_keyword" :key="index">
                <v-card-title v-if="item_action.length">{{ $t(index) }}</v-card-title>
                <v-card-text v-if="item_action.length">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" style="width: 50px;">
                            {{ $t('show_hide') }}
                          </th>
                          <th class="text-center" style="width: 150px;">
                            {{ $t('actions') }}
                          </th>
                          <th class="text-center" style="width: 300px;">
                            {{ $t('description') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in item_action"
                          :key="item.name"
                        >
                          <td class="text-center" style="width: 50px;">
                            <div class="d-flex justify-center">
                              <v-switch
                                v-model="item.active"
                                :ref="'ref_'+item.name"
                                :id="'id_'+ item.name"
                                inset
                                dense
                                :ripple="false"
                                :name="item.name"
                                :persistent-hint="true"
                              />
                            </div>
                          </td>
                          <td class="text-left" style="width: 150px;white-space: pre-wrap;">
                            <v-btn class="mr-1 mt-0 mb-0" color="accent" icon small >
                              <v-icon small>{{ item.icon }}</v-icon> 
                            </v-btn>
                            {{ $t(item.name) }}
                          </td>
                          <td class="text-left" style="width: 300px;white-space: pre-wrap;">{{ $t(item.description) }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
              
            </v-tab-item>
            <v-tab-item :transition="false" :reverse-transition="false" :value="'order'">
              <v-card v-for="(item_action,index) in action_order" :key="index">
                <v-card-title v-if="item_action.length">{{ $t(index) }}</v-card-title>
                <v-card-text v-if="item_action.length">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" style="width: 50px;">
                            {{ $t('show_hide') }}
                          </th>
                          <th class="text-center" style="width: 150px;">
                            {{ $t('actions') }}
                          </th>
                          <th class="text-center" style="width: 300px;">
                            {{ $t('description') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in item_action"
                          :key="item.name"
                        >
                          <td class="text-center" style="width: 50px;">
                            <div class="d-flex justify-center" >
                              <v-switch
                                v-model="item.active"
                                :ref="'ref_'+item.name"
                                :id="'id_'+ item.name"
                                inset
                                dense
                                :ripple="false"
                                :name="item.name"
                                :persistent-hint="true"
                              />
                            </div>
                          </td>
                          <td class="text-left" style="width: 150px;white-space: pre-wrap;">
                            <v-btn class="mr-1 mt-0 mb-0" color="accent" icon small >
                              <v-icon small>{{ item.icon }}</v-icon>
                            </v-btn>
                            {{ $t(item.name) }}
                          </td>
                          <td class="text-left" style="width: 300px;white-space: pre-wrap;">{{ $t(item.description) }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
              
            </v-tab-item>
            <v-tab-item :transition="false" :reverse-transition="false" :value="'lighthouse'">
              <v-card v-for="(item_action,index) in action_lighthouse" :key="index">
                <v-card-title v-if="item_action.length">{{ $t(index) }}</v-card-title>
                <v-card-text v-if="item_action.length">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" style="width: 50px;">
                            {{ $t('show_hide') }}
                          </th>
                          <th class="text-center" style="width: 150px;">
                            {{ $t('actions') }}
                          </th>
                          <th class="text-center" style="width: 300px;">
                            {{ $t('description') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in item_action"
                          :key="item.name"
                        >
                          <td class="text-center" style="width: 50px;">
                            <div class="d-flex justify-center" >
                              <v-switch
                                v-model="item.active"
                                :ref="'ref_'+item.name"
                                :id="'id_'+ item.name"
                                inset
                                dense
                                :ripple="false"
                                :name="item.name"
                                :persistent-hint="true"
                              />
                            </div>
                          </td>
                          <td class="text-left" style="width: 150px;white-space: pre-wrap;">
                            <v-btn class="mr-1 mt-0 mb-0" color="accent" icon small >
                              <v-icon small>{{ item.icon }}</v-icon>
                            </v-btn>
                            {{ $t(item.name) }}
                          </td>
                          <td class="text-left" style="width: 300px;white-space: pre-wrap;">{{ $t(item.description) }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
              
            </v-tab-item>
          </v-tabs-items>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-5 ma-0 ">
        <v-spacer></v-spacer>
        <!-- <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn> -->
        <v-btn color="primary" rounded depressed @click.native="updateSetting" :loading="loading" :disabled="loading" class="no-caps">{{ $t('save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>  
<script>
  /**
   * Vuetify ChangeAction Dialog component
   *
   * Insert component where you want to use it:
   * <ChangeAction ref="ChangeAction"></ChangeAction>
   *
   * Call it:
   * this.$refs.ChangeAction.open('Delete', 'Are you sure?', { color: 'red' }).then((ChangeAction) => {})
   * Or use await:
   * if (await this.$refs.ChangeAction.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$ChangeAction
   * <template>
   *   <v-app>
   *     ...
   *     <ChangeAction ref="ChangeAction"></ChangeAction>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$ChangeAction = this.$refs.ChangeAction.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      loading: false,
      resolve: null,
      reject: null,
      tab: 'keyword',
      uuid: null,
      action_keyword: [],
      action_order: [],
      action_lighthouse: [],
      has_scrollbar: false,
      options: {
        color: 'primary',
        width: 1024,
        zIndex: 200
      } 
    }),
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
        }
      },
      open(uuid,json_setting_action) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.uuid = uuid
        this.action_keyword = json_setting_action.keyword
        this.action_order = json_setting_action.order
        this.action_lighthouse = json_setting_action.lighthouse
        let that = this
        setTimeout(function(){
          that.$nextTick(() => {
            that.checkForScrollbar();
          });
        },100)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      updateSetting(){
        this.loading = true
        let disable_action_keyword = {}
        for(let i in this.action_keyword){
          disable_action_keyword[i] = []
          for(let e of this.action_keyword[i]){
            //console.log(e.active)
            if(!e.active){
              disable_action_keyword[i].push(e.name)
            }
          }
          
        }
        let disable_action_order = {}
        for(let i in this.action_order){
          disable_action_order[i] = []
          for(let e of this.action_order[i]){
            if(!e.active){
              disable_action_order[i].push(e.name)
            }
          }
        }
        let disable_action_lighthouse = {}
        for(let i in this.action_lighthouse){
          disable_action_lighthouse[i] = []
          for(let e of this.action_lighthouse[i]){
            if(!e.active){
              disable_action_lighthouse[i].push(e.name)
            }
          }
        }
        //console.log(this.action_keyword)
        //console.log(disable_action_keyword)
        //console.log(disable_action_order)

        this.axios.post('/admin/user/update/button-action', {
          uuid: this.uuid,
          disable_action_keyword: JSON.stringify(disable_action_keyword),
          disable_action_order: JSON.stringify(disable_action_order),
          disable_action_lighthouse: JSON.stringify(disable_action_lighthouse),
        })
        .then(res => {
          if (res.data.status === 'success') {
            //console.log(res.data)
            this.$root.$refs.master.enabledScroll()
            this.resolve({
              confirm: true,
            })
            this.dialog = false
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading = false)
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>