<template>
  <v-dialog 
  scrollable 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  transition="dialog-bottom-transition"
  >
      <v-card rounded class="dialog-card">
        <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
          <div>
            {{ $t('provider_service') }} {{ providers.providers_name }}
          </div>
          
          <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
            <v-icon size="24">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text rounded :class="'ma-0 pb-5 pt-3 pl-5 ' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">
          
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    <v-checkbox
                      v-model="select_all"
                      @change="selectAll"
                      :label="$t('select_all')"
                    ></v-checkbox>
                  </th>
                  <th class="text-left">
                    {{ $t('code') }}
                  </th>
                  <th v-for="(lang, index) in langs" :key="index" 
                    class="text-left">
                    {{ $t('description') }} {{ $t(lang) }}
                  </th>
                  <th class="text-right">
                    {{ $t('min_qty') }}
                  </th>
                  <th class="text-right">
                    {{ $t('price') }}
                  </th>
                  <th class="text-center">
                    {{ $t('price_buy') }}
                  </th>
                  <th class="text-center">
                    {{ $t('currency') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in services"
                  :key="item.id"
                  :class="item.class_error"
                >
                  <td class="text-center">
                    <v-checkbox
                      v-model="selecte_service"
                      @change="selectService(item.id)"
                      :value="item.id"
                    ></v-checkbox>
                  </td>
                  <td class="text-left">{{ item.code }}</td>
                  <td v-for="(lang, index) in langs" :key="index" class="text-left pre-line">
                    <x-text-area
                      v-model="item['description_'+lang]"
                      rules="required"
                      :label="$t('description') + $t(lang)"
                      filled
                    ></x-text-area>
                  </td>
                  <td class="text-right">{{ item.min_qty }}</td>
                  <td class="text-right">{{ item.price + ' ' + providers.providers_currency }}</td>
                  <td>
                    <x-text-field
                      v-model="item.price_buy"
                      rules="required|between:1,100000"
                      type="number"
                      :label="$t('price_buy')"
                      filled
                    ></x-text-field>
                  </td>
                  <td class="text-center">
                    {{ currency }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>
        
        <v-divider></v-divider>
        <v-card-actions class="pa-5 ma-0 ">
          <v-spacer></v-spacer>
          <!-- <v-btn color="grey" text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn> -->
          <v-btn 
            color="primary" 
            depressed 
            @click.native="saveService" 
            class="no-caps"
            :loading="loading" :disabled="loading"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify SelectServicesProviders Dialog component
   *
   * Insert component where you want to use it:
   * <SelectServicesProviders ref="SelectServicesProviders"></SelectServicesProviders>
   *
   * Call it:
   * this.$refs.SelectServicesProviders.open('Delete', 'Are you sure?', { color: 'red' }).then((SelectServicesProviders) => {})
   * Or use await:
   * if (await this.$refs.SelectServicesProviders.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$SelectServicesProviders
   * <template>
   *   <v-app>
   *     ...
   *     <SelectServicesProviders ref="SelectServicesProviders"></SelectServicesProviders>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$SelectServicesProviders = this.$refs.SelectServicesProviders.open
   * }
   */
  export default {
    data: () => ({
      locale: 'th',
      loading: false,
      dialog: false,
      resolve: null,
      reject: null,
      select_all: false,
      selecte_service: [],
      langs: [],
      currency: null,
      services: null,
      has_scrollbar: false,
      providers: {
        providers_name: null
      },
      options: {
        color: 'primary',
        width: 1400,
        zIndex: 200
      }
    }),
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
        }
      },
      open(services,providers,currency,lang) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.langs = lang
        this.currency = currency
        //console.log(services)
        this.providers = providers
        let array = services.map((item) => {
          //console.log(item)
          item.price = parseFloat(item.price)/100
          item.price_buy = parseFloat(item.price)*(1.2)
          for(let lang of this.langs){
            item['description_'+lang] = item.description
          }
          return item
        })
        // console.log(array)
        this.services = array
        let that = this
        setTimeout(function(){          
          that.$nextTick(() => {
            that.checkForScrollbar();
          });
        },100)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      formatNumber (number) {
        return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
      },
      formatCurrency (number) {
        return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: this.business.currency}).format(number)
      },
      formatDate (dt, format) {
        if (dt === null) {
          dt = '-'
        } else {
          dt = (format == 'ago') ? this.moment(dt).fromNow() : this.moment(dt).tz(this.$auth.user().timezone).format(format)
        }
        return dt
      },
      selectAll(){
        //console.log(this.select_all)
        //console.log(this.selecte_service)
        if(this.select_all){
          let selecte = [] 
          for(let e of this.services){
            selecte.push(e.id)
          }
          this.selecte_service = selecte
        }
        else{
          this.selecte_service = []
        }
      },
      selectService(id){
        console.log(id)
      },
      saveService() {
        if(!this.selecte_service.length){
          this.$root.$snackbar(this.$t('alert_select'))
          return false
        }
        let chk = true
        let array_service = []
        for(let e of this.selecte_service){
            this.services = this.services.map((item) => {
              if(e == item.id){
                item.class_error = ''
                
                for(let lang of this.langs){
                  if(!item['description_'+lang] || !item['description_'+lang]){
                    chk = false
                    item.class_error = 'tr_error'
                  }
                }

                if(!item.price_buy){
                  chk = false
                  item.class_error = 'tr_error'
                }

                array_service.push(item)
              }
              return item
            })
        }
        if(!chk){
          this.$root.$snackbar(this.$t('invalid_value'))
          return false
        }
        this.$root.$confirm(this.$t('confirm'), this.$t('confirm_save_service'),{
          color: 'primary',
          width: 360,
          zIndex: 200
        },false)
        .then((confirm) => {
          if (confirm.confirm) {
            this.loading = true
            this.axios.post('/admin/provider/add-service', {
              uuid: this.providers.providers_uuid,
              array_service: JSON.stringify(array_service)
            })
            .then(res => {
              if (res.data.status === 'success') {
                this.$root.$refs.master.enabledScroll()
                this.resolve({
                  confirm: true,
                })
                this.dialog = false
              }
            })
            .catch(err => {
              if (err.response.data.status === 'error') {
                this.$root.$snackbar(err.response.data.msg)
              }
            })
            .finally(() => this.loading = false)
          }
        })
        
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>