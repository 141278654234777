<template>
  <v-dialog 
  scrollable 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  transition="dialog-bottom-transition"
  >
    <v-card rounded class="dialog-card">
      <v-card-title class="d-block ma-0 pa-0">
        <div class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
          <div>
            {{ $t('language_currency') }}
          </div>
          <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
            <v-icon size="24">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-divider></v-divider>
        <div v-if="has_languages && has_currencies" class="pt-5 pl-5 pr-5 pb-5 ma-0">
          <v-tabs 
            v-model="tab"
            :color="($store.state.app.dark) ? 'white' : 'black'"
            class="content-tabs"
            background-color="transparent"
            fixed-tabs
            show-arrows
          >
            <v-tabs-slider></v-tabs-slider> 
            <v-tab v-if="has_languages" class="no-caps" :href="'#language'" :ripple="false">
              {{ $t('language') }}
            </v-tab>
            <v-tab v-if="has_currencies" class="no-caps" :href="'#currency'" :ripple="false">
              {{ $t('currency') }}
            </v-tab>
          </v-tabs>   
        </div>
        <div class="pt-0 pl-5 pr-5 pb-0 d-flex justify-end">
          <x-text-field
            v-if="tab == 'language'"
            v-model="search_language"
            class="search-input"
            width="100%"
            :placeholder="$t('search')"
            filled
            clearable
            @input="searchLanguage"
          ></x-text-field>
          <x-text-field
            v-if="tab == 'currency'"
            v-model="search_currency"
            class="search-input"
            width="100%"
            :placeholder="$t('search')"
            filled
            clearable   
            @input="searchCurrency"
          ></x-text-field>
        </div>
      </v-card-title>
      
      <v-card-text rounded :class="'ma-0 pl-5 pt-0 pb-5 ' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">             
        <v-tabs-items  rounded v-model="tab" :touchless="false">
          <v-tab-item v-if="has_languages" class="ma-0 pa-0" rounded :transition="false" :reverse-transition="false" :value="'language'">
            <v-row>
              <v-col 
              cols="6"  
              lg="2"
              md="3"
              sm="4"
              v-for="(item, index) in languages" :key="index">
                <v-card
                  @click="activeLanguage(item)"
                  depressed 
                  large 
                  outlined
                  class="pa-5" 
                  :color="(item.code == language) ? ($vuetify.theme.dark ? 'black' : 'grey darken-4') : ''"
                  :dark="$vuetify.theme.dark ? ((item.code == language) ? false : true) : ((item.code == language) ? true : false)"
                >
                  <div class="name-language pl-1"><b>{{ item.name }}</b></div> 
                  <div class="name-language pl-1">{{ item.country_name }}</div>
                </v-card>
              </v-col>
            </v-row> 
            
            
            <div v-if="!languages.length" class="pa-0 d-flex justify-center" style="height: 250px;">
              <h2 class="ma-auto">{{ $t('no_data') }}</h2>
            </div>
          </v-tab-item>
          <v-tab-item v-if="has_currencies" class="ma-0 pa-0" rounded :transition="false" :reverse-transition="false" :value="'currency'"> 
            <v-row>
              <v-col 
              cols="6"  
              lg="2"
              md="3"
              sm="4"
              v-for="(item, index) in currencys" :key="index">
                <v-card
                  @click="activeCurency(item)"
                  depressed 
                  large 
                  outlined
                  class="pa-2" 
                  :color="(item.alpha3 == currency) ? ($vuetify.theme.dark ? 'black' : 'grey darken-4') : ''"
                  :dark="$vuetify.theme.dark ? ((item.alpha3 == currency) ? false : true) : ((item.alpha3 == currency) ? true : false)"
                >
                  <div class="name-currency pl-1"><b>{{ item.langEN }}</b></div>
                  <div class="pl-1">{{item.alpha3}} - {{ hexToSymbols(item.symbol_hex) }}</div>
                  <div class="pl-1">1 $ = {{ formatNumber(item.rate) }} {{ hexToSymbols(item.symbol_hex) }}</div>
                </v-card>
              </v-col>
            </v-row> 
            <div v-if="!currencys.length" class="pa-0 d-flex justify-center" style="height: 250px;">
              <h2 class="ma-auto">{{ $t('no_data') }}</h2>
            </div>
          </v-tab-item>
        </v-tabs-items>
      
          
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="ma-0 pa-5">
        <v-spacer></v-spacer>
        <!-- <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn> -->
        <v-btn color="primary" rounded depressed @click.native="updateSetting" :loading="loading" :disabled="loading" class="no-caps">{{ $t('save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>  
<script>
  /**
   * Vuetify ChangeLanguagesCurrency Dialog component
   *
   * Insert component where you want to use it:
   * <ChangeLanguagesCurrency ref="ChangeLanguagesCurrency"></ChangeLanguagesCurrency>
   *
   * Call it:
   * this.$refs.ChangeLanguagesCurrency.open('Delete', 'Are you sure?', { color: 'red' }).then((ChangeLanguagesCurrency) => {})
   * Or use await:
   * if (await this.$refs.ChangeLanguagesCurrency.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$ChangeLanguagesCurrency
   * <template>
   *   <v-app>
   *     ...
   *     <ChangeLanguagesCurrency ref="ChangeLanguagesCurrency"></ChangeLanguagesCurrency>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$ChangeLanguagesCurrency = this.$refs.ChangeLanguagesCurrency.open
   * }
   */
  // import languages from '@/json/language.json'
  // import currencys from '@/json/currency.json'
  export default {
    data: () => ({
      locale: 'en',
      dialog: false,
      loading: false,
      resolve: null,
      reject: null,
      tab: 'language',
      search_language: null,
      search_currency: null,
      language: null,
      currency: null,
      languages: [],
      currencys: [],
      array_languages: [],
      array_currencies: [],
      has_languages: false,
      has_currencies: false,
      has_scrollbar: false,
      options: {
        color: 'primary',
        width: 1200,
        zIndex: 200
      } 
    }),
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
          //console.log(this.has_scrollbar)
          // const hasHorizontalScrollbar = myDiv.scrollWidth > myDiv.clientWidth;

          // console.log('Has vertical scrollbar:', hasVerticalScrollbar);
          // console.log('Has horizontal scrollbar:', hasHorizontalScrollbar);
        }
      },
      formatNumber (number) {
        return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
      },
      open(language,currency,array_languages,array_currencies,array_rate_currency,has_languages,has_currencies) {
        //console.log(array_languages)
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.has_languages = has_languages
        this.has_currencies = has_currencies
        this.search_language = null
        this.search_currency = null
        this.tab = 'language'
        this.array_languages = array_languages
        this.languages = array_languages
        if(array_rate_currency){
          this.array_currencies = array_currencies.filter(item => {
            if(array_rate_currency[item.alpha3]){
              item.rate = array_rate_currency[item.alpha3]
              return item
            }
          });
        }
        else{
          this.array_currencies = array_currencies
        }
        this.currencys = this.array_currencies
        
        this.language = language
        this.setLanguage(language)
        this.currency = currency
        this.setCurency(currency)
        let that = this
        setTimeout(function(){
          that.$nextTick(() => {
            that.checkForScrollbar();
          });
        },100)
        
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      searchLanguage(){
        if(this.search_language){
          let query = this.search_language.toLowerCase();
          this.languages = this.array_languages.filter(item => 
            Object.values(item).some(value => 
              typeof value === 'string' && value.toLowerCase().includes(query)
            )
          );

        }
        else{
          this.languages = this.array_languages
        }
        this.$nextTick(() => {
          this.checkForScrollbar();
        });
      },
      searchCurrency(){
        if(this.search_currency){
          let query = this.search_currency.toLowerCase();
          this.currencys = this.array_currencies.filter(item => 
            Object.values(item).some(value => 
              typeof value === 'string' && value.toLowerCase().includes(query)
            )
          );
        }
        else{
          this.currencys = this.array_currencies
        }
        this.$nextTick(() => {
          this.checkForScrollbar();
        });
      },
      hexToSymbols(hexString) {
        // แยกสตริงออกเป็นส่วนๆ โดยใช้เครื่องหมายเซมิโคลอน (;)
        const hexParts = hexString.split(';');
        
        // แปลงแต่ละส่วนจากเลขฐานสิบหกเป็นตัวอักษร และรวมกันเป็นสตริงเดียว
        const symbols = hexParts.map(part => String.fromCharCode(parseInt(part, 16))).join('');
        
        return symbols;
      },
      setLanguage(language){
        // Find the index of the object with the matching locale
        const index = this.languages.findIndex(item => item.code === language);

        if (index > -1) {
            // Add active = true to the object
            //this.languages[index].active = true;

            // Remove the object from its current position
            const [item] = this.languages.splice(index, 1);

            // Insert the object at the beginning of the array
            this.languages.unshift(item);
        }
      },
      setCurency(code){
        // Find the index of the object with the matching locale
        const index = this.currencys.findIndex(item => item.alpha3 === code);

        if (index > -1) {
            // Add active = true to the object
            //this.currencys[index].active = true;

            // Remove the object from its current position
            const [item] = this.currencys.splice(index, 1);

            // Insert the object at the beginning of the array
            this.currencys.unshift(item);
        }
      },
      activeLanguage(item_language){
        //console.log(item_language.code)
        this.language = item_language.code
        if(this.has_currencies){
          this.currency = item_language.currency_code[0]
          this.search_currency = null
          this.currencys = this.array_currencies
          this.setCurency(this.currency)
        }
      },
      activeCurency(item_currency){
        this.currency = item_currency.alpha3
      },
      updateSetting(){
        if(this.has_currencies){
          this.loading = true
          this.axios.post('/auth/update/currency', {
            currency: this.currency,
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              this.$root.$refs.master.enabledScroll()
              this.resolve({
                confirm: true,
                language: this.language
              })
              this.dialog = false
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        else{
          this.$root.$refs.master.enabledScroll()
          this.resolve({
            confirm: true,
            language: this.language
          })
          this.dialog = false
        }

      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>
<style >
.div_search{
  height:50px;
  width: 100%;
}
.name-currency{
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.name-language{
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.custom-text-field{
  margin: 0 !important;
}
.search-input{
    height: 75px;
}
@media (max-width: 600px) {
  .search-input{
    width: 100%;
  }
}
</style>