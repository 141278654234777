<template>
  <v-dialog 
  scrollable 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @click:outside="cancel" 
  v-model="dialog"
  :max-width="options.width" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  transition="dialog-bottom-transition"
  >
    <v-card rounded class="d-flex flex-column dialog-card">
      <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
        <div>
          {{ $t('report_keyword_all') }}
        </div>
        <div class="d-flex">
          <x-select
            class="input-search mr-2"
            v-model="year"
            :items="item_year"
            item-text="name"
            item-value="value"
            @change="getData"
            filled
          ></x-select>
          <div class="ma-auto">
            <!-- <v-btn
              color="grey"
              text
              class="no-caps"
              @click="CopyReport()"
            >
              <v-icon>mdi-content-copy</v-icon>
              {{ $t('copy') }}
            </v-btn> -->

            <v-tooltip top class="">
              <template v-slot:activator="{ on }">
                <v-btn
                  @click.stop="CopyReport()" 
                  v-on="on"
                  fab 
                  small 
                  text
                  color="grey"
                  class="no-caps border-radius-circle"
                >
                  <v-icon size="20">mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('copy') }}</span>
            </v-tooltip>

            <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
              <v-icon size="24">mdi-close</v-icon>
            </v-btn>
          </div>
          
        </div>
        
        
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text rounded :class="'ma-0 pl-5 pt-5 pb-5' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">
        <v-tabs 
          v-if="has_tab"
          v-model="tab_rank"
          :color="($store.state.app.dark) ? 'white' : 'black'"
          class="content-tabs"
          background-color="transparent"
          fixed-tabs
          show-arrows
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab v-if="has_all" @click="getDataRank('all')" href="#all" class="no-caps" :ripple="false">
            {{ $t('ranking') }} {{ $t('all') }}
          </v-tab>
          <v-tab v-if="has_rank_group" @click="getDataRank('rank_group')" href="#rank_group" class="no-caps" :ripple="false">
            {{ $t('ranking') }} {{ $t('url') }}
          </v-tab>
          <v-tab v-if="has_rank_absolute" @click="getDataRank('rank_absolute')" href="#rank_absolute" class="no-caps" :ripple="false">
            {{ $t('ranking_ads') }} {{ $t('url') }}
          </v-tab>
          <v-tab v-if="has_rank_group_domain" @click="getDataRank('rank_group_domain')" href="#rank_group_domain" class="no-caps" :ripple="false">
            {{ $t('ranking') }} {{ $t('best_website') }}
          </v-tab>
          <v-tab v-if="has_rank_absolute_domain" @click="getDataRank('rank_absolute_domain')" href="#rank_absolute_domain" class="no-caps" :ripple="false">
            {{ $t('ranking_ads') }} {{ $t('best_website') }}
          </v-tab>
        </v-tabs>

        <v-skeleton-loader
          v-if="loading"
          class="mx-auto mt-3"
          type="table-thead, table-tbody"
        ></v-skeleton-loader>
        
        <v-simple-table v-if="!loading" class="mt-3">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">{{ $t('status') }}</th>
                <th class="text-center">{{ $t('url') }}</th>
                <th class="text-center">{{ $t('keyword') }}</th>
                <th class="text-center">{{ $t('search_engine') }}</th>
                <th class="text-center">{{ $t('locale') }}</th>
                <th class="text-center">{{ $t('language') }}</th>
                <th class="text-center">{{ $t('device') }}</th>
                <th class="text-center">{{ $t('os') }}</th>
                <th
                  v-for="(item,index) in year_week"
                  class="text-center"
                  :key="index"
                >
                {{ item.text }}
                </th>
                <th class="text-center">{{ $t('ranking_now') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item,index) in body"
                :key="index"
              >
                <td class="text-center">
                  <span v-if="item['status_active']" class="success--text">{{ $t('active') }}</span>
                  <span v-else class="error--text">{{ $t('unactive') }}</span>
                </td>
                <td class="text-left url-in-table">
                  <a :href="item['url']" target="_blank">{{ encodeDecodeURL(item['url']) }}</a>
                </td>
                <td class="text-center">{{ item['keyword'] }}</td>
                <td class="text-center">{{ item['search_engine'] }}</td>
                <td class="text-center">{{ item['locale_name'] }}</td>
                <td class="text-center">{{ item['language_name'] }}</td>
                <td class="text-center">{{ item['device'] }}</td>
                <td class="text-center">{{ item['os'] }}</td>
                <th
                  v-for="(item_year_week,index_year_week) in year_week"
                  class="text-center"
                  :key="index_year_week"
                >
                  {{ array_ranking[item.uuid] && array_ranking[item.uuid][item_year_week.week] ? array_ranking[item.uuid][item_year_week.week] : '-' }}
                </th>
                <td class="text-center">{{ setRankNow(item) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

      </v-card-text>
      <!-- <v-card-actions class="pl-5 pr-5 pb-5 pt-0 ma-0 ">
        <v-spacer></v-spacer>
        
        <v-btn color="grey" text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>
<script>
     

  export default {
    data: () => ({
      loading: false,
      dialog: false,
      resolve: null,
      reject: null,
      has_tab: true,
      has_all: true,
      has_rank_group: true,
      has_rank_absolute: true,
      has_rank_group_domain: true,
      has_rank_absolute_domain: true,
      tab_rank: 'all',
      data: [],
      body: [],
      array_ranking: {},
      year_week: [],
      year: 1,
      item_year: [],
      has_scrollbar: false,
      options: {
        color: 'primary',
        width: 1024,
        zIndex: 200
      }
    }),
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
        }
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      selectOrder(table){
        //this.item_table = table
        this.initTable(table)
      },
      encodeDecodeURL(url) {
        if(!url){
          return ''
        }
        const parts = url.split('/');
        const decodedParts = parts.map(part => {
          try {
            // พยายามดีโค้ดแต่ละส่วนของ URL
            return decodeURIComponent(part);
          } catch {
            // หากเกิดข้อผิดพลาด, ส่งคืนส่วนนั้นๆ ออกมาเป็นเอนโค้ด
            return part;
          }
        });
        // รวมกลับเป็น URL ที่ดีโค้ดได้บางส่วนและเอนโค้ดบางส่วน
        return decodedParts.join('/');
      },
      copyText() {
        let array_table = []
        let th = this.$t('status') + '\t'
        th += this.$t('url') + '\t'
        th += this.$t('keyword') + '\t'
        th += this.$t('search_engine') + '\t'
        th += this.$t('locale') + '\t'
        th += this.$t('language') + '\t'
        th += this.$t('device') + '\t'
        th += this.$t('os') + '\t'
        
        for(let year_week of this.year_week){
          th += this.$t(year_week.text) + '\t'
        }
        th += this.$t('ranking_now') + '\t'
        array_table.push(th)
        for(let e of this.body){
          let td = (e.status_active ? this.$t('active') : this.$t('unactive')) + '\t'
          td += (e.url ? e.url : '-') + '\t'
          td += (e.keyword ? e.keyword : '-') + '\t'
          td += (e.search_engine ? e.search_engine : '-') + '\t'
          td += (e.locale_name ? e.locale_name : '-') + '\t'
          td += (e.language_name ? e.language_name : '-') + '\t'
          td += (e.device ? e.device : '-') + '\t'
          td += (e.os ? e.os : '-') + '\t'
          
          for(let year_week of this.year_week){
            td += (this.array_ranking[e.uuid] && this.array_ranking[e.uuid][year_week.week] ? this.array_ranking[e.uuid][year_week.week] : '-') + '\t'
          }
          td += this.setRankNow(e) + '\t'
          array_table.push(td)
        } 
        navigator.clipboard.writeText(array_table.join('\n')); 
        this.$root.$snackbar(this.$t('copied_to_clipboard'))
      
      },
      initTable(){
        this.array_ranking = this.data.array_ranking
        this.year_week = this.data.year_week
        this.body = this.data.keyword

        let that = this
        setTimeout(function(){
          that.$nextTick(() => {
            that.checkForScrollbar();
          });
        },100)
      },
      getDataRank(type){
        this.tab_rank = type
        this.getData()
      },
      parseDateWeek(date_week){
        let array = date_week.split('-')
        let year = array[0]
        let weekNumber = array[1]
        let date = this.moment(`${year}-01-01`).add(weekNumber - 1, 'weeks').startOf('isoWeek').locale('en');

        // หาวันแรกของเดือนที่วันที่อยู่
        let startOfMonth = this.moment(date).startOf('month');
        let firstDayOfWeek = startOfMonth.isoWeekday(); // ISO (1 = Monday)
        
        // คำนวณหมายเลขสัปดาห์ของเดือนโดยพิจารณาจากวันเริ่มต้นของเดือน
        let daysIntoMonth = date.diff(startOfMonth, 'days');
        let weekOfMonth;
        if (firstDayOfWeek > 1) {
            // หากวันแรกของเดือนไม่ใช่วันจันทร์
            weekOfMonth = Math.ceil((daysIntoMonth + firstDayOfWeek - 1) / 7);
        } else {
            // หากวันแรกของเดือนคือวันจันทร์
            weekOfMonth = Math.floor(daysIntoMonth / 7) + 1;
        }

        // แปลงเดือนเป็นชื่อย่อ
        let month = date.format('MMM'); // แปลงเดือนเป็นชื่อย่อ
        let yearDisplayed = date.format('YYYY'); // ปี
        return month+ ', Week '+weekOfMonth+ ', '+yearDisplayed

      },
      setRankNow(data){
        if(this.tab_rank == 'all'){
          return data.best_ranking ? data.best_ranking : '-'
        }
        else if(this.tab_rank == 'rank_group'){
          return data.rank_group_url ? data.rank_group_url : '-'
        }
        else if(this.tab_rank == 'rank_absolute'){
          return data.rank_absolute_url ? data.rank_absolute_url : '-'
        }
        else if(this.tab_rank == 'rank_group_domain_url'){
          return data.rank_group_domain_url ? data.rank_group_domain_url : '-'
        }
        else if(this.tab_rank == 'rank_absolute_domain'){
          return data.rank_absolute_domain_url ? data.rank_absolute_domain_url : '-'
        }
      },
      setRank(data){
        //console.log(this.tab_rank)
        return data
        // let best_ranking = data.rank_group;
        // let rank_group_domain = data.rank_group;
        // if(data.domain_match && data.domain_match.data && data.domain_match.data.length){
        //   rank_group_domain = data.domain_match.data.rank_group;
        //   if(rank_group_domain < best_ranking){
        //     best_ranking = rank_group_domain;
        //   }
        // }
        // let rank_absolute_domain = data.rank_absolute;
        // if(data.domain_match && data.domain_match.data && data.domain_match.data.length){
        //   rank_absolute_domain = data.domain_match.data.rank_absolute;
        // }

        // if(this.tab_rank == 'all'){
        //   return best_ranking ? best_ranking : '-'
        // }
        // else if(this.tab_rank == 'rank_group'){
        //   return data.rank_group ? data.rank_group : '-'
        // }
        // else if(this.tab_rank == 'rank_absolute'){
        //   return data.rank_absolute ? data.rank_absolute : '-'
        // }
        // else if(this.tab_rank == 'rank_group_domain'){
        //   return rank_group_domain ? rank_group_domain : '-'
        // }
        // else if(this.tab_rank == 'rank_absolute_domain'){
        //   return rank_absolute_domain ? rank_absolute_domain : '-'
        // }
        
      },
      CopyReport(){
        this.copyText()
      },
      open() {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.item_year = []
        let array_years = [1,2,3]
        for(let year of array_years){
          this.item_year.push({
            name : this.$t('last_year',{year : year}),
            value: year
          })
        }
        this.year = 1
        this.tab_rank = 'all'
        this.has_tab = true
        this.has_all = true
        this.has_rank_group = true
        this.has_rank_absolute = true
        this.has_rank_group_domain = true
        this.has_rank_absolute_domain = true

        if(this.$auth.user().json_disable_column && this.$auth.user().json_disable_column.keyword){
          let column_keyword = this.$auth.user().json_disable_column.keyword
  
          let count = 5
          if(column_keyword.indexOf("rank_absolute_domain_url") != -1){
            this.has_rank_absolute_domain = false
            count--
          }
          else{
            this.tab_rank = 'rank_absolute_domain'
          }

          if(column_keyword.indexOf("rank_group_domain_url") != -1){
            this.has_rank_group_domain = false
            count--
          }else{
            this.tab_rank = 'rank_group_domain'
          }

          if(column_keyword.indexOf("rank_absolute_url") != -1){
            this.has_rank_absolute = false
            count--
          }
          else{
            this.tab_rank = 'rank_absolute'
          }

          if(column_keyword.indexOf("rank_group_url") != -1){
            this.has_rank_group = false
            count--
          }
          else{
            this.tab_rank = 'rank_group'
          }

          if(column_keyword.indexOf("best_ranking") != -1){
            this.has_all = false
            count--
          }
          else{
            this.tab_rank = 'all'
          }
          if(count <= 1){
            this.has_tab = false
          }
        }

        this.getData()
        
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      getData(){
        this.loading = true
        this.axios.post('/tracking/keyword/report-keyword-all', {
          year: this.year,
          rank: this.tab_rank,
          enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
        })
        .then(res => {
          if (res.data.status === 'success') {
            this.data = res.data
            this.initTable()
          } 
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading = false)
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false
        })
        this.dialog = false
      }
    } 
  }
</script>
<style>
.url-in-table{
  max-width: 360px;
  overflow-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.input-search{
  height: 55px;
  width: 200px;
}
</style>