<template>
  <v-dialog 
  scrollable 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  transition="dialog-bottom-transition"
  >
    <v-card class="dialog-card">
      <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
        <div>
          {{ $t('setting_dataforseo_playground') }}
        </div>
        
        <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text rounded :class="'ma-0 pl-5 pt-5 pb-5 ' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">
        <v-tabs 
            v-model="tab"
            :color="($store.state.app.dark) ? 'white' : 'black'"
            class="content-tabs"
            background-color="transparent"
            fixed-tabs
            show-arrows
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab 
            v-for="(item_search_engine,index_search_engine) in settings" :key="index_search_engine"
            class="no-caps" 
            :href="'#'+index_search_engine" 
            :ripple="false" 
            >
              {{ $t(index_search_engine) }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" :touchless="false">
            <v-tab-item 
            v-for="(item_search_engine,index_search_engine) in settings" :key="index_search_engine"
            :transition="false" 
            :reverse-transition="false" 
            :value="index_search_engine"
            >
              <v-card v-for="(item_playground,index_playground) in item_search_engine" :key="index_playground">
                <v-card-title >{{ index_playground }}</v-card-title>
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" style="width: 50px;">
                            {{ $t('active') }}
                          </th>
                          <th class="text-center" style="width: 200px;max-width: 200px;">
                            {{ $t('service') }}
                          </th>
                          <th class="text-center" style="width: 100px;">
                            {{ $t('cost') }}
                          </th>
                          <th class="text-center" style="width: 100px;">
                            {{ $t('price') }}
                          </th>
                          <th class="text-center" style="width: 100px;">
                            {{ $t('expires') }} ({{ $t('day') }})
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item,index) in item_playground"
                          :key="index"
                        >
                          <td class="text-center" style="width: 50px;">
                            <div class="d-flex justify-center">
                              <v-switch
                                v-model="item.active"
                                :ref="'ref_'+item.type_function"
                                :id="'id_'+ item.type_function"
                                inset
                                dense
                                :ripple="false"
                                :name="item.type_function"
                                :persistent-hint="true"
                              />
                            </div>
                          </td>
                          <td class="text-left " >
                            <div class="" style="width: 200px;max-width: 200px; white-space: pre-wrap;">{{ $t(item.type_function) }}</div>
                          </td>
                          <td class="text-left " style="width: 100px;">
                            <div :class="'div-input pa-2 ' + (item.error_cost ? 'error-field' : '')">
                              <x-text-field
                                v-model="item.cost"
                                type="number"
                                filled
                              ></x-text-field>
                            </div>
                            
                          </td>
                          <td class="text-left" style="width: 100px;">
                            <div :class="'div-input pa-2 ' + (item.error_price ? 'error-field' : '')">
                              <x-text-field
                                v-model="item.price"
                                type="number"
                                filled
                              ></x-text-field>
                            </div>
                          </td>
                          <td class="text-left" style="width: 0px;">
                            <div :class="'div-input pa-2 ' + (item.error_day_interval ? 'error-field' : '')">
                              <x-text-field
                                v-model="item.day_interval"
                                type="number"
                                filled
                              ></x-text-field>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
              
            </v-tab-item>
          </v-tabs-items>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pl-5 pr-5 pb-5 pt-5 ma-0 ">
        <v-spacer></v-spacer>
        <!-- <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn> -->
        <v-btn color="primary" rounded depressed @click.native="updateSetting" :loading="loading" :disabled="loading" class="no-caps">{{ $t('save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>  
<script>
  
  export default {
    data: () => ({
      dialog: false,
      loading: false,
      resolve: null,
      reject: null,
      tab: '',
      uuid: null,
      settings: {},
      has_scrollbar: false,
      options: {
        color: 'primary',
        width: 1240,
        zIndex: 200
      } 
    }),
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
        }
      },
      open(uuid,settings) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.uuid = uuid
        this.settings = settings
        let that = this
        setTimeout(function(){
          that.$nextTick(() => {
            that.checkForScrollbar();
          });
        },100)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      isValidFloat(value) {
        value = String(value); // แปลง value เป็นสตริงก่อนการตรวจสอบ
        const num = parseFloat(value);
        return !isNaN(num) && (value.includes('.') || Number.isInteger(num));
      },
      isValidInteger(value) {
          const num = Number(value); // หรือใช้ parseInt(value, 10) สำหรับแปลงเป็นเบส 10
          return Number.isInteger(num);
      },
      updateSetting(){
        
        let chk = true
        let update_settings = []
        for(let search_engine in this.settings){
          for(let playground in this.settings[search_engine]){
            for(let item of this.settings[search_engine][playground]){
              item.error_cost = false
              item.error_price = false
              item.error_day_interval = false
              if(!item.cost || !this.isValidFloat(item.cost)){
                item.cost = 0
                item.error_cost = true
                chk = false
              }
              if(!item.price || !this.isValidFloat(item.price)){
                item.price = 0
                item.error_price = true
                chk = false
              }
              if(!item.day_interval || !this.isValidInteger(item.day_interval)){
                item.day_interval = 0
                item.error_day_interval = true
                chk = false
              }
              update_settings.push(item)
            }     
          }
        }
        if(!chk){
          this.$root.$snackbar(this.$t('invalid_value'))
          return false
        }
        this.loading = true
        this.axios.post('/admin/tracking/update/setting-dataforseo-playground', {
          uuid: this.uuid,
          settings: JSON.stringify(update_settings),
        })
        .then(res => {
          if (res.data.status === 'success') {
            //console.log(res.data)
            this.$root.$refs.master.enabledScroll()
            this.resolve({
              confirm: true,
            })
            this.dialog = false
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading = false)
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>
<style>
.div-input{
  width: 200px;
  max-width: 200px;
  height: 75px;
}
.error-field .v-input__slot{
  border-color: #b70000 !important;
}
</style>
