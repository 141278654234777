<template>
  <div class="auth-main viewContainer" >
    <v-container class="ma-0 pa-0">
      <!-- <v-btn @click="loading_main ? loading_main=false : loading_main=true">test</v-btn> -->
      <ValidationObserver ref="validatePayment" v-slot="{ invalid }">
        <v-form 
          ref="formPayment" 
          @submit.prevent="SavePayment"
        >
          
            <v-card-title class="ma-0 pr-5 pl-5 pt-5 pb-0">{{ $t('transfer') }}</v-card-title>
          
            <v-card-text class="ma-0 pr-5 pl-5 pt-0 pb-0">
              <div class="mb-4" v-if="form_payment.type == 'transfer'">

                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">
                          {{ $t('account_number') }}
                        </th>
                        <th class="text-center">
                          {{ $t('account_name') }}
                        </th>
                        <th class="text-center">
                          {{ $t('bank') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in form_payment.bank_account"
                        :key="item.bank_account_id"
                      >
                        <td>{{ item.bank_account_id }}</td>
                        <td>{{ item.bank_account_name }}</td>
                        <td>{{ item.bank_account_branch }}</td>
                      </tr>
                      <tr v-if="loading_main">
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div class="mb-2" v-if="form_payment.type == 'transfer' || form_payment.type == 'topup_by_admin'">
                <v-label v-if="form_payment.type == 'transfer'" :class="'input-label mb-2 '+form_payment.label_cover">{{ $t('image_slip') }}</v-label>
                <v-label v-if="form_payment.type == 'topup_by_admin'" :class="'input-label mb-2 '+form_payment.label_cover">{{ $t('related_image') }}</v-label>
                <v-skeleton-loader
                    v-if="loading_main"
                    type="image"
                ></v-skeleton-loader>
                <v-hover v-if="!loading_main">
                  <template v-slot:default="{ hover }">
                    <v-avatar
                      width="100%"
                      :height="!form_payment.view ? 250 : '100%'"
                      class="input-upload"
                    >
                      <v-img :src="form_payment.slip" class="" contain 
                      :style="{'width': '100%'}" >
                        <v-fade-transition v-if="!form_payment.view">
                          <v-overlay
                            v-if="hover"
                            absolute
                          >
                              <v-btn @click="UploadSlip" small fab color="primary"><v-icon size="14">mdi-folder-open</v-icon></v-btn>
                              <v-btn v-if="form_payment.slip" @click="form_payment.slip=null; form_payment.upload_slip=null; $refs.fileupload.value=null;" class="ml-2" small fab color="error"><v-icon size="14">mdi-close</v-icon></v-btn>
                          </v-overlay>
                        </v-fade-transition>
                      </v-img>
                    </v-avatar>
                  </template>
                </v-hover>
                <input
                  v-if="!form_payment.view"
                  type="file"
                  style="display: none"
                  id="slip"
                  ref="fileupload"
                  name="slip"
                  accept="image/*"
                  @change="onFilePicked"
                >
              </div>

              <div v-if="form_payment.view"> 
                <div class="view_div d-flex justify-space-between">
                  <div class="text-body-1 mt-auto mb-auto">{{$t('type')}}</div>
                  <div class="text-h6 mt-auto mb-auto">{{ $t(form_payment.type) }} </div>
                </div>

                <div class="view_div d-flex justify-space-between">
                  <div class="text-body-1 mt-auto mb-auto">{{$t('status')}}</div>
                  <div class="text-h6 mt-auto mb-auto">{{ $t(form_payment.status) }} </div>
                </div>

                <div v-if="form_payment.type == 'transfer' && form_payment.status == 'unapprove'" class="view_div d-flex justify-space-between">
                  <div class="text-body-1 mt-auto mb-auto">{{$t('reason')}}</div>
                  <div class="text-h6 mt-auto mb-auto" style="white-space: pre;">{{ form_payment.note_disapprove }} </div>
                </div>
              </div>

              <div v-if="!form_payment.view" class="mt-5"> 

                <loading_input v-if="loading_main"/>

                <x-text-field
                  v-if="!loading_main"
                  type="number"
                  rules="required"
                  v-model="form_payment.amount"
                  :label="$t('msg_confirm_promptpay')"
                  filled
                  @keyup="CalAmountUSD"
                ></x-text-field>
              </div>
              <div v-if="form_payment.view"> 
                <div class="view_div ma-0 d-flex justify-space-between">
                  <div class="text-body-1 mt-auto mb-auto">{{$t('amount_usd')}}</div>
                  <div class="text-h6 mt-auto mb-auto">
                    <!-- {{ formatNumber(form_payment.amount) }}  -->
                    <amount_currency :amount="parseFloat(form_payment.amount)" top></amount_currency>
                  </div>
                </div> 
              </div>

              <!-- <div v-if="!form_payment.view">
                <loading_input v-if="loading_main"/>
                <x-select
                  v-if="!loading_main"
                  :items="form_payment.item_currency"
                  v-model="form_payment.currency"
                  rules="required"
                  :label="$t('currency')"
                  item-text="name"
                  item-value="value"
                  filled
                  @change="ChangeCurrency"
                ></x-select>
              </div>

              <div class="view_div" v-if="form_payment.view">
                <div class="text-body-1">{{$t('currency')}}</div>
                <div class="text-h6">{{ form_payment.currency }}</div>
              </div> -->

              <loading_input v-if="loading_main"/>

              <div class="view_div ma-0 d-flex justify-space-between" v-if="!loading_main">
                <div class="text-body-1 mt-auto mb-auto">{{$t('service_fee')}}</div>
                <div class="text-h6 mt-auto mb-auto">
                  <!-- {{ formatNumber(form_payment.service_fee) }} {{ form_payment.currency }}  -->
                  <amount_currency :amount="parseFloat(form_payment.service_fee)" top></amount_currency>
                  <span> ({{ form_payment.percent_service_fee }}%)</span>
                </div>
              </div>

              <loading_input v-if="loading_main"/>
              
              <div class="view_div ma-0 d-flex justify-space-between" v-if="!loading_main">
                <div class="text-body-1 mt-auto mb-auto">{{$t('fee')}}</div>
                <div class="text-h6 mt-auto mb-auto">
                  <!-- {{ formatNumber(form_payment.fee) }} {{ form_payment.currency }}  -->
                  <amount_currency :amount="parseFloat(form_payment.fee)" top></amount_currency>
                  <span> ({{ form_payment.percent_fee }}%)</span>
                </div>
              </div>

              <loading_input v-if="loading_main"/>

              <div class="view_div ma-0 d-flex justify-space-between" v-if="!loading_main">
                <div class="text-body-1 mt-auto mb-auto">{{$t('total_amount')}}</div>
                <div class="text-h6 mt-auto mb-auto">
                  <!-- {{ formatNumber(form_payment.total_amount) }} {{ form_payment.currency }} -->
                  <amount_currency :amount="parseFloat(form_payment.total_amount)" top></amount_currency>
                </div>
              </div>

              <!-- <v-card v-if="loading_main || loading_price" flat tile class="content-tabs-card mt-7" >
                <v-skeleton-loader
                  class="mx-auto loading_width_150"
                  type="button"
                ></v-skeleton-loader>
              </v-card>
              <div class="view_div" v-if="!loading_main && !loading_price">
                <div class="text-body-1">{{$t('amount_usd')}}</div>
                <div class="text-h6">{{ formatNumber(form_payment.amount_usd )}} USD</div>
              </div> -->

            </v-card-text>
            <v-card-actions class="ma-0 pr-5 pl-5 pt-5 pb-0">
              <v-spacer></v-spacer>
              <v-skeleton-loader
                v-if="loading_main" 
                class="mx-auto"
                type="button"
              ></v-skeleton-loader>
              <v-btn 
                v-if="!form_payment.view && !loading_main"
                type="submit" 
                color="primary" 
                depressed 
                class="no-caps"
                :loading="loading" :disabled="loading || invalid"
                >{{ $t('save') }}
              </v-btn>
            </v-card-actions>
          
        </v-form>
      </ValidationObserver>
    </v-container>
  </div>
</template>
<script>


  export default {
    data: () => ({
      resolve: null,
      reject: null,
      loading_main: true,
      loading: false,
      loading_price: false,
      locale: 'TH',
      form_payment: {
        label_cover: '',
        view: false,
        type: 'transfer',
        amount: 0,
        currency: 'USD',
        amount_usd: 0,
        bank_account: [],
        item_currency: [],
        main_currency: 'USD',
        main_currency_rate: 1,
        currency_rate: 1,
        fee: 0,
        service_fee: 0,
        percent_fee: 0,
        percent_service_fee: 0,
        slip: null,
        upload_slip: null,
        total_amount: 0,
        status: 'create',
        note_disapprove: null,
      },
      options: {
        color: 'primary',
        width: 720,
        zIndex: 200
      }
    }),
    methods: {
      setDataPaymentTransfer(){
        let data_payment = this.$store.state.app.data_payment
        data_payment['transfer'] = this.form_payment
        this.$store.dispatch('setDataPayment', data_payment)
      },
      open(view,amount) {
        if(this.$store.state && this.$store.state && this.$store.state.app.data_payment && this.$store.state.app.data_payment.transfer){
          this.form_payment = this.$store.state.app.data_payment.transfer
          this.loading_main = false
        }
        else{
          if(this.$refs.validatePayment){
            this.$refs.validatePayment.reset();
          }
          this.form_payment.bank_account = []
          this.loading_main = true
          this.axios.get('/billing/get-main-currency')
          .then(res => {
            if (res.data.status === 'success') {
              
              this.form_payment.view = view
              this.form_payment.main_currency = res.data.currency
              this.form_payment.main_currency_rate = parseFloat(res.data.rate)
              this.form_payment.currency_rate = this.form_payment.main_currency_rate
              // this.form_payment.item_currency = res.data.item_currency
              this.form_payment.bank_account = res.data.bank_account
              this.form_payment.fee_transfer = res.data.fee_transfer
              this.form_payment.service_fee_transfer = res.data.service_fee_transfer
              this.form_payment.amount = amount
              this.form_payment.percent_fee = parseFloat(this.form_payment.fee_transfer)
              this.form_payment.percent_service_fee = parseFloat(this.form_payment.service_fee_transfer)
              this.CalAmountUSD()
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading_main = false)
        }
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      formatCurrency (number,currency) {
        return currency ? new Intl.NumberFormat('th', {style: 'currency', currency: currency}).format(number) : '-'
      },
      UploadSlip () {
        document.getElementById('slip').click()
      },
      onFilePicked (e) {
        const files = e.target.files
        //console.log(files)
        if(files[0] !== undefined) {
          if(files[0].name.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader ()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.form_payment.slip = fr.result
            this.form_payment.upload_slip = files[0]
          })
        }
      },
      
      SavePayment() {
        if(!this.form_payment.upload_slip){
          this.form_payment.label_cover = 'error--text'
          this.$root.$snackbar(this.$t('enter_img'))
          return false
        }
        this.form_payment.label_cover = ''

        this.$root.$confirm(this.$t('confirm'), this.$t('confirm_create_transfer'),{
          color: 'primary',
          width: 360,
          zIndex: 200
        },false)
        .then((confirm) => {
          if (confirm.confirm) {
            this.loading = true

            let settings = { headers: { 'content-type': 'multipart/form-data' } }
            let formData = new FormData()
            formData.append('slip', this.form_payment.upload_slip)
            formData.append('amount', this.form_payment.amount)
            formData.append('currency', this.form_payment.currency)
            
            this.axios.post('/billing/add-transfer', formData, settings)
            .then(res => {
              if (res.data.status === 'success') {
                this.$root.$snackbar(this.$t('save_topup_success'))
                // this.$root.$refs.dataTableReload()
                let data_payment = this.$store.state.app.data_payment
                data_payment['transfer'] = null
                this.$store.dispatch('setDataPayment', data_payment)
                this.$router.push({
                  name: 'billing-history', 
                })
              }
            })
            .catch(err => {
              if (err.response.data.status === 'error') {
                this.$root.$snackbar(err.response.data.msg)
              }
            })
            .finally(() => this.loading = false)
          }
        })
      },
      ChangeCurrency() {
        if(this.form_payment.currency != 'USD'){
          this.loading_price = true
          let currency = this.form_payment.currency != this.form_payment.main_currency ? this.form_payment.currency : 'USD'
          this.axios.post('/billing/get-transfer-currency', {
            currency: currency,
          })
          .then(res => {
            if (res.data.status === 'success') {
              // console.log(res.data)
              this.form_payment.currency_rate = res.data.rate
              this.CalAmountUSD()
            }
          })
          .catch(err => {
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading_price = false)
        }
        else{
          this.CalAmountUSD()
        }
      },
      CalAmountUSD(){
        //console.log(this.form_payment.currency)
        if(this.form_payment.amount){
          if(this.form_payment.currency != 'USD'){
            if(this.form_payment.currency == this.main_currency){
              //console.log((1/this.main_currency_rate))
              this.form_payment.amount_usd = this.form_payment.amount*(1/this.form_payment.main_currency_rate)
            }
            else{
              this.form_payment.amount_usd = this.form_payment.amount*(this.form_payment.currency_rate/this.form_payment.main_currency_rate)
            }
          
          }
          else{
            this.form_payment.amount_usd = this.form_payment.amount
          }

          this.form_payment.service_fee = parseFloat(this.form_payment.amount) + (this.form_payment.amount*this.form_payment.percent_service_fee/100)
          this.form_payment.fee = parseFloat(this.form_payment.amount) + (this.form_payment.amount*this.form_payment.percent_fee/100)
          this.form_payment.total_amount = parseFloat(this.form_payment.amount) + (this.form_payment.amount*this.form_payment.percent_service_fee/100)  + (this.form_payment.amount*this.form_payment.percent_fee/100)
        }
        else{
          this.form_payment.amount_usd = 0
          this.form_payment.service_fee = 0
          this.form_payment.fee = 0
          this.form_payment.total_amount = 0
        }
        this.setDataPaymentTransfer()
      },
    },
  }
</script>
<style>
.v-skeleton-loader__image{
  height: 250px;
}
.loading_width_120 .v-skeleton-loader__button{
  width: 120px;
}
.loading_width_150 .v-skeleton-loader__button{
  width: 150px;
}
.loading_width_250 .v-skeleton-loader__button{
  width: 250px;
}
</style>