<template>
  <v-dialog 
  scrollable 
  :fullscreen="$vuetify.breakpoint.xsOnly"
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  transition="dialog-bottom-transition"
  >
    <v-card rounded class="dialog-card">
      <v-card-title class="pl-5 pr-5 pt-3 pb-3 ma-0 d-flex justify-space-between">
        <div>
        {{ $t('view_lighthouse') }}
        </div>
        <v-btn fab small text @click.native="cancel" color="grey" class="no-caps blighthouse-radius-circle ml-2">
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text rounded :class="'ma-0 pt-5 pl-5 pb-5 ' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">

        <div v-for="(item, index) in data_lighthouse" :key="index">
          <div v-if="item.type == 'text'" class="view_div ml-0 mr-0 pt-0 pb-0 mt-0 d-flex justify-space-between" >
            <div class="text-body-1 mt-auto mb-auto">{{ item.name }}</div>
            <div class="text-h6 mt-auto mb-auto">{{ item.value }}</div>    
          </div>
          <div v-else-if="item.type == 'url'" class="view_div ml-0 mr-0 pt-0 pb-0 mt-0 d-flex justify-space-between" >
            <div class="text-body-1 mt-auto mb-auto">{{ item.name }}</div>
            <div class="text-h6 mt-auto mb-auto"><a target="_blank">{{ encodeDecodeURL(item.value) }}</a></div>    
          </div>
          <div v-else-if="item.type == 'boolean'" class="view_div ml-0 mr-0 pt-0 pb-0 mt-0 d-flex justify-space-between" >
            <div class="text-body-1 mt-auto mb-auto">{{ item.name }}</div>
            <div class="text-h6 mt-auto mb-auto">
              <div v-if="item.value">
                <v-icon color="green" small>mdi-check</v-icon>
              </div>
              <div v-else>
                <v-icon color="red" small>mdi-close</v-icon>
              </div>
            </div>    
          </div>
          <div v-else-if="item.type == 'chip'" class="view_div ml-0 mr-0 pt-0 pb-0 mt-0" >
            <div class="text-body-1">{{ item.name }}</div>
            <div>
              <v-chip
                v-for="(chip, index_chip) in item.value" :key="'chip_' + index_chip"
                class="mt-2 mr-2"
                color="primary"
              >
                <a v-if="chip" :class="($vuetify.theme.dark ? 'black--text' : 'white--text')" :href="chip" target="_blank">{{ encodeDecodeURL(chip) }}</a>
              </v-chip>
            </div>  
          </div>
          <div v-else-if="item.type == 'array'" class="view_div ml-0 mr-0 pt-0 pb-0 mt-0" >
            <div class="text-body-1 mt-4" >{{ item.name }}</div>
            <v-card outlined class="view_div ml-0 mr-0 pt-0 pb-0">
              <v-simple-table class="">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        {{$t('key')}}
                      </th>
                      <th class="text-left">
                        {{$t('value')}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in item.value"
                      :key="index"
                    >
                      <td>{{ item.name }}</td>
                      <td>{{ item.value }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>  
          </div>
          <div v-else-if="item.type == 'object'" class="view_div ml-0 mr-0 pt-0 pb-0 mt-0" >
            <div class="text-body-1 mt-4" >{{ item.name }}</div>
            <v-card outlined class="view_div ml-0 mr-0 pt-0 pb-0">
              <v-simple-table class="">
                <template v-slot:default>
                  <thead>
                      <tr>
                        <th v-for="(item_td_tble, index_td_tble) in Object.keys(item.value)" :key="index_td_tble" class="text-center">
                          {{ item_td_tble }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td v-for="(key, colIndex) in Object.keys(item.value)" :key="colIndex" class="text-center">{{ item.value[key] }}</td>
                      </tr>
                    </tbody>
                </template>
              </v-simple-table>
            </v-card>  
          </div>
        </div>

        <!-- <div class="view_div ml-0 mr-0 pt-0 pb-0 mt-0 d-flex justify-space-between">
          <div class="text-body-1 mt-auto mb-auto">{{$t('type')}}</div>
          <div class="text-h6 mt-auto mb-auto">{{ $t(lighthouse.type) }}</div>    
        </div>

        <div class="view_div ml-0 mr-0 pt-3 pb-0 mt-0 d-flex justify-space-between">
          <div class="text-body-1 mt-auto mb-auto">{{$t('website')}}</div>
          <div class="text-h6 mt-auto mb-auto">
            <a target="_blank">{{ encodeDecodeURL(lighthouse.site) }}</a>
          </div>   
        </div>

        <div class="view_div ml-0 mr-0 pt-3 pb-0 d-flex justify-space-between">   
          <div class="text-body-1 mt-auto mb-auto">{{$t('device')}}</div>
          <div class="text-h6 mt-auto mb-auto">{{ $t(lighthouse.device) }}</div>   
        </div>

        <div class="view_div ml-0 mr-0 pt-3 pb-0 d-flex justify-space-between">   
          <div class="text-body-1 mt-auto mb-auto">{{$t('samples')}}</div>
          <div class="text-h6 mt-auto mb-auto">{{ formatNumber(lighthouse.samples) }}</div>   
        </div>

        <div class="view_div ml-0 mr-0 pt-3 pb-0 d-flex justify-space-between">   
          <div class="text-body-1 mt-auto mb-auto">{{$t('budget')}}</div>
          <div class="text-h6 mt-auto mb-auto">{{ formatNumber(lighthouse.budget) }}</div>   
        </div>

        <div class="view_div ml-0 mr-0 pt-3 pb-0 d-flex justify-space-between">   
          <div class="text-body-1 mt-auto mb-auto">{{$t('timeout')}}</div>
          <div class="text-h6 mt-auto mb-auto">{{ formatNumber(lighthouse.timeout) }}</div>   
        </div>

        <div class="view_div ml-0 mr-0 pt-3 pb-0 d-flex justify-space-between">   
          <div class="text-body-1 mt-auto mb-auto">{{$t('webhook_mode')}}</div>
          <div class="text-h6 mt-auto mb-auto">{{ $t(lighthouse.webhook_mode) }}</div>   
        </div>

        <div class="view_div ml-0 mr-0 pt-0 pb-0 mt-4" v-if="lighthouse && lighthouse.sitemaps">
          <div class="text-body-1">{{$t('url')}}</div>
          <div>
            <v-chip
              v-for="(chip, index_chip) in JSON.parse(lighthouse.sitemaps)" :key="'chip_' + index_chip"
              class="mt-2 mr-2"
              color="primary"
            >
              <a v-if="chip" :class="($vuetify.theme.dark ? 'black--text' : 'white--text')" :href="chip" target="_blank">{{ encodeDecodeURL(chip) }}</a>
            </v-chip>
          </div>       
        </div>

        <div class="view_div ml-0 mr-0 pt-0 pb-0 mt-4" v-if="lighthouse && lighthouse.exclude_urls">
          <div class="text-body-1">{{$t('exclude_urls')}}</div>
          <div>
            <v-chip
              v-for="(chip, index_chip) in JSON.parse(lighthouse.exclude_urls)" :key="'chip_' + index_chip"
              class="mt-2 mr-2"
              color="primary"
            >
            <a v-if="chip" :class="($vuetify.theme.dark ? 'black--text' : 'white--text')" :href="chip" target="_blank">{{ encodeDecodeURL(chip) }}</a>
            </v-chip>
          </div>    
        </div>

        <div class="view_div ml-0 mr-0 pt-0 pb-0 mt-4" v-if="lighthouse && lighthouse.include_urls">
          <div class="text-body-1">{{$t('include_urls')}}</div>
          <div>
            <v-chip
              v-for="(chip, index_chip) in JSON.parse(lighthouse.include_urls)" :key="'chip_' + index_chip"
              class="mt-2 mr-2"
              color="primary"
            >
            <a v-if="chip" :class="($vuetify.theme.dark ? 'black--text' : 'white--text')" :href="chip" target="_blank">{{ encodeDecodeURL(chip) }}</a>
            </v-chip>
          </div>       
        </div>
        <div class="text-body-1 mt-4" v-if="lighthouse && lighthouse.cookies">{{$t('cookies')}}</div>
        <v-card outlined class="view_div ml-0 mr-0 pt-0 pb-0" v-if="lighthouse && lighthouse.cookies">
          
          <v-simple-table class="">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{$t('key')}}
                  </th>
                  <th class="text-left">
                    {{$t('value')}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in JSON.parse(lighthouse.cookies)"
                  :key="index"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <div v-if="lighthouse && lighthouse.auth">
          <div class="view_div ml-0 mr-0 pt-3 pb-0 d-flex justify-space-between">   
            <div class="text-body-1 mt-auto mb-auto">{{$t('username')}}</div>
            <div class="text-h6 mt-auto mb-auto">{{ JSON.parse(lighthouse.auth).username }}</div>   
          </div>
          <div class="view_div ml-0 mr-0 pt-3 pb-0 d-flex justify-space-between">   
            <div class="text-body-1 mt-auto mb-auto">{{$t('password')}}</div>
            <div class="text-h6 mt-auto mb-auto">{{ JSON.parse(lighthouse.auth).password }}</div>   
          </div>
        </div> -->
        
        
        <!-- <div class="view_div ml-0 mr-0 pt-3 pb-0 d-flex justify-space-between" v-if="!$auth.user().status_enterprise">   
          <div class="text-body-1 mt-auto mb-auto">{{$t('total_price')}}</div>
          <div class="text-h6 mt-auto mb-auto">
            <amount_currency :amount="parseFloat(lighthouse.price_usd)" top></amount_currency>
          </div>   
        </div> -->

        <!-- <div class="view_div ml-0 mr-0 pt-3 pb-0 d-flex justify-space-between">   
          <div class="text-body-1 mt-auto mb-auto">{{$t('status')}}</div>
          <div class="text-h6 mt-auto mb-auto">{{ $t(lighthouse.status) }}</div>   
        </div> -->

      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  
  export default {
    data: () => ({
      loading: false,
      dialog: false,
      resolve: null,
      reject: null,
      lighthouse: {

      },
      data_lighthouse: [],
      has_scrollbar: false,
      options: {
        color: 'primary',
        width: 720,
        zIndex: 200
      }
    }),
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
        }
      },
      formatNumber (number) {
        // const num = Number(number);
        // if (num && num !== 0 && Math.abs(num) < 0.01) {
        //     // ระบุตำแหน่งของตัวเลขที่สำคัญแรกโดยตรวจสอบจาก logarithm
        //     const significantDigits = Math.ceil(-Math.log10(Math.abs(num)));
        //     // ถ้าค่าที่ได้มากกว่า 2 ให้เรากำหนดความแม่นยำตามค่านั้น
        //     return num.toFixed(significantDigits > 2 ? significantDigits : 2);
        // } else {
        //     // ค่ามากกว่าหรือเท่ากับ 0.01 ใช้ toFixed 2
        //     return num ? num.toFixed(2) : 0;
        // }
        return new Intl.NumberFormat('th').format(number)
      },
      encodeDecodeURL(url) {
        if(!url){
          return '' 
        }
        const parts = url.split('/');
        const decodedParts = parts.map(part => {
          try {
            // พยายามดีโค้ดแต่ละส่วนของ URL
            return decodeURIComponent(part);
          } catch {
            // หากเกิดข้อผิดพลาด, ส่งคืนส่วนนั้นๆ ออกมาเป็นเอนโค้ด
            return part;
          }
        });
        // รวมกลับเป็น URL ที่ดีโค้ดได้บางส่วนและเอนโค้ดบางส่วน
        return decodedParts.join('/');
      },
      intData(lighthouse){
        let data_lighthouse = []
        data_lighthouse.push({
          name: this.$t('type'),
          value: this.$t(lighthouse.type),
          type: 'text'
        })
        data_lighthouse.push({
          name: this.$t('device'),
          value: this.$t(lighthouse.device),
          type: 'text'
        })
        if(lighthouse.type == 'domain'){
          data_lighthouse.push({
            name: this.$t('domain'),
            value: lighthouse.site,
            type: 'url'
          })
          if(lighthouse.sitemaps){
            data_lighthouse.push({
              name: this.$t('sitemaps'),
              value: (lighthouse.sitemaps ? JSON.parse(lighthouse.sitemaps) : []),
              type: 'chip'
            })
          }
          if(lighthouse.include_urls){
            data_lighthouse.push({
              name: this.$t('include_urls'),
              value: (lighthouse.include_urls ? JSON.parse(lighthouse.include_urls) : []),
              type: 'chip'
            })
          }
          if(lighthouse.exclude_urls){
            data_lighthouse.push({
              name: this.$t('exclude_urls'),
              value: (lighthouse.exclude_urls ? JSON.parse(lighthouse.exclude_urls) : []),
              type: 'chip'
            })
          }
        }
        else{
          data_lighthouse.push({
            name: this.$t('url'),
            value: (lighthouse.urls ? JSON.parse(lighthouse.urls) : []),
            type: 'chip'
          })
        }
        if(lighthouse.samples){
          data_lighthouse.push({
            name: this.$t('samples'),
            value: this.formatNumber(lighthouse.samples),
            type: 'text'
          })
        }
        if(lighthouse.budget){
          data_lighthouse.push({
            name: this.$t('budget'),
            value: this.formatNumber(lighthouse.budget),
            type: 'text'
          })
        }
        if(lighthouse.timeout){
          data_lighthouse.push({
            name: this.$t('timeout'),
            value: this.formatNumber(lighthouse.timeout),
            type: 'text'
          })
        }
        
        if(lighthouse.throttle){
          data_lighthouse.push({
            name: this.$t('throttle'),
            value: lighthouse.throttle,
            type: 'boolean'
          })
        }
        if(lighthouse.disable_sitemap){
          data_lighthouse.push({
            name: this.$t('disable_sitemap'),
            value: lighthouse.disable_sitemap,
            type: 'boolean'
          })
        }
        if(lighthouse.disable_javascript){
          data_lighthouse.push({
            name: this.$t('disable_javascript'),
            value: lighthouse.disable_javascript,
            type: 'boolean'
          })
        }
        if(lighthouse.disable_i18n_pages){
          data_lighthouse.push({
            name: this.$t('disable_i18n_pages'),
            value: lighthouse.disable_i18n_pages,
            type: 'boolean'
          })
        }
        if(lighthouse.disable_robots_txt){
          data_lighthouse.push({
            name: this.$t('disable_robots_txt'),
            value: lighthouse.disable_robots_txt,
            type: 'boolean'
          })
        }
        if(lighthouse.debug){
          data_lighthouse.push({
            name: this.$t('debug'),
            value: lighthouse.debug,
            type: 'boolean'
          })
        }
        if(lighthouse.disable_dynamic_sampling){
          data_lighthouse.push({
            name: this.$t('disable_dynamic_sampling'),
            value: lighthouse.disable_dynamic_sampling,
            type: 'boolean'
          })
        }

        if(lighthouse.dynamic_sampling){
          data_lighthouse.push({
            name: this.$t('dynamic_sampling'),
            value: this.formatNumber(lighthouse.dynamic_sampling),
            type: 'text'
          })
        }
        if(lighthouse.max_routes){
          data_lighthouse.push({
            name: this.$t('max_routes'),
            value: this.formatNumber(lighthouse.max_routes),
            type: 'text'
          })
        }
        if(lighthouse.concurrency){
          data_lighthouse.push({
            name: this.$t('concurrency'),
            value: this.formatNumber(lighthouse.concurrency),
            type: 'text'
          })
        }
        if(lighthouse.ttl){
          data_lighthouse.push({
            name: this.$t('ttl'),
            value: this.formatNumber(lighthouse.ttl),
            type: 'text'
          })
        }
        if(lighthouse.job_timeout){
          data_lighthouse.push({
            name: this.$t('job_timeout'),
            value: this.formatNumber(lighthouse.job_timeout),
            type: 'text'
          })
        }
        if(lighthouse.job_cpu){
          data_lighthouse.push({
            name: this.$t('job_cpu'),
            value: this.formatNumber(lighthouse.job_cpu),
            type: 'text'
          })
        }
        if(lighthouse.job_cpu){
          data_lighthouse.push({
            name: this.$t('job_cpu'),
            value: this.formatNumber(lighthouse.job_cpu),
            type: 'text'
          })
        }
        if(lighthouse.job_memory){
          data_lighthouse.push({
            name: this.$t('job_memory'),
            value: this.formatNumber(lighthouse.job_memory),
            type: 'text'
          })
        }
        if(lighthouse.job_region){
          data_lighthouse.push({
            name: this.$t('job_region'),
            value: lighthouse.job_region,
            type: 'text'
          })
        }
        if(lighthouse.extra_headers){
          data_lighthouse.push({
            name: this.$t('extra_headers'),
            value: (lighthouse.extra_headers ? JSON.parse(lighthouse.extra_headers) : {}),
            type: 'object'
          })
        }
        if(lighthouse.cookies){
          data_lighthouse.push({
            name: this.$t('cookies'),
            value: (lighthouse.cookies ? JSON.parse(lighthouse.cookies) : []),
            type: 'array'
          })
        }
        if(lighthouse.default_query_params){
          data_lighthouse.push({
            name: this.$t('default_query_params'),
            value: (lighthouse.default_query_params ? JSON.parse(lighthouse.default_query_params) : {}),
            type: 'object'
          })
        }
        if(lighthouse.auth){
          data_lighthouse.push({
            name: this.$t('auth'),
            value: (lighthouse.auth ? JSON.parse(lighthouse.auth) : {}),
            type: 'object'
          })
        }
        
        this.data_lighthouse = data_lighthouse
        let that = this
        setTimeout(function(){
          that.$nextTick(() => {
            that.checkForScrollbar();
          });
        },100)
      },
      open(lighthouse) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.lighthouse = lighthouse
        this.intData(lighthouse)
        
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false
        })
        this.dialog = false
      }
    }
  }
</script>








