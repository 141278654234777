<template>
  <v-dialog 
  scrollable 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }" 
  transition="dialog-bottom-transition"
  >
    <ValidationObserver ref="validateKeyword" v-slot="{ invalid }">
      <v-form 
        ref="formKeyword" 
        @submit.prevent="save"
      >
        <v-card class="dialog-card">
          <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
            <div>
            {{ $t('edit') }}
            </div>
            <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
              <v-icon size="24">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text :class="'ma-0 pt-5 pb-0 pl-5 ' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">

            <x-text-field
              v-if="type == 'service'"
              v-model="item.providers_service_code"
              :label="$t('code')"
              rules="required"
              filled
            ></x-text-field>

            <x-text-field
              v-if="type == 'service'"
              v-model="item.providers_service_description"
              :label="$t('description')"
              rules="required"
              filled
            ></x-text-field>
            
            <x-text-field
              v-if="type == 'service'"
              v-model="item.providers_service_min_qty"
              :label="$t('min_qty')"
              rules="required|between:1,100000"
              filled
            ></x-text-field>
            
            <x-text-field
              v-if="type == 'service'"
              v-model="item.price"
              :label="$t('price_buy')"
              rules="required|between:0,100000"
              filled
            ></x-text-field>
            
            <x-text-field
              v-if="type == 'service'"
              v-model="item.serpclick_tolerance"
              :label="$t('tolerance')"
              rules="required"
              filled
            ></x-text-field>

            <x-text-field
              v-if="type == 'service'"
              v-model="item.serpclick_days"
              :label="$t('day')"
              rules="required"
              filled
            ></x-text-field>

            <x-text-field
              v-if="type == 'service'"
              v-model="item.serpclick_min_daily_change"
              :label="$t('min_daily_change')"
              rules="required"
              filled
            ></x-text-field>

            <x-text-field
              v-if="type == 'service'"
              v-model="item.serpclick_max_daily_change"
              :label="$t('max_daily_change')"
              rules="required"
              filled
            ></x-text-field>

            <x-text-field
              v-if="type == 'extra'"
              v-model="item.providers_extras_code"
              :label="$t('code')"
              rules="required"
              filled
            ></x-text-field>

            <x-text-field
              v-if="type == 'extra'"
              v-model="item.providers_extras_description"
              :label="$t('description')"
              rules="required"
              filled
            ></x-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pl-5 pr-5 pb-5 pt-5 ma-0 ">
            <v-spacer></v-spacer>
            <!-- <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn> -->
            <v-btn 
              type="submit" 
              color="primary" 
              depressed 
              class="no-caps"
              :loading="loading" :disabled="loading || invalid"
              >{{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify DialogEditSerpClick Dialog component
   *
   * Insert component where you want to use it:
   * <DialogEditSerpClick ref="DialogEditSerpClick"></DialogEditSerpClick>
   *
   * Call it:
   * this.$refs.DialogEditSerpClick.open('Delete', 'Are you sure?', { color: 'red' }).then((DialogEditSerpClick) => {})
   * Or use await:
   * if (await this.$refs.DialogEditSerpClick.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$DialogEditSerpClick
   * <template>
   *   <v-app>
   *     ...
   *     <DialogEditSerpClick ref="DialogEditSerpClick"></DialogEditSerpClick>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$DialogEditSerpClick = this.$refs.DialogEditSerpClick.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      loading: false,
      resolve: null,
      type: null,
      reject: null,
      has_scrollbar: false,
      item: {},
      options: {
        color: 'primary',
        width: 720,
        zIndex: 200
      }
    }),
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
        }
      },
      open(item,type) {
        this.dialog = true
        this.item = item
        this.type = type
        let that = this
        setTimeout(function(){
          that.$nextTick(() => {
            that.checkForScrollbar();
          });
        },100)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      save() {
        this.$root.$refs.master.disabledScroll()
        this.loading = true
        this.axios.post('/admin/backlink/update/'+this.type+'-serpclick', {
          uuid: this.item.uuid,
          providers_service_code: this.item.providers_service_code,
          providers_service_description: this.item.providers_service_description,
          providers_service_min_qty: this.item.providers_service_min_qty,
          price: this.item.price,
          providers_extras_code: this.item.providers_extras_code,
          providers_extras_description: this.item.providers_extras_description,
        })
        .then(res => {
          if (res.data.status === 'success') {
            this.$root.$refs.master.enabledScroll()
            this.resolve({
              confirm: true
            })
            this.dialog = false
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading = false)
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>