<template>
  <v-card class="mt-0 content-tabs-wrapper" tile elevation="0">
    <v-tabs
        v-model="tab"
        :color="($store.state.app.dark) ? 'white' : 'black'"
        class="content-tabs mb-3"
        background-color="transparent"
        fixed-tabs
        show-arrows
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab :to="{ name: 'tracking-keyword', query: $route.query }" class="no-caps" :ripple="false">
        <v-icon left>mdi-table-large</v-icon> {{ $t('table') }}
      </v-tab>
      <v-tab v-if="!enterprise && $auth.user().page_dashboard" :to="{ name: 'tracking-dashboard', query: $route.query }" class="no-caps" :ripple="false">
        <v-icon left>mdi-view-dashboard</v-icon> {{ $t('dashboard') }} 
      </v-tab>
      <v-tab v-if="$auth.user().page_package" :to="{ name: 'tracking-package', query: $route.query }" class="no-caps" :ripple="false">
        <v-icon left>mdi-view-trophy</v-icon> {{ $t('package') }}
      </v-tab>
    </v-tabs>
  </v-card>
</template>
<script>
  export default {
    data: () => ({
      tab: 'tracking-keyword',
      enterprise: false
    }),
    created () {
      // this.tab = 'keyword'
      // console.log(this.tab)
      if(this.$route.query && this.$route.query.enterprise_uuid){
        this.enterprise = true
      }
    }
  }
</script>