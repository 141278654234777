<template>
  <div class="auth-main viewContainer" >
    <v-container class="ma-0 pa-0">
      
        <!-- <v-btn @click="loading_main ? loading_main=false : loading_main=true">test</v-btn> -->
        <v-card-title class="ma-0 pr-5 pl-5 pt-5 pb-0">{{ $t('credit_card') }}</v-card-title>
        
        <v-card-text class="ma-0 pr-5 pl-5 pt-4 pb-0">
          <div 
          v-if="loading_main"
          :class="'view_div div_loading_btn ' + ($vuetify.breakpoint.width > 400 ? 'flex-between' : '')"
          >
            <v-skeleton-loader
              class="mb-2"
              type="button"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="mb-2"
              type="button"
            ></v-skeleton-loader>
          </div>
          <div 
          v-if="!loading_main"
          :class="'view_div ma-0 ' + ($vuetify.breakpoint.width > 450 ? 'flex-between' : '')"
          >
            <v-btn 
              v-if="!form_payment.omise_customer.id"
              color="primary" 
              class="no-caps mb-2 btn-credit-card" 
              rounded
              x-large 
              depressed 
              @click="addCreditCard" 
              >
              {{ $t('add_credit_card') }}
            </v-btn>
            <v-btn 
              v-if="form_payment.omise_customer.id"
              color="primary" 
              class="no-caps mb-2 btn-credit-card" 
              rounded
              x-large 
              depressed 
              @click="updateCreditCard" 
              >
              {{ $t('update_credit_card') }}
            </v-btn>
            <v-btn 
              v-if="form_payment.omise_customer.id"
              color="error" 
              class="no-caps btn-credit-card" 
              rounded
              x-large 
              depressed 
              @click="deleteCreditCard" 
              >
              {{ $t('delete_credit_card') }}
            </v-btn>
          </div>
          
          <div v-if="form_payment.omise_customer.id">
            <loading_input v-if="loading_main"/>
            <div v-if="!loading_main" class="view_div">
              <x-text-field
                  type="number"
                  v-model="form_payment.amount"
                  :label="$t('msg_confirm_promptpay')"
                  @keyup="CalAmountUSD"
              ></x-text-field>
            </div>

            <loading_input v-if="loading_main"/>

            <div v-if="!loading_main" class="view_div ma-0 d-flex justify-space-between">
              <div class="text-body-1 mt-auto mb-auto">{{$t('service_fee')}}</div>
              <div class="text-h6 mt-auto mb-auto">
                <!-- {{ formatNumber(form_payment.service_fee) }} USD  -->
                <amount_currency :amount="parseFloat(form_payment.service_fee)" top></amount_currency>
                ({{ form_payment.percent_service_fee }}%)</div>
            </div>
            
            <loading_input v-if="loading_main"/>

            <div v-if="!loading_main" class="view_div ma-0 d-flex justify-space-between">
              <div class="text-body-1 mt-auto mb-auto">{{$t('fee')}}</div>
              <div class="text-h6 mt-auto mb-auto">
                <!-- {{ formatNumber(form_payment.fee) }} USD  -->
                <amount_currency :amount="parseFloat(form_payment.fee)" top></amount_currency>
                ({{ form_payment.percent_fee }}%)</div>
            </div>

            <loading_input v-if="loading_main"/>

            <div v-if="!loading_main" class="view_div ma-0 d-flex justify-space-between">
              <div class="text-body-1 mt-auto mb-auto">{{$t('total_amount')}}</div>
              <div class="text-h6 mt-auto mb-auto">
                <!-- {{ formatNumber(form_payment.total_amount) }} USD -->
                <amount_currency :amount="parseFloat(form_payment.total_amount)" top></amount_currency>
              </div>
            </div>
          </div>
        </v-card-text>
        
        <v-card-actions class="ma-0 pr-5 pl-5 pt-5 pb-0">         
          <v-spacer></v-spacer>
          <v-skeleton-loader
            v-if="loading_main" 
            class="mx-auto"
            type="button"
          ></v-skeleton-loader>
          <v-btn 
            v-if="!loading_main" 
            color="primary" 
            rounded 
            depressed 
            @click.native="agree" 
            :loading="loading" :disabled="loading"
            class="no-caps"
          >{{ $t('save') }}
          </v-btn>
        </v-card-actions>
      
    </v-container>
  </div>
</template>
<script>

  export default {
    data: () => ({
      dialog: false,
      loading_main: true,
      loading: false,
      resolve: null,
      reject: null,
      form_payment: {
        amount:  0,
        omise_customer:  {
          id: null
        },
        service_fee:  0,
        percent_service_fee:  0,
        fee:  0,
        percent_fee:  0,
        total_amount:  0,
        valueFields: {
            cardType: "",
            cardName: "",
            cardNumber: "",
            cardMonth: "",
            cardYear: "",
            cardCvv: "",
        },
        labels : {
          cardNumber : "Card Number",
          cardName : "Full Name",
          cardHolder : "Card Holder",
          cardMonth : "MM",
          cardYear : "YY",
          cardExpires : "Expires",
          cardCvv : "CVV"
        }
      },
    }),
    methods: {
      setDataPaymentCreditCard(){
        let data_payment = this.$store.state.app.data_payment
        data_payment['credit_card'] = this.form_payment
        this.$store.dispatch('setDataPayment', data_payment)
      },
      open() {
        if(this.$store.state && this.$store.state && this.$store.state.app.data_payment && this.$store.state.app.data_payment.credit_card){
          this.form_payment = this.$store.state.app.data_payment.credit_card
          this.loading_main = false
        }
        else{
          this.loading_main = true
          this.axios.get('/billing/get-omise-card')
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              this.form_payment.omise_customer = res.data.omise_customer
              this.form_payment.labels.cardName = this.$t('name')
              this.form_payment.labels.cardNumber = this.$t('card_number')
              this.form_payment.labels.cardHolder = this.$t('name')
              this.form_payment.labels.expires = this.$t('expires')
              // this.omise_customer = omise_customer
              this.form_payment.amount = 0
              this.form_payment.percent_fee = parseFloat(res.data.fee_credit_card)
              this.form_payment.percent_service_fee = parseFloat(res.data.service_fee_credit_card)
              
              this.CalAmountUSD()
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading_main = false)
        }
        
        
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      formatCurrency (number,currency) {
        return currency ? new Intl.NumberFormat('th', {style: 'currency', currency: currency}).format(number) : '-'
      },
      CalAmountUSD(){
        if(this.form_payment.amount){
          this.form_payment.service_fee = parseFloat(this.form_payment.amount) + (this.form_payment.amount*this.form_payment.percent_service_fee/100)
          this.form_payment.fee = parseFloat(this.form_payment.amount) + (this.form_payment.amount*this.form_payment.percent_fee/100)
          this.form_payment.total_amount = parseFloat(this.form_payment.amount) + (this.form_payment.amount*this.form_payment.percent_service_fee/100)  + (this.form_payment.amount*this.form_payment.percent_fee/100)
        }
        else{
          this.form_payment.service_fee = 0
          this.form_payment.fee = 0
          this.form_payment.total_amount = 0
        }
        this.setDataPaymentCreditCard()
      },
      addCreditCard(){
        
        this.$root.$update_credit_card(this.form_payment.valueFields,this.form_payment.labels)
        .then((confirm) => {
          if (confirm.confirm) {
            //console.log(confirm)
            this.form_payment.omise_customer = confirm.data.omise_customer
          }
        })
      },
      updateCreditCard(){
        this.form_payment.valueFields.cardName = this.form_payment.omise_customer.name
        this.form_payment.valueFields.cardNumber = '**** **** ****'+this.form_payment.omise_customer.last_digits
        this.form_payment.valueFields.cardMonth = this.form_payment.omise_customer.expiration_month
        this.form_payment.valueFields.cardYear = this.form_payment.omise_customer.expiration_year
        this.form_payment.valueFields.cardCvv = null
        this.$root.$update_credit_card(this.form_payment.valueFields,this.form_payment.labels)
        .then((confirm) => {
          if (confirm.confirm) {
            //console.log(confirm)
            this.form_payment.omise_customer = confirm.data.omise_customer
            this.CalAmountUSD()
          }
        })
      },
      deleteCreditCard(){
        this.$root.$confirm(this.$t('confirm'), this.$t('confirm_delete_credit_card'),{
          color: 'primary',
          width: 360,
          zIndex: 200
        },false)
        .then((confirm) => {
          if (confirm.confirm) {
            this.axios.get('/billing/delete-omise-card')
            .then(res => {
              if (res.data.status === 'success') {
                this.$root.$snackbar(this.$t('credit_card_deleted'))
                this.form_payment.omise_customer = {
                  id: null
                }
                this.CalAmountUSD()
              }
            })
            .catch(err => {
              if (err.response.data.status === 'error') {
                this.$root.$snackbar(err.response.data.msg)
              }
            })
          }
        })
      },
      agree() {

        this.$root.$confirm(this.$t('confirm'), this.$t('confirm_create_transfer'),{
          color: 'primary',
          width: 360,
          zIndex: 200
        },false)
        .then((confirm) => {
          if (confirm.confirm) {
            this.loading = true
            this.axios.post('/billing/add-creditcard', {
              amount: this.form_payment.amount
            })
            .then(res => {
              if (res.data.status === 'success') {
                //console.log(res.data)
                let data_payment = this.$store.state.app.data_payment
                data_payment['credit_card'] = null
                this.$store.dispatch('setDataPayment', data_payment)
                this.$root.$refs.master.getCredit()
                this.$root.$snackbar(this.$t('received_credit',{amount: this.form_payment.amount}))
                // this.$root.$refs.dataTableReload()
                this.$router.push({
                  name: 'billing-history', 
                })
              }
            })
            .catch(err => {
              if (err.response.data.status === 'error') {
                this.$root.$snackbar(err.response.data.msg)
              }
            })
            .finally(() => this.loading = false)
          }
        })

        
      },
    }
  }
</script>
<style>
.div_loading_btn .v-skeleton-loader__button{
  width: 150px;
  height: 50px;
}
.loading_width_120 .v-skeleton-loader__button{
  width: 120px;
}
.loading_width_250 .v-skeleton-loader__button{
  width: 250px;
}
@media (max-width: 450px) {
  .btn-credit-card{
    width: 100%;
  }
}
</style>