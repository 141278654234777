<template>
  <v-app class="app">
    <v-navigation-drawer
      v-if="$auth.check() && ((!$vuetify.breakpoint.smAndDown && parseInt($auth.user().role) == 2) || parseInt($auth.user().role) != 2)"
      v-model="drawer"
      fixed
      :temporary="$vuetify.breakpoint.smAndDown ? true : false"
      :permanent="$vuetify.breakpoint.smAndDown ? false : true"
      floating
      left
      class="sidebar"
    >

      <template v-slot:prepend v-if="$vuetify.breakpoint.smAndDown">
        <div class="pa-4 d-flex text-right">
          <v-btn
            icon
            small
            @click="switchDarkTheme"
          >
            <v-icon v-if="$vuetify.theme.dark" small>mdi-lightbulb</v-icon>
            <v-icon v-if="!$vuetify.theme.dark" small>mdi-lightbulb-on-outline</v-icon>
          </v-btn>
          <v-divider vertical class="mx-2" />
          <v-app-bar-nav-icon small @click.stop="drawer = !drawer"><v-icon small v-if="!drawer">mdi-minus</v-icon><v-icon small v-if="drawer">mdi-close</v-icon></v-app-bar-nav-icon>
        </div>
      </template>

      
      <v-list class="pa-4 sidebar-list">
        <v-list-item-group class="menu-item" v-model="menu_dashboard">
          <template v-for="(item, index) in dashboardMenu">
           
            <v-list-item
              v-if="!item.heading && !item.children"
              :key="'nav_sub_' + index"
              :to="item.condition ? initPath(item) : item.to"
              exact
              :ripple="false"
            >
              <v-list-item-icon v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group
              v-if="item.children"
              :key="'nav_sub_' + index"
            >
              <template 
                v-slot:activator
              >
                <v-list-item
                  
                  :to="item.condition ? initPath(item) : item.to"
                  exact
                  :ripple="false"
                >
                  <v-list-item-icon v-if="item.icon">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-list-item
                v-for="(sub_item, sub_index) in item.sub"
                :key="'nav_child_' + sub_index"
                :to="item.condition ? initPath(sub_item) : sub_item.to"
                exact
                :ripple="false"
              >
                <v-list-item-icon v-if="sub_item.icon">
                  <v-icon>{{ sub_item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ sub_item.label }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

            </v-list-group>
          </template>
        </v-list-item-group>
      </v-list>

    </v-navigation-drawer>

    <v-bottom-navigation
      v-if="$vuetify.breakpoint.smAndDown && parseInt($auth.user().role) == 2"
      v-model="bottomNav"
      fixed
      grow
    >
      <!-- <v-btn
        v-for="(item, index) in dashboardMenu"
        :key="index"
        :to="item.condition ? initPath(item) : item.to"
      >
        <span>{{ item.label }}</span>
        <v-icon>{{ item.icon }}</v-icon>
      </v-btn> -->

      <v-list-item
        v-for="(item, index) in dashboardMenu"
        :key="index"      
          :to="item.condition ? initPath(item) : item.to"
          exact
          :ripple="false"
        >
          <v-list-item-content v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-content>
          <!-- <v-list-item-content class="d-none d-sm-none">
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item-content> -->
        </v-list-item>
    </v-bottom-navigation>

    <v-app-bar
      app
      clipped-right
      style="z-index:6"
      elevation="0"
    >
      <v-app-bar-nav-icon
        v-if="$auth.check() && parseInt($auth.user().role) != 2"
        class="d-md-none d-lg-none d-xl-none d-flex align-center"
        @click.stop="drawer = !drawer"
      >
        <v-icon>mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <!-- <router-link :to="{name: 'home'}">
          <v-img
            :max-width="($vuetify.breakpoint.smAndDown) ? ((innerWidth < 400) ? '94' : '132') : '144'"
            :src="getLogoImage()"
          />
        </router-link> -->
        <h3>SEOCROWN</h3>
      </div>  
     
      <v-spacer></v-spacer>

      
      <!-- <v-menu offset-y bottom left origin="top right" v-if="languages !== null && languages.length > 1">
        <template v-slot:activator="{ on }">
          <div class="d-flex align-center">
            <v-btn
              large
              icon
              text
              class=""
              v-on="on"
            >
              {{ $t('language_abbr') }}
            </v-btn>
          </div>
        </template>
        <v-list>
          <v-list-item @click="changeLang(item.code)" v-for="(item, index) in languages" :key="'languages_' + index">
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <div v-if="has_languages" class="align-center d-md-flex d-lg-flex d-xl-flex ">
        <v-tooltip bottom
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              small
              @click="changeLanguageCurrency"
              v-on="on"
            >
              <v-icon small>mdi-earth</v-icon>
            </v-btn>
          </template>
          <div>{{ $t('language_currency') }}</div>
        </v-tooltip>
      </div>
      <div class="align-center d-md-flex d-lg-flex d-xl-flex ml-3 mr-3">
        <v-tooltip bottom
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              small
              @click="switchDarkTheme"
              v-on="on"
            >
              <v-icon v-if="$vuetify.theme.dark" small>mdi-lightbulb</v-icon>
              <v-icon v-if="!$vuetify.theme.dark" small>mdi-lightbulb-on-outline</v-icon>
            </v-btn>
          </template>
          <div>{{ $t('theme') }}</div>
        </v-tooltip>
        
      </div>
      <div v-if="$auth.check() && (parseInt($auth.user().role) == 2) && (!$auth.user().status_enterprise)" 
      class="">
        <v-btn
          class=""
          :to="{ name: 'billing-history' }"
          depressed
          rounded
          color="primary"
        >
          <amount_currency :amount="parseFloat(credit)" bottom></amount_currency>
        </v-btn>
      </div>
      <div v-if="$auth.check() && ($route.query && $route.query.enterprise_uuid)" 
      class="">
        <v-btn
          class=""
          depressed
          rounded
          color="primary"
        >
          {{ formatNumber(credit) }}
          <v-icon right>mdi-currency-usd</v-icon>
        </v-btn>
      </div>
      
      <div class="d-flex align-center navigation" v-if="!$auth.check() && $route.name != 'register-third-party' && $route.name != 'login-third-party'">
        <v-btn
          depressed
          rounded
          text
          :small="$vuetify.breakpoint.smAndDown"
          class="no-caps"
          :to="{ name: 'login' }"
        >
          {{ $t('log_in') }}
        </v-btn>
      </div>
      <v-menu
        v-if="$auth.check()"
        offset-y
        bottom left origin="top right"
        :close-on-content-click="true"
      >
        <template v-slot:activator="{ on }">
          <div class="d-flex align-center ml-3">
            <v-btn
              icon
              small
              fab
              v-on="on"
            >
              <v-avatar
                color="primary"
                size="40"
              >
                <img v-if="$auth.user().avatar" :src="$auth.user().avatar">
                <span v-else class="white--text text-h5">{{ $auth.user().first_name.charAt(0) }}</span>
              </v-avatar>
            </v-btn>
          </div>
        </template>
        
        <v-list dense>
          <v-list-item class="px-3">
            <v-list-item-avatar>
              <!-- <img :src="$auth.user().avatar"> -->
              <v-avatar
                color="primary"
                size="40"
              >
                <img v-if="$auth.user().avatar" :src="$auth.user().avatar">
                <span v-else class="white--text text-h5">{{ $auth.user().first_name.charAt(0) }}</span>
              </v-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ $auth.user().first_name + ' ' + $auth.user().last_name }}</v-list-item-title>
              <v-list-item-subtitle>{{ $auth.user().email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
          <v-list-item-group class="menu-item">
            <template v-for="(item, index) in accountMenu">
              <v-layout
                v-if="item.heading"
                :key="item.heading"
              >
                <v-subheader class="text-uppercase">{{ item.heading }}</v-subheader>
              </v-layout>

              <v-list-group
                v-else-if="item.children"
                :key="'nav_parent_' + index"
                :value="item.opened"
                no-action
                :sub-group="false"
                :prepend-icon="item.icon"
              >
                <template #activator>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="(child, i) in item.children"
                  :key="'nav_child_' + i"
                  :to="child.to"
                  exact
                >
                  <v-list-item-icon v-if="child.icon">
                    <v-icon>{{ child.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ child.label }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
              <v-list-item
                v-else
                :key="'nav_sub_' + index"
                :to="item.to"
                exact
              >
                <v-list-item-icon v-if="item.icon">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-divider class=""/>
            <v-list-item v-if="!$auth.impersonating()" @click="LogoutUser">
              <v-list-item-icon>
                <v-icon>mdi-power</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="$auth.impersonating() && parseInt($auth.user().role) != 4" @click="$auth.unimpersonate({redirect: {name: 'admin.users'}})">
              <v-list-item-icon>
                <v-icon>mdi-power</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="$auth.impersonating() && parseInt($auth.user().role) == 4" @click="$auth.unimpersonate({redirect: {name: 'business.staff'}})">
              <v-list-item-icon>
                <v-icon>mdi-power</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <div class="d-flex align-center" 
      v-if="!$auth.check() && 
      $route.name != 'register-third-party' && 
      $route.name != 'login-third-party' &&
      $route.name != 'affiliate'
      ">
        <v-btn
          depressed
          rounded
          :light="true"
          color="white"
          :small="$vuetify.breakpoint.smAndDown"
          class="no-caps"
          :to="{name: 'register'}">
            {{ $t('sign_up') }}
        </v-btn>
      </div>
    </v-app-bar>
    <v-main :class="($auth.check() ? 'main' : '') + ' ' + ($vuetify.breakpoint.smAndDown && parseInt($auth.user().role) == 2 ? 'scroll_mobile' : 'scroll_desktop')" >
      <router-view name="primary"></router-view>
      <v-footer color="transparent" :absolute="$auth.check() || ($vuetify.breakpoint.height < 750) ? false : true">
        <v-container class="footer-container">
          <v-row no-gutters class="align-center">
            <v-col
              cols="12"
              sm="12"
              md="auto"
            > 
              <div class="text-caption text--secondary mb-2 mb-md-0" v-html="$t('footer_copyright')" />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md
            >
              <div class="text-caption d-flex flex-column flex-md-row align-md-center justify-md-end">
                <!-- <router-link :to="{name: 'terms'}" class="text--secondary text-decoration-none mb-2 mb-md-0">{{ $t('terms_and_conditions') }}</router-link>
                <router-link :to="{name: 'privacy'}" class="text--secondary text-decoration-none ml-md-2 mb-2 mb-md-0">{{ $t('privacy_policy') }}</router-link>
                <router-link :to="{name: 'about'}" class="text--secondary text-decoration-none ml-md-2 mr-md-2 mb-2 mb-md-0">{{ $t('about') }}</router-link> -->
                <!-- <router-link :to="{name: 'faq'}" class="text--secondary text-decoration-none ml-md-2 mb-2 mb-md-0">{{ $t('faq') }}</router-link>
                <router-link :to="{name: 'contact'}" class="text--secondary text-decoration-none ml-md-2 mr-md-2 mb-2 mb-md-0">{{ $t('contact_us') }}</router-link> -->
                <v-divider vertical class="mx-2 my-2 d-none d-md-flex"></v-divider>
                <div v-if="$init.app.social.length > 0" class="ml-n1 ml-md-0 mr-md-n1">
                  <v-btn
                    v-for="(item, i) in $init.app.social"
                    :key="'social_' + i"
                    icon
                    small
                    color=""
                    :href="item.url"
                  ><v-icon>{{ item.icon }}</v-icon></v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-main>
    <v-snackbar
      v-model="showCookieConsent"
      :multi-line="true"
      :timeout="-1"
      :bottom="true"
      :vertical="false"
      class="termsConsent"
    >
      <i18n path="legal_agreement_confirmation">
        <template #terms_and_conditions>
          <router-link :to="{name: 'terms'}" class="white--text">{{ $t('terms_and_conditions').toLowerCase() }}</router-link>
        </template>
        <template #privacy_policy>
          <router-link :to="{name: 'privacy'}" class="white--text">{{ $t('privacy_policy').toLowerCase() }}</router-link>
        </template>
      </i18n>
      <v-btn
        dark
        text
        icon
        depressed
        @click="$store.dispatch('setCookieConsent', false)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <snackbar ref="snackbar"></snackbar>
    <confirm ref="confirm"></confirm>
    <report_seo ref="report_seo"></report_seo>
    <report_seo_rival ref="report_seo_rival"></report_seo_rival>
    <!-- <dialog_add_keyword ref="dialog_add_keyword"></dialog_add_keyword> -->
    <!-- <report_keyword ref="report_keyword"></report_keyword> -->
    <select_services_providers ref="select_services_providers"></select_services_providers>
    <select_extras_providers ref="select_extras_providers"></select_extras_providers>
    <select_payment ref="select_payment"></select_payment>
    <dialog_payment ref="dialog_payment"></dialog_payment>
    <confirm_promptpay ref="confirm_promptpay"></confirm_promptpay>
    <qrcode_promptpay ref="qrcode_promptpay"></qrcode_promptpay>
    <confirm_credit_card ref="confirm_credit_card"></confirm_credit_card>
    <update_credit_card ref="update_credit_card"></update_credit_card>
    <!-- <dialog_create_order ref="dialog_create_order"></dialog_create_order> -->
    <dialog_view_order ref="dialog_view_order"></dialog_view_order>
    <dialog_view_backlink ref="dialog_view_backlink"></dialog_view_backlink>
    <dialog_customer_topup ref="dialog_customer_topup"></dialog_customer_topup>
    <custom_filters ref="custom_filters"></custom_filters>
    <select_date_active_keyword ref="select_date_active_keyword"></select_date_active_keyword>
    <select_date_boost_keyword ref="select_date_boost_keyword"></select_date_boost_keyword>
    <change_backlink_default ref="change_backlink_default"></change_backlink_default>
    <report_backlink ref="report_backlink"></report_backlink>
    <report_seo_all ref="report_seo_all"></report_seo_all>
    <report_backlink_order ref="report_backlink_order"></report_backlink_order>
    <dialog_edit_serpclick ref="dialog_edit_serpclick"></dialog_edit_serpclick>
    <dialog_table_backlink ref="dialog_table_backlink"></dialog_table_backlink>
    <custom_column ref="custom_column"></custom_column>
    <change_action ref="change_action"></change_action>
    <change_languages_currency ref="change_languages_currency"></change_languages_currency>
    <dialog_view_package ref="dialog_view_package"></dialog_view_package>
    <dialog_view_package_boost_keyword ref="dialog_view_package_boost_keyword"></dialog_view_package_boost_keyword>
    <confirm_delete ref="confirm_delete"></confirm_delete>
    <setting_payment ref="setting_payment"></setting_payment>
    <setting_menu ref="setting_menu"></setting_menu>
    <change_column ref="change_column"></change_column>
    <filters_dashboard ref="filters_dashboard"></filters_dashboard>
    <dialog_table_serpclick ref="dialog_table_serpclick"></dialog_table_serpclick>
    <dialog_table_report ref="dialog_table_report"></dialog_table_report>
    <setting_dataforseo_playground ref="setting_dataforseo_playground"></setting_dataforseo_playground>
    <dialog_table_insights ref="dialog_table_insights"></dialog_table_insights>
    <dialog_expand_table ref="dialog_expand_table"></dialog_expand_table>
    <dialog_expand_table_object ref="dialog_expand_table_object"></dialog_expand_table_object>
    <dialog_view_lighthouse ref="dialog_view_lighthouse"></dialog_view_lighthouse>
    <confirm_active_lighthouse ref="confirm_active_lighthouse"></confirm_active_lighthouse>
    <dialog_table_lighthouse ref="dialog_table_lighthouse"></dialog_table_lighthouse>
    <dialog_iframe ref="dialog_iframe"></dialog_iframe>
    
    <v-overlay
      opacity="1"
      :dark="$store.state.app.dark"
      :color="($store.state.app.dark) ? 'grey darken-4' : 'grey lighten-5'"
      :value="$store.state.app.loading"
      z-index="99999"
    >
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>
<script>
import { getAvailableLanguages, loadLanguageAsync } from '@/plugins/i18n'
// import currencys from '@/json/currency.json'
export default {
  components: {
  },
  data: () => ({
    locale: 'th',
    drawer: false,
    bottomNav: false,
    languages: null,
    menu_dashboard: null,
    credit: 0,
    array_rate_currency: [],
    currency: 'USD',
    data_currency: null,
    currency_rate: 1,
    array_countries: [],
    array_currencies: [],
    array_languages: [],
    has_languages: false,
    has_currencies: false,
    count_api: 0
  }),
  mounted () {

    //console.log(process.env.VUE_APP_URL_DOC)

    this.$root.$snackbar = this.$refs.snackbar.show
    this.$root.$confirm = this.$refs.confirm.open
    this.$root.$report_seo = this.$refs.report_seo.open
    this.$root.$report_seo_rival = this.$refs.report_seo_rival.open
    // this.$root.$dialog_add_keyword = this.$refs.dialog_add_keyword.open
    // this.$root.$report_keyword = this.$refs.report_keyword.open
    this.$root.$select_services_providers = this.$refs.select_services_providers.open
    this.$root.$select_extras_providers = this.$refs.select_extras_providers.open
    this.$root.$select_payment = this.$refs.select_payment.open
    this.$root.$dialog_payment = this.$refs.dialog_payment.open
    this.$root.$confirm_promptpay = this.$refs.confirm_promptpay.open
    this.$root.$qrcode_promptpay = this.$refs.qrcode_promptpay.open
    this.$root.$close_qrcode_promptpay = this.$refs.qrcode_promptpay.cancel
    this.$root.$confirm_credit_card = this.$refs.confirm_credit_card.open
    this.$root.$update_credit_card = this.$refs.update_credit_card.open
    // this.$root.$dialog_create_order = this.$refs.dialog_create_order.open
    this.$root.$dialog_view_order = this.$refs.dialog_view_order.open
    this.$root.$dialog_view_backlink = this.$refs.dialog_view_backlink.open
    this.$root.$dialog_customer_topup = this.$refs.dialog_customer_topup.open
    this.$root.$custom_filters = this.$refs.custom_filters.open
    this.$root.$select_date_active_keyword = this.$refs.select_date_active_keyword.open
    this.$root.$select_date_boost_keyword = this.$refs.select_date_boost_keyword.open
    this.$root.$change_backlink_default = this.$refs.change_backlink_default.open
    this.$root.$report_backlink = this.$refs.report_backlink.open
    this.$root.$report_seo_all = this.$refs.report_seo_all.open
    this.$root.$report_backlink_order = this.$refs.report_backlink_order.open
    this.$root.$dialog_edit_serpclick = this.$refs.dialog_edit_serpclick.open
    this.$root.$dialog_table_backlink = this.$refs.dialog_table_backlink.open
    this.$root.$custom_column = this.$refs.custom_column.open
    this.$root.$change_action = this.$refs.change_action.open
    this.$root.$change_languages_currency = this.$refs.change_languages_currency.open
    this.$root.$dialog_view_package = this.$refs.dialog_view_package.open
    this.$root.$dialog_view_package_boost_keyword = this.$refs.dialog_view_package_boost_keyword.open
    this.$root.$confirm_delete = this.$refs.confirm_delete.open
    this.$root.$setting_payment = this.$refs.setting_payment.open
    this.$root.$setting_menu = this.$refs.setting_menu.open
    this.$root.$change_column = this.$refs.change_column.open
    this.$root.$filters_dashboard = this.$refs.filters_dashboard.open
    this.$root.$dialog_table_serpclick = this.$refs.dialog_table_serpclick.open
    this.$root.$dialog_table_report = this.$refs.dialog_table_report.open
    this.$root.$setting_dataforseo_playground = this.$refs.setting_dataforseo_playground.open
    this.$root.$dialog_table_insights = this.$refs.dialog_table_insights.open
    this.$root.$dialog_expand_table = this.$refs.dialog_expand_table.open
    this.$root.$dialog_expand_table_object = this.$refs.dialog_expand_table_object.open
    this.$root.$dialog_view_lighthouse = this.$refs.dialog_view_lighthouse.open
    this.$root.$confirm_active_lighthouse = this.$refs.confirm_active_lighthouse.open
    this.$root.$dialog_table_lighthouse = this.$refs.dialog_table_lighthouse.open
    this.$root.$dialog_iframe = this.$refs.dialog_iframe.open
    
    /* Get available translations */
    getAvailableLanguages().then(result => this.languages = result)

    /* Set language */
    let language = this.$route.query.l || null
    if (language !== null) {
      loadLanguageAsync(language)
    }

    // Set locale
    let locale = Intl.DateTimeFormat().resolvedOptions().locale || 'en'
    locale = (this.$auth.check()) ? this.$auth.user().locale : locale
    this.locale = locale
    this.moment.locale(this.locale.substr(0,2))
    
    this.getRateCurrency()

    // console.log(this.$store.state.app.dark)
    // console.log(this.$vuetify.theme.dark)
    this.$vuetify.theme.dark = this.$store.state.app.dark
    this.$root.$refs.master = this;
    //this.disabledScroll()
  },
  methods: {
    disabledScroll() {
      let elHtml = document.getElementsByTagName('html')[0]
      elHtml.style.overflowY = 'hidden'
    },
    enabledScroll() {
      let elHtml = document.getElementsByTagName('html')[0]
      elHtml.style.overflowY = null
    },
    initPath (item) {
      //console.log(item)
      let path = item.to
      if(item.condition){
        for(let e of item.condition_value){
          if(e.value_if == this.$route.name){
            path = e.to
          }
        }
      }
      return path
    },
    getLogoImage () {
      const url = this.$vuetify.theme.dark ? require('@/assets/logo-white.svg') : require('@/assets/logo-black.svg')
      return url;
    },
    switchDarkTheme () {
      let dark = this.$vuetify.theme.dark
      this.$vuetify.theme.dark = !dark
      this.$store.dispatch('setDark', !dark)
      if(this.$route.name == 'tracking-dashboard'){
        this.$root.$emit('switchThemeChart');
        this.$root.$emit('switchThemeChartRefreshBacklink');
      }
    },
    changeLanguageCurrency(){
      let language = this.$store && this.$store.state && this.$store.state.app && this.$store.state.app.language ? this.$store.state.app.language.toLowerCase() : 'th-en'
      //console.log(language)
      language = language.toLowerCase()
      let currency = this.currency
      this.$root.$change_languages_currency(
        language,
        currency,
        this.array_languages,
        this.array_currencies,
        this.array_rate_currency,
        this.has_languages,
        this.has_currencies,
      ).then((confirm) => {
          if (confirm.confirm) {
            this.changeLang(confirm.language)
          }
      })
    },
    changeLang(language) {
      //console.log(language)
      loadLanguageAsync(language)
      this.$store.dispatch('setLanguage', language)
      location.reload()
    },
    getRateCurrency(){
      if (this.$auth.check()){
        this.axios
        .get('/auth/rate-currency')
        .then(response => {
          if(response.data.status == 'success'){
            //console.log(response.data)
            this.count_api = 0
            this.array_rate_currency = response.data.rate
            this.has_languages = true
            this.has_currencies = true
            this.getCurrency()
          }
        })
        .catch(err => {
          if(this.count_api <= 3){
            this.count_api++
            this.getRateCurrency()
          }
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
      }
      else{
        this.axios
          .get('/auth/countries')
          .then(response => {
            if(response.data.status == 'success'){
              //console.log(response.data)
              this.count_api = 0
              let data = response.data.data
              let item_languages = [] 
              if(data && data.resources){
                this.array_countries = data.resources
                for(let e of data.resources){
                  for(let l of e.languages){
                    item_languages.push({
                      code: e.countries.alpha2.toLowerCase() + '-' + l.alpha2.toLowerCase(),
                      name: l.langEN,
                      country_name: e.countries.langEN,
                    })
                  }
                }
   
              }
              //console.log(item_languages)
              this.has_languages = true
              this.has_currencies = false
              this.array_languages = item_languages
            }
          })
          .catch(err => {
            if(this.count_api <= 3){
                this.count_api++
                this.getRateCurrency()
            }
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
      }
    },
    getCurrency(){
      if (this.$auth.check()){
        this.axios
        .get('/auth/countries-currency')
        .then(response => {
          if(response.data.status == 'success'){
            //console.log(response.data)
            this.count_api = 0
            let data = response.data.data
            let array_currencies = {}
            let item_languages = [] 
            let item_currencies = [] 
            if(data && data.resources){
              this.array_countries = data.resources
              for(let e of data.resources){
                let currency_code = []
                for(let c of e.currencies){
                  currency_code.push(c.alpha3)
                  array_currencies[c.alpha3] = c
                }
                for(let l of e.languages){
                  item_languages.push({
                    code: e.countries.alpha2.toLowerCase() + '-' + l.alpha2.toLowerCase(),
                    name: l.langEN,
                    country_name: e.countries.langEN,
                    currency_code: currency_code
                  })
                }
              }
              
              for(let e in array_currencies){
                item_currencies.push(array_currencies[e])
              }
            }
            //console.log(item_languages)
            //console.log(item_currencies)
            this.has_languages = true
            this.has_currencies = true
            this.array_languages = item_languages
            this.array_currencies = item_currencies

            this.getCredit()
          }
        })
        .catch(err => {
          if(this.count_api <= 3){
            this.count_api++
            this.getCurrency()
          }
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
      }
      else{
        this.getCredit()
      }
    },
    getCredit () {
      if ((this.$auth.check() && (this.$auth.user().role === 2) && (this.$auth.user().status_enterprise != 1)) || (this.$route.query && this.$route.query.enterprise_uuid)) {
        let enterprise_uuid = this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''
        this.axios
        .post('/billing/get-credit',{
          enterprise_uuid: enterprise_uuid
        })
        .then(response => {
          if(response.data.status == 'success'){
            this.count_api = 0
            this.currency = response.data.currency
            this.currency_rate = response.data.rate
            this.credit = response.data.credit
            this.data_currency = this.array_currencies.find(e => e.alpha3 == this.currency)
          }
        })
        .catch(err => {
          if(this.count_api <= 3){
            this.count_api++
            this.getCredit()
          }
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
      }
    },
    // formatNumber (number) {
    //   return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    // },
    formatNumber (number) {
        const num = Number(parseFloat(number));
        if (num && num !== 0 && Math.abs(num) < 0.01) {
            // ระบุตำแหน่งของตัวเลขที่สำคัญแรกโดยตรวจสอบจาก logarithm
            const significantDigits = Math.ceil(-Math.log10(Math.abs(num)));
            // ถ้าค่าที่ได้มากกว่า 2 ให้เรากำหนดความแม่นยำตามค่านั้น
            return num.toFixed(significantDigits > 2 ? significantDigits : 2);
        } else {
            // ค่ามากกว่าหรือเท่ากับ 0.01 ใช้ toFixed 2
            // return num ? num.toFixed(2) : 0;
            return new Intl.NumberFormat('th').format(num)
        }
      },
    LogoutUser () {
      return this.$auth.logout()
    }
  },
  // watch:{
  //   $route (to, from){
  //     console.log(to)
  //     console.log(this.menu_dashboard)
  //     this.menu_dashboard = 1
  //   }
  // }, 
  computed: {
    showCookieConsent () {
      return this.$store.state.app.showCookieConsent
    },
    innerWidth () {
      return parseInt(window.innerWidth)
    },
    topMenuLeft () {
      return [
        {
          label: this.$t('about'),
          to: {name: 'about'},
          showIfLoggedIn: true,
          showIfNotLoggedIn: true
        }
      ]
    },
    dashboardMenu () {
      let menu = []
      if (this.$auth.check()) {
        if (this.$auth.user().role === 1) { // ADMIN
          menu = [
            { heading: this.$t('admin') },
            {
              label: this.$t('keyword'),
              icon: 'mdi-google',
              to: {name: 'admin.tracking-keyword'}
            },
            {
              label: this.$t('order'),
              icon: 'mdi-shopping',
              to: {name: 'admin.backlink-history'}
            },
            {
              label: this.$t('serp_provider'),
              icon: 'mdi-google',
              to: {name: 'admin.serp-provider'}
            },
            {
              label: this.$t('manage_package'),
              icon: 'mdi-settings',
              to: {name: 'admin.tracking-manage-package'}
            },
            {
              label: this.$t('package'),
              icon: 'mdi-trophy',
              to: {name: 'admin.tracking-package'}
            },
            {
              label: this.$t('full_data'),
              icon: 'mdi-table-large',
              to: {name: 'admin.full-data'}
            },
            {
              label: this.$t('lighthouse'),
              icon: 'mdi-home',
              to: {name: 'admin.lighthouse-history'}
            },
            {
              label: this.$t('payment'),
              icon: 'mdi-square-inc-cash',
              to: {name: 'admin.billing-history'}
            },
            {
              label: this.$t('provider'),
              icon: 'mdi-api',
              to: {name: 'admin.providers'},
            },
            {
              label: this.$t('provider_service'),
              icon: 'mdi-view-list',
              to: {name: 'admin.services'}
            },
            {
              label: this.$t('provider_extras'),
              icon: 'mdi-view-module',
              to: {name: 'admin.extras'}
            },
            {
              label: this.$t('users'),
              icon: 'mdi-account-search-outline',
              to: {name: 'admin.users'}
            },
            // {
            //   label: this.$t('settings'),
            //   icon: 'mdi-account-circle',
            //   to: {name: 'admin.settings'}
            // },
          ]
        } else if (this.$auth.user().role === 2) { // CUSTOMER
          menu = []
          let count = 0
          if(this.$auth.user().page_keyword){
            count++
            menu.push({
              label: this.$t('keyword'),
              icon: 'mdi-google',
              to: {name: 'tracking-keyword'}
            })
          }
          if(this.$auth.user().page_order){
            count++
            menu.push({
              label: this.$t('order'),
              icon: 'mdi-shopping',
              to: {name: 'backlink-history'}
            })
          }
          if(this.$auth.user().page_lighthouse){
            count++
            menu.push({
              label: this.$t('lighthouse'),
              icon: 'mdi-home',
              to: {name: 'lighthouse-history'}
            })
          }
          
          if(!this.$auth.user().status_enterprise && this.$auth.user().page_payment){
            count++
            menu.push({
              label: this.$t('payment'),
              icon: 'mdi-square-inc-cash',
              to: {name: 'billing-history'}
            })
          }
          if(count <= 1){
            menu.push({
              label: this.$t('profile'),
              icon: 'mdi-account-circle',
              to: {name: 'settings.profile'}
            })
          }
        } else if (this.$auth.user().role === 3) { // SALES
          menu = [
            {
              label: this.$t('payment'),
              icon: 'mdi-square-inc-cash',
              to: {name: 'sales.billing'}
            },
            {
              label: this.$t('users'),
              icon: 'mdi-account-search-outline',
              to: {name: 'sales.users'}
            },
          ]
        }
      }
      return menu
    },
    accountMenu () {
      let menu = []
      if (this.$auth.check()) {
        if (this.$auth.user().role === 1) { // ADMIN
          menu = [
            { heading: this.$t('settings') },
            {
              label: this.$t('profile'),
              icon: 'mdi-account-circle',
              to: {name: 'settings.profile'}
            }
          ]
        } else if (this.$auth.user().role === 2) { // CUSTOMER
          menu = [
            { heading: this.$t('settings') },
            {
              label: this.$t('profile'),
              icon: 'mdi-account-circle',
              to: {name: 'settings.profile'}
            },
          ]
          // if(!this.$auth.user().status_enterprise){
          //   menu.push({
          //     label: this.$t('payment'),
          //     icon: 'mdi-square-inc-cash',
          //     to: {name: 'billing-history'}
          //   })
          // }
        } else if (this.$auth.user().role === 3) { // SALES
          menu = [
            { heading: this.$t('settings') },
            {
              label: this.$t('profile'),
              icon: 'mdi-account-circle',
              to: {name: 'settings.profile'}
            },
          ]
        }
      }
      return menu
    }
  }
};
</script>

<style>
.scroll_mobile .v-main__wrap{
  height: calc(100vh - 103px);
}
.scroll_desktop .v-main__wrap{
  height: calc(100vh - 55px);
} 
</style>

