<template>
  <div class="auth-main viewContainer" >
    <v-container class="ma-0 pa-0">
      
        <v-card-title class="ma-0 pr-5 pl-5 pt-5 pb-0">{{ $t('confirm_promptpay') }}</v-card-title>
        <v-card-text class="ma-0 pr-5 pl-5 pt-4 pb-0">
          <loading_input v-if="loading_main"/>
          <div class="view_div" v-if="!loading_main">
            <x-text-field
                type="number"
                v-model="form_payment.amount"
                :label="$t('msg_confirm_promptpay')"
                @keyup="CalAmountUSD"
            ></x-text-field>
          </div>
          <loading_input v-if="loading_main"/>
          <div class="view_div ma-0 d-flex justify-space-between" v-if="!loading_main">
            <div class="text-body-1 mt-auto mb-auto">{{$t('amount_thb')}}</div>
            <div class="text-h6 mt-auto mb-auto">{{ formatNumber(form_payment.amount_thb) }} THB</div>
          </div>
          <loading_input v-if="loading_main"/>
          <div class="view_div ma-0 d-flex justify-space-between" v-if="!loading_main">
            <div class="text-body-1 mt-auto mb-auto">{{$t('service_fee')}}</div>
            <div class="text-h6 mt-auto mb-auto">{{ formatNumber(form_payment.service_fee) }} THB ({{ form_payment.percent_service_fee }}%)</div>
          </div>
          <loading_input v-if="loading_main"/>
          <div class="view_div ma-0 d-flex justify-space-between" v-if="!loading_main">
            <div class="text-body-1 mt-auto mb-auto">{{$t('fee')}}</div>
            <div class="text-h6 mt-auto mb-auto">{{ formatNumber(form_payment.fee) }} THB ({{ form_payment.percent_fee }}%)</div>
          </div>
          <loading_input v-if="loading_main"/>
          <div class="view_div ma-0 d-flex justify-space-between" v-if="!loading_main">
            <div class="text-body-1 mt-auto mb-auto">{{$t('total_amount')}}</div>
            <div class="text-h6 mt-auto mb-auto">{{ formatNumber(form_payment.total_amount) }} THB</div>
          </div>
        </v-card-text>
        
        <v-card-actions class="ma-0 pr-5 pl-5 pt-5 pb-0">
          <v-spacer></v-spacer>
          <v-skeleton-loader
            v-if="loading_main" 
            class="mx-auto"
            type="button"
          ></v-skeleton-loader>
          <v-btn 
          v-if="!loading_main"
          color="primary" 
          rounded 
          depressed 
          @click.native="agree" 
          class="no-caps"
          :loading="loading"
          :disabled="loading"
          >{{ $t('save') }}</v-btn>
        </v-card-actions>
      
    </v-container>
  </div>
</template>
<script>
  
  export default {
    data: () => ({
      loading: false,
      loading_main: true,
      resolve: null,
      reject: null,
      form_payment: {
        rate_thb:  1,
        amount:  0,
        amount_thb:  0,
        service_fee:  0,
        percent_service_fee:  0,
        fee:  0,
        percent_fee:  0,
        total_amount:  0,
      },
    }),
    methods: {
      setDataPaymentPromptpay(){
        let data_payment = this.$store.state.app.data_payment
        data_payment['promptpay'] = this.form_payment
        this.$store.dispatch('setDataPayment', data_payment)
      },
      open() {
        if(this.$store.state && this.$store.state && this.$store.state.app.data_payment && this.$store.state.app.data_payment.promptpay){
          this.form_payment = this.$store.state.app.data_payment.promptpay
          this.loading_main = false
        }
        else{
          this.loading_main = true
          this.axios.get('/billing/get-main-currency')
          .then(res => {
            if (res.data.status === 'success') {
              this.form_payment.amount = 0
              this.form_payment.amount_thb = 0
              this.form_payment.rate_thb = res.data.rate_thb
              this.form_payment.percent_fee = parseFloat(res.data.fee_promptpay)
              this.form_payment.percent_service_fee = parseFloat(res.data.service_fee_promptpay)
              this.CalAmountUSD()
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading_main = false)
        }
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      formatCurrency (number,currency) {
        return currency ? new Intl.NumberFormat('th', {style: 'currency', currency: currency}).format(number) : '-'
      },
      CalAmountUSD(){
        if(this.form_payment.amount){
          this.form_payment.amount_thb = parseFloat(this.form_payment.amount)*this.form_payment.rate_thb
          this.form_payment.service_fee = parseFloat(this.form_payment.amount_thb) + (this.form_payment.amount_thb*this.form_payment.percent_service_fee/100)
          this.form_payment.fee = parseFloat(this.form_payment.amount_thb) + (this.form_payment.amount_thb*this.form_payment.percent_fee/100)
          this.form_payment.total_amount = parseFloat(this.form_payment.amount_thb) + (this.form_payment.amount_thb*this.form_payment.percent_service_fee/100)  + (this.form_payment.amount_thb*this.form_payment.percent_fee/100)
        }
        else{
          this.form_payment.service_fee = 0
          this.form_payment.fee = 0
          this.form_payment.total_amount = 0
          this.form_payment.amount_thb = 0
        }
        this.setDataPaymentPromptpay()
      },
      agree() {
        this.loading = true
        this.axios.post('/billing/add-promptpay', {
          amount: this.form_payment.amount
        })
        .then(res => {
          if (res.data.status === 'success') {
            //console.log(res.data)
            if(res.data.source && 
            res.data.source.scannable_code &&
            res.data.source.scannable_code.image
            ){
              this.$root.$qrcode_promptpay(res.data.source.scannable_code.image.download_uri)
            }

            let data_payment = this.$store.state.app.data_payment
            data_payment['promptpay'] = null
            this.$store.dispatch('setDataPayment', data_payment)

            // this.$root.$refs.dataTableReload()
            this.$router.push({
              name: 'billing-history', 
            })
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading = false)
      },
    }
  }
</script>
<style>
.loading_width_120 .v-skeleton-loader__button{
  width: 120px;
}
.loading_width_250 .v-skeleton-loader__button{
  width: 250px;
}
</style>