<template>
  <v-dialog 
  scrollable 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }" 
  transition="dialog-bottom-transition"
  >
    <v-card class="dialog-card">
      <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
        <div>
        {{ $t('confirm_active_lighthouse') }}
        </div>
        <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-5 ma-0">
        <!-- <div class="view_div d-flex justify-space-between">
          <div class="text-body-1 mt-auto mb-auto">{{ $t('price') }}</div>
          <div class="text-h6">
            <amount_currency :amount="parseFloat(price_lighthouse)" top></amount_currency>
          </div>
        </div>

        <div class="view_div d-flex justify-space-between" v-if="lighthouse.type == 'domain'">
          <div class="text-body-1 mt-auto mb-auto">{{$t('quantity')}} {{$t('domain')}}</div>
          <div class="text-h6">1</div>
        </div>
        <div class="view_div d-flex justify-space-between" v-if="lighthouse.type == 'url'">
          <div class="text-body-1 mt-auto mb-auto">{{$t('quantity')}} {{$t('url')}}</div>
          <div class="text-h6">{{ JSON.parse(lighthouse.urls).length }}</div>
        </div>

        <div class="view_div d-flex justify-space-between">
          <div class="text-body-1 mt-auto mb-auto">{{$t('amount')}}</div>
          <div class="text-h6 mt-auto mb-auto">
            <amount_currency :amount="parseFloat(lighthouse.price_usd)" top></amount_currency>
          </div>
        </div> -->

        <div class="view_div d-flex justify-space-between">
          <div class="text-body-1 mt-auto mb-auto">{{$t('type')}}</div>
          <div class="text-h6">{{$t(lighthouse.type)}}</div>
        </div>

        <div class="view_div d-flex justify-space-between" v-if="lighthouse.type == 'domain'">
          <div class="text-body-1 mt-auto mb-auto">{{$t('domain')}}</div>
          <div class="text-h6 mt-auto mb-auto">
            <a target="_blank">{{ encodeDecodeURL(lighthouse.site) }}</a>
          </div>
        </div>

        <div class="view_div d-flex justify-space-between" v-if="lighthouse.type == 'url'">
          <div class="text-body-1 mt-auto mb-auto">{{$t('url')}}</div>
          <div v-if="lighthouse && lighthouse.urls" class="text-right" style="max-width: 500px;">
            <v-chip
              v-for="(chip, index_chip) in JSON.parse(lighthouse.urls)" :key="'chip_' + index_chip"
              class="mt-2 ml-2"
              color="primary"
            >
              {{ encodeDecodeURL(chip) }}
            </v-chip>
          </div>      
        </div>

        <div class="view_div d-flex justify-space-between">
          <div class="text-body-1 mt-auto mb-auto">{{$t('device')}}</div>
          <div class="text-h6">{{$t(lighthouse.device)}}</div>
        </div>


      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-5 ma-0 ">
        <v-spacer></v-spacer>
        <!-- <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn> -->
        <v-btn 
          color="primary" 
          rounded 
          depressed 
          @click.native="agree" 
          class="no-caps"
          :loading="loading" 
          :disabled="loading"
        >
          {{ $t('confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify ConfirmPromptpay Dialog component
   *
   * Insert component where you want to use it:
   * <ConfirmPromptpay ref="ConfirmPromptpay"></ConfirmPromptpay>
   *
   * Call it:
   * this.$refs.ConfirmPromptpay.open('Delete', 'Are you sure?', { color: 'red' }).then((ConfirmPromptpay) => {})
   * Or use await:
   * if (await this.$refs.ConfirmPromptpay.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$ConfirmPromptpay
   * <template>
   *   <v-app>
   *     ...
   *     <ConfirmPromptpay ref="ConfirmPromptpay"></ConfirmPromptpay>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$ConfirmPromptpay = this.$refs.ConfirmPromptpay.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      loading: false,
      resolve: null,
      reject: null,
      uuid: null,
      lighthouse: {},
      price_lighthouse: 0,
      options: {
        color: 'primary',
        width: 720,
        zIndex: 200
      }
    }),
    methods: {
      open(uuid,lighthouse,price_lighthouse) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.uuid = uuid
        this.lighthouse = lighthouse
        this.price_lighthouse = price_lighthouse
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      encodeDecodeURL(url) {
        if(!url){
          return '' 
        }
        const parts = url.split('/');
        const decodedParts = parts.map(part => {
          try {
            // พยายามดีโค้ดแต่ละส่วนของ URL
            return decodeURIComponent(part);
          } catch {
            // หากเกิดข้อผิดพลาด, ส่งคืนส่วนนั้นๆ ออกมาเป็นเอนโค้ด
            return part;
          }
        });
        // รวมกลับเป็น URL ที่ดีโค้ดได้บางส่วนและเอนโค้ดบางส่วน
        return decodedParts.join('/');
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      formatCurrency (number,currency) {
        return currency ? new Intl.NumberFormat('th', {style: 'currency', currency: currency}).format(number) : '-'
      },
      agree() {

        this.loading = true
        this.axios.post('/lighthouse/active', {
          enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
          uuid: this.uuid,
        })
        .then(res => {
          if (res.data.status === 'success') {
            this.$root.$refs.master.enabledScroll()
            this.resolve({
              confirm: true,
            })
            this.dialog = false
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading = false)
        
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>