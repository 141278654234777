<template>
  <v-dialog 
  scrollable 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  transition="dialog-bottom-transition"
  >
    <v-card rounded class="d-flex flex-column dialog-card">
      <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
        <div>
          {{ $t('open_more_data') }}
        </div>

        <div>
          <v-tooltip top class="">
            <template v-slot:activator="{ on }">
              <v-btn
                @click.stop="copyData()" 
                v-on="on"
                fab 
                small 
                text
                color="grey"
                class="no-caps border-radius-circle"
              >
                <v-icon size="24">mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('copy') }}</span>
          </v-tooltip>

          <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
            <v-icon size="24">mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text rounded :class="'ma-0 pt-1 pb-5 pl-5 ' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">

        <v-simple-table v-if="item" class="">
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="(item_td_tble, index_td_tble) in Object.keys(item[0])" :key="index_td_tble" class="text-center">
                  {{ item_td_tble }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, rowIndex) in item" :key="rowIndex">
                <td v-for="(key, colIndex) in Object.keys(row)" :key="colIndex" class="text-center">
                  
                  <div v-if="row[key] && isArrayofObjects(row[key])">
                    <v-simple-table v-if="row[key].length" class="mr-2" style="max-width: 350px;">
                     <template v-slot:default>
                        <thead>
                          <tr>
                            <th v-for="(item_td_tble, index_td_tble) in Object.keys(row[key][0])" :key="index_td_tble" class="text-center">
                              {{ item_td_tble }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(row_sub, row_index) in row[key]" :key="row_index">
                            <td v-for="(key_sub, col_index) in Object.keys(row_sub)" :key="col_index" class="text-center">{{ row_sub[key_sub] }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                  <div v-else-if="row[key] && Array.isArray(row[key])">
                    <div class="d-flex flex-wrap"> 
                      <v-chip
                        v-for="(chip, index_chip) in row[key]" :key="'chip_' + index_chip"
                        class="ma-2 custom-chip"
                        color="primary"
                      >
                        {{ chip }}
                      </v-chip>
                    </div> 
                  </div>
                  <div v-else-if="row[key] && isPlainObject(row[key])">
                    <v-simple-table class="mr-2" style="max-width: 350px;">
                      <template v-slot:default>
                          <tbody>
                            <tr v-for="(item_td_tble, index_td_tble) in Object.keys(row[key])" :key="index_td_tble">
                              <td class="text-center" style="width: 150px;"><b>{{ item_td_tble }}</b></td>
                              <td class="text-center" style="width: 200px;">{{ row[key][item_td_tble] }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                  </div>
                  <div v-else-if="row[key] && validateURLWithRegex(row[key])">
                    <a class="mr-2" :href="row[key]" target="_blank">{{ encodeDecodeURL(row[key]) }}</a>
                  </div>
                  <div v-else>
                    {{ row[key] }}
                  </div>
                </td>
              </tr>
            </tbody>           
          </template>
        </v-simple-table>

      </v-card-text>
      <!-- <v-card-actions class="pl-5 pr-5 pb-5 pt-0 ma-0 ">
        <v-spacer></v-spacer>
        
        <v-btn color="grey" text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>
<script>
 
  export default {
    data: () => ({
      loading: false,
      dialog: false,
      resolve: null,
      reject: null,
      item: [],
      has_scrollbar: false,
      options: {
        color: 'primary',
        width: 720,
        zIndex: 200
      }
    }),
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
        }
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      encodeDecodeURL(url) {
        if(!url){
          return '';
        }
        //console.log(url)
        const parts = url.split('/');
        const decodedParts = parts.map(part => {
          try {
            // พยายามดีโค้ดแต่ละส่วนของ URL
            return decodeURIComponent(part);
          } catch {
            // หากเกิดข้อผิดพลาด, ส่งคืนส่วนนั้นๆ ออกมาเป็นเอนโค้ด
            return part;
          }
        });
        // รวมกลับเป็น URL ที่ดีโค้ดได้บางส่วนและเอนโค้ดบางส่วน
        return decodedParts.join('/');
      },
      copyData() {
        //console.log(this.item)
        let array = []
        let array_header = []
        let text_head = ''
        for(let e in this.item[0]){
          text_head += e+'\t'
          array_header.push(e)
        } 
        array.push(text_head)
        //console.log(text_head)
        for(let body of this.item){
          let td = ''
          for(let head of array_header){
            let data = body[head]
            if(!data){
               td += '-\t'
            }
            else if(this.isArrayofObjects(data)){
              td += (JSON.stringify(data) + '\t')
            }
            else if(Array.isArray(data)){
              td += (data + '\t')
            }
            else if(this.isPlainObject(data)){
              td += (JSON.stringify(data) + '\t')
            }
            else{
              td += (data + '\t')
            }
          }
          array.push(td)
        } 
        navigator.clipboard.writeText(array.join('\n')); 
        this.$root.$snackbar(this.$t('copied_to_clipboard'))
      },
      isArrayofObjects(data) {
        return Array.isArray(data) && data.every(item => 
          typeof item === 'object' && item !== null && !Array.isArray(item)
        );
      },
      isPlainObject(obj) {
          return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
      },
      validateURLWithRegex(url) {
        const pattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.[^\s]{2,})$/gi;
        return pattern.test(url);
      },
      open(item) {
        this.dialog = true
        this.item = item
        let that = this
        setTimeout(function(){
          that.$nextTick(() => {
            that.checkForScrollbar();
          });
        },100)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      cancel() {
        this.resolve({
          confirm: false
        })
        this.dialog = false
      }
    }
  }
</script>
<style>
.url-in-table{
  max-width: 360px;
  overflow-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.td-chip{
  max-width: 360px;
}
</style>


