<template>
  <v-tooltip 
  v-if="$root.$refs.master && $root.$refs.master.currency && $root.$refs.master.currency != 'USD'" 
  :top="top"
  :right="right"
  :left="left"
  :bottom="bottom"
  >
    <template v-slot:activator="{ on }">
      <span v-if="$root.$refs.master && $root.$refs.master.data_currency && $root.$refs.master.data_currency.position == 'before'" :class="class_text" v-on="on">
        {{ $root.$refs.master && $root.$refs.master.data_currency && $root.$refs.master.data_currency.symbol_hex ? hexToSymbols($root.$refs.master.data_currency.symbol_hex) : $root.$refs.master.currency }}{{ ($root.$refs.master && $root.$refs.master.data_currency && $root.$refs.master.data_currency.space_between_price_and_symbol ? '': ' ') }}{{formatNumber(parseFloat(amount)*$root.$refs.master.currency_rate) }}
      </span>
      <span v-else :class="class_text" v-on="on">
        {{ formatNumber(parseFloat(amount)*$root.$refs.master.currency_rate) }}{{ ($root.$refs.master && $root.$refs.master.data_currency && $root.$refs.master.data_currency.space_between_price_and_symbol ? '': ' ') }}{{ $root.$refs.master && $root.$refs.master.data_currency && $root.$refs.master.data_currency.symbol_hex ? hexToSymbols($root.$refs.master.data_currency.symbol_hex) : $root.$refs.master.currency }}
      </span>
    </template>
    <div>$ {{ formatNumber(amount)}}</div>
  </v-tooltip>
  <span :class="class_text" v-else>$ {{ formatNumber(amount) }}</span>
  
</template>
<script>
  export default {
    data: () => ({
      
    }),
    props: {
      amount: {
        default: 0,
        required: false,
        type: Number
      },
      class_text: {
        default: '',
        required: false,
        type: String
      },
      top: {
        default: false,
        required: false,
        type: Boolean
      },
      right: {
        default: false,
        required: false,
        type: Boolean
      },
      left: {
        default: false,
        required: false,
        type: Boolean
      },
      bottom: {
        default: false,
        required: false,
        type: Boolean
      },
    },
    created () {
      
    },
    methods: {
      hexToSymbols(hexString) {
        // แยกสตริงออกเป็นส่วนๆ โดยใช้เครื่องหมายเซมิโคลอน (;)
        const hexParts = hexString.split(';');
        
        // แปลงแต่ละส่วนจากเลขฐานสิบหกเป็นตัวอักษร และรวมกันเป็นสตริงเดียว
        const symbols = hexParts.map(part => String.fromCharCode(parseInt(part, 16))).join('');
        
        return symbols;
      },
      formatNumber (number) {
        const num = Number(parseFloat(number));
        if (num && num !== 0 && Math.abs(num) < 0.01) {
            // ระบุตำแหน่งของตัวเลขที่สำคัญแรกโดยตรวจสอบจาก logarithm
            const significantDigits = Math.ceil(-Math.log10(Math.abs(num)));
            // ถ้าค่าที่ได้มากกว่า 2 ให้เรากำหนดความแม่นยำตามค่านั้น
            return num.toFixed(significantDigits > 2 ? significantDigits : 2);
        } else {
            // ค่ามากกว่าหรือเท่ากับ 0.01 ใช้ toFixed 2
            // return num ? num.toFixed(2) : 0;
            return new Intl.NumberFormat('th').format(num)
        }
      },
    }
  }
</script>