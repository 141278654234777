<template>
  <v-dialog 
  scrollable 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  transition="dialog-bottom-transition"
  >
    <v-card rounded class="d-flex flex-column dialog-card">
      <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
        <div>
          {{ $t('table_backlink') }}
        </div>
        <div>
          <!-- <v-btn
            color="grey"
            text
            class="no-caps"
            @click="CopyBacklink()"
          >
            <v-icon>mdi-content-copy</v-icon>
            {{ $t('copy') }}
          </v-btn> -->

          <!-- <v-btn
            v-if="report_link"
            color="grey"
            text
            class="no-caps ml-2"
            @click="DownloadZip()"
          >
            <v-icon>mdi-zip-box</v-icon>
            {{ $t('download') }}
          </v-btn> -->

          <v-tooltip top class="">
            <template v-slot:activator="{ on }">
              <v-btn
                @click.stop="CopyBacklink()" 
                v-on="on"
                fab 
                small 
                text
                color="grey"
                class="no-caps border-radius-circle"
              >
                <v-icon size="20">mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('copy') }}</span>
          </v-tooltip>

          <v-tooltip v-if="report_link" top class="">
            <template v-slot:activator="{ on }">
              <v-btn
                @click.stop="DownloadZip()" 
                v-on="on"
                fab 
                small 
                text
                color="grey"
                class="no-caps border-radius-circle ml-2"
              >
                <v-icon size="20">mdi-zip-box</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('download') }}</span>
          </v-tooltip>

          <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
            <v-icon size="24">mdi-close</v-icon>
          </v-btn>

        </div>
        
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text rounded :class="'ma-0 pl-5 pt-5 pb-0' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">

        <v-tabs 
          v-if="type == 'keyword'"
          v-model="tab_order"
          :color="($store.state.app.dark) ? 'white' : 'black'"
          class="content-tabs mb-2"
          background-color="transparent"
          fixed-tabs
          show-arrows
        >
          <v-tab 
          v-for="item in item_order_backlink"
          :key="item.uuid"
          @click="selectOrder(item.data)"
          >
            Order ID: {{ item.uuid.substr(item.uuid.length - 5) }}
          </v-tab>

        </v-tabs>

        <v-tabs 
          v-model="tab_file"
          :color="($store.state.app.dark) ? 'white' : 'black'"
          class="content-tabs mb-2"
          background-color="transparent"
          fixed-tabs
          show-arrows
        >
          <v-tab 
          v-for="(item,index) in item_file_backlink"
          :key="index"
          @click="initTable(item)"
          >
             {{ item.filename }}
          </v-tab>

        </v-tabs>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="(item,index) in head_backlink"
                  class="text-center"
                  :key="index"
                >
                {{ $t(item.key) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item,index) in item_backlink"
                :key="index"
              >
                <td 
                  v-for="(body,index_body) in item.td"
                  :key="index_body"
                >   
                  <div v-if="
                  item.th[index_body] == 'url' || 
                  item.th[index_body] == 'link_url' || 
                  item.th[index_body] == 'linkonpage'
                  " 
                  :class="'text-left ' + (file_type != 'txt' ? 'url-in-table' : 'url-text-in-table')">
                    <a :href="body" target="_blank">{{ encodeDecodeURL(body) }}</a>
                  </div>
                  <div v-else-if="item.th[index_body] == 'created_at'" class="text-left">
                    {{ moment(body).tz($auth.user().timezone).format('DD/MM/YYYY') }}
                  </div>
                  <div v-else class="text-left">{{ body }}</div>
                </td>

                <!-- <td class="text-left">{{ moment(item['created_at']).tz($auth.user().timezone).format('DD/MM/YYYY') }}</td>
                <td class="text-left url-in-table">
                  <a :href="item['url']" target="_blank">{{ encodeDecodeURL(item['url']) }}</a>
                </td>
                <td class="text-left url-in-table">
                  <a :href="item['link_url']" target="_blank">{{ encodeDecodeURL(item['link_url']) }}</a>
                </td>
                <td class="text-center">{{ item['anchor'] }}</td>
                <td class="text-left">{{ item['engine'] }}</td>
                <td class="text-center">{{ item['dofollow'] }}</td>
                <td class="text-left">{{ item['email'] }}</td>
                <td class="text-left">{{ item['login'] }}</td>
                <td class="text-left">{{ item['password'] }}</td> -->
              </tr>
            </tbody>
          </template>
        </v-simple-table>

      </v-card-text>
      <!-- <v-card-actions class="pl-5 pr-5 pb-5 pt-0 ma-0 ">
        <v-spacer></v-spacer>
        
        <v-btn color="grey" text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify DialogTableBacklink Dialog component
   *
   * Insert component where you want to use it:
   * <DialogTableBacklink ref="DialogTableBacklink"></DialogTableBacklink>
   *
   * Call it:
   * this.$refs.DialogTableBacklink.open('Delete', 'Are you sure?', { color: 'red' }).then((DialogTableBacklink) => {})
   * Or use await:
   * if (await this.$refs.DialogTableBacklink.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$DialogTableBacklink
   * <template>
   *   <v-app>
   *     ...
   *     <DialogTableBacklink ref="DialogTableBacklink"></DialogTableBacklink>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$DialogTableBacklink = this.$refs.DialogTableBacklink.open
   * }
   */

  // import { copyStringToClipboard } from '@/utils/helpers';

  export default {
    data: () => ({
      loading: false,
      dialog: false,
      resolve: null,
      reject: null,
      type: null,
      date: [],
      tab_order: null,
      tab_file: null,
      item_backlink: [],
      item_file_backlink: [],
      file_type: null,
      head_backlink: [],
      item_order_backlink: [],
      report_link: '',
      has_scrollbar: false,
      options: {
        color: 'primary',
        width: 1024,
        zIndex: 200
      }
    }),
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
        }
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      selectOrder(backlink){
        //this.item_backlink = backlink
        this.initFile(backlink)
      },
      encodeDecodeURL(url) {
        if(!url){
          return '';
        }
        const parts = url.split('/');
        const decodedParts = parts.map(part => {
          try {
            // พยายามดีโค้ดแต่ละส่วนของ URL
            return decodeURIComponent(part);
          } catch {
            // หากเกิดข้อผิดพลาด, ส่งคืนส่วนนั้นๆ ออกมาเป็นเอนโค้ด
            return part;
          }
        });
        // รวมกลับเป็น URL ที่ดีโค้ดได้บางส่วนและเอนโค้ดบางส่วน
        return decodedParts.join('/');
      },
      copyText() {
        let array_backlink = []
        //console.log(this.item_backlink)
        let text_head = ''
        for(let e of this.head_backlink){
          text_head += e.key+'\t'
        } 
        array_backlink.push(text_head)
        for(let e of this.item_backlink){
          let td = e.td.join('\t')
          if(td){
            td = td.replace('\r', '')
          }
          array_backlink.push(td)
        } 
        //console.log(array_backlink)
        navigator.clipboard.writeText(array_backlink.join('\n')); 
        this.$root.$snackbar(this.$t('copied_to_clipboard'))
      },
      initTable(item_backlink){
        //console.log(item_backlink)
        this.file_type = item_backlink.filetype
        let head = []
        let body = []
        if(item_backlink.data.length){
          if(item_backlink.filetype == 'txt'){
            head.push({
                key: 'url',
            })
          }
          else{
            for(let key in item_backlink.data[0]){
              head.push({
                key: key,
              })
            }
          }

          head.push({
            key: 'created_at',
          })
          
          let now = 0
          for(let date of this.date){
            let new_data = item_backlink.data.slice(now, now + date.backlink);
            
            for(let e of new_data){
              let th = []
              let td = []
              if(item_backlink.filetype == 'txt'){
                th.push('url')
                td.push(e)
              }
              else{
                for(let key in e){
                  th.push(key)
                  td.push(e[key])
                }
              }
              th.push('created_at')
              td.push(date.created_at)

              body.push({
                th: th,
                td: td
              })
            }
            now += date.backlink
          }

          // for(let e of item_backlink.data){
          //   let th = []
          //   let td = []
          //   if(item_backlink.filetype == 'txt'){
          //     th.push('url')
          //     td.push(e)
          //   }
          //   else{
          //     for(let key in e){
          //       th.push(key)
          //       td.push(e[key])
          //     }
          //   }

          //   body.push({
          //     th: th,
          //     td: td
          //   })

          // }
          // console.log(head)
          // console.log(body)
        }
        this.head_backlink = head
        this.item_backlink = body
        let that = this
        setTimeout(function(){
          that.$nextTick(() => {
            that.checkForScrollbar();
          });
        },100)
      },
      CopyBacklink(){
        this.copyText()
      },
      DownloadZip(){
        window.open(this.report_link, '_blank');
      },
      initFile(item){
        //console.log(item)
        this.date = item.date
        this.item_file_backlink = item.backlink
        this.tab_file = 0
        this.initTable(item.backlink[0])
      },
      open(item_backlink,type,report_link) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.type = type
        this.report_link = report_link
 
        if(this.type == 'keyword'){
          this.item_order_backlink = item_backlink
          this.tab_order = this.item_order_backlink[0].uuid
          this.initFile(this.item_order_backlink[0].data)
          // this.selectOrder(this.item_order_backlink[0].backlink)
        }
        else{ 
          this.initFile(item_backlink)
          //this.item_backlink = item_backlink
        }
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false
        })
        this.dialog = false
      }
    }
  }
</script>
<style>
.url-in-table{
  max-width: 360px;
  overflow-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.url-text-in-table{
  max-width: 700px;
  overflow-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>