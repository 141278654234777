<template>
  <v-dialog 
  scrollable 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  transition="dialog-bottom-transition"
  >
    <ValidationObserver ref="validatePayment" v-slot="{ invalid }">
      <v-form 
        ref="formPayment" 
        @submit.prevent="SavePayment"
      >
        <v-card rounded class="dialog-card">
          <v-card-title class="headline">{{ $t('transfer') }}</v-card-title>
         
          <v-card-text>
            <div class="mb-2" v-if="form_payment.type == 'transfer'">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">
                        {{ $t('account_number') }}
                      </th>
                      <th class="text-center">
                        {{ $t('account_name') }}
                      </th>
                      <th class="text-center">
                        {{ $t('bank') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in bank_account"
                      :key="item.bank_account_id"
                    >
                      <td>{{ item.bank_account_id }}</td>
                      <td>{{ item.bank_account_name }}</td>
                      <td>{{ item.bank_account_branch }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <div class="mb-2" v-if="form_payment.type == 'transfer' || form_payment.type == 'topup_by_admin'">
              <v-label v-if="form_payment.type == 'transfer'" :class="'input-label mb-2 '+label_cover">{{ $t('image_slip') }}</v-label>
              <v-label v-if="form_payment.type == 'topup_by_admin'" :class="'input-label mb-2 '+label_cover">{{ $t('related_image') }}</v-label>
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-avatar
                    width="100%"
                    :height="!view ? 250 : '100%'"
                    class="input-upload"
                  >
                    <v-img :src="form_payment.slip" class="" contain 
                    :style="{'width': '100%'}" >
                      <v-fade-transition v-if="!view">
                        <v-overlay
                          v-if="hover"
                          absolute
                        >
                            <v-btn @click="UploadSlip" small fab color="primary"><v-icon size="14">mdi-folder-open</v-icon></v-btn>
                            <v-btn v-if="form_payment.slip" @click="form_payment.slip=null; form_payment.upload_slip=null; $refs.fileupload.value=null;" class="ml-2" small fab color="error"><v-icon size="14">mdi-close</v-icon></v-btn>
                        </v-overlay>
                      </v-fade-transition>
                    </v-img>
                  </v-avatar>
                </template>
              </v-hover>
              <input
                v-if="!view"
                type="file"
                style="display: none"
                id="slip"
                ref="fileupload"
                name="slip"
                accept="image/*"
                @change="onFilePicked"
              >
            </div>

            <div v-if="view"> 
              <div class="view_div">
                <div class="text-body-1">{{$t('type')}}</div>
                <div class="text-h6">{{ $t(form_payment.type) }} </div>
              </div>

              <div class="view_div">
                <div class="text-body-1">{{$t('status')}}</div>
                <div class="text-h6">{{ $t(form_payment.status) }} </div>
              </div>

              <div v-if="form_payment.type == 'transfer' && form_payment.status == 'unapprove'" class="view_div">
                <div class="text-body-1">{{$t('reason')}}</div>
                <div class="text-h6" style="white-space: pre;">{{ form_payment.note_disapprove }} </div>
              </div>
            </div>

            <div v-if="!view"> 
              <x-text-field
                type="number"
                rules="required"
                v-model="form_payment.amount"
                :label="$t('amount')"
                filled
                @keyup="CalAmountUSD"
              ></x-text-field>
            </div>
            <div v-if="view"> 
              <div class="view_div">
                <div class="text-body-1">{{$t('amount')}}</div>
                <div class="text-h6">{{ formatNumber(form_payment.amount) }} </div>
              </div>
            </div>

            <div v-if="!view">
              <x-select
                :items="item_currency"
                v-model="form_payment.currency"
                rules="required"
                :label="$t('currency')"
                item-text="name"
                item-value="value"
                filled
                @change="ChangeCurrency"
              ></x-select>
            </div>

            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('currency')}}</div>
              <div class="text-h6">{{ form_payment.currency }}</div>
            </div>

            <div class="view_div">
              <div class="text-body-1">{{$t('service_fee')}}</div>
              <div class="text-h6">{{ formatNumber(form_payment.service_fee) }} {{ form_payment.currency }} ({{ form_payment.percent_service_fee }}%)</div>
            </div>
            
            <div class="view_div">
              <div class="text-body-1">{{$t('fee')}}</div>
              <div class="text-h6">{{ formatNumber(form_payment.fee) }} {{ form_payment.currency }} ({{ form_payment.percent_fee }}%)</div>
            </div>
            <div class="view_div">
              <div class="text-body-1">{{$t('total_amount')}}</div>
              <div class="text-h6">{{ formatNumber(form_payment.total_amount) }} {{ form_payment.currency }}</div>
            </div>
            <div class="view_div">
              <div class="text-body-1">{{$t('amount_usd')}}</div>
              <div class="text-h6">{{ formatNumber(form_payment.amount_usd )}} USD</div>
            </div>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
            <v-btn 
              v-if="!view"
              type="submit" 
              color="primary" 
              depressed 
              class="no-caps"
              :loading="loading" :disabled="loading || invalid"
              >{{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify DialogPaymentTransfer Dialog component
   *
   * Insert component where you want to use it:
   * <DialogPaymentTransfer ref="DialogPaymentTransfer"></DialogPaymentTransfer>
   *
   * Call it:
   * this.$refs.DialogPaymentTransfer.open('Delete', 'Are you sure?', { color: 'red' }).then((DialogPaymentTransfer) => {})
   * Or use await:
   * if (await this.$refs.DialogPaymentTransfer.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$DialogPaymentTransfer
   * <template>
   *   <v-app>
   *     ...
   *     <DialogPaymentTransfer ref="DialogPaymentTransfer"></DialogPaymentTransfer>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$DialogPaymentTransfer = this.$refs.DialogPaymentTransfer.open
   * }
   */

  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      view: false,
      loading: false,
      bank_account: [],
      item_currency: [],
      main_currency: 'USD',
      main_currency_rate: 1,
      currency_rate: 1,
      fee: 0,
      service_fee: 0,
      percent_fee: 0,
      percent_service_fee: 0,
      label_cover: '',
      locale: 'TH',
      form_payment: {
        amount: 0,
        currency: 'USD',
        amount_usd: 0,
      },
      options: {
        color: 'primary',
        width: 720,
        zIndex: 200
      }
    }),
    methods: {
      open(view,item,item_currency,main_currency,main_currency_rate,bank_account,fee,service_fee) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        if(this.$refs.validatePayment){
          this.$refs.validatePayment.reset();
        }
        this.view = view
        this.item_currency = item_currency
        this.main_currency = main_currency
        this.main_currency_rate = parseFloat(main_currency_rate)
        this.currency_rate = parseFloat(main_currency_rate)
        this.bank_account = bank_account
        this.form_payment = item
        this.form_payment.percent_fee = parseFloat(fee)
        this.form_payment.percent_service_fee = parseFloat(service_fee)
        //console.log(this.form_payment.currency)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      formatCurrency (number,currency) {
        return currency ? new Intl.NumberFormat('th', {style: 'currency', currency: currency}).format(number) : '-'
      },
      UploadSlip () {
        document.getElementById('slip').click()
      },
      onFilePicked (e) {
        const files = e.target.files
        //console.log(files)
        if(files[0] !== undefined) {
          if(files[0].name.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader ()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.form_payment.slip = fr.result
            this.form_payment.upload_slip = files[0]
          })
        }
      },
      
      SavePayment() {
        if(!this.form_payment.upload_slip){
          this.label_cover = 'error--text'
          this.$root.$snackbar(this.$t('enter_img'))
          return false
        }
        this.label_cover = ''

        this.$root.$confirm(this.$t('confirm'), this.$t('confirm_create_transfer'),{
          color: 'primary',
          width: 360,
          zIndex: 200
        },false)
        .then((confirm) => {
          if (confirm.confirm) {
            this.loading = true

            let settings = { headers: { 'content-type': 'multipart/form-data' } }
            let formData = new FormData()
            formData.append('slip', this.form_payment.upload_slip)
            formData.append('amount', this.form_payment.amount)
            formData.append('currency', this.form_payment.currency)
            
            this.axios.post('/billing/add-transfer', formData, settings)
            .then(res => {
              if (res.data.status === 'success') {
                this.$root.$refs.master.enabledScroll()
                this.resolve({
                  confirm: true
                })
                this.dialog = false
              }
            })
            .catch(err => {
              if (err.response.data.status === 'error') {
                this.$root.$snackbar(err.response.data.msg)
              }
            })
            .finally(() => this.loading = false)
          }
        })
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false
        })
        this.dialog = false
      },
      ChangeCurrency() {
        if(this.form_payment.currency != 'USD'){
          let currency = this.form_payment.currency != this.main_currency ? this.form_payment.currency : 'USD'
          this.axios.post('/billing/get-transfer-currency', {
            currency: currency,
          })
          .then(res => {
            if (res.data.status === 'success') {
              // console.log(res.data)
              this.currency_rate = res.data.rate
              this.CalAmountUSD()
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
        }
        else{
          this.CalAmountUSD()
        }
      },
      CalAmountUSD(){
        //console.log(this.form_payment.currency)
        if(this.form_payment.amount){
          if(this.form_payment.currency != 'USD'){
            if(this.form_payment.currency == this.main_currency){
              //console.log((1/this.main_currency_rate))
              this.form_payment.amount_usd = this.form_payment.amount*(1/this.main_currency_rate)
            }
            else{
              this.form_payment.amount_usd = this.form_payment.amount*(this.currency_rate/this.main_currency_rate)
            }
          
          }
          else{
            this.form_payment.amount_usd = this.form_payment.amount
          }

          this.form_payment.service_fee = parseFloat(this.form_payment.amount) + (this.form_payment.amount*this.form_payment.percent_service_fee/100)
          this.form_payment.fee = parseFloat(this.form_payment.amount) + (this.form_payment.amount*this.form_payment.percent_fee/100)
          this.form_payment.total_amount = parseFloat(this.form_payment.amount) + (this.form_payment.amount*this.form_payment.percent_service_fee/100)  + (this.form_payment.amount*this.form_payment.percent_fee/100)
        }
        else{
          this.form_payment.amount_usd = 0
          this.form_payment.service_fee = 0
          this.form_payment.fee = 0
          this.form_payment.total_amount = 0
        }
      },
    },
  }
</script>