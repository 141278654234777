'use strict';

import Vue from 'vue'

// Data
import DataTable from '@/components/data/DataTable.vue'
Vue.component('data-table', DataTable)

import DataForm from '@/components/data/DataForm.vue'
Vue.component('data-form', DataForm)

// Form elements
import XTextField from '@/components/form/XTextField.vue'
Vue.component('x-text-field', XTextField)

import XTextArea from '@/components/form/XTextArea.vue'
Vue.component('x-text-area', XTextArea)

import XPassword from '@/components/form/XPassword.vue'
Vue.component('x-password', XPassword)

import XCheckbox from '@/components/form/XCheckbox.vue'
Vue.component('x-checkbox', XCheckbox)

import XSelect from '@/components/form/XSelect.vue'
Vue.component('x-select', XSelect)

import XAutoComplete from '@/components/form/XAutoComplete.vue'
Vue.component('x-autocomplete', XAutoComplete)

import XEditor from '@/components/form/XEditor.vue'
Vue.component('x-editor', XEditor)

import XColorPicker from '@/components/form/XColorPicker.vue'
Vue.component('x-color-picker', XColorPicker)

import XDatePicker from '@/components/form/XDatePicker.vue'
Vue.component('x-date-picker', XDatePicker)

import XDateTimePicker from '@/components/form/XDateTimePicker.vue'
Vue.component('x-datetime-picker', XDateTimePicker)

// Snackbar
import Snackbar from '@/components/ui/Snackbar.vue'
Vue.component('snackbar', Snackbar)

// Confirm
import Confirm from '@/components/ui/Confirm.vue'
Vue.component('confirm', Confirm)

// ReportSEO
import ReportSEO from '@/components/ui/ReportSEO.vue'
Vue.component('report_seo', ReportSEO)

// // ReportSEO
// import ReportKeyword from '@/components/ui/ReportKeyword.vue'
// Vue.component('report_keyword', ReportKeyword)

// ReportSEORival
import ReportSEORival from '@/components/ui/ReportSEORival.vue'
Vue.component('report_seo_rival', ReportSEORival)

// // Dialog Add Keyword
// import DialogAddKeyword from '@/components/ui/DialogAddKeyword.vue'
// Vue.component('dialog_add_keyword', DialogAddKeyword)

// Select Services Providers
import SelectServicesProviders from '@/components/ui/SelectServicesProviders.vue'
Vue.component('select_services_providers', SelectServicesProviders)

// Select Extras Providers
import SelectExtrasProviders from '@/components/ui/SelectExtrasProviders.vue'
Vue.component('select_extras_providers', SelectExtrasProviders)

// Select Payment
import SelectPayment from '@/components/ui/SelectPayment.vue'
Vue.component('select_payment', SelectPayment)

// Select Payment
import DialogPayment from '@/components/ui/DialogPayment.vue'
Vue.component('dialog_payment', DialogPayment)

// Confirm Promptpay
import ConfirmPromptpay from '@/components/ui/ConfirmPromptpay.vue'
Vue.component('confirm_promptpay', ConfirmPromptpay)

// Qrcode Promptpay
import QrcodePromptpay from '@/components/ui/QrcodePromptpay.vue'
Vue.component('qrcode_promptpay', QrcodePromptpay)

// Confirm CreditCard
import ConfirmCreditCard from '@/components/ui/ConfirmCreditCard.vue'
Vue.component('confirm_credit_card', ConfirmCreditCard)

// Update CreditCard
import UpdateCreditCard from '@/components/ui/UpdateCreditCard.vue'
Vue.component('update_credit_card', UpdateCreditCard)

// // Dialog create Order
// import DialogCreateOrder from '@/components/ui/DialogCreateOrder.vue'
// Vue.component('dialog_create_order', DialogCreateOrder)

// Dialog view Order
import DialogViewOrder from '@/components/ui/DialogViewOrder.vue'
Vue.component('dialog_view_order', DialogViewOrder)

// Dialog view Backlink
import DialogViewBacklink from '@/components/ui/DialogViewBacklink.vue'
Vue.component('dialog_view_backlink', DialogViewBacklink)

// Dialog Customer TopUp
import DialogCustomerTopUp from '@/components/ui/DialogCustomerTopUp.vue'
Vue.component('dialog_customer_topup', DialogCustomerTopUp)

// Custom Filters
import CustomFilters from '@/components/ui/CustomFilters.vue'
Vue.component('custom_filters', CustomFilters)

// Custom Column
import CustomColumn from '@/components/ui/CustomColumn.vue'
Vue.component('custom_column', CustomColumn)

// Menu Keyword
import MenuKeyword from '@/components/ui/MenuKeyword.vue'
Vue.component('menu_keyword', MenuKeyword)


// // Panel Add Keyword
// import CreateKeyword from '@/components/panel/CreateKeyword.vue'
// Vue.component('create_keyword', CreateKeyword)

// // Panel Create Order
// import CreateOrder from '@/components/panel/CreateOrder.vue'
// Vue.component('create_order', CreateOrder)

// // Panel Create Order
// import CreatePayment from '@/components/panel/CreatePayment.vue'
// Vue.component('create_payment', CreatePayment)

// Panel Payment Transfer
import PaymentTransfer from '@/components/panel/PaymentTransfer.vue'
Vue.component('payment_transfer', PaymentTransfer)

// Panel Payment Transfer
import PaymentCreditCard from '@/components/panel/PaymentCreditCard.vue'
Vue.component('payment_credit_card', PaymentCreditCard)

// Panel Payment Transfer
import PaymentPromptpay from '@/components/panel/PaymentPromptpay.vue'
Vue.component('payment_promptpay', PaymentPromptpay)

// // Panel Dashboard
// import Dashboard from '@/views/customer/Dashboard.vue'
// Vue.component('dashboard', Dashboard)

// Panel Create Buy Package
// import CreateBuyPackage from '@/components/panel/CreateBuyPackage.vue'
// Vue.component('create_buy_package', CreateBuyPackage)

// // Panel Package
// import Package from '@/components/panel/Package.vue'
// Vue.component('package', Package)

// Select Date Active Keyword
import SelectDateActiveKeyword from '@/components/ui/SelectDateActiveKeyword.vue'
Vue.component('select_date_active_keyword', SelectDateActiveKeyword)

// Select Date Boost Keyword
import SelectDateBoostKeyword from '@/components/ui/SelectDateBoostKeyword.vue'
Vue.component('select_date_boost_keyword', SelectDateBoostKeyword)

// Change Backlink Default
import ChangeBacklinkDefault from '@/components/ui/ChangeBacklinkDefault.vue'
Vue.component('change_backlink_default', ChangeBacklinkDefault)

// Change Action
import ChangeAction from '@/components/ui/ChangeAction.vue'
Vue.component('change_action', ChangeAction)

// Report Backlink
import ReportBacklink from '@/components/ui/ReportBacklink.vue'
Vue.component('report_backlink', ReportBacklink)

// Report SEO All
import ReportSEOAll from '@/components/ui/ReportSEOAll.vue'
Vue.component('report_seo_all', ReportSEOAll)

// Report Backlink Order
import ReportBacklinkOrder from '@/components/ui/ReportBacklinkOrder.vue'
Vue.component('report_backlink_order', ReportBacklinkOrder)

// Dialog Edit Serp Click
import DialogEditSerpClick from '@/components/ui/DialogEditSerpClick.vue'
Vue.component('dialog_edit_serpclick', DialogEditSerpClick)

// Dialog Table Backlink
import DialogTableBacklink from '@/components/ui/DialogTableBacklink.vue'
Vue.component('dialog_table_backlink', DialogTableBacklink)

// Loading Input
import LoadingInput from '@/components/ui/LoadingInput.vue'
Vue.component('loading_input', LoadingInput)

// Change Languages Currency
import ChangeLanguagesCurrency from '@/components/ui/ChangeLanguagesCurrency.vue'
Vue.component('change_languages_currency', ChangeLanguagesCurrency)

// Dialog View Package
import DialogViewPackage from '@/components/ui/DialogViewPackage.vue'
Vue.component('dialog_view_package', DialogViewPackage)

// Dialog View Package
import DialogViewPackageBoostKeyword from '@/components/ui/DialogViewPackageBoostKeyword.vue'
Vue.component('dialog_view_package_boost_keyword', DialogViewPackageBoostKeyword)

// Amount Currency
import AmountCurrency from '@/components/ui/AmountCurrency.vue'
Vue.component('amount_currency', AmountCurrency)

// Confirm Delete
import ConfirmDelete from '@/components/ui/ConfirmDelete.vue'
Vue.component('confirm_delete', ConfirmDelete)

// Panel Create
import PanelCreate from '@/components/ui/PanelCreate.vue'
Vue.component('panel_create', PanelCreate)

// Setting Payment
import SettingPayment from '@/components/ui/SettingPayment.vue'
Vue.component('setting_payment', SettingPayment)

// Setting Menu
import SettingMenu from '@/components/ui/SettingMenu.vue'
Vue.component('setting_menu', SettingMenu)

// Change Column
import ChangeColumn from '@/components/ui/ChangeColumn.vue'
Vue.component('change_column', ChangeColumn)

// Filters Dashboard
import FiltersDashboard from '@/components/ui/FiltersDashboard.vue'
Vue.component('filters_dashboard', FiltersDashboard)

// Dialog Table Serpclick
import DialogTableSerpclick from '@/components/ui/DialogTableSerpclick.vue'
Vue.component('dialog_table_serpclick', DialogTableSerpclick)

// Dialog Table Report
import DialogTableReport from '@/components/ui/DialogTableReport.vue'
Vue.component('dialog_table_report', DialogTableReport)

// Dialog Table Report
import Sparkline from '@/components/ui/Sparkline.vue'
Vue.component('sparkline', Sparkline)

// Panel Package
import PanelPackage from '@/components/panel/PanelPackage.vue'
Vue.component('panel_package', PanelPackage)

// Panel Package Boost Keyword
import PanelPackageBoostKeyword from '@/components/panel/PanelPackageBoostKeyword.vue'
Vue.component('panel_package_boost_keyword', PanelPackageBoostKeyword)

// Panel Package Full Data Package keyword
import PanelPackageKeywordFullData from '@/components/panel/PanelPackageKeywordFullData.vue'
Vue.component('panel_package_keyword_full_data', PanelPackageKeywordFullData)

// Panel Package Full Data Package boost keyword
import PanelPackageBoostKeywordFullData from '@/components/panel/PanelPackageBoostKeywordFullData.vue'
Vue.component('panel_package_boost_keyword_full_data', PanelPackageBoostKeywordFullData)

// Panel Service Full Data
import PanelServiceFullData from '@/components/panel/PanelServiceFullData.vue'
Vue.component('panel_service_full_data', PanelServiceFullData)

// Panel Extra Full Data
import PanelExtraFullData from '@/components/panel/PanelExtraFullData.vue'
Vue.component('panel_extra_full_data', PanelExtraFullData)

// Setting Dataforseo Playground
import SettingDataforseoPlayground from '@/components/ui/SettingDataforseoPlayground.vue'
Vue.component('setting_dataforseo_playground', SettingDataforseoPlayground)

// Dialog Table Insights
import DialogTableInsights from '@/components/ui/DialogTableInsights.vue'
Vue.component('dialog_table_insights', DialogTableInsights)

// Dialog Expand Table
import DialogExpandTable from '@/components/ui/DialogExpandTable.vue'
Vue.component('dialog_expand_table', DialogExpandTable)

// Dialog Expand Table Object
import DialogExpandTableObject from '@/components/ui/DialogExpandTableObject.vue'
Vue.component('dialog_expand_table_object', DialogExpandTableObject)


// Dialog View Lighthouse
import DialogViewLighthouse from '@/components/ui/DialogViewLighthouse.vue'
Vue.component('dialog_view_lighthouse', DialogViewLighthouse)

// Confirm Active Lighthouse
import ConfirmActiveLighthouse from '@/components/ui/ConfirmActiveLighthouse.vue'
Vue.component('confirm_active_lighthouse', ConfirmActiveLighthouse)

// Dialog Table Lighthouse
import DialogTableLighthouse from '@/components/ui/DialogTableLighthouse.vue'
Vue.component('dialog_table_lighthouse', DialogTableLighthouse)

// Dialog Iframe
import DialogIframe from '@/components/ui/DialogIframe.vue'
Vue.component('dialog_iframe', DialogIframe)
