<template>
  <v-dialog 
  scrollable 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  transition="dialog-bottom-transition"
  >
      <v-card rounded class="dialog-card">
        <v-card-title class="headline">{{ $t('select_payment') }}</v-card-title>
        <v-card-text class="text-center">
          <v-btn 
          v-if="status_payment_transfer"
          class="icon_payment" 
          color="primary"
          x-large
          @click="selectPayment('transfer')"
          >
            <v-icon
              top
              large
            >
            mdi-cash-multiple
            </v-icon>
            <div>{{ $t('transfer') }}</div>
            
          </v-btn>
        </v-card-text>
        <v-card-text class="text-center">
          <v-btn 
          v-if="status_payment_credit_card"
          class="icon_payment" 
          color="primary"
          x-large
          @click="selectPayment('credit_card')"
          >
          <v-icon
              top
              large
            >
            mdi-credit-card
            </v-icon>
            <div>{{ $t('credit_card') }}</div>
            
          </v-btn>
        </v-card-text>
        <v-card-text class="text-center">
          <v-btn 
          v-if="status_payment_promptpay"
          class="icon_payment" 
          color="primary"
          x-large
          @click="selectPayment('promptpay')"
          >
          <v-icon
              top
              large
            >
            mdi-qrcode
            </v-icon>
            <div>{{ $t('promptpay') }}</div>
            
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify SelectPayment Dialog component
   *
   * Insert component where you want to use it:
   * <SelectPayment ref="SelectPayment"></SelectPayment>
   *
   * Call it:
   * this.$refs.SelectPayment.open('Delete', 'Are you sure?', { color: 'red' }).then((SelectPayment) => {})
   * Or use await:
   * if (await this.$refs.SelectPayment.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$SelectPayment
   * <template>
   *   <v-app>
   *     ...
   *     <SelectPayment ref="SelectPayment"></SelectPayment>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$SelectPayment = this.$refs.SelectPayment.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      status_payment_transfer: false,
      status_payment_credit_card: false,
      status_payment_promptpay: false,
      options: {
        color: 'primary',
        width: 360,
        zIndex: 200
      }
    }),
    methods: {
      open(status_payment_transfer,status_payment_credit_card,status_payment_promptpay) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.status_payment_transfer = status_payment_transfer
        this.status_payment_credit_card = status_payment_credit_card
        this.status_payment_promptpay = status_payment_promptpay
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      selectPayment(type) {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: true,
          type: type
        })
        this.dialog = false
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>