<template>
  <v-dialog 
  scrollable 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }" 
  transition="dialog-bottom-transition"
  >
    <v-card class="dialog-card">
      <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
        <div>
          {{ $t('edit_default_backlink') }}
        </div>
        
        <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text rounded :class="'ma-0 pb-5 pt-5 pl-5 ' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">
        <div class="mt-8">
          <v-item-group 
            v-for="(item, index) in array_setting_backlink" :key="index"
          >
            <v-row>
              <v-col
                cols="6"
                class="pb-0"
              >
                <x-text-field
                  type="number"
                  v-model="item.days"
                  :label="$t('day')"
                  :name="$t('day')"
                  dense
                />
              </v-col>
              
              <v-col
                cols="6"
                class="pb-0"
              >
                <x-text-field
                  type="number"
                  v-model="item.tolerance"
                  :label="$t('tolerance')"
                  :name="$t('tolerance')"
                  dense
                />
              </v-col>
              <v-col
                cols="6"
                class="pb-0"
              >
                <x-text-field
                  type="number"
                  v-model="item.min_daily_change"
                  :label="$t('min_daily_change')"
                  :name="$t('min_daily_change')"
                  dense
                />
              </v-col>
              <v-col
                cols="6"
                class="pb-0"
              >
                <x-text-field
                  type="number"
                  v-model="item.max_daily_change"
                  :label="$t('max_daily_change')"
                  :name="$t('max_daily_change')"
                  dense
                />
              </v-col>
              <v-col
                cols
                class="pb-0"
              >
                <x-text-field
                  type="number"
                  v-model="item.max_backlink"
                  :label="$t('max_backlink')"
                  :name="$t('max_backlink')"
                  dense
                />
              </v-col>
              <v-col
                cols="auto"
                class="pt-0 d-flex"
              >
                <div class="d-flex ma-auto pt-2">
                  <v-btn color="" depressed small fab
                  @click="deleteItemBacklink(index)"
                  :disabled="item.delete_disabled" 
                  class="no-caps" >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                  <v-btn color="" depressed small fab  
                  @click="addItemBacklink(index)"
                  :disabled="item.add_disabled" 
                  class="no-caps ml-2" >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </v-col>

            </v-row>
            <v-divider class="mt-4 mb-6"></v-divider>
          </v-item-group>

          <v-card-text> {{ $t('more_backlink') }}</v-card-text>
          <v-row>
            <v-col
              cols="6"
              class="pb-0"
            >
              <x-text-field
                type="number"
                v-model="last_item.days"
                :label="$t('day')"
                :name="$t('day')"
                dense
              />
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <x-text-field
                type="number"
                v-model="last_item.tolerance"
                :label="$t('tolerance')"
                :name="$t('tolerance')"
                dense
              />
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <x-text-field
                type="number"
                v-model="last_item.min_daily_change"
                :label="$t('min_daily_change')"
                :name="$t('min_daily_change')"
                dense
              />
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <x-text-field
                type="number"
                v-model="last_item.max_daily_change"
                :label="$t('max_daily_change')"
                :name="$t('max_daily_change')"
                dense
              />
            </v-col>
            

          </v-row>
        </div>      
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="ma-0 pa-5">
        <v-spacer></v-spacer>
        <!-- <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn> -->
        <v-btn color="primary" rounded depressed @click.native="agree" :loading="loading" :disabled="loading" class="no-caps">{{ $t('save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>  
<script>
  /**
   * Vuetify ChangeBacklinkDefault Dialog component
   *
   * Insert component where you want to use it:
   * <ChangeBacklinkDefault ref="ChangeBacklinkDefault"></ChangeBacklinkDefault>
   *
   * Call it:
   * this.$refs.ChangeBacklinkDefault.open('Delete', 'Are you sure?', { color: 'red' }).then((ChangeBacklinkDefault) => {})
   * Or use await:
   * if (await this.$refs.ChangeBacklinkDefault.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$ChangeBacklinkDefault
   * <template>
   *   <v-app>
   *     ...
   *     <ChangeBacklinkDefault ref="ChangeBacklinkDefault"></ChangeBacklinkDefault>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$ChangeBacklinkDefault = this.$refs.ChangeBacklinkDefault.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      loading: false,
      resolve: null,
      reject: null,
      uuid: null,
      array_setting_backlink: [],
      last_item: {},
      has_scrollbar: false,
      options: {
        color: 'primary',
        width: 720,
        zIndex: 200
      } 
    }),
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
        }
      },
      open(uuid,json_setting_backlink) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.uuid = uuid
        //console.log(json_setting_backlink)
        let array_setting_backlink = []
        let count = 1
        for(let e of json_setting_backlink){
          if(!e.max_limit_backlink){
            array_setting_backlink.push({
              max_limit_backlink: false,
              max_backlink: e.max_backlink,
              tolerance: e.tolerance,
              days: e.days,
              min_daily_change: e.min_daily_change,
              max_daily_change: e.max_daily_change,
              add_disabled: (count == json_setting_backlink.length-1 ? false : true),
              delete_disabled: (count == json_setting_backlink.length-1 ? false : true)
            })
            count++
          }
          else{
            this.last_item = e
          }
        }
        this.array_setting_backlink = array_setting_backlink
        let that = this
        setTimeout(function(){
          that.$nextTick(() => {
            that.checkForScrollbar();
          });
        },100)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      deleteItemBacklink(index){
        this.array_setting_backlink[index-1].add_disabled = false
        if(this.array_setting_backlink.length > 2){
          this.array_setting_backlink[index-1].delete_disabled = false
        }
        else{
          this.array_setting_backlink[index-1].delete_disabled = true
        }
        this.array_setting_backlink[index-1].max_disabled = false
        this.array_setting_backlink.length = this.array_setting_backlink.length - 1; 
      },
      addItemBacklink(index){
        let data_old = this.array_setting_backlink[index]
        this.array_setting_backlink[index].add_disabled = true
        this.array_setting_backlink[index].delete_disabled = true
        let max_backlink = data_old.max_backlink*1.5
        this.array_setting_backlink.push({
          max_limit_backlink: false,
          max_backlink: max_backlink,
          tolerance: data_old.tolerance,
          days: data_old.days,
          min_daily_change: data_old.min_daily_change,
          max_daily_change: data_old.max_daily_change,
          add_disabled: false,
          delete_disabled: false
        })
        //console.log(this.array_setting_backlink)
      },
      agree() {
        //console.log(this.array_setting_backlink)

        let json_setting_backlink = []
        for(let e of this.array_setting_backlink){
          json_setting_backlink.push({
            max_limit_backlink: false,
            max_backlink: e.max_backlink,
            tolerance: e.tolerance,
            days: e.days,
            min_daily_change: e.min_daily_change,
            max_daily_change: e.max_daily_change,
          })
        }
        json_setting_backlink.push({
          max_limit_backlink: true,
          max_backlink: this.last_item.max_backlink,
          tolerance: this.last_item.tolerance,
          days: this.last_item.days,
          min_daily_change: this.last_item.min_daily_change,
          max_daily_change: this.last_item.max_daily_change,
        })
        //console.log(json_setting_backlink)
        this.loading = true
        this.axios.post('/admin/backlink/update/default', {
          uuid: this.uuid,
          json_setting_backlink: JSON.stringify(json_setting_backlink)
        })
        .then(res => {
          if (res.data.status === 'success') {
            this.$root.$refs.master.enabledScroll()
            this.resolve({
              confirm: true,
            })
            this.dialog = false
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading = false)
        
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>