<template>
  <v-dialog 
  scrollable 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }" 
  transition="dialog-bottom-transition"
  >
    <v-card class="dialog-card">
      <v-card-title class=" d-flex justify-space-between">
        <div>
        {{ $t('credit_card') }}
        </div>
        <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="view_div flex-between">
          <v-btn 
            v-if="!omise_customer.id"
            color="primary" 
            class="no-caps" 
            rounded
            x-large 
            depressed 
            @click="addCreditCard" 
            >
            {{ $t('add_credit_card') }}
          </v-btn>
          <v-btn 
            v-if="omise_customer.id"
            color="primary" 
            class="no-caps" 
            rounded
            x-large 
            depressed 
            @click="updateCreditCard" 
            >
            {{ $t('update_credit_card') }}
          </v-btn>
          <v-btn 
            v-if="omise_customer.id"
            color="danger" 
            class="no-caps" 
            rounded
            x-large 
            depressed 
            @click="deleteCreditCard" 
            >
            {{ $t('delete_credit_card') }}
          </v-btn>
        </div>
        <div v-if="omise_customer.id">
          <div class="view_div">
            <v-text-field
                type="number"
                v-model="amount"
                :label="$t('msg_confirm_promptpay')"
                @keyup="CalAmountUSD"
            ></v-text-field>
          </div>

          <div class="view_div">
            <div class="text-body-1">{{$t('service_fee')}}</div>
            <div class="text-h6">{{ formatNumber(service_fee) }} USD ({{ percent_service_fee }}%)</div>
          </div>
          
          <div class="view_div">
            <div class="text-body-1">{{$t('fee')}}</div>
            <div class="text-h6">{{ formatNumber(fee) }} USD ({{ percent_fee }}%)</div>
          </div>
          <div class="view_div">
            <div class="text-body-1">{{$t('total_amount')}}</div>
            <div class="text-h6">{{ formatNumber(total_amount) }} USD</div>
          </div>
        </div>
      </v-card-text>
      
      <v-card-actions>
        <v-spacer></v-spacer>                                  
        <!-- <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn> -->
        <v-btn 
          color="primary" 
          rounded 
          depressed 
          @click.native="agree" 
          :loading="loading" :disabled="loading"
          class="no-caps"
        >{{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify ConfirmCreditCard Dialog component
   *
   * Insert component where you want to use it:
   * <ConfirmCreditCard ref="ConfirmCreditCard"></ConfirmCreditCard>
   *
   * Call it:
   * this.$refs.ConfirmCreditCard.open('Delete', 'Are you sure?', { color: 'red' }).then((ConfirmCreditCard) => {})
   * Or use await:
   * if (await this.$refs.ConfirmCreditCard.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$ConfirmCreditCard
   * <template>
   *   <v-app>
   *     ...
   *     <ConfirmCreditCard ref="ConfirmCreditCard"></ConfirmCreditCard>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$ConfirmCreditCard = this.$refs.ConfirmCreditCard.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      loading: false,
      resolve: null,
      reject: null,
      amount:  0,
      omise_customer:  {
        id: null
      },
      service_fee:  0,
      percent_service_fee:  0,
      fee:  0,
      percent_fee:  0,
      total_amount:  0,
      options: {
        color: 'primary',
        width: 500,
        zIndex: 200
      },
      valueFields: {
          cardType: "",
          cardName: "",
          cardNumber: "",
          cardMonth: "",
          cardYear: "",
          cardCvv: "",
      },
      labels : {
        cardNumber : "Card Number",
        cardName : "Full Name",
        cardHolder : "Card Holder",
        cardMonth : "MM",
        cardYear : "YY",
        cardExpires : "Expires",
        cardCvv : "CVV"
      }
    }),
    methods: {
      open(omise_customer,percent_fee,percent_service_fee) {
        this.$root.$refs.master.disabledScroll()
        this.labels.cardName = this.$t('name')
        this.labels.cardNumber = this.$t('card_number')
        this.labels.cardHolder = this.$t('name')
        this.labels.expires = this.$t('expires')
        this.omise_customer = omise_customer
        this.dialog = true
        this.amount = 0
        this.percent_fee = percent_fee
        this.percent_service_fee = percent_service_fee
        
        this.CalAmountUSD()
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      formatCurrency (number,currency) {
        return currency ? new Intl.NumberFormat('th', {style: 'currency', currency: currency}).format(number) : '-'
      },
      CalAmountUSD(){
        if(this.amount){
          this.service_fee = parseFloat(this.amount) + (this.amount*this.percent_service_fee/100)
          this.fee = parseFloat(this.amount) + (this.amount*this.percent_fee/100)
          this.total_amount = parseFloat(this.amount) + (this.amount*this.percent_service_fee/100)  + (this.amount*this.percent_fee/100)
        }
        else{
          this.service_fee = 0
          this.fee = 0
          this.total_amount = 0
        }
      },
      addCreditCard(){
        
        this.$root.$update_credit_card(this.valueFields,this.labels)
        .then((confirm) => {
          if (confirm.confirm) {
            //console.log(confirm)
            this.omise_customer = confirm.data.omise_customer
          }
        })
      },
      updateCreditCard(){
        this.valueFields.cardName = this.omise_customer.name
        this.valueFields.cardNumber = '**** **** ****'+this.omise_customer.last_digits
        this.valueFields.cardMonth = this.omise_customer.expiration_month
        this.valueFields.cardYear = this.omise_customer.expiration_year
        this.valueFields.cardCvv = null
        this.$root.$update_credit_card(this.valueFields,this.labels)
        .then((confirm) => {
          if (confirm.confirm) {
            //console.log(confirm)
            this.omise_customer = confirm.data.omise_customer
          }
        })
      },
      deleteCreditCard(){
        this.$root.$confirm(this.$t('confirm'), this.$t('confirm_delete_credit_card'),{
          color: 'primary',
          width: 360,
          zIndex: 200
        },false)
        .then((confirm) => {
          if (confirm.confirm) {
            this.axios.get('/billing/delete-omise-card')
            .then(res => {
              if (res.data.status === 'success') {
                this.$root.$snackbar(this.$t('credit_card_deleted'))
                this.omise_customer = {
                  id: null
                }
              }
            })
            .catch(err => {
              if (err.response.data.status === 'error') {
                this.$root.$snackbar(err.response.data.msg)
              }
            })
          }
        })
      },
      agree() {

        this.$root.$confirm(this.$t('confirm'), this.$t('confirm_create_transfer'),{
          color: 'primary',
          width: 360,
          zIndex: 200
        },false)
        .then((confirm) => {
          if (confirm.confirm) {
            this.loading = true
            this.axios.post('/billing/add-creditcard', {
              amount: this.amount
            })
            .then(res => {
              if (res.data.status === 'success') {
                //console.log(res.data)
                this.$root.$refs.master.enabledScroll()
                this.resolve({
                  confirm: true,
                  amount: this.amount
                })
                this.dialog = false
              }
            })
            .catch(err => {
              if (err.response.data.status === 'error') {
                this.$root.$snackbar(err.response.data.msg)
              }
            })
            .finally(() => this.loading = false)
          }
        })

        
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>