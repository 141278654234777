<template>
  <v-dialog 
  scrollable 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }" 
  transition="dialog-bottom-transition"
  >
    <v-card class="dialog-card">
      <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
        <div>
          {{ $t('filters') }}
        </div>
        <div>
          <v-tooltip top class="">
            <template v-slot:activator="{ on }">
              <v-btn
                @click.stop="resetFilter" 
                v-on="on"
                fab 
                small 
                text
                color="grey"
                class="no-caps border-radius-circle"
              >
                <v-icon size="24">mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('reset') }}</span>
          </v-tooltip> 

          <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
            <v-icon size="24">mdi-close</v-icon>
          </v-btn>
        </div>
        
      </v-card-title>
      <v-divider></v-divider> 
      <v-card-text rounded :class="'ma-0 pt-5 pb-0 pl-5 ' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">  
        <!-- <div class="filter-header">
          <v-btn 
            @click="resetFilter"
            class="no-caps"
            rounded
            depressed
          >
            <v-icon left>mdi-reload</v-icon>
            {{ $t('reset') }}
          </v-btn>
        </div> -->
        <div class="filter-list">
          <div
            v-for="(item, index) in filters"
            :key="index"
            class="filter-list-item "
          >
            <div class="filter-list-item-title text-body-1 mb-auto">{{ item.placeholder }}</div>
            <div class="filter-list-item-value">
              <div v-if="item.type == 'enum'">
                <v-chip-group  v-model="activeFilter" column multiple>
                  <v-chip
                    v-for="(chip, index_chip) in item.items"
                    :key="`${index}-${index_chip}`"
                    active-class="primary"
                    :value="`${index}-${index_chip}`"
                    :outlined="false"
                    close
                    :ripple="false"
                    @click="selectFilter(item.column,chip.pk)"
                  >
                    {{ chip.val }}
                  </v-chip>
                </v-chip-group>
              </div>
              <div v-if="item.type == 'number'" class="d-flex">
                <div class="mr-2 div-filter">
                  <x-text-field
                    v-model="item.min"
                    type="number"
                    :label="$t('min')"
                    filled
                  ></x-text-field>
                </div>
                <div class="ml-2 div-filter">
                  <x-text-field
                    v-model="item.max"
                    type="number"
                    :label="$t('max')"
                    filled
                  ></x-text-field>
                </div>
              </div>
              <div v-if="item.type == 'date_time'" class="d-flex">
                <div class="mr-2 div-filter">
                  <x-date-picker
                    v-model="item.start_date"
                    class=""
                    ref="start_date"
                    id="start_date"
                    :label="$t('start_date')"
                    name="start_date"
                    prepend-inner-icon="mdi-timer"
                    locale="th"  
                    _format="form_item.format"
                    :persistent-hint="true"
                    filled
                  ></x-date-picker>
                </div>
                <div class="ml-2 div-filter">
                  <x-date-picker
                    v-model="item.end_date"
                    class=""
                    ref="end_date"
                    id="end_date"
                    :label="$t('end_date')" 
                    name="end_date"
                    prepend-inner-icon="mdi-timer"
                    locale="th"  
                    _format="form_item.format"
                    :persistent-hint="true"
                    filled
                  ></x-date-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pl-5 pr-5 pb-5 pt-5 ma-0 ">
        <v-spacer></v-spacer>
        <!-- <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn> -->
        <v-btn color="primary" rounded depressed @click.native="agree" class="no-caps">{{ $t('confirm') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify CustomFilters Dialog component
   *
   * Insert component where you want to use it:
   * <CustomFilters ref="CustomFilters"></CustomFilters>
   *
   * Call it:
   * this.$refs.CustomFilters.open('Delete', 'Are you sure?', { color: 'red' }).then((CustomFilters) => {})
   * Or use await:
   * if (await this.$refs.CustomFilters.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$CustomFilters
   * <template>
   *   <v-app>
   *     ...
   *     <CustomFilters ref="CustomFilters"></CustomFilters>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$CustomFilters = this.$refs.CustomFilters.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      filters: [],
      activeFilter: [],
      has_scrollbar: false,
      options: {
        color: 'primary',
        width: 1020,
        zIndex: 200
      }
    }),
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
        }
      },
      selectFilter (column,value) {
        //console.log(column)
        //console.log(value)
        this.filters = this.filters.filter((e) => {
          //console.log(e)
          if(e.column == column){
            //console.log(e.items)
            e.items = e.items.filter((v) => {
              if(v.pk == value){
                v.active = v.active ? false : true
              }
              return v
            })
          }
          //console.log(e)
          return e
        })
      },
      resetFilter () {
        //console.log(this.filters)
        let filters = JSON.parse(JSON.stringify(this.filters))
        this.filters = filters.filter((e) => {
          if(e.type == 'enum'){
            e.items = e.items.filter((v) => {
              v.active = false
              return v
            })
          }
          else if(e.type == 'number'){
            e.min = null
            e.max = null
          }
          else if(e.type == 'date_time'){
            e.start_date = null
            e.end_date = null
          }
          return e
        })
        this.activeFilter = []
      },
      open(filters,filter_first = false) {   
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.filters = JSON.parse(JSON.stringify(filters))
        if(filter_first){
          this.resetFilter()
        }
        //console.log(filters)
        let that = this
        setTimeout(function(){
          that.$nextTick(() => {
            that.checkForScrollbar();
          });
        },100)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      }, 
      agree() {
        //console.log(this.filters)
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: true,
          filters: this.filters
        })
        this.dialog = false
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>
<style>
.custom-text-field{
  margin: 5px!important;
  max-width: 250px!important;
}
@media (min-width: 720px) {
  .div-filter{
    max-width: 200px;
  }
}
</style>

                     