<template>
  <v-dialog 
  scrollable 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }" 
  transition="dialog-bottom-transition"
  >
    <v-card class="dialog-card">

      <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
        <div>
          {{ $t('update_credit_card') }}
        </div>
        
        <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text rounded :class="'ma-0 pb-5 pt-5 pl-5 ' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">
        <div class="">
          <v-row no-gutters>
            <v-col cols="12">
              <div class="">
                <vue-paycard class="" :value-fields="valueFields" :input-fields="inputFields" :set-type="valueFields.cardType"/>
              </div>
            </v-col>
            <v-col cols="12" class="mt-4 mt-sm-8">
              <v-card flat tile>
                <v-card-text class="pa-0">
                  <v-row>
                    <v-col cols="12">
                      <x-text-field
                        @input="formatCardNumber(valueFields.cardNumber)"
                        v-model="valueFields.cardNumber"
                        :label="labels.cardNumber"
                        :data-card-field="inputFields.cardNumber"
                        hide-details="auto"
                        autocomplete="off"
                        :maxlength="19"
                      ></x-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pt-sm-3">
                      <x-text-field
                        v-model="valueFields.cardName"
                        :label="labels.cardName"
                        :data-card-field="inputFields.cardName"
                        hide-details="auto"
                      ></x-text-field>
                    </v-col>
                    <v-col cols="6" sm="4" class="pt-0 pt-sm-3">
                      <x-select
                        :items="itemsCardMonth"
                        v-model="valueFields.cardMonth"
                        :label="labels.cardMonth"
                        :data-card-field="inputFields.cardMonth"
                        hide-details="auto"
                      ></x-select>
                    </v-col>
                    <v-col cols="6" sm="4" class="pt-0 pt-sm-3">
                      <x-select
                        :items="itemsCardYear"
                        v-model="valueFields.cardYear"
                        :label="labels.cardYear"
                        :data-card-field="inputFields.cardYear"
                        hide-details="auto"
                      ></x-select>
                    </v-col>
                    <v-col cols="12" sm="4" class="pt-0 pt-sm-3">
                      <x-text-field
                        type="number"
                        @input="formatCardCvv(valueFields.cardCvv)"
                        v-model="valueFields.cardCvv"
                        :label="labels.cardCvv"
                        :data-card-field="inputFields.cardCvv"
                        hide-details="auto"
                        rules="required|min:3|max:3"
                      ></x-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="ma-0 pa-5">
        <v-spacer></v-spacer>
        <!-- <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn> -->
        <v-btn 
          color="primary" 
          rounded 
          depressed 
          @click.native="SaveCreditCard" 
          :loading="loading" 
          :disabled="loading"
          class="no-caps"
          >{{ $t('save_credit_card') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify Update Credit Card Dialog component
   *
   * Insert component where you want to use it:
   * <UpdateCreditCard ref="UpdateCreditCard"></UpdateCreditCard>
   *
   * Call it:
   * this.$refs.UpdateCreditCard.open('Delete', 'Are you sure?', { color: 'red' }).then((UpdateCreditCard) => {})
   * Or use await:
   * if (await this.$refs.UpdateCreditCard.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$UpdateCreditCard
   * <template>
   *   <v-app>
   *     ...
   *     <UpdateCreditCard ref="UpdateCreditCard"></UpdateCreditCard>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$UpdateCreditCard = this.$refs.UpdateCreditCard.open
   * }
   */
  import { VuePaycard } from "vue-paycard";
  export default {
    components: {
      VuePaycard,
    },
    data: () => ({
      dialog: false,
      loading: false,
      resolve: null,
      reject: null,
      itemsCardMonth: [1,2,3,4,5,6,7,8,9,10,11,12],
      itemsCardYear: [],
      valueFields: {
          cardType: "",
          cardName: "",
          cardNumber: "",
          cardMonth: "",
          cardYear: "",
          cardCvv: "",
      },
      inputFields : {
        cardNumber : "v-card-number",
        cardName : "v-card-name",
        cardMonth : "v-card-month",
        cardYear : "v-card-year",
        cardCvv : "v-card-cvv"
      },
      labels : {
        cardNumber : "Card Number",
        cardName : "Full Name",
        cardHolder : "Card Holder",
        cardMonth : "MM",
        cardYear : "YY",
        cardExpires : "Expires",
        cardCvv : "CVV"
      },
      options: {
        color: 'primary',
        width: 500,
        zIndex: 200
      },
      has_scrollbar: false,
    }),
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
        }
      },
      formatCardNumber(value){
        if(value){
          this.valueFields.cardNumber = value.replace(/ /g,'');
          this.valueFields.cardNumber = this.valueFields.cardNumber.match(/.{1,4}/g).join(' ')
        }
      }, 
      formatCardCvv(value){
        if(value && value.length > 3){
          this.valueFields.cardCvv = value.slice(0, 3)
        }
      }, 
      formatNumber (number) {
        return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
      },
      formatCurrency (number, currency) {
        return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: currency}).format(number)
      },
      formatDate (dt, format) {
        if (dt === null) {
          dt = '-'
        } else {
          dt = (format == 'ago') ? this.moment(dt).fromNow() : this.moment(dt).tz(this.$auth.user().timezone).format(format)
        }
        return dt
      },
      open(valueFields,labels) {
        this.$root.$refs.master.disabledScroll()
        this.itemsCardYear = []
        for(let i = 0; i <= 10; i++){
          let year = parseInt(this.moment().format('YYYY'))
          this.itemsCardYear.push(year+i)
        }
        this.valueFields = valueFields
        this.labels = labels
        this.dialog = true
        let that = this
        setTimeout(function(){          
          that.$nextTick(() => {
            that.checkForScrollbar();
          });
        },100)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      SaveCreditCard () {
        //console.log(this.valueFields)
        this.loading = true
        this.axios.post('/billing/add-omise-card', {
          name: this.valueFields.cardName,
          number: this.valueFields.cardNumber,
          exp_month: this.valueFields.cardMonth,
          exp_year: this.valueFields.cardYear,
          cvc: this.valueFields.cardCvv,
        })
        .then(res => {
          this.loading = false
          if (res.data.status === 'success') {
            this.$root.$snackbar(this.$t('update_credit_card_successfully'))
            this.$root.$refs.master.enabledScroll()
            this.resolve({
              confirm: true,
              data: res.data
            })
            this.dialog = false
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.error)
          }
        })

      },
      agree() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: true,
        })
        this.dialog = false
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>