<template>
  <div class="fill-height">
    <!-- <v-container fluid v-if="!loaded" class="fill-height">
      <v-row 
        align="center"
        justify="center"
        class="fill-height"
      >
        <v-progress-circular
          :size="32"
          width="3"
          color="ctaBg"
          indeterminate
          class="ma-12"
        ></v-progress-circular>
      </v-row>
    </v-container> -->
    <!-- <v-btn @click="loaded ? loaded=false : loaded=true">test</v-btn> -->
    
    <v-card flat  color="transparent">
 
          <x-select
            v-if="select_provider"
            v-model="provider_uuid"
            :items="item_provider"
            item-text="name"
            item-value="uuid"
            :label="$t('provider')"
            class="select_provider"
            filled
          ></x-select>


          <v-card rounded outlined :class="'d-flex flex-wrap mb-5 pb-5'"  
          v-if="
          this.$route.name == 'tracking-keyword' && 
          (settings.active_bulk || settings.unactive_bulk || settings.report_keyword_all)
          " 
          >
            
            <v-btn
              v-if="settings.active_bulk"
              :to="{ name: 'tracking-active-keyword', query: $route.query }"
              outlined
              rounded
              depressed 
              large 
              class="no-caps mt-5 ml-5 mr-5 " 
              color="ctaBg ctaFg--text"
            >
              <v-icon left>mdi-play</v-icon> {{ $t('active_bulk') }}
            </v-btn>

            <v-btn
              v-if="settings.unactive_bulk"
              :to="{ name: 'tracking-unactive-keyword', query: $route.query }"
              outlined
              rounded
              depressed 
              large 
              class="no-caps mt-5 ml-5 mr-5 " 
              color="ctaBg ctaFg--text"
            >
              <v-icon left>mdi-stop</v-icon> {{ $t('unactive_bulk') }}
            </v-btn>

            <v-btn
              v-if="settings.report_keyword_all"
              @click="getReportKeywordAll"
              outlined
              rounded
              depressed 
              large 
              class="no-caps mt-5 ml-5 mr-5 " 
              color="ctaBg ctaFg--text"
              :loading="report_all_loading" :disabled="report_all_loading"
            >
              <v-icon left>mdi-table-large</v-icon> {{ $t('report_keyword_all') }}
            </v-btn> 

          </v-card>

          <v-card rounded outlined class="pa-5" >
          
            <v-row class="mt-2 mb-2 mr-0 ml-0" v-if="loaded">
              <v-col cols="12" md="4" class="mt-2 mb-2 pa-0 d-flex">
                <v-toolbar-title class="">{{ translations.items }}</v-toolbar-title>
                <v-btn
                  v-if="((rows.length == 0 && filteredResults) || parseInt(rows.length) > 0) && settings.create === true"
                  @click="uuid = null; dataForm = true; type_action = 'add'"
                  class="ml-2 no-caps"
                  small
                  text
                  color="ctaBg ctaFg--text"
                >
                  <v-icon left>mdi-plus</v-icon>
                  {{ translations.add_item }}
                </v-btn>
                <v-btn 
                  @click="getService" 
                  v-if="settings.get_service === true" 
                  small
                  text
                  class="ml-2 no-caps" 
                  color="ctaBg ctaFg--text"
                  :loading="create_custom_loading" :disabled="create_custom_loading"
                >
                  <v-icon left>mdi-plus</v-icon> {{ $t('get_provider_service') }}
                </v-btn>

                <v-btn 
                  @click="getExtras" 
                  v-if="settings.get_extras === true" 
                  small
                  text
                  class="ml-2 no-caps" 
                  color="ctaBg ctaFg--text"
                  :loading="create_custom_loading" :disabled="create_custom_loading"
                >
                  <v-icon left>mdi-plus</v-icon> {{ $t('get_provider_extras') }}
                </v-btn>
              </v-col>
              <v-col cols="12" md="8" class="mt-2 mb-2 pa-0 d-flex justify-end">
                <v-text-field
                  v-if="loaded && ((rows.length == 0 && filteredResults) || parseInt(rows.length) > 0)"
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('search')"
                  single-line
                  clearable
                  flat
                  dense
                  solo-inverted
                  hide-details 
                  rounded
                  :style="{'max-width': ($vuetify.breakpoint.xs) ? '100%' : '320px'}"
                ></v-text-field>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-badge
                      
                      bordered
                      color="primary"
                      overlap
                      :content="count_filter"
                      v-if="filters.length"
                      :value="count_filter"
                    >
                      <v-btn
                        v-on="on"
                        icon
                        depressed
                        :class="{'ml-2': !(parseInt(rows.length) > 0 && showExport)}"
                        :color="!count_filter ? 'default' : 'primary'"
                        @click="CustomFilters"
                      >
                        <v-icon>mdi-filter-variant</v-icon>
                      </v-btn>

                    </v-badge>
                  </template>
                  <span>{{ $t('filters') }}</span>
                </v-tooltip>


                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      depressed
                      class="ml-2"
                      color="default"
                      @click="CustomColumn"
                    >
                      <v-icon>mdi-table-column-plus-after</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('edit_column') }}</span>
                </v-tooltip>

                <v-tooltip top v-if="$vuetify.breakpoint.width > 1500">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      depressed
                      class="ml-2"
                      color="default"
                      @click="expandTable"
                    >
                      <v-icon v-if="!$store.state.app.classExpandContainer">mdi-arrow-expand</v-icon>
                      <v-icon v-else>mdi-arrow-collapse</v-icon>
                    </v-btn>
                  </template>
                  <span v-if="!$store.state.app.classExpandContainer">{{ $t('expand_table') }}</span>
                  <span v-else>{{ $t('collapse_table') }}</span>
                  
                </v-tooltip>  
              </v-col>
            </v-row>
            
          
          
          <div v-if="!filteredResults && !loading && loaded && rows.length == 0" class="text-center my-10">
            <div class="mt-10">
              <slot name="empty-head"></slot>
            </div>
            <h1 class="title my-4">{{ $t('no_items_yet', {items: translations.items_lowercase}) }}</h1>
            <div class="mx-5" :class="{'pb-4': !settings.create}">
              <slot name="empty-text"></slot>
            </div>
            <div>
              <v-btn @click="uuid = null; dataForm = true; type_action = 'add'" v-if="settings.create === true" depressed large class="mt-3 mb-5 no-caps" color="ctaBg ctaFg--text"><v-icon left>mdi-plus</v-icon> {{ translations.add_item }}</v-btn>
              
              <!-- <v-btn 
              @click="createKeyword" 
              v-if="settings.create_keyword === true" 
              depressed 
              large 
              class="mt-3 mb-5 no-caps" 
              color="ctaBg ctaFg--text"
              :loading="create_custom_loading" :disabled="create_custom_loading"
              >
                <v-icon left>mdi-plus</v-icon> {{ translations.create_item }}
              </v-btn> -->

              <v-btn 
              @click="getService" 
              v-if="settings.get_service === true" 
              depressed 
              large 
              class="mt-3 mb-5 no-caps" 
              color="ctaBg ctaFg--text"
              :loading="create_custom_loading" :disabled="create_custom_loading"
              >
                <v-icon left>mdi-plus</v-icon> {{ $t('get_provider_service') }}
              </v-btn>

              <v-btn 
              @click="getExtras" 
              v-if="settings.get_extras === true" 
              depressed 
              large 
              class="mt-3 mb-5 no-caps" 
              color="ctaBg ctaFg--text"
              :loading="create_custom_loading" :disabled="create_custom_loading"
              >
                <v-icon left>mdi-plus</v-icon> {{ $t('get_provider_extras') }}
              </v-btn>

              <!-- <v-btn 
              @click="createPayment" 
              v-if="settings.create_payment === true" 
              depressed 
              large 
              class="mt-3 mb-5 no-caps" 
              color="ctaBg ctaFg--text"
              :loading="create_custom_loading" :disabled="create_custom_loading"
              >
                <v-icon left>mdi-plus</v-icon> {{ $t('top_up') }}
              </v-btn>

              <v-btn 
              @click="createOrder" 
              v-if="settings.create_order === true" 
              depressed 
              large 
              class="mt-3 mb-5 no-caps" 
              color="ctaBg ctaFg--text"
              :loading="create_custom_loading" :disabled="create_custom_loading"
              >
                <v-icon left>mdi-plus</v-icon> {{ $t('purchase_order') }}
              </v-btn>


              <v-btn 
              @click="createPackageKeyword" 
              v-if="settings.create_package_keyword === true" 
              depressed 
              large 
              class="mt-3 mb-5 no-caps" 
              color="ctaBg ctaFg--text"
              :loading="create_custom_loading" :disabled="create_custom_loading"
              >
                <v-icon left>mdi-cash-usd</v-icon> {{ $t('buy_package') }}
              </v-btn> -->
              

            </div>
          </div>


          <v-toolbar flat color="transparent" class="toolbar-content-no-padding toolbar-filter-table" v-show="filters.length > 0 && loaded && ((rows.length == 0 && filteredResults) || parseInt(rows.length) > 0)" height="auto">
            <!-- <v-spacer></v-spacer> -->
            
            <v-expand-transition v-if="filters.length">
              <v-row v-show="open_filter" class="justify-end my-0">
                <v-col class="py-0" cols="12" sm="6" md="4" lg="3" v-for="(filter, filter_index) in filters" :key="'filter_' + filter_index">

                  <v-autocomplete
                    @change="changeFilter"
                    v-model="filter.model"
                    :items="filter.items"
                    :placeholder="filter.placeholder"
                    :prepend-inner-icon="filter.icon"
                    item-text="val"
                    item-value="pk"
                    flat
                    chips
                    :multiple="true"
                    solo
                    clearable
                    hide-no-data
                    hide-details
                    deletable-chips
                    class="input-autocomplete-table"
                  ></v-autocomplete>

                </v-col>
              </v-row>
            </v-expand-transition>
          </v-toolbar>
    <!--
          <v-btn
            v-if="((rows.length == 0 && filteredResults) || parseInt(rows.length) > 0) && settings.create === true"
            color="pink"
            dark
            large
            fixed
            right
            bottom
            fab
            id="createBtn"
            @click="uuid = null; dataForm = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <v-tooltip bottom v-model="showFabToolTip" activator="#createBtn" v-if="((rows.length == 0 && filteredResults) || parseInt(rows.length) > 0) && settings.create === true">
            <span>{{ translations.add_item }}</span>
          </v-tooltip>
    -->
        
          <v-skeleton-loader
              v-if="!loaded"
              rounded
              type="table"
          ></v-skeleton-loader>
          <!-- :sort-desc.sync="descending" -->
          <v-data-table
            :mobile-breakpoint="0"
            class="elevation-1"
            v-show="loaded && ((rows.length == 0 && filteredResults) || parseInt(rows.length) > 0)"
            v-model="selected"
            :headers="headers"
            v-bind="$attrs"
            :items="rows"
            :loading="loading"
            item-key="uuid"
            :options.sync="options"
            :server-items-length="totalItems"
            :show-select="settings.select_all"
            :items-per-page-options="itemsPerPageOptions"
            :header-props="{
                'sort-icon': (sortedDesc ? 'mdi-arrow-down' : 'mdi-arrow-up')
            }"
            :loading-text="$t('loading_items')"
            :no-data-text="$t('no_data_available')"
            :no-results-text="$t('no_results_found')"
            :footer-props="{
              itemsPerPageText: $t('rows_per_page'),
              itemsPerPageOptions: [10, 25, 50, 100, 250, { text: $t('all'), value: -1 }],  
              itemsPerPageAllText: $t('all'),
              pageText: $t('page_of'),
              showFirstLastPage: true,
              firstIcon: 'mdi-chevron-double-left',
              lastIcon: 'mdi-chevron-double-right',
              prevIcon: 'mdi-chevron-left',
              nextIcon: 'mdi-chevron-right'
            }"
          >
          <!-- :header-props="{
                'sort-icon': (descending ? 'mdi-arrow-down' : 'mdi-arrow-up')
            }" -->

            <!-- <template :slot="'header.uuid'" slot-scope="props">
              <div v-if="props.header.select_all">
                <div class="d-flex">
                  <v-checkbox
                    class=""
                    v-model="checkbox_all"
                    @change="selectAll"
                  />
                </div>
              </div>
              <div v-else>
                {{ props.header.text }}
              </div>
            </template> -->

            <template :slot="'header.'+ item.value" slot-scope="props" v-for="(item, index) in headers">
              <div 
              v-if="item" 
              :key="index"
              :class="['text-'+ item.align ,((options.sortBy.length && options.sortBy[0] == item.value) ? '' : 'hide-icon'),(loading ? 'hide-icon' : '')]"
              >              
                <div v-if="
                  props.header.value == headers[1].value &&
                  has_item_checkbox &&
                  (settings.active_select || settings.unactive_select || 
                  settings.order_select || settings.chart_ranking_select)
                  "
                  class="d-flex flex-wrap"
                  style="max-width: 125px;width: 125px;"
                  >
                  <v-tooltip top v-if="settings.active_select">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click.stop="checkboxAll('active')" 
                        v-on="on"
                        color="primary"
                        class="border-radius-circle ma-1 "
                        x-small 
                        fab 
                        dark
                        :loading="active_all_loading" 
                      >
                        <v-icon left>mdi-play</v-icon>
                      </v-btn>
                    </template>
                    {{ $t('active_select') }}
                  </v-tooltip>

                  <v-tooltip top v-if="settings.unactive_select">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click.stop="checkboxAll('unactive')" 
                        v-on="on"
                        color="primary"
                        class="border-radius-circle ma-1"
                        x-small 
                        fab 
                        dark
                        :loading="unactive_all_loading"
                      >
                        <v-icon left>mdi-stop</v-icon>
                      </v-btn>
                    </template>
                    {{ $t('unactive_select') }}
                  </v-tooltip>

                  <v-tooltip top v-if="settings.order_select">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click.stop="checkboxAll('order')" 
                        v-on="on"
                        color="primary"
                        class="border-radius-circle ma-1"
                        x-small 
                        fab 
                        dark
                        :loading="order_all_loading" 
                      >
                        <v-icon left>mdi-shopping</v-icon>
                      </v-btn>
                    </template>
                    {{ $t('order_select') }}
                  </v-tooltip>

                  <v-tooltip top v-if="settings.chart_ranking_select">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click.stop="checkboxAll('chart_ranking')" 
                        v-on="on"
                        color="primary"
                        class="border-radius-circle ma-1"
                        x-small 
                        fab 
                        dark
                        :loading="chart_all_loading" 
                      >
                        <v-icon left>mdi-chart-bar</v-icon>
                      </v-btn>
                    </template>
                    {{ $t('chart_ranking_select') }}
                  </v-tooltip>

                  <v-tooltip top v-if="settings.data_insights_select">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click.stop="checkboxAll('data_insights')" 
                        v-on="on"
                        color="primary"
                        class="border-radius-circle ma-1"
                        x-small 
                        fab 
                        dark
                        :loading="data_insights_loading" 
                      >
                        <v-icon left>mdi-table-large</v-icon>
                      </v-btn>
                    </template>
                    {{ $t('data_insights_select') }}
                  </v-tooltip>
                </div>
                <div v-else>
                  <div v-if="props.header.select_all">
                    <div class="d-flex">
                      <v-checkbox
                        class=""
                        v-model="checkbox_all"
                        @change="selectAll"
                      />
                    </div>
                  </div>
                  <div v-else>
                    {{ props.header.text }}
                  </div>
                </div>
              </div>
            </template>


            <!-- <template :slot="'header.'+ (headers.length ? headers[1].value : '')" slot-scope="props">
              <div v-if="
                has_item_checkbox &&
                (settings.active_select || settings.unactive_select || 
                settings.order_select || settings.chart_ranking_select)
                ">
                <v-tooltip top v-if="settings.active_select">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click.stop="checkboxAll('active')" 
                      v-on="on"
                      color="primary"
                      class="border-radius-circle mr-1"
                      x-small 
                      fab 
                      dark
                      :loading="active_all_loading" 
                    >
                      <v-icon left>mdi-play</v-icon>
                    </v-btn>
                  </template>
                  {{ $t('active_select') }}
                </v-tooltip>

                <v-tooltip top v-if="settings.unactive_select">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click.stop="checkboxAll('unactive')" 
                      v-on="on"
                      color="primary"
                      class="border-radius-circle mr-1"
                      x-small 
                      fab 
                      dark
                      :loading="unactive_all_loading"
                    >
                      <v-icon left>mdi-stop</v-icon>
                    </v-btn>
                  </template>
                  {{ $t('unactive_select') }}
                </v-tooltip>

                <v-tooltip top v-if="settings.order_select">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click.stop="checkboxAll('order')" 
                      v-on="on"
                      color="primary"
                      class="border-radius-circle mr-1"
                      x-small 
                      fab 
                      dark
                      :loading="order_all_loading" 
                    >
                      <v-icon left>mdi-shopping</v-icon>
                    </v-btn>
                  </template>
                  {{ $t('order_select') }}
                </v-tooltip>

                <v-tooltip top v-if="settings.chart_ranking_select">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click.stop="checkboxAll('chart_ranking')" 
                      v-on="on"
                      color="primary"
                      class="border-radius-circle"
                      x-small 
                      fab 
                      dark
                      :loading="chart_all_loading" 
                    >
                      <v-icon left>mdi-chart-bar</v-icon>
                    </v-btn>
                  </template>
                  {{ $t('chart_ranking_select') }}
                </v-tooltip>
              </div>
              <div v-else>
                {{ props.header.text }}
              </div>
            </template> -->

            <template :slot="'item.' + item.value" slot-scope="props" v-for="(item, index) in headers">
              <div v-if="index != Object.keys(headers).length - 1 || ! settings.actions" :style="item.style" :key="'col_' + index">
                <div v-if="item.type === 'boolean'" class="text-center">
                  <v-hover
                      v-if="item.value == 'status_primary' && props.item['status_approve'] == 'approve'"
                      v-slot="{ hover }"
                  >
                    <div 
                    v-if="hover || props.item[item.value] === 1 || props.item[item.value] === '1' || props.item[item.value] === true"
                    >
                      <v-icon
                      :color="hover ? 'blue' : ''" 
                      small
                      :class="hover ? 'cursor_pointer' : ''" 
                      @click="updateStatusPrimary(props.item['uuid'])"
                      :disabled="disable_btn || props.item[item.value] == 1"
                      >mdi-check</v-icon>
                    </div>
                    <div v-else>
                      <v-icon small :disabled="disable_btn">mdi-close</v-icon>
                    </div>
                  </v-hover>
                  <div v-if="item.value != 'status_primary'">
                    <div v-if="props.item[item.value] === 1 || props.item[item.value] === '1' || props.item[item.value] === true">
                      <v-icon small>mdi-check</v-icon>
                    </div>
                    <div v-else>
                      <v-icon small>mdi-close</v-icon>
                    </div>
                  </div>
                    

                </div>
                <div v-else-if="item.type === 'select'">
                  <div class="">{{ formatSelect(props.item[item.value],item.items) }}</div>
                </div>
                <div v-else-if="item.type === 'link'">
                  <a :href="props.item[item.value]" target="_blank">{{ props.item[item.value].replace('http://', '').replace('https://', '').replace('//', '') }}</a>
                </div>
                <div v-else-if="item.type === 'number' || item.type === 'currency'">
                  <div class="text-right">{{ props.item[item.value] }}</div>
                </div>
                <div v-else-if="item.type === 'first_name_last_name'">
                  <div class="">{{ props.item[item.value] + ' ' +props.item[item.last_name] }}</div>
                </div>
                <div v-else-if="item.type === 'lang'">
                  <div 
                  :class="(props.item[item.value] == 'active' ? 'green--text' : (props.item[item.value] == 'unactive'  ? 'red--text' : ''))"
                  >
                    {{ $t(props.item[item.value]) }}</div>
                </div>
                <div v-else-if="item.type === 'active'">
                  <div 
                  :class="(props.item[item.value] ? 'green--text' : 'red--text')"
                  >
                    {{ $t((props.item[item.value] ? 'active' : 'unactive')) }}</div>
                </div>
                <div v-else-if="item.type === 'active_package'">
                  <div 
                  :class="(props.item[item.value] ? 'green--text' : 'red--text')"
                  >
                    {{ $t((props.item[item.value] ? 'active' : 'expires')) }}</div>
                </div>
                <div v-else-if="item.type === 'package_date'">
                  <div class="">{{ props.item[item.value] }} {{ $t(props.item['package_date_format']) }}</div>
                </div>
                <div v-else-if="item.type === 'frequency_date'">
                  <div class="">{{ props.item[item.value] }} {{ $t(props.item['frequency_date_format']) }}</div>
                </div>
                <div v-else-if="item.type === 'package_frequency'">
                  <div class="">{{ props.item[item.value] }} {{ $t(props.item['format_date_update_ranking']) }}</div>
                </div>
                <div v-else-if="item.type === 'purchase_amount'">
                  <div class="">{{ formatCurrency(props.item[item.value]/100,props.item['currency']) }}</div>
                </div>
                <div v-else-if="item.type === 'points' && props.item[item.value]">
                  <div class="">{{ formatNumber(props.item[item.value]) }}</div>
                </div>
                <div v-else-if="item.type === 'points_payment' && props.item[item.value]">
                  <div class="">
                    <amount_currency :amount="parseFloat(props.item[item.value])" top :class_text="(props.item.type == 'payment' || props.item.type == 'refund') ? 'green--text': 'red--text'"></amount_currency>
                  </div>
                </div>
                <div v-else-if="item.type === 'points_currency' && props.item[item.value]">
                  <div class="">
                    <amount_currency :amount="parseFloat(props.item[item.value])" top></amount_currency>
                  </div>
                </div>
                <div v-else-if="item.type === 'checkbox'">
                  <div class="">
                    <v-checkbox
                      class="justify-center"
                      v-model="checkbox"
                      :value="props.item[item.value]"
                      @change="selectItem"
                    />
                  </div>
                </div>
                <div v-else-if="item.type === 'uuid'">
                  <div class="">{{ props.item[item.value].substr(props.item[item.value].length - 5) }}</div>
                </div>
                <div v-else-if="item.type === 'date_time' || item.type === 'date'">
                  <span v-html="parseDateTime(props.item[item.value], item.format, item.color_is_past, item.color_is_future)"></span>
                </div>
                <div v-else-if="item.type === 'image'">
                  <v-img :src="props.item[item.value]" class="elevation-1 my-3 d-block" v-if="props.item[item.value]" contain :max-width="item.max_width" :max-height="item.max_height"></v-img>
                </div>
                <div v-else-if="item.type === 'chip'">
                  <div class="d-flex flex-wrap" v-if="props.item[item.value] && JSON.parse(props.item[item.value]).length" style="width: 500px;"> 
                    <v-chip
                      v-for="(chip, index_chip) in JSON.parse(props.item[item.value])" :key="'chip_' + index_chip"
                      class="ma-2"
                      color="primary"
                    >
                      {{ (item.chip_value ? encodeDecodeURL(chip[item.chip_value]) : encodeDecodeURL(chip)) }}
                      
                    </v-chip>
                  </div>
                </div>
                <div v-else-if="item.type === 'chip_url'">
                  <div class="d-flex flex-wrap" v-if="props.item[item.value] && JSON.parse(props.item[item.value]).length" style="width: 500px;"> 
                    <v-chip
                      v-for="(chip, index_chip) in JSON.parse(props.item[item.value])" :key="'chip_' + index_chip"
                      class="ma-2"
                      color="primary"
                    >
                      <a v-if="chip" :class="($vuetify.theme.dark ? 'black--text' : 'white--text')" :href="chip" target="_blank">{{ encodeDecodeURL(chip) }}</a>
                    </v-chip>
                  </div>
                </div>
                
                <div v-else-if="item.type === 'rating'">
                  <div class="text-center">
                    <v-rating
                      v-model="props.item[item.value]"
                      color="yellow darken-3"
                      background-color="grey darken-1"
                      empty-icon="$ratingFull"
                      readonly
                    ></v-rating>
                  </div>
                </div>
                <div v-else-if="item.type === 'note'">
                  <div class="pre-line">{{ props.item[item.value] }}</div>
                </div>
                <div v-else-if="item.type === 'ranking'">
                  

                  <v-tooltip top
                  v-if="props.item.status_match_domain"
                  >
                    <template v-slot:activator="{ on }">
                      <div v-on="on" v-if="!props.item[item.value]">-</div>
                      <div v-on="on" v-else-if="props.item[item.value] == 0">{{ $t('no_rank') }}</div>
                      <div v-on="on" v-else>{{ props.item[item.value] }}</div>
                    </template>
                    <div v-for="(match_domain) in JSON.parse(props.item.json_match_domain)" :key="match_domain.url">
                      <div>{{ $t('other_ranking') }}</div>
                      <div>{{ match_domain.url }} {{ $t('ranking') }} {{ match_domain.rank_absolute_url }}</div>
                    </div>
                  </v-tooltip>
                  <div v-else>
                    <div v-if="!props.item[item.value]">-</div>
                    <div v-else-if="props.item[item.value] == 0">{{ $t('no_rank') }}</div>
                    <div v-else>{{ props.item[item.value] }}</div>
                  </div>
                </div>
                <div v-else-if="item.type === 'url'" class="url-in-table">
                  <a v-if="props.item[item.value]" :href="props.item[item.value]" target="_blank">{{ encodeDecodeURL(props.item[item.value]) }}</a>
                  <span v-else>-</span>
                </div>
                <div v-else-if="item.type === 'image'">
                  <!-- <v-img :src="props.item[item.value]" class="my-3 d-block rounded" v-if="props.item[item.value]" contain :max-width="item.max_width" :max-height="item.max_height"></v-img> -->
                  <v-img :src="props.item[item.value]" class="my-3 d-block small-rounded grey lighten-4" v-if="props.item[item.value]" contain :max-width="80" :max-height="80"></v-img>
                </div>
                <div v-else-if="item.type === 'status'" class="row-switch">
                  <!-- :label="item.text" -->
                  <v-switch
                    v-if="!item.condition_date || (item.condition_date && ActionConditionDate(item.condition_date_data,props.item))"
                    inset
                    dense
                    :ripple="false"
                    :input-value="props.item[item.value]"
                    :value="item.value_active"
                    @change="ChangeStatus(props.item[item.value],props.item.uuid,item)"
                    hide-details
                    class="input-switch-no-label"
                  ></v-switch>
                </div>
                <div v-else-if="item.type === 'avatar'">
                  <v-avatar
                    :tile="false"
                    size="32"
                    v-if="props.item[item.value]"
                  >
                    <v-img :src="props.item[item.value]" class="elevation-1 my-3 d-block" contain :max-width="item.max_width" :max-height="item.max_height"></v-img>
                  </v-avatar>
                  <v-avatar
                    :tile="false"
                    size="32"
                    :color="randomColor()"
                    class="white--text"
                    v-else
                  >
                  {{ props.item['email'] ? props.item['email'].substring(0,2) : '' }}
                  </v-avatar>
                </div>
                <div v-else-if="item.value === 'avatar'" class="text-center">
                  <v-avatar
                    :tile="false"
                    size="32"
                    color="grey lighten-4"
                  >
                    <v-img :src="props.item[item.value]" v-if="props.item[item.value]"></v-img>
                  </v-avatar>
                </div>
                <div v-else-if="item.type === 'lang_service'">

                  {{ props.item.status_serpclick ? props.item[item.value] : LangServce(props.item.providers_service_id,props.item.providers_id,table) }}

                </div>
                <div v-else-if="item.type === 'lang_service_order'">
                  {{ props.item.status_serpclick ? props.item[item.value] : LangServce(props.item.providers_service_id,props.item.providers_id,'service') }}
                </div>
                <div v-else>
                  {{ props.item[item.value] }}
                </div>
              </div>
              <div v-if="index == Object.keys(headers).length - 1 && settings.actions" :style="{width: settings.actions_width}" :key="'col_' + index">

                <span v-for="(item, index) in actions" :key="'action_' + index">
                  <span v-if="(!item.condition || (item.condition && ActionCondition(item.condition_array,props.item)))">
                  <v-tooltip top v-if="item.action">
                    <template v-slot:activator="{ on }">
                      <v-btn :disabled="item.condition_disable ? ActionDisable(item.condition_disable_array,props.item,item.action) : false" class="mr-1 mt-0 mb-0" v-on="on" :color="item.color" icon small @click="executeAction(item.action, props.item.uuid, props.item)"><v-icon small>{{ item.icon }}</v-icon></v-btn>
                    </template>
                    <span>{{ item.text }}</span>
                  </v-tooltip>
                  <v-divider v-else vertical style="vertical-align: middle;height: 16px" class="mx-1"></v-divider>
                  </span>
                </span>
              </div>
            </template>

            <template v-slot:footer>
              <div class="ma-3">
                <v-btn v-if="settings.select_all" depressed small color="error" :disabled="disableDeleteSelected" @click="deleteSelected">{{ $t('delete_selected') }}</v-btn>
              </div>
            </template>

            <template v-slot:no-data>
              <div v-if="!loading" class="text-center">
                {{ $t('data_table_no_result') }}
              </div>
              <div v-if="loading" class="text-center">
                {{ $t('loading_items') }}
              </div>
            </template>

          </v-data-table>

       </v-card>   


      <v-dialog
        scrollable
        :retain-focus="false"
        :width="settings.dialog_width || 720"
        v-model="dataForm"
        :dataForm="dataForm"
        @keydown.esc="canceldataForm"
        @click:outside="canceldataForm"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        transition="dialog-bottom-transition"
      >
        <data-form
          v-if="dataForm"
          v-on:data-list-events="dataListEvents = $event"
          :model="model"
          :uuid="uuid"
          :type_action="type_action"
        ></data-form>
      </v-dialog>

    </v-card>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { copyStringToClipboard } from '@/utils/helpers';
  import Cookies from 'js-cookie'

  export default {
    data: () => {
      return {
        locale: 'en',
        lang: ['en'], 
        count_api: 0,
        checkbox: [],
        filter_first: true,
        checkbox_all: false,
        has_item_checkbox: false,
        first_loading: false,
        descending: false,
        descending_old: false,
        page_old: 1,
        hoveredColumn: null,
        sortedBy: 'created_at',
        sortedDesc: false,
        search: '',
        expand_table: false,
        showExport: false,
        totalItems: 0,
        pageCount: 0,
        dataListEvents: null,
        filteredResults: false,
        showFabToolTip: false,
        dataForm: false,
        uuid: null,
        id: null,
        type_action: null,
        disableDeleteSelected: true,
        loading: true,
        disable_btn: false,
        loaded: false,
        create_custom_loading: false,
        loading_keyword_active: false,
        loading_keyword_excel: false,
        loading_dashboard: false,
        loading_buy_package: false,
        loading_package: false,
        bank_account: [],
        min_withdraw: null,
        max_withdraw: null,
        keyword: null,
        json_date: [],
        selected: [],
        settings: [],
        headers: [],
        actions: [],
        translations: [],
        rows: [],
        filters: [],
        headers_columns: [],
        open_filter: false,
        count_filter: 0,
        itemsPerPageOptions: [5, 10, 25, 50, 100,250],
        footerProps: {
          itemsPerPageOptions: [5, 10, 25, 50, 100, 250, { text: 'All', value: -1 }]
        },
        watchPagination: false,
        options: {
          page: 1,
          itemsPerPage: 10,
          filters: []
        },
        optionsOld: [],
        provider_uuid: null,
        item_provider: [],
        model_status: {},
        main_currency: 'USD',
        main_currency_rate: 1,
        rate_thb: 1,
        item_currency: [],
        fee_transfer: 0,
        fee_credit_card: 0,
        fee_promptpay: 0,
        service_fee_transfer: 0,
        service_fee_credit_card: 0,
        service_fee_promptpay: 0,
        omise_customer: {},
        status_payment_transfer: false,
        status_payment_credit_card: false,
        status_payment_promptpay: false,
        lang_service: {},
        my_package_keyword: [],
        tab_action_item: [],
        tab_action: 'table',
        tab_payment: 'transfer',
        order_all_loading: false,
        active_all_loading: false,
        unactive_all_loading: false,
        chart_all_loading: false,
        report_all_loading: false,
        data_insights_loading: false,
        disable_columns: []
      }
    },
    props: {
      api: {
        default: '/app/data-table',
        required: false,
        type: String
      },
      create: {
        default: null,
        required: false,
        type: String
      },
      edit: {
        default: null,
        required: false,
        type: String
      },
      model: {
        default: '',
        required: false,
        type: String
      },
      table: {
        default: '',
        required: false,
        type: String
      },
      where: {
        default: '',
        required: false,
        type: String
      },
      where_value: {
        default: '',
        required: false,
        type: String
      },
      select_provider: {
        default: false,
        required: false,
        type: Boolean
      },
      customfilter: {
        default: '',
        required: false,
        type: String
      },
      user_uuid: {
        default: '',
        required: false,
        type: String
      },
      enterprise: {
        default: false,
        required: false,
        type: Boolean
      }
    },
    created () {
      // Set locale
      this.locale = (this.$auth.check()) ? this.$auth.user().locale: Intl.DateTimeFormat().resolvedOptions().locale || this.$i18n.locale
      this.moment.locale(this.locale.substr(0,2))
      this.tab_action = 'table'

      let disable_columns = []
      if(Cookies.get('hide_columns')){
        let hide_columns = JSON.parse(Cookies.get('hide_columns'))
        if(hide_columns[this.model]){
          disable_columns = hide_columns[this.model]
        }
      }
      this.disable_columns = disable_columns
      // if(this.$auth.user().role == 2 && this.$route.name == 'tracking-keyword'){
      //   this.axios.get('/tracking/keyword/get-date-call-api')
      //   .then(res => {
      //     //console.log(res.data)
      //     this.json_date = res.data.json_date
      //   })
      //   .catch(err => {
      //     if (err.response.data.status === 'error') {
      //       this.$root.$snackbar(err.response.data.msg)
      //     }
      //   })
      // }
      
      if(this.$route.name == 'admin.billing-history' || this.$route.name == 'billing-history'){
        this.axios.get('/billing/get-main-currency')
        .then(res => {
          if (res.data.status === 'success') {
            //console.log(res.data)
            this.status_payment_transfer = res.data.setting.status_payment_transfer ? true : false
            this.status_payment_credit_card = res.data.setting.status_payment_credit_card ? true : false
            this.status_payment_promptpay = res.data.setting.status_payment_promptpay ? true : false
            this.main_currency = res.data.currency
            this.main_currency_rate = res.data.rate
            this.item_currency = res.data.item_currency
            this.bank_account = res.data.bank_account
            this.fee_transfer = res.data.fee_transfer
            this.fee_credit_card = res.data.fee_credit_card
            this.fee_promptpay = res.data.fee_promptpay
            this.service_fee_transfer = res.data.service_fee_transfer
            this.service_fee_credit_card = res.data.service_fee_credit_card
            this.service_fee_promptpay = res.data.service_fee_promptpay
            this.rate_thb = res.data.rate_thb
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
      }

      // if(this.$route.name == 'billing-history'){
      //   this.axios.get('/billing/get-omise-card')
      //   .then(res => {
      //     if (res.data.status === 'success') {
      //       //console.log(res.data)
      //       this.omise_customer = res.data.omise_customer
      //     }
      //   })
      //   .catch(err => {
      //     this.loading = false
      //     if (err.response.data.status === 'error') {
      //       this.$root.$snackbar(err.response.data.msg)
      //     }
      //   })
      // }

      // if(this.$route.name == 'tracking-keyword' && this.$auth.user().role == 2){
      //   this.axios.get('/package/keyword/get-all-active')
      //   .then(res => {
      //     if (res.data.status === 'success') {
      //       //console.log(res.data)
      //       this.my_package_keyword = res.data.package_keyword

      //     }
      //   })
      //   .catch(err => {
      //     this.loading = false
      //     if (err.response.data.status === 'error') {
      //       this.$root.$snackbar(err.response.data.msg)
      //     }
      //   })
      // }

      if(this.$route.name == 'admin.extras' || this.$route.name == 'admin.services'){
        this.axios.get('/admin/get-lang')
        .then(res => {
          if (res.data.status === 'success') {
            //console.log(res.data)
            this.lang = res.data.lang
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
      }

      if(
        this.$route.name == 'admin.extras' || 
        this.$route.name == 'admin.services' ||
        this.$route.name == 'admin.backlink-history' || 
        this.$route.name == 'backlink-history'
      ){
        this.axios.get('/backlink/get-lang-service')
        .then(res => {
          if (res.data.status === 'success') {
            //console.log(res.data)
            this.lang_service = res.data.lang_service
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
      }

      // if(this.$route.name == 'admin.users'){
      //   this.$root.$refs.master.getCredit()
      // }
      

    },
    watch: {
      options: {
        handler (val) {
          if (this.watchPagination) {
            let string_val = String(JSON.stringify(val))
            let string_old = String(this.optionsOld)

            if (string_val !== string_old) {
              //console.log(1)
              this.reloadData(true)
            }

            this.optionsOld = string_old
          }
        },
        deep: true
      },
      search () {
        this.searchData()
      },
      selected () {
        this.disableDeleteSelected = (this.selected.length > 0) ? false : true;
      },
      dataListEvents () {
        if (this.dataListEvents.closeDialog) {
          this.canceldataForm()
          //this.dataForm = false
        }
        if (this.dataListEvents.reload) {
          this.reloadData()
        }
      }
    },
    mounted () {
      if (typeof this.$route.params.showSnackbar !== 'undefined') {
        this.$root.$snackbar(this.$t(this.$route.params.showSnackbar))
      }
      // this.$store.dispatch('setClassExpandContainer', '')
      //console.log(this.select_provider)
      if(this.select_provider){
        this.axios.get('/admin/provider/get')
        .then(res => {
          if (res.data.status === 'success') {
            //console.log(res.data)
            this.item_provider = res.data.providers
            this.provider_uuid = res.data.providers[0].uuid
            //console.log(this.provider_uuid)
            this.getDataFromApi()
            .then(data => {
              this.rows = data.items
              this.watchPagination = true
            })
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
      }
      else{
        this.getDataFromApi()
        .then(data => {
          this.rows = data.items
          this.watchPagination = true
        })
      }
      this.$root.$refs.dataTableReload = this.reloadData;
    },
    methods: {
      copyStringToClipboard,
      searchData: _.debounce(function() {
        this.loading = true
        this.reloadData()
      }, 400),
      formatSelect (data , items) {
        let item = items.find(e => e.value == data)
        return item ? item.name : ''
      },
      formatNumberCurency (value) {
        const num = Number(value);
        if (num && num !== 0 && Math.abs(num) < 0.01) {
            // ระบุตำแหน่งของตัวเลขที่สำคัญแรกโดยตรวจสอบจาก logarithm
            const significantDigits = Math.ceil(-Math.log10(Math.abs(num)));
            // ถ้าค่าที่ได้มากกว่า 2 ให้เรากำหนดความแม่นยำตามค่านั้น
            return num.toFixed(significantDigits > 2 ? significantDigits : 2);
        } else {
            // ค่ามากกว่าหรือเท่ากับ 0.01 ใช้ toFixed 2
            return num ? num.toFixed(2) : 0;
        }
      },
      formatNumber (number) {
        return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
      },
      formatCurrency (number,currency) {
        return currency ? new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: currency}).format(number) : '-'
      },
      encodeDecodeURL(url) {
        if(!url){
          return ''
        }
        const parts = url.split('/');
        const decodedParts = parts.map(part => {
          try {
            // พยายามดีโค้ดแต่ละส่วนของ URL
            return decodeURIComponent(part);
          } catch {
            // หากเกิดข้อผิดพลาด, ส่งคืนส่วนนั้นๆ ออกมาเป็นเอนโค้ด
            return part;
          }
        });
        // รวมกลับเป็น URL ที่ดีโค้ดได้บางส่วนและเอนโค้ดบางส่วน
        return decodedParts.join('/');
      },
      LangServce(service_id,providers_id,type){
        //console.log(this.lang_service)
        //console.log(type)
        let language = this.$store.state && this.$store.state.app && this.$store.state.app.language ? this.$store.state.app.language.toLowerCase() : 'th-en'
        if(this.lang_service && this.lang_service[language]){
          let service = this.lang_service[language].find(e => e.providers_id == providers_id && e.type == type && e.providers_service_id == service_id)
          //console.log(service)
          if(service){
            return service.providers_service_description_translation
          }
          else{
            return ''
          }
        }
        else{
          return ''
        }
      },
      handleMouseOver(column) {
        this.hoveredColumn = column;
      },
      handleMouseLeave() {
        this.hoveredColumn = null;
      },
      updateSortBy(newSortBy) {
        //console.log(newSortBy)
        //this.sortedDesc = true;
        //this.sortedBy = newSortBy;
      },
      updateSortDesc(newSortDesc) {
        //console.log(newSortDesc)
        //this.sortedDesc = false;
        //this.sortedDesc = newSortDesc;
        //this.descending = newSortDesc;
      },
      getDataFromApi (sort = false) {
        this.loading = true
        return new Promise((resolve) => {
          let that = this
          
          // console.log(this.options.sortDesc)
          // if(this.options.sortBy.length){
          //   this.options.sortDesc[0] = true
          // }
          //console.log(this.options.sortBy)

          if(!this.options.sortBy || !this.options.sortBy.length){
            if(this.headers.length){
              this.options.sortBy = []
              this.sortedDesc = true
              for(let e of this.headers){
                if(e.value == 'created_at'){
                  this.options.sortBy = ['created_at']
                }
              }
            }
            else{
              this.options.sortBy = ['created_at']
              this.sortedDesc = true
            }
          }
          else{
            if(this.options.sortBy[0] == 'created_at'){
              let chk = false
              for(let e of this.headers){
                if(e.value == 'created_at'){
                  chk = true
                }
              }
              if(!chk){
                this.options.sortBy = []
              }
            }  
          }   
           
          if(this.sortedBy != this.options.sortBy[0]){
            this.sortedDesc = false
          }

          if(!sort){
            this.options.sortBy = ['created_at']
            this.sortedDesc = true
          }
          //console.log(this.sortedDesc)  
          if(this.options.page != 1 || this.page_old != 1){
            this.sortedDesc = this.descending_old
          }
          this.descending_old = this.sortedDesc
          this.page_old = this.options.page
          //console.log(this.descending)
          let params_data = {
              locale: this.$i18n.locale,
              model: this.model,
              search: this.search,
              page: this.options.page,
              itemsPerPage: this.options.itemsPerPage,
              sortBy: JSON.stringify(this.options.sortBy),
              descending: this.sortedDesc,
              filters: JSON.stringify(this.options.filters),
              disable_columns: JSON.stringify(this.disable_columns),
          }
          if(this.where){
            params_data['where'] = this.where
          }
          if(this.where_value){
            params_data['where_value'] = this.where_value
          }
          if(this.select_provider){
            params_data['provider_uuid'] = this.provider_uuid
          }
          if(this.enterprise){
            params_data['user_uuid'] = this.user_uuid
            params_data['enterprise'] = 1
          }
          this.axios.post(this.api, params_data)
          .then(res => {
            that.disable_btn = false
            if (res.data.status === 'success') {

              // this.options.sortBy = ['created_at']
              // this.options.sortDesc = false

              this.count_api = 0
              that.headers = Object.keys(res.data.headers).map((k) => {
                //console.log(res.data.headers[k])
                return res.data.headers[k]
              })
              //console.log(that.headers)
              //console.log(that.headers)
              that.headers_columns = res.data.headers_columns
              if(!that.filters.length){
                that.filters = res.data.filters
                for(let f of that.filters){
                  if(f.model && f.model.length){
                    //console.log(f.model)
                    that.filters = that.filters.filter((e) => {
                      if(e.column == f.column){
                        for(let i of f.model){
                          //console.log(i)
                          e.items = e.items.filter((v) => {
                            if(v.pk == i){
                              v.active = v.active ? false : true
                            }
                            return v
                          })
                        }
                      }
                      return e
                    })
                  }
                }  
              }
              //that.options.sortDesc[0] = true
              that.settings = res.data.settings
              that.filteredResults = res.data.filteredResults
              that.actions = res.data.actions
              that.showExport = res.data.showExport
              that.translations = res.data.translations
              that.totalItems = res.data.total
              that.loading = false
              that.loaded = true
              that.checkbox_all = false
              that.checkbox = []
              let items = res.data.records 
              
              // console.log(that.options)
              // let sortBy = that.options.sortBy
              // let sortDesc = that.options.sortDesc
              // //console.log(sortBy)
              // //console.log(sortDesc)
              // if (sortBy.length === 1 && sortDesc.length === 1) {
              //   items = items.sort((a, b) => {
              //     const sortA = a[sortBy[0]]
              //     const sortB = b[sortBy[0]]
              //     //console.log(sortA)
              //     //console.log(sortB)
              //     if (sortDesc[0]) {
              //       if (sortA < sortB) return 1
              //       if (sortA > sortB) return -1
              //       return 0
              //     } else {
              //       if (sortA < sortB) return -1
              //       if (sortA > sortB) return 1
              //       return 0
              //     }
              //   })
              // }
              //console.log(items)
              that.first_loading = true
              
              // if(this.descending_old){
              //   this.descending = false
              // }
              // else{
              //   this.descending = true
              // }
              
              // if(this.options.sortBy[0] == 'created_at' && typeof this.descending == 'undefined'){
              //   this.descending = false
              // }
              // else if(typeof this.descending == 'undefined'){
              //   this.descending = true
              // }  
              //console.log(this.sortedDesc)
              that.has_item_checkbox = false  
              // if(!this.options.sortBy || !this.options.sortBy.length){
              // }
              // console.log(sort)
              // if(sort){
              //   if(that.sortedDesc){
              //     that.sortedDesc = false
              //   }
              //   else{
              //     that.sortedDesc = true
              //   }
              // }
              // else{
              //   that.sortedDesc = true
              // }
              // if(!sort){
              // }
              
              if(that.sortedDesc){
                that.sortedDesc = false
              }
              else{
                that.sortedDesc = true
              }
              
              
              
              that.sortedBy = that.options.sortBy[0] 
              resolve({
                items
              })
            }
          })
          .catch(err => {
            if(this.count_api <= 3){
              this.count_api++
              this.getDataFromApi()
            }
            console.log(err.response.data)
          })
          .finally(() => that.loading = false)
        })
      },
      reloadData (sort = false) {
        this.tab_action = 'table'
        this.watchPagination = false
        this.loading = true
        this.getDataFromApi(sort)
          .then(data => {
            this.rows = data.items
            this.watchPagination = true
          })
      },
      changeFilter () {
        let filters = {};
        for (let f in this.filters) {
          let filter = this.filters[f].column
          let pk = this.filters[f].model
          if (pk && pk.length > 0) filters[filter] = pk
        }
        this.options.filters = filters
      },
      CustomFilters (){
        let filters = JSON.parse(JSON.stringify(this.filters))
        //console.log(filters)
        
        this.$root.$custom_filters(filters,this.filter_first)
        .then((confirm) => {
          if (confirm.confirm) {
            this.filters = JSON.parse(JSON.stringify(confirm.filters))
            //console.log(this.filters)
            let filters = {};
            for (let f of this.filters) {
              let filter = f.column
              let pk = []
              if(f.type == 'enum'){
                for (let item of f.items) {
                  if(item.active){
                    pk.push(item.pk)
                  }
                }
              }
              else if(f.type == 'number'){
                if(f.min || f.max){
                  pk.push(f.min)
                  pk.push(f.max)
                }
              }
              else if(f.type == 'date_time'){
                if(f.start_date || f.end_date){
                  pk.push(f.start_date)
                  pk.push(f.end_date)
                }
              }
              if (pk && pk.length > 0) filters[filter] = pk
            }
            this.count_filter = Object.keys(filters).length
            this.options.filters = filters
          }
        })
        if(this.filter_first){
          this.filter_first = false
        }
      },
      CustomColumn(){
        //console.log(this.headers_columns)
        if(this.headers_columns.length){
          this.$root.$custom_column(this.headers_columns,this.model)
          .then((confirm) => {
            if (confirm.confirm) {
              //console.log(confirm)
              this.disable_columns = confirm.disable_columns
              this.reloadData()
            }
          })
        }
      },
      expandTable(){
        if(this.$store.state.app.classExpandContainer){
          this.$store.dispatch('setClassExpandContainer', '')
        }
        else{
          this.$store.dispatch('setClassExpandContainer', 'expand_container')
        }
      },
      ActionConditionShowHide(condition_show_hide,show_hide_page,show_hide_name){
        if(condition_show_hide && this.$auth.user().json_disable_action && this.$auth.user().json_disable_action[show_hide_page]){
          let json_disable_action = this.$auth.user().json_disable_action[show_hide_page]
          //console.log(json_disable_action)
          //console.log(show_hide_name)
          if(json_disable_action.find(e => e == show_hide_name)){
            return false
          }
        }
        return true
      },
      ActionDisable(condition_disable_array, data, action){
        // console.log(condition_disable_array)
        // console.log(data)

        let condition = true
        let total = condition_disable_array.length
        let count = 0
        for(let e of condition_disable_array){
          let value1 = e.value1.type == 'value' ? data[e.value1.value] : e.value1.value
          let value2 = e.value2.type == 'value' ? data[e.value2.value] : e.value2.value
          let type = 'value'
          if(e.value2.type == 'datetime' && data[e.value1.value]){
            value1 = this.moment(data[e.value1.value]);
            value2 = this.moment()
            type = 'datetime'
          }
          if(type == 'value'){
            if(e.condition == '='){
              if(e.or){
                //console.log(value2)
                let chk = false
                for(let value of value2){
                  if(value1 == value){
                    chk = true
                  }
                }
                if(chk){
                  count++
                }
              }
              else{
                if(value1 == value2){
                  count++
                }
              }
            }
            else if(e.condition == '!='){
              if(e.or){
                let chk = false
                for(let value of value2){
                  if(value1 != value){
                    chk = true
                  }
                }
                if(chk){
                  count++
                }
              }
              else{
                if(value1 != value2){
                  count++
                }
              }
            }
            else if(e.condition == '>'){
              if(e.or){
                let chk = false
                for(let value of value2){
                  if(value1 > value){
                    chk = true
                  }
                }
                if(chk){
                  count++
                }
              }
              else{
                if(value1 > value2){
                  count++
                }
              }
            }
            else if(e.condition == '<'){
              if(e.or){
                let chk = false
                for(let value of value2){
                  if(value1 < value){
                    chk = true
                  }
                }
                if(chk){
                  count++
                }
              }
              else{
                if(value1 < value2){
                  count++
                }
              }
            }
            else if(e.condition == '>='){
              if(e.or){
                let chk = false
                for(let value of value2){
                  if(value1 >= value){
                    chk = true
                  }
                }
                if(chk){
                  count++
                }
              }
              else{
                if(value1 >= value2){
                  count++
                }
              }
            }
            else if(e.condition == '<='){
              if(e.or){
                let chk = false
                for(let value of value2){
                  if(value1 <= value){
                    chk = true
                  }
                }
                if(chk){
                  count++
                }
              }
              else{
                if(value1 <= value2){
                  count++
                }
              }
            }
          }
          else if(type == 'datetime'){
            //console.log(value1.diff(value2,'minutes'))
            if(e.condition == '>'){
              if(value1.diff(value2,'minutes') > 0){
                count++
              }
            }
            else{
              if(value2.diff(value1,'minutes') > 0){
                count++
              }
            }
          }
          
        }

        condition = count == total ? false : true
        return condition
      },
      ActionCondition (condition_array, data) {
        let condition = false
        let total = condition_array.length
        let count = 0
        for(let e of condition_array){
          let value1 = e.value1.type == 'value' ? data[e.value1.value] : e.value1.value
          let value2 = e.value2.type == 'value' ? data[e.value2.value] : e.value2.value
          //console.log(e.value2.type) 
          if(e.value2.type == 'date'){
            //console.log(this.moment(value1).isAfter(this.moment()))
            if(e.condition == '>'){
              if(this.moment(value1).isAfter(this.moment())){
                count++
              }
            }
            else{
              if(this.moment().isAfter(this.moment(value1))){
                count++
              }
            }
          }
          else if(e.condition == '='){
            if(e.or){
              let chk = false
              for(let value of value2){
                if(value1 == value){
                  chk = true
                }
              }
              if(chk){
                count++
              }
            }
            else{
              if(value1 == value2){
                count++
              }
            }
          }
          else if(e.condition == '!='){
            if(e.or){
              let chk = false
              for(let value of value2){
                if(value1 != value){
                  chk = true
                }
              }
              if(chk){
                count++
              }
            }
            else{
              if(value1 != value2){
                count++
              }
            }
          }
          else if(e.condition == '>'){
            if(e.or){
              let chk = false
              for(let value of value2){
                if(value1 > value){
                  chk = true
                }
              }
              if(chk){
                count++
              }
            }
            else{
              if(value1 > value2){
                count++
              }
            }
          }
          else if(e.condition == '<'){
            if(e.or){
              let chk = false
              for(let value of value2){
                if(value1 < value){
                  chk = true
                }
              }
              if(chk){
                count++
              }
            }
            else{
              if(value1 < value2){
                count++
              }
            }
          }
          else if(e.condition == '>='){
            if(e.or){
              let chk = false
              for(let value of value2){
                if(value1 >= value){
                  chk = true
                }
              }
              if(chk){
                count++
              }
            }
            else{
              if(value1 >= value2){
                count++
              }
            }
          }
          else if(e.condition == '<='){
            if(e.or){
              let chk = false
              for(let value of value2){
                if(value1 <= value){
                  chk = true
                }
              }
              if(chk){
                count++
              }
            }
            else{
              if(value1 <= value2){
                count++
              }
            }
          }
        }
        condition = count == total ? true : false
        return condition
      },
      ChangeStatus (e,uuid,item) {
        //console.log(e)
        //console.log(uuid)
        //console.log(item)
        if(this.model_status[uuid] || this.model_status[uuid] == 0){
          if(this.model_status[uuid] == item.value_active){
            this.model_status[uuid] = item.value_unactive
          }
          else{
            this.model_status[uuid] = item.value_active
          }
        }
        else{
          if(e == item.value_active){
            this.model_status[uuid] = item.value_unactive
          }
          else{
            this.model_status[uuid] = item.value_active
          }
        }
        //console.log(this.model_status[uuid])
        //console.log(item.value_active)
        let url = this.model_status[uuid] == item.value_active ? item.url_active : item.url_unactive
        this.axios.post(url, {
            uuid: uuid,
        })
        .then(res => {
          if (res.data.status === 'success') {
            this.$root.$snackbar(this.$t('change_status_saved'))
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        
      },
      ActionConditionDate(condition_data, data){
        //console.log(condition_data)
        //console.log(data)
        let res = true
        let diff
        if(condition_data.value2 == 'now'){
          diff = this.moment(data[condition_data.value1]).diff(this.moment(), condition_data.format)
        }
        else{
          diff = this.moment(data[condition_data.value1]).diff(this.moment(data[condition_data.value2]), condition_data.format)
        }
        if(condition_data.condition == '>'){
          if(diff > 0){
            res = true
          }
          else{
            res = false
          }
        }
        else{
          if(diff > 0){
            res = false
          }
          else{
            res = true
          }
        }
        return res
      },
      selectAll(){
        //console.log(this.rows)
        if(this.checkbox_all){
          let selecte = [] 
          for(let e of this.rows){
            selecte.push(e.uuid)
          }
          this.checkbox = selecte
          this.has_item_checkbox = true
        }
        else{
          this.checkbox = []
          this.has_item_checkbox = false
        }
        //console.log(this.checkbox)
      },
      selectItem(){
        // console.log(this.checkbox)
        if(this.checkbox.length){
          this.has_item_checkbox = true
        }
        else{
          this.has_item_checkbox = false
        }
      },
      OrderAll(){
        if(this.checkbox.length){
          let array_keyword = []
          for(let uuid of this.checkbox){
            let data_row = this.rows.find(e => e.uuid == uuid)
            if(data_row){
              array_keyword.push({
                uuid: uuid,
                keyword: data_row.keyword,
                domain: data_row.url,
              })
            }
          }
          //console.log(array_keyword)

          this.$router.push({
            name: 'backlink-add-order', 
            query: this.$route.query, 
            params: { 
              select_all: true,
              array_keyword: array_keyword,
            }
          })

        }
        else{
          this.$root.$snackbar(this.$t('alert_select_keyword'))
        }
      },
      executeAction (action, uuid, item) {
        this.type_action = action
        
        if (action == 'delete') {
          this.uuid = null
          this.deleteRecords([uuid])
        }
        if (action == 'log_in_as') {
          this.$auth.impersonate({
              data: {
                uuid: uuid
              },
              success: function () {
                this.$root.$refs.master.getCredit()
              },
              error: function () {},
              redirect: {name: 'home'},
          })
        }
        if (action == 'delete_keyword') {
          this.uuid = uuid
          this.$root.$confirm_delete(this.$t('confirm_delete_keyword'),'deleted')
          .then((confirm) => {
            if (confirm.confirm) {
              if(confirm.password == 'deleted'){
                this.loading = true
                this.axios.post('/tracking/keyword/delete', {
                    uuid: uuid,
                })
                .then(res => {
                  if (res.data.status === 'success') {
                    this.$root.$snackbar(this.$t('data_deleted'))
                    this.reloadData()
                  }
                })
                .catch(err => {
                  this.loading = false
                  if (err.response.data.status === 'error') {
                    this.$root.$snackbar(err.response.data.msg)
                  }
                })
                .finally(() => this.loading = false)  
              }
              else{
                this.$root.$snackbar(this.$t('invalid_value'))
              }
            }
          })
        }
        if (action == 'delete_user_admin') {
          let reason = false
          this.uuid = uuid
          this.$root.$confirm_delete(this.$t('confirm_delete_user'),'deleted')
          .then((confirm) => {
            if (confirm.confirm) {
              if(confirm.password == 'deleted'){
                this.loading = true
                this.axios.post('/admin/user/delete', {
                    uuid: uuid,
                })
                .then(res => {
                  if (res.data.status === 'success') {
                    this.$root.$snackbar(this.$t('data_deleted'))
                    this.reloadData()
                  }
                })
                .catch(err => {
                  this.loading = false
                  if (err.response.data.status === 'error') {
                    this.$root.$snackbar(err.response.data.msg)
                  }
                })
                .finally(() => this.loading = false)  
              }
              else{
                this.$root.$snackbar(this.$t('invalid_value'))
              }
            }
          })
        }
        if (action == 'view_package') {
          this.loading = true
          this.axios.post('/package/keyword/get', {
            uuid: uuid,
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              this.$root.$dialog_view_package(res.data.package)
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'view_package_boost_keyword') {
          this.loading = true
          this.axios.post('/package/boost-keyword/get', {
            uuid: uuid,
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              //console.log(res.data.package)
              this.$root.$dialog_view_package_boost_keyword(res.data.package)
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        
        if (action == 'view_purchase_package') {
          this.loading = true
          this.axios.post('/billing/get-package/keyword', {
            uuid: uuid,
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              this.$root.$dialog_view_package(res.data.package)
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'view_purchase_package_boost_keyword') {
          this.loading = true
          this.axios.post('/billing/get-package/boost-keyword', {
            uuid: uuid,
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              this.$root.$dialog_view_package_boost_keyword(res.data.package)
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        
        if (action == 'view_purchase_order') {
          this.loading = true
          this.axios.post('/billing/get-backlink', {
            uuid: uuid,
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              let order_item_service = JSON.parse(JSON.stringify(res.data.order_item_service))
              if(!res.data.order.status_serpclick){
                let description = this.LangServce(order_item_service.providers_service_id,res.data.order.providers_id,'service')
                order_item_service.description = description
              }
              //console.log(order_item_service)

              let order_item_extras = JSON.parse(JSON.stringify(res.data.order_item_extras))
              let new_order_item_extras = []
              for(let e of order_item_extras){
                let array = JSON.parse(JSON.stringify(e))
                if(!res.data.order.status_serpclick){
                  let description = this.LangServce(e.providers_extras_id,res.data.order.providers_id,'extras')
                  array.description = description
                }
                new_order_item_extras.push(array)
              }
              //console.log(new_order_item_extras)
              this.$root.$dialog_view_order(
                res.data.order,
                order_item_service,
                new_order_item_extras,
              )
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'view_payment') {
          this.loading = true
          this.axios.post('/billing/get', {
            uuid: uuid,
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              let payment = res.data.payment
              let form_payment = {
                amount: payment.amount,
                currency: payment.currency,
                amount_usd: payment.amount_usd,
                slip: payment.slip,
                service_fee: payment.service_fee,
                fee: payment.fee,
                total_amount: payment.total_amount,
                type: payment.type,
                status: payment.status,
                note_disapprove: payment.note_disapprove,
                percent_service_fee: payment.percent_service_fee
              }
              let fee = 0
              let service_fee = 0

              if(payment.type == 'transfer'){
                fee = this.fee_transfer
                service_fee = this.service_fee_transfer
              }
              else if(payment.type == 'promptpay'){
                fee = this.fee_promptpay
                service_fee = this.service_fee_promptpay
              }
              else if(payment.type == 'credit_card'){
                fee = this.fee_credit_card
                service_fee = this.service_fee_credit_card
              }
              this.$root.$dialog_payment(
                true,
                form_payment,
                this.item_currency,
                this.main_currency,
                this.main_currency_rate,
                this.bank_account,
                fee,
                service_fee
              )
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if(action == 'view_qrcode_promptpay'){
          this.loading = true
          this.axios.post('/billing/get', {
            uuid: uuid,
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              let payment = res.data.payment
              if(payment && payment.log_omise){
                let source = JSON.parse(payment.log_omise)
                //console.log(source)
                if(source && 
                source.scannable_code &&
                source.scannable_code.image
                ){
                  this.$root.$qrcode_promptpay(source.scannable_code.image.download_uri)
                }
              }
              
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }

        if (action == 'active_keyword') { 
          this.loading = true
          let data_row = this.rows.find(e => e.uuid == uuid)
          if(!data_row){
            return false
          }
          this.axios.post('/package/keyword/get-date',{
            uuid: uuid,
            serp_provider_id: data_row.serp_provider_id,
            enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
            type: 'single'
          })
          .then(res => {
            if (res.data.status === 'success') { 
              if(res.data.package_keyword && res.data.package_keyword.length){
                let json_date = []
                for(let item of res.data.package_keyword){ 
                  // let dub = json_date.find(e => e.date == item.frequency_date && e.format == item.frequency_date_format)
                  // if(!dub){
                  //   json_date.push({
                  //     id: item.uuid,
                  //     date: item.frequency_date,
                  //     format: item.frequency_date_format,
                  //   })
                  // }
                  json_date.push({
                    id: item.uuid,
                    date: item.frequency_date,
                    format: item.frequency_date_format,
                    limit_active: item.limit_active_keyword,
                    count_acive: item.count_acive_package,
                    package_date_expired: item.package_date_expired,
                    depth: item.depth,
                  })
                }
                this.$root.$select_date_active_keyword(json_date,1)
                .then((confirm) => {
                  if (confirm.confirm) {
                    //console.log(confirm)
                    let package_uuid = confirm.data.id
                    let reason = false
                    this.uuid = uuid
                    this.$root.$confirm(this.$t('confirm'), this.$t('confirm_active_seo'),{
                      color: 'primary',
                      width: 360,
                      zIndex: 200
                    },reason)
                    .then((confirm) => {
                      if (confirm.confirm) {
                        this.loading = true
                        this.axios.post('/tracking/keyword/active', {
                          uuid: uuid,
                          package_uuid: package_uuid,
                          enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
                        })
                        .then(res => {
                          if (res.data.status === 'success') {
                            this.$root.$snackbar(this.$t('item_saved'))
                            this.reloadData()
                          }
                        })
                        .catch(err => {
                          this.loading = false
                          if (err.response.data.status === 'error') {
                            this.$root.$snackbar(err.response.data.msg)
                          }
                        })
                        .finally(() => this.loading = false)
                      }
                    })
                  }
                })
              }
              else{
                this.$root.$snackbar(this.$t('alert_buy_package'))
                this.$router.push({
                  name: 'tracking-package', 
                  query: this.$route.query, 
                })
              }
              
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              if(err.response.data.type == 'buy_package'){
                this.$root.$snackbar(this.$t('alert_buy_package'))
                this.$router.push({
                  name: 'tracking-package-keyword-add', 
                  query: this.$route.query, 
                  params: { 
                    serp_provider_uuid: err.response.data.serp_provider_uuid,
                  }
                })
              }
              else{
                this.$root.$snackbar(err.response.data.msg)
              }
            }
          })
          .finally(() => this.loading = false)


          // let reason = false
          // this.uuid = uuid
          // this.$root.$confirm(this.$t('confirm'), this.$t('confirm_active_seo'),{
          //   color: 'primary',
          //   width: 360,
          //   zIndex: 200
          // },reason)
          // .then((confirm) => {
          //   if (confirm.confirm) {
          //     this.loading = true
          //     this.axios.post('/tracking/keyword/'+action, {
          //       uuid: uuid,
          //     })
          //     .then(res => {
          //       if (res.data.status === 'success') {
          //         this.$root.$snackbar(this.$t('item_saved'))
          //         this.reloadData()
          //       }
          //     })
          //     .catch(err => {
          //       this.loading = false
          //       if (err.response.data.status === 'error') {
          //         this.$root.$snackbar(err.response.data.msg)
          //       }
          //     })
          //     .finally(() => this.loading = false)
          //   }
          // })

        }  
        
        if (action == 'active_enterprise') {
          this.uuid = uuid
          this.$root.$confirm_delete(this.$t('confirm_active_enterprise'),'enterprise')
          .then((confirm) => {
            if (confirm.confirm) {
              if(confirm.password == 'enterprise'){
                this.loading = true
                this.axios.post('/admin/enterprise/active', {
                  uuid: uuid,
                })
                .then(res => {
                  if (res.data.status === 'success') {
                    this.$root.$snackbar(this.$t('item_saved'))
                    this.reloadData()
                  }
                })
                .catch(err => {
                  if (err.response.data.status === 'error') {
                    this.$root.$snackbar(err.response.data.msg)
                  }
                })
                .finally(() => this.loading = false)  
              }
              else{
                this.$root.$snackbar(this.$t('invalid_value'))
              }
            }
          })
        }
        if (action == 'unactive_enterprise') {
          this.uuid = uuid
          this.$root.$confirm_delete(this.$t('confirm_unactive_enterprise'),'enterprise')
          .then((confirm) => {
            if (confirm.confirm) {
              if(confirm.password == 'enterprise'){
                this.loading = true
                this.axios.post('/admin/enterprise/unactive', {
                  uuid: uuid,
                })
                .then(res => {
                  if (res.data.status === 'success') {
                    this.$root.$snackbar(this.$t('item_saved'))
                    this.reloadData()
                  }
                })
                .catch(err => {
                  if (err.response.data.status === 'error') {
                    this.$root.$snackbar(err.response.data.msg)
                  }
                })
                .finally(() => this.loading = false)
              }
              else{
                this.$root.$snackbar(this.$t('invalid_value'))
              }
              
            }
          })
        }
        
        if (action == 'unactive_keyword_all') {
          this.uuid = uuid
          this.$root.$confirm_delete(this.$t('confirm_unactive_keyword_all'),'confirm')
          .then((confirm) => {
            if (confirm.confirm) {
              if(confirm.password == 'confirm'){
                this.loading = true
                this.axios.post('/admin/tracking/unactive-all', {
                  uuid: uuid,
                })
                .then(res => {
                  if (res.data.status === 'success') {
                    this.$root.$snackbar(this.$t('item_saved'))
                    this.reloadData()
                  }
                })
                .catch(err => {
                  if (err.response.data.status === 'error') {
                    this.$root.$snackbar(err.response.data.msg)
                  }
                })
                .finally(() => this.loading = false)  
              }
              else{
                this.$root.$snackbar(this.$t('invalid_value'))
              }
            }
          })
        }
        if (action == 'keyword_enterprise') {
          this.$router.push({
            name: 'tracking-keyword', 
            query: { 
              enterprise_uuid: uuid
            }
          })
        }
        if (action == 'order_enterprise') {
          this.$router.push({
            name: 'backlink-history', 
            query: { 
              enterprise_uuid: uuid
            }
          })
        }
        if (action == 'lighthouse_enterprise') {
          this.$router.push({
            name: 'lighthouse-history', 
            query: { 
              enterprise_uuid: uuid
            }
          })
        }
        if (action == 'unactive_keyword') {
          let reason = false
          this.uuid = uuid
          this.$root.$confirm(this.$t('confirm'), this.$t('confirm_unactive_seo'),{
            color: 'primary',
            width: 360,
            zIndex: 200
          },reason)
          .then((confirm) => {
            if (confirm.confirm) {
              this.loading = true
              this.axios.post('/tracking/keyword/unactive', {
                uuid: uuid,
                enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
              })
              .then(res => {
                if (res.data.status === 'success') {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
              .catch(err => {
                if (err.response.data.status === 'error') {
                  this.$root.$snackbar(err.response.data.msg)
                }
              })
              .finally(() => this.loading = false)
            }
          })
        }

        if (action == 'active_boost_keyword') { 
          this.loading = true
          let data_row = this.rows.find(e => e.uuid == uuid)
          if(!data_row){
            return false
          }
          this.axios.post('/package/boost-keyword/get-date',{
            uuid: uuid,
            serp_provider_id: data_row.serp_provider_id,
            enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
            type: 'single'
          })
          .then(res => {
            if (res.data.status === 'success') { 
              
              if(res.data.package_keyword && res.data.package_keyword.length){
                let json_date = []
                for(let item of res.data.package_keyword){ 

                  json_date.push({
                    id: item.uuid,
                    limit_active: item.limit_boost_keyword,
                    count_acive: item.count_acive_package,
                    package_date_expired: item.package_date_expired,
                    top_ranking: item.top_ranking,
                  })
                }
                this.$root.$select_date_boost_keyword(json_date,1)
                .then((confirm) => {
                  if (confirm.confirm) {
                    //console.log(confirm)
                    let package_uuid = confirm.data.id
                    let reason = false
                    this.uuid = uuid
                    this.$root.$confirm(this.$t('confirm'), this.$t('confirm_active_boost_keyword'),{
                      color: 'primary',
                      width: 360,
                      zIndex: 200
                    },reason)
                    .then((confirm) => {
                      if (confirm.confirm) {
                        this.loading = true
                        this.axios.post('/tracking/keyword/active-boost-keyword', {
                          uuid: uuid,
                          package_uuid: package_uuid,
                          enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
                        })
                        .then(res => {
                          if (res.data.status === 'success') {
                            this.$root.$snackbar(this.$t('item_saved'))
                            this.reloadData()
                          }
                        })
                        .catch(err => {
                          this.loading = false
                          if (err.response.data.status === 'error') {
                            this.$root.$snackbar(err.response.data.msg)
                          }
                        })
                        .finally(() => this.loading = false)
                      }
                    })
                  }
                })
              }
              else{
                this.$root.$snackbar(this.$t('alert_buy_package'))
                this.$router.push({
                  name: 'tracking-package-boost-keyword-add', 
                  query: this.$route.query, 
                })
              }
              
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              if(err.response.data.type == 'buy_package'){
                this.$root.$snackbar(this.$t('alert_buy_package'))
                this.$router.push({
                  name: 'tracking-package-boost-keyword-add', 
                  query: this.$route.query, 
                  params: { 
                    serp_provider_uuid: err.response.data.serp_provider_uuid,
                  }
                })
              }
              else{
                this.$root.$snackbar(err.response.data.msg)
              }
            }
          })
          .finally(() => this.loading = false)
        }
        
        if (action == 'unactive_boost_keyword') {
          let reason = false
          this.uuid = uuid
          this.$root.$confirm(this.$t('confirm'), this.$t('confirm_unactive_boost_keyword'),{
            color: 'primary',
            width: 360,
            zIndex: 200
          },reason)
          .then((confirm) => {
            if (confirm.confirm) {
              this.loading = true
              this.axios.post('/tracking/keyword/unactive-boost-keyword', {
                uuid: uuid,
                enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
              })
              .then(res => {
                if (res.data.status === 'success') {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
              .catch(err => {
                if (err.response.data.status === 'error') {
                  this.$root.$snackbar(err.response.data.msg)
                }
              })
              .finally(() => this.loading = false)
            }
          })
        }

        if (action == 'google_data_insights') {
          this.loading = true
          this.axios.post('/tracking/keyword/get-insights-playground', {
            type: 'single',
            search_engine: 'google',
            enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              this.$root.$dialog_table_insights(uuid,res.data.settings,'single')
            }
          })
          .catch(err => {
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'report_keyword') {
          this.$root.$report_seo(uuid)
          // this.loading = true
          // //console.log(this.keyword)
          // this.axios.post('/tracking/keyword/report', {
          //   uuid: uuid,
          //   enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
          // })
          // .then(res => {
          //   if (res.data.status === 'success') {
          //     //console.log(res.data)
          //     this.$root.$report_seo(res.data.ranking,res.data.keyword)
          //   }
          // })
          // .catch(err => {
          //   this.loading = false
          //   if (err.response.data.status === 'error') {
          //     this.$root.$snackbar(err.response.data.msg)
          //   }
          // })
          // .finally(() => this.loading = false)
        }
        
        if (action == 'chart_order_backlink') {
          this.loading = true
          this.axios.post('/backlink/report/chart', {
            uuid: uuid,
            enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
          })
          .then(res => {
            if (res.data.status === 'success') {
              if(res.data.backlinks && res.data.backlinks.length){
                this.$root.$report_backlink_order(uuid,res.data.backlinks,'backlink')
              }
              else{
                this.$root.$snackbar(this.$t('not_found'))
              }
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'order_serpclick') {
          this.loading = true
          this.axios.post('/backlink/serpclick/chart', {
            uuid: uuid,
            enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
          })
          .then(res => {
            if (res.data.status === 'success') {
              if(res.data.serpclicks && res.data.serpclicks.length){
                this.$root.$report_backlink_order(uuid,res.data.serpclicks,'serpclick')
              }
              else{
                this.$root.$snackbar(this.$t('not_found'))
              }
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'table_serpclick') {
          this.loading = true
          this.axios.post('/backlink/serpclick/table', {
            uuid: uuid,
            enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
          })
          .then(res => {
            if (res.data.status === 'success') {
              if(res.data.serpclicks && res.data.serpclicks.length){
                this.$root.$dialog_table_serpclick(
                  res.data.serpclicks,
                  res.data.keyword,
                  res.data.keywords,
                  res.data.links,
                  'order'
                )
              }
              else{
                this.$root.$snackbar(this.$t('not_found'))
              }
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'chart_keyword_backlink') {
          this.loading = true
          this.axios.post('/tracking/keyword/backlink', {
            uuid: uuid,
            enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
          })
          .then(res => {
            if (res.data.status === 'success') {
              if(res.data.orders && res.data.orders.length){
                this.$root.$report_backlink(res.data.orders,res.data.backlinks,item.keyword)
              }
              else{
                this.$root.$snackbar(this.$t('not_found'))
              }
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'chart_keyword_serpclick') {
          this.loading = true
          this.axios.post('/tracking/keyword/serpclick', {
            uuid: uuid,
            enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
          })
          .then(res => {
            if (res.data.status === 'success') {
              if(res.data.orders && res.data.orders.length){
                this.$root.$report_backlink(res.data.orders,res.data.serpclicks,item.keyword)
              }
              else{
                this.$root.$snackbar(this.$t('not_found'))
              }
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'rival_keyword') {
          this.$root.$report_seo_rival(uuid)
          // this.loading = true
          // this.axios.post('/tracking/keyword/rival', {
          //   enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
          //   uuid: uuid,
          //   limit: 10,
          //   page: 1,
          // })
          // .then(res => {
          //   if (res.data.status === 'success') {
          //     //console.log(res.data)
          //     this.$root.$report_seo_rival(
          //       uuid,
          //       res.data.rival,
          //       res.data.keyword,
          //       res.data.url
          //     )

          //   }
          // })
          // .catch(err => {
          //   this.loading = false
          //   if (err.response.data.status === 'error') {
          //     this.$root.$snackbar(err.response.data.msg)
          //   }
          // })
          // .finally(() => this.loading = false)
        }
        if (action == 'admin-report') {
          this.loading = true
          //console.log(this.keyword)
          this.axios.post('/admin/keyword/'+action, {
            uuid: uuid,
          })
          .then(res => {
            if (res.data.status === 'success') {
              console.log(res.data)
              this.$root.$report_seo(res.data.google_search,res.data.keyword)
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'admin_package_date') {
          this.$router.push({
            name: 'admin.tracking-manage-package-date', 
            query: { 
              uuid: uuid
            }
          })
        }
        if (action == 'admin-dashboard') {
          this.$router.push({name: 'tracking-dashboard',query: {uuid:uuid}})
        }
        if (action == 'admin-rival') {
          this.$root.$report_seo_rival(uuid)
          // this.loading = true
          // this.axios.post('/admin/keyword/'+action, {
          //   uuid: uuid,
          //   limit: 10,
          //   page: 0,
          // })
          // .then(res => {
          //   if (res.data.status === 'success') {
          //     //console.log(res.data)
          //     this.$root.$report_seo_rival(
          //       uuid,
          //       res.data.google_search,
          //       res.data.keyword,
          //       res.data.url
          //     )
          //   }
          // })
          // .catch(err => {
          //   this.loading = false
          //   if (err.response.data.status === 'error') {
          //     this.$root.$snackbar(err.response.data.msg)
          //   }
          // })
          // .finally(() => this.loading = false)
        }
        if (action == 'approve_transfer') {
          this.$root.$confirm(this.$t('confirm'), this.$t('confirm_approve_transfer'),{
            color: 'primary',
            width: 360,
            zIndex: 200
          },false,true,'amount_usd', this.formatNumber(item.amount_usd) ,'number')
          .then((confirm) => {
            if (confirm.confirm) {
              this.loading = true
              this.axios.post('/admin/billing/transfer/approve', {
                uuid: uuid,
                amount_usd: confirm.text
              })
              .then(res => {
                if (res.data.status === 'success') {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
              .catch(err => {
                if (err.response.data.status === 'error') {
                  this.$root.$snackbar(err.response.data.msg)
                }
              })
              .finally(() => this.loading = false)
            }
          })
        }
        if (action == 'unapprove_transfer') {
          this.$root.$confirm(this.$t('confirm'), this.$t('confirm_unapprove_transfer'),{
            color: 'primary',
            width: 360,
            zIndex: 200
          },true)
          .then((confirm) => {
            if (confirm.confirm) {
              if(!confirm.reason){
                this.$root.$snackbar(this.$t('enter_reason_unapprove'))
                return false
              }
              this.loading = true
              this.axios.post('/admin/billing/transfer/unapprove', {
                uuid: uuid,
                note: confirm.reason
              })
              .then(res => {
                if (res.data.status === 'success') {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
              .catch(err => {
                if (err.response.data.status === 'error') {
                  this.$root.$snackbar(err.response.data.msg)
                }
              })
              .finally(() => this.loading = false)
            }
          })
        }
        if (action == 'approve_topup') {
          this.$root.$confirm(this.$t('confirm'), this.$t('confirm_approve_topup'),{
            color: 'primary',
            width: 360,
            zIndex: 200
          },false,true,'amount_usd', this.formatNumber(item.amount_usd) ,'number')
          .then((confirm) => {
            if (confirm.confirm) {
              this.loading = true
              this.axios.post('/admin/billing/topup/approve', {
                uuid: uuid,
                amount_usd: confirm.text
              })
              .then(res => {
                if (res.data.status === 'success') {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
              .catch(err => {
                if (err.response.data.status === 'error') {
                  this.$root.$snackbar(err.response.data.msg)
                }
              })
              .finally(() => this.loading = false)
            }
          })
        }
        if (action == 'unapprove_topup') {
          this.$root.$confirm(this.$t('confirm'), this.$t('confirm_unapprove_topup'),{
            color: 'primary',
            width: 360,
            zIndex: 200
          },true)
          .then((confirm) => {
            if (confirm.confirm) {
              if(!confirm.reason){
                this.$root.$snackbar(this.$t('enter_reason_unapprove'))
                return false
              }
              this.loading = true
              this.axios.post('/admin/billing/topup/unapprove', {
                uuid: uuid,
                note: confirm.reason
              })
              .then(res => {
                if (res.data.status === 'success') {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
              .catch(err => {
                if (err.response.data.status === 'error') {
                  this.$root.$snackbar(err.response.data.msg)
                }
              })
              .finally(() => this.loading = false)
            }
          })
        }
        if (action == 'view_order') {
          this.loading = true
          this.axios.post('/backlink/get', {
            uuid: uuid,
            enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              let order_item_service = JSON.parse(JSON.stringify(res.data.order_item_service))
              if(!res.data.order.status_serpclick){
                let description = this.LangServce(order_item_service.providers_service_id,res.data.order.providers_id,'service')
                order_item_service.description = description
              }
              //console.log(order_item_service)

              let order_item_extras = JSON.parse(JSON.stringify(res.data.order_item_extras))
              let new_order_item_extras = []
              for(let e of order_item_extras){
                let array = JSON.parse(JSON.stringify(e))
                if(!res.data.order.status_serpclick){
                  let description = this.LangServce(e.providers_extras_id,res.data.order.providers_id,'extras')
                  array.description = description
                }
                new_order_item_extras.push(array)
              }
              //console.log(new_order_item_extras)
              this.$root.$dialog_view_order(
                res.data.order,
                order_item_service,
                new_order_item_extras,
              )
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'keyword_backlink') {
          this.loading = true
          this.axios.post('/tracking/keyword/get-backlink', {
            uuid: uuid,
            enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
            type: 'text',
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)   
              this.$root.$dialog_view_backlink(res.data.backlink)
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'order_backlink') {
          this.loading = true
          this.axios.post('/backlink/get/text', {
            uuid: uuid,
            enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)   
              this.$root.$dialog_view_backlink(res.data.backlink)
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'keyword_table_backlink') {
          this.loading = true
          this.axios.post('/tracking/keyword/get-backlink-table', {
            uuid: uuid,
            enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)   
              if(res.data.backlink.length){
                this.$root.$dialog_table_backlink(res.data.backlink,'keyword','')
              }
              else{
                this.$root.$snackbar(this.$t('not_found'))
              }

            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'keyword_table_serpclick') {
          this.loading = true
          this.axios.post('/tracking/keyword/get-serpclick-table', {
            uuid: uuid,
            enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)   
              if(res.data.serpclick.length){
                this.$root.$dialog_table_serpclick(
                  res.data.serpclick,
                  res.data.keyword,
                  res.data.keywords,
                  res.data.links,
                  'keyword'
                )
              }
              else{
                this.$root.$snackbar(this.$t('not_found'))
              }

            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'order_table_backlink') {
          this.loading = true
          this.axios.post('/backlink/report/table', {
            uuid: uuid,
            enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)   
              if(res.data.data && res.data.data.backlink && res.data.data.backlink.length){
                this.$root.$dialog_table_backlink(res.data.data,'order',res.data.report_link)
              }
              else{
                this.$root.$snackbar(this.$t('not_found'))
              }
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'order_keyword') {
          //console.log(item.keyword)
          //console.log(item.domain)
          this.$router.push({
            name: 'backlink-add-order',
            query: this.$route.query,  
            params: { 
              uuid: uuid,
              select_all: false,
              keyword: item.keyword,
              domain: item.url,
            }
          })
        }
        if (action == 'report_order') {
          if(item.report_link){
            window.open(item.report_link, '_blank');
          }
        }
        if (action == 'edit_default_backlink') {
          this.loading = true
          this.axios.post('/admin/backlink/get/default', {
            uuid: uuid,
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              this.$root.$change_backlink_default(uuid,res.data.json_setting_backlink)
              .then((confirm) => {
                if (confirm.confirm) {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'show_hide_action') {
          this.loading = true
          this.axios.post('/admin/user/get/button-action', {
            uuid: uuid,
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)

              this.$root.$change_action(uuid,res.data.json_default_action)
              .then((confirm) => {
                if (confirm.confirm) {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'show_hide_column') {
          this.loading = true
          this.axios.post('/admin/user/get/column', {
            uuid: uuid,
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)

              this.$root.$change_column(uuid,res.data.column)
              .then((confirm) => {
                if (confirm.confirm) {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'setting_dataforseo_playground') {  
          this.loading = true
          this.axios.post('/admin/tracking/get/setting-dataforseo-playground', {
            uuid: uuid,
          })                                  
          .then(res => {
            if (res.data.status === 'success') {
              // console.log(res.data)

              this.$root.$setting_dataforseo_playground(uuid,res.data.settings)
              .then((confirm) => {
                if (confirm.confirm) {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'setting_payment') {
          this.loading = true
          this.axios.post('/admin/billing/get/setting-payment', {
            uuid: uuid,
          })
          .then(res => {
            if (res.data.status === 'success') {
              // console.log(res.data)

              this.$root.$setting_payment(uuid,res.data.setting)
              .then((confirm) => {
                if (confirm.confirm) {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'setting_menu') {
          this.loading = true
          this.axios.post('/admin/user/setting-menu', {
            uuid: uuid,
          })
          .then(res => {
            if (res.data.status === 'success') {
              // console.log(res.data)

              this.$root.$setting_menu(uuid,res.data.setting)
              .then((confirm) => {
                if (confirm.confirm) {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'edit_serpclick_service') {
          this.loading = true
          this.axios.post('/admin/backlink/get/service-serpclick', {
            uuid: uuid,
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              this.$root.$dialog_edit_serpclick(res.data.service,'service')
              .then((confirm) => {
                if (confirm.confirm) {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'edit_serpclick_extra') {
          this.loading = true
          this.axios.post('/admin/backlink/get/extra-serpclick', {
            uuid: uuid,
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              this.$root.$dialog_edit_serpclick(res.data.extra,'extra')
              .then((confirm) => {
                if (confirm.confirm) {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'admin_top_up') {
          this.$root.$dialog_customer_topup(uuid)
          .then((confirm_payment) => {
            if (confirm_payment.confirm) {
              this.$root.$snackbar(this.$t('save_topup_success'))
              this.$root.$refs.master.getCredit()
              this.reloadData()
            }
          })
        }
        if (action == 'sales_top_up') {
          this.$root.$dialog_customer_topup(uuid)
          .then((confirm_payment) => {
            if (confirm_payment.confirm) {
              this.$root.$snackbar(this.$t('save_topup_success'))
              this.$root.$refs.master.getCredit()
              this.reloadData()
            }
          })
        }
        if (action == 'view_lighthouse') {
          this.loading = true
          this.axios.post('/lighthouse/get', {
            enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
            uuid: uuid,
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              this.$root.$dialog_view_lighthouse(res.data.lighthouse)
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if (action == 'active_lighthouse') {
          this.loading = true
          this.axios.post('/lighthouse/get', {
            enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
            uuid: uuid,
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              if(res.data.lighthouse.status == 'waiting_processing' || res.data.lighthouse.status == 'success'){
                
                this.$root.$confirm_active_lighthouse(uuid,res.data.lighthouse,res.data.price_lighthouse)
                .then((confirm) => {
                  if (confirm.confirm) {
                    this.$root.$snackbar(this.$t('active_lighthouse_success'))
                    this.$root.$refs.master.getCredit()
                    this.reloadData()
                  }
                })
              }
              else{
                this.$root.$snackbar(this.$t('not_found'))
              }
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
          
        }
        if(action == 'table_lighthouse'){
          this.$root.$dialog_table_lighthouse(uuid)
        }
        if (action == 'edit') {
          this.uuid = uuid
          this.dataForm = true
        }
        if (action == 'view') {
          this.uuid = uuid
          this.dataForm = true
        }
      },
      checkboxAll(type){
        if(!this.checkbox.length){
          this.$root.$snackbar(this.$t('alert_select_keyword'))
          return false
        }

        if(type == 'active'){
          let array_uuid = []
          let array_serp_provider_id = []
          for(let uuid of this.checkbox){
            let data_row = this.rows.find(e => e.uuid == uuid && !e.status_active)
            if(data_row){
              array_uuid.push(data_row.uuid)
              if (!array_serp_provider_id.includes(data_row.serp_provider_id)) {
                array_serp_provider_id.push(data_row.serp_provider_id); 
              }
            }
          }
          
          if(!array_uuid.length){
            this.$root.$snackbar(this.$t('alert_select_keyword_active'))
            return false
          }

          if(array_serp_provider_id.length > 1){
            this.$root.$snackbar(this.$t('alert_select_keyword_search_engine_duplicate'))
            return false
          }

          this.active_all_loading = true
          this.axios.post('/package/keyword/get-date',{
            array_uuid: JSON.stringify(array_uuid),
            serp_provider_id: array_serp_provider_id[0],
            enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
            type: 'multiple'
          })
          .then(res => {
            if (res.data.status === 'success') { 
              if(res.data.package_keyword && res.data.package_keyword.length){
                let json_date = []
                for(let item of res.data.package_keyword){ 
                  json_date.push({
                    id: item.uuid,
                    date: item.frequency_date,
                    format: item.frequency_date_format,
                    limit_active: item.limit_active_keyword,
                    count_acive: item.count_acive_package,
                    package_date_expired: item.package_date_expired,
                    depth: item.depth,
                  })
                }
                this.$root.$select_date_active_keyword(json_date,array_uuid.length)
                .then((confirm) => {
                  if (confirm.confirm) {
                    // console.log(confirm.data)
                    // console.log(array_uuid.length)
                    // console.log(confirm.data.count_acive)
                    if(array_uuid.length <= (confirm.data.limit_active-confirm.data.count_acive)){
                      let package_uuid = confirm.data.id
                      let reason = false
                      this.$root.$confirm(this.$t('confirm'), this.$t('confirm_active_seo'),{
                        color: 'primary',
                        width: 360,
                        zIndex: 200
                      },reason)
                      .then((confirm) => {
                        if (confirm.confirm) {
                          this.active_all_loading = true
                          this.axios.post('/tracking/keyword/active-all', {
                            array_uuid: JSON.stringify(array_uuid),
                            package_uuid: package_uuid,
                            enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
                          })
                          .then(res => {
                            if (res.data.status === 'success') {
                              this.$root.$snackbar(this.$t('item_saved'))
                              this.reloadData()
                            }
                          })
                          .catch(err => {
                            this.active_all_loading = false
                            if (err.response.data.status === 'error') {
                              this.$root.$snackbar(err.response.data.msg)
                            }
                          })
                          .finally(() => this.active_all_loading = false)
                        }
                      })
                    }
                    else{
                      this.$root.$snackbar(this.$t('active_limit'))
                    }
                    
                  }
                })
              }
              else{
                this.$root.$snackbar(this.$t('alert_buy_package'))
                this.$router.push({
                  name: 'tracking-package', 
                  query: this.$route.query, 
                })
              }
              
            }
          })
          .catch(err => {
            this.active_all_loading = false
            if (err.response.data.status === 'error') {
              if(err.response.data.type == 'buy_package'){
                this.$root.$snackbar(this.$t('alert_buy_package'))
                this.$router.push({
                  name: 'tracking-package-keyword-add', 
                  query: this.$route.query, 
                  params: { 
                    serp_provider_uuid: err.response.data.serp_provider_uuid,
                  }
                })
              }
              else{
                this.$root.$snackbar(err.response.data.msg)
              }
            }
          })
          .finally(() => this.active_all_loading = false)

        }
        else if(type == 'unactive'){
          let array_uuid = []
          for(let uuid of this.checkbox){
            let data_row = this.rows.find(e => e.uuid == uuid && e.status_active)
            if(data_row){
              array_uuid.push(data_row.uuid)
            }
          }
          
          if(!array_uuid.length){
            this.$root.$snackbar(this.$t('alert_select_keyword_unactive'))
            return false
          }   

          let reason = false
          this.$root.$confirm(this.$t('confirm'), this.$t('confirm_unactive_seo'),{
            color: 'primary',
            width: 360,
            zIndex: 200
          },reason)
          .then((confirm) => {
            if (confirm.confirm) {
              this.unactive_all_loading = true
              this.axios.post('/tracking/keyword/unactive-all', {
                array_uuid: JSON.stringify(array_uuid),
                enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
              })
              .then(res => {
                if (res.data.status === 'success') {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
              .catch(err => {
                this.unactive_all_loading = false
                if (err.response.data.status === 'error') {
                  this.$root.$snackbar(err.response.data.msg)
                }
              })
              .finally(() => this.unactive_all_loading = false)
            }
          })


          

        }
        else if(type == 'order'){
          let array_keyword = []
          for(let uuid of this.checkbox){
            let data_row = this.rows.find(e => e.uuid == uuid)
            if(data_row){
              array_keyword.push({
                uuid: uuid,
                keyword: data_row.keyword,
                domain: data_row.url,
              })
            }
          }
          //console.log(array_keyword)

          this.$router.push({
            name: 'backlink-add-order', 
            query: this.$route.query, 
            params: { 
              select_all: true,
              array_keyword: array_keyword,
            }
          })
        }
        else if(type == 'chart_ranking'){
          //console.log(this.checkbox)
          this.$root.$report_seo_all(this.checkbox)
          // this.chart_all_loading = true
          // //console.log(this.keyword)
          // this.axios.post('/tracking/keyword/report-all', {
          //   array_uuid: JSON.stringify(this.checkbox),
          //   enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
          // })
          // .then(res => {
          //   if (res.data.status === 'success') {
          //     //console.log(res.data)
          //     this.$root.$report_seo_all(res.data.report)
          //   }
          // })
          // .catch(err => {
          //   this.chart_all_loading = false
          //   if (err.response.data.status === 'error') {
          //     this.$root.$snackbar(err.response.data.msg)
          //   }
          // })
          // .finally(() => this.chart_all_loading = false)
        }
        else if(type == 'data_insights'){
          
          let array_uuid = []
          let array_serp_provider_id = []
          for(let uuid of this.checkbox){
            let data_row = this.rows.find(e => e.uuid == uuid)
            if(data_row){
              array_uuid.push(data_row.uuid)
              if (!array_serp_provider_id.includes(data_row.serp_provider_id)) {
                array_serp_provider_id.push(data_row.serp_provider_id); 
              }
            }
          }
          
          if(!array_uuid.length){
            this.$root.$snackbar(this.$t('alert_select_keyword_active'))
            return false
          }

          if(array_serp_provider_id.length > 1){
            this.$root.$snackbar(this.$t('alert_select_keyword_search_engine_duplicate'))
            return false
          }
          this.data_insights_loading = true
          this.axios.post('/tracking/keyword/check-search-engine-uuid', {
            array_uuid: JSON.stringify(array_uuid),
            enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
          })
          .then(res => {                   
            if (res.data.status === 'success') {
              //console.log(res.data)
              this.data_insights_loading = true
              this.axios.post('/tracking/keyword/get-insights-playground', {
                type: 'multiple',
                search_engine: res.data.search_engine,
                enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
              })
              .then(res => {
                if (res.data.status === 'success') {
                  //console.log(res.data)
                  this.$root.$dialog_table_insights(array_uuid,res.data.settings,'multiple')
                }
              })
              .catch(err => {
                if (err.response.data.status === 'error') {
                  this.$root.$snackbar(err.response.data.msg)
                }
              })
              .finally(() => this.data_insights_loading = false)
            }
          })
          .catch(err => {
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.data_insights_loading = false)
        }
      },
      // createKeyword () {
      //   let that = this
      //   this.$nextTick(() => {
      //     if (this.$refs.create_keyword) {
      //       this.$refs.create_keyword.open()
      //     }
      //     else{
      //       setTimeout(function(){
      //         that.createKeyword()
      //       },0)
      //     }
      //   });
      // },
      getReportKeywordAll(){
        this.$root.$dialog_table_report()
        // this.report_all_loading = true
        // this.axios.post('/tracking/keyword/report-keyword-all', {
        //   enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
        // })
        // .then(res => {
        //   if (res.data.status === 'success') {
        //     this.$root.$dialog_table_report(res.data)
        //   } 
        // })
        // .catch(err => {
        //   this.loading = false
        //   if (err.response.data.status === 'error') {
        //     this.$root.$snackbar(err.response.data.msg)
        //   }
        // })
        // .finally(() => this.report_all_loading = false)
      },
      getService () {
        this.create_custom_loading = true
        this.axios.post('/admin/provider/get-service', {
          uuid: this.provider_uuid,
        })
        .then(res => {
          if (res.data.status === 'success') {
            //console.log(res.data)
            this.$root.$select_services_providers(
              res.data.services,
              res.data.providers,
              res.data.currency,
              this.lang
            )
            .then((confirm) => {
              if (confirm.confirm) {
                this.$root.$snackbar(this.$t('save_service_success'))
                this.reloadData()
              }
            })
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.create_custom_loading = false)
      },
      getExtras () {
        this.create_custom_loading = true
        this.axios.post('/admin/provider/get-extras', {
          uuid: this.provider_uuid,
        })
        .then(res => {
          if (res.data.status === 'success') {
            this.$root.$select_extras_providers(
              res.data.extras,
              res.data.providers,
              res.data.currency,
              this.lang
            )
            .then((confirm) => {
              if (confirm.confirm) {
                this.$root.$snackbar(this.$t('save_service_success'))
                this.reloadData()
              }
            })
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.create_custom_loading = false)
      },
      // selectPayment(type){
      //   let that = this
        
      //     this.$nextTick(() => {
      //       if(type == 'transfer'){
      //         if (this.$refs.payment_transfer) {
      //           let form_payment = {
      //             amount: 0,
      //             currency: 'USD',
      //             amount_usd: 0,
      //             slip: null,
      //             upload_slip: null,
      //             service_fee: 0,
      //             fee: 0,
      //             total_amount: 0,
      //             type: 'transfer',
      //             status: 'create',
      //             note_disapprove: null,
      //           }
      //           this.$refs.payment_transfer.open(
      //             false,
      //             form_payment,
      //             this.item_currency,
      //             this.main_currency,
      //             this.main_currency_rate,
      //             this.bank_account,
      //             this.fee_transfer,
      //             this.service_fee_transfer,
      //           )
      //         }
      //         else{
      //           setTimeout(function(){
      //             that.selectPayment('transfer')
      //           },0)
      //         }
      //       }
      //       else if(type == 'credit_card'){
      //         if (this.$refs.payment_credit_card) {
      //           this.$refs.payment_credit_card.open(
      //             this.omise_customer,
      //             this.fee_credit_card,
      //             this.service_fee_credit_card
      //           )
      //         }
      //         else{
      //           setTimeout(function(){
      //             that.selectPayment('credit_card')
      //           },0)
      //         }
      //       }
      //       else if(type == 'promptpay'){
      //         if (this.$refs.payment_promptpay) {
      //           this.$refs.payment_promptpay.open(
      //             this.fee_promptpay,
      //             this.service_fee_promptpay,
      //             this.rate_thb
      //           )
      //         }
      //         else{
      //           setTimeout(function(){
      //             that.selectPayment('promptpay')
      //           },0)
      //         }
      //       }
            
      //     });
      // },
      // createPayment() {
      //   this.tab_payment = 'transfer'
      //   this.selectPayment('transfer')
        
      // },
      // createOrder() {
      //   let that = this
      //   this.$nextTick(() => {
      //     if (this.$refs.create_order) {
      //       this.$refs.create_order.open(this.lang_service)
      //     }
      //     else{
      //       setTimeout(function(){
      //         that.createOrder()
      //       },0)
      //     }
      //   });
      // },
      // reportKeywordActive () {
      //   this.loading_keyword_active = true
      //   this.axios.get('/tracking/keyword/report-active')
      //   .then(res => {
      //     if (res.data.status === 'success') {
      //       this.$root.$report_keyword(res.data.keyword)
      //     }
      //   })
      //   .catch(err => {
      //     if (err.response.data.status === 'error') {
      //       this.$root.$snackbar(err.response.data.msg)
      //     }
      //   })
      //   .finally(() => this.loading_keyword_active = false)
      // },
      reportKeywordExcel () {
        this.loading_keyword_excel = true
        this.axios.get('/tracking/keyword/report-excel')
        .then(res => {
          if (res.data.status === 'success') {
            //console.log(res.data)
            let array_date = []
						let array = {}
            for(let e of res.data.report){
              if (array_date.indexOf(e.year + '-' + e.week) == -1) {
								array_date.push(e.year + '-' + e.week)
							}

              if(array[e.keyword_uuid]){
								array[e.keyword_uuid].week[e.year + '-' + e.week] = e.min_position
							}
							else{
								var week = {}
								week[e.year + '-' + e.week] = e.min_position
								array[e.keyword_uuid] = {
									url: e.url,
									keyword: e.keyword,
									week: week,
								}
							}
            }
            //console.log(array_date)
            //console.log(array)
            array_date.sort()
            let text = this.$t('keyword')+'\t'+this.$t('url')+'\t'
						for(let e of array_date){
							let date = e.split('-')
							text += this.moment().day("Monday").year(date[0]).week(date[1]).tz(this.$auth.user().timezone).format('DD/MM/YYYY') + '\t'
            }
						text += '\n'
						for(let i in array){
							text += array[i].keyword + '\t' + array[i].url +'\t'
							for(let e of array_date){
								let rank = array[i].week[e] ? array[i].week[e] : '-'
								text += rank + '\t'
              }
							text += '\n'
            }
            //console.log(text)
            this.copyStringToClipboard(text)
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading_keyword_excel = false)
      },
      createPackageKeyword(){
        this.create_custom_loading = true
        this.axios.get('/package/keyword/get-all-active')
        .then(res => {
          if (res.data.status === 'success') {
            // console.log(res.data)
            // this.$root.$dialog_add_keyword(
            //   res.data.search_engines,
            //   res.data.locations,
            //   res.data.languages,
            //   res.data.json_date
            // )
            // .then((confirm) => {
            //     if (confirm.confirm) {
            //       //console.log(confirm)
            //       this.$root.$snackbar(this.$t('item_saved'))
            //       this.reloadData()
            //     }
            // })
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.create_custom_loading = false)
      },
      getDashboard(){
        let that = this
        this.$nextTick(() => {
          if (this.$refs.dashboard) {
            this.$refs.dashboard.open()
          }
          else{
            setTimeout(function(){
              that.getDashboard()
            },0)
          }
        });
      },
      getPackage(){
        let that = this
        this.$nextTick(() => {
          if (this.$refs.package) {
            this.$refs.package.open()
          }
          else{
            setTimeout(function(){
              that.getPackage()
            },0)
          }
        });
      },
      // createBuyPackage(){
      //   let that = this
      //   this.$nextTick(() => {
      //     if (this.$refs.create_buy_package) {
      //       this.$refs.create_buy_package.open()
      //     }
      //     else{
      //       setTimeout(function(){
      //         that.createBuyPackage()
      //       },0)
      //     }
      //   });
      // },
      deleteSelected () {
        if (this.selected.length > 0) {
          let uuids = this.selected
          uuids = this.$_.map(uuids, function(o) { return o.uuid; })
          this.deleteRecords(uuids)
        }
      },
      // deleteRecords (uuids) {
      //   this.$root.$confirm(this.$t('delete'), this.$t('confirm_delete'))
      //   .then((confirm) => {
      //     if (confirm.confirm) {
      //       this.loading = true

      //       let that = this
      //       this.axios.post(this.api + '/delete', {
      //           locale: this.$i18n.locale,
      //           model: this.model,
      //           uuids: uuids
      //         })
      //       .then(res => {
      //         if (res.data.status === 'success') {
      //           this.reloadData()
      //           this.$root.$snackbar(this.$t('items_deleted', { items: res.data.deleted }))
      //         }
      //       })
      //       .catch(err => {
      //         if (err.response.data.status === 'error') {
      //           this.$root.$snackbar(err.response.data.msg)
      //           this.reloadData()
      //         }
      //       })
      //       .finally(() => that.loading = false)
      //     }
      //   })
      // },
      parseDateTime (datetime, format, color_is_past, color_is_future) {
        //this.moment.locale(this.$auth.user().locale)

        let color = null
        if (typeof color_is_past !== 'undefined' && this.moment(datetime).isBefore(this.moment())) {
          color = color_is_past
        }
        if (typeof color_is_future !== 'undefined' && this.moment(datetime).isAfter(this.moment())) {
          color = color_is_future
        }

        if (datetime === null) {
          datetime = '-'
        } else {
          datetime = (format == 'ago') ? this.moment(datetime).tz(this.$auth.user().timezone).fromNow() : this.moment(datetime).tz(this.$auth.user().timezone).format(format)
        }

        if (color !== null) {
          return '<div style="font-weight:bold;color: ' + color + '">' + datetime + '</div>'
        } else {
          return datetime
        }
      },
      randomColor() {
        let array = [
          'red','pink','purple','teal','light-blue','blue','green','orange','brown','blue-grey','black','teal'
        ]
        return array[Math.floor(Math.random()*array.length)]
      },
      exportRows () {
        this.loading = true
        let that = this
        let uuids = this.selected
        uuids = this.$_.map(uuids, function(o) { return o.uuid; })

        this.axios({
          url: this.api + '/export',
          method: 'GET',
          responseType: 'blob',
          params: {
            locale: this.$i18n.locale,
            model: this.model,
            uuids: uuids
          }
        })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', that.translations.items + '.xlsx')
          document.body.appendChild(link)
          link.click()
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading = false)
      },
      canceldataForm(){
        this.$root.$refs.master.enabledScroll()
        this.dataForm = false
      }
    },
    computed: {
      app () {
        return this.$store.getters.app
      }
    }
  }
</script>
<style scoped>
.cursor_pointer{
  cursor: pointer;
}
.v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field input{
  max-height: 100%;
}
.pre-line{
  white-space: pre-line!important;
}
.url-in-table{
  max-width: 360px;
  overflow-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.left-2{
  left: 2px;
}
.div_main_add_btn .v-skeleton-loader__button{
  width: 110px!important;
  height: 40px!important;
}
.div_main_select_all_btn .v-skeleton-loader__button{
  width: 170px!important;
  height: 40px!important;
}

.div_main_header .loding_search .v-skeleton-loader__button{
  width: 400px!important;
  height: 40px!important;
}
.div_main_header .v-skeleton-loader__button{
  width: 170px!important;
  height: 40px!important;
}

.border-radius-circle{
  border-radius: 100px!important;
}
.v-data-table{
  border-radius: 0!important;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}

</style>

