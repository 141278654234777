<template>
  <v-dialog 
  scrollable 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  transition="dialog-bottom-transition"
  >
      <v-card rounded class="dialog-card">

        <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
          <div>
            <div v-if="loading">
              <v-skeleton-loader
                class="mx-auto title_loading"
                type="button"
              ></v-skeleton-loader>
            </div>
            <div v-if="!loading">
              {{ keyword }} 
            </div>
          </div>
          <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
            <v-icon size="24">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text rounded :class="'ma-0 pl-5 pt-5 pb-5 ' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">
          <v-tabs 
            v-if="has_tab"
            v-model="tab_rank"
            :color="($store.state.app.dark) ? 'white' : 'black'"
            class="content-tabs mb-3"
            background-color="transparent"
            fixed-tabs
            show-arrows
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab v-if="has_all" @click="getData('all')" class="no-caps" :ripple="false">
              {{ $t('ranking') }} {{ $t('all') }}
            </v-tab>
            <v-tab v-if="has_rank_group" @click="getData('rank_group')" class="no-caps" :ripple="false">
              {{ $t('ranking') }} {{ $t('url') }}
            </v-tab>
            <v-tab v-if="has_rank_absolute" @click="getData('rank_absolute')" class="no-caps" :ripple="false">
              {{ $t('ranking_ads') }} {{ $t('url') }}
            </v-tab>
            <v-tab v-if="has_rank_group_domain" @click="getData('rank_group_domain')" class="no-caps" :ripple="false">
              {{ $t('ranking') }} {{ $t('best_website') }}
            </v-tab>
            <v-tab v-if="has_rank_absolute_domain" @click="getData('rank_absolute_domain')" class="no-caps" :ripple="false">
              {{ $t('ranking_ads') }} {{ $t('best_website') }}
            </v-tab>
          </v-tabs>
          
          <v-card rounded outlined class="pa-4">   
            <v-card v-if="loading" flat tile >
              <v-skeleton-loader
                class="mx-auto chart_loading"
                type="image"
              ></v-skeleton-loader>
            </v-card>                      
            <highcharts v-if="!loading" :constructorType="'stockChart'" class="hc" :options="chartOptions" ref="chart"></highcharts>
          </v-card>
        </v-card-text>
        <!-- <v-card-actions class="pl-5 pr-5 pb-5 pt-0 ma-0 ">
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
        </v-card-actions> -->
      </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify ReportSEO Dialog component
   *
   * Insert component where you want to use it:
   * <ReportSEO ref="ReportSEO"></ReportSEO>
   *
   * Call it:
   * this.$refs.ReportSEO.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
   * Or use await:
   * if (await this.$refs.ReportSEO.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
   * <template>
   *   <v-app>
   *     ...
   *     <ReportSEO ref="ReportSEO"></ReportSEO>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$ReportSEO = this.$refs.ReportSEO.open
   * }
   */

  import Highcharts from "highcharts";
  // Highcharts.setOptions({
  //     global: {
  //         useUTC: true  // หรือ false แล้วแต่ความต้องการใช้ UTC หรือไม่
  //     },
  //     lang: {
  //         decimalPoint: '.',
  //         thousandsSep: ',',
  //         months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  //         shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  //         weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  //         rangeSelectorZoom: "Zoom",
  //         rangeSelectorFrom: "From",
  //         rangeSelectorTo: "To",
  //         resetZoom: "Reset zoom",
  //         resetZoomTitle: "Reset zoom level 1:1"
  //     }
  // });
  import Stock from "highcharts/modules/stock";
  import exportingInit from 'highcharts/modules/exporting'

  exportingInit(Highcharts)
  Stock(Highcharts);
  
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      uuid: null,
      loading: true,
      has_tab: true,
      has_all: true,
      has_rank_group: true,
      has_rank_absolute: true,
      has_rank_group_domain: true,
      has_rank_absolute_domain: true,
      tab_rank: 'all',
      options: {
        color: 'primary',
        width: 1280,
        zIndex: 200
      },
      keyword: null,
      best_ranking: null,
      has_scrollbar: false,
      chartOptions: {
        chart: {
          style: {
            fontFamily: 'Helvetica, Arial, sans-serif'
          },
          // events: {
          //   load: function() {
          //     // Draw labels
          //     var chart = this;
          //     // Assuming 'data' is an array of points and you want to label the last point
          //     var lastPoint = chart.series[0].data[chart.series[0].data.length - 1];

          //     // Find the y-coordinate for the last data point's Y value
          //     var yPos = chart.yAxis[0].toPixels(lastPoint.y, true);

          //     // Adding custom label for last price
          //     chart.renderer.label(lastPoint.y, chart.plotWidth, yPos + 50, 'rect', chart.plotWidth, yPos)
          //       .attr({
          //         fill: '#000000',
          //         padding: 8,
          //         r: 3, // rounded corners
          //         zIndex: 7
          //       })
          //       .css({
          //         color: 'white',
          //         fontSize: '11px',
          //         fontWeight: '500'
          //       })
          //       .add();

          //       if (this.navigator && this.navigator.yAxis && this.navigator.group) {
          //         const navigatorY = this.navigator.yAxis.top; // Using a valid property, such as 'top'
          //         this.navigator.group.attr({
          //           translateY: - navigatorY
          //         });
          //       }
          //   }
          // },
          plotBorderColor: 'rgba(0, 0, 0, 0.05)',
          plotBorderWidth: 1,
          plotBorderDashStyle: 'Solid' // This is optional and can be omitted for a solid border

        },
        series: [
          {
            name: 'A',
            data: [[1619032324000,100], [1619112420000,80], [1619193730000,50]]
          }
        ],
        credits: {
          enabled: false
        },
        exporting: {
          buttons: {
              contextButton: {
                  symbol: 'menu',
                  align: 'right', 
                  verticalAlign: 'top', 
                  y: -3 
              },
          }
        },
        // chart: {
        //   spacingTop: 70, // เพิ่มพื้นที่ว่างด้านบน 50px
        // },
        navigator: {
          // top: 1,
          enabled: true,
          outlineColor: '#5D5D5E',
          adaptToUpdatedData: false,
          maskFill: 'rgba(93, 93, 94, 0.5)',
          series: {
            color: '#000000',
            lineColor: '#000000'
          },
          handles: {
            backgroundColor: '#FFFFFF',
            borderColor: '#5D5D5E'
          },
          xAxis: {
            labels: {
              enabled: false // Disabling labels on the navigator
            }
          }
        },
        scrollbar: {
          enabled: false // Disabling the scrollbar entirely
        },
        rangeSelector: {
          enabled: true,
          inputEnabled: true,
          selected: 4,
          inputDateFormat: '%d/%m/%Y',
          inputEditDateFormat: '%d/%m/%Y',
          inputBoxWidth: 100,
          inputBoxHeight: 18,
          inputStyle: { // Styling the text inside the input boxes
              color: '#000', // Text color
              fontWeight: 'bold', // Text weight
              borderRadius: '4px', // Adds rounded corners
              padding: '5px' // Optional: adds padding inside the input boxes
          },
          buttons: [{
              type: 'week',
              count: 1,
              text: '1w'
          }, {
              type: 'month',
              count: 1,
              text: '1m'
          }, {
              type: 'month',
              count: 2,
              text: '2m'
          }, {
              type: 'month',
              count: 6,
              text: '6m'
          }, {
              type: 'year',
              count: 1,
              text: '1y'
          }, {
              type: 'all',
              text: 'All'
          }],
          buttonTheme: {
            fill: 'none',
            stroke: 'none',
            'stroke-width': 0,
            style: {
              color: '#000000',
              fontWeight: 'bold'
            },
            states: {
              hover: {
                fill: '#5D5D5E',
                style: {
                  color: 'white'
                }
              },
              select: {
                fill: '#000000',
                style: {
                  color: 'white'
                }
              }
            }
          }
        },
        tooltip: {
          headerFormat: '<span>{point.x:%d/%m/%Y %H:%M}</span><br/>', 
          split: false,
          shared: false,  // ตั้งค่านี้เป็น false เพื่อให้ tooltip ไม่แสดงขึ้นพร้อมกันสำหรับทุกเส้น
          crosshairs: true  // เปิดใช้งาน crosshairs เพื่อช่วยในการติดตามเส้นบนกราฟ
        },
        xAxis: {
          lineColor: '#5D5D5E',
          type: 'datetime',
          tickPixelInterval: 200,
          // dateTimeLabelFormats: {
          //     day: '%e/%m/%Y' // Day format
          // },
          labels: {
              style: {
                color: '#000000'
              },
              // format: '{value:%e/%m/%Y}', // Display date format
              rotation: 0, // Set rotation to 0 for no tilt
              align: 'center' // Align labels to center under the tick
          },
          opposite: true
        },
        yAxis: {
          allowDecimals: false,
          reversed: true,
          min: 1,
          minRange: 1,
          // startOnTick: false,
          // endOnTick: false,
          title: {
            text: 'อันดับ/วันเวลา'
          },
          startOnTick: false,
          endOnTick: false,
          gridLineColor: 'rgba(0, 0, 0, 0.05)',
          gridLineWidth: 1,
          gridLineDashStyle: 'Solid', // This is already the default, but specifying it for clarity
          labels: {
            style: {
              color: '#000000'
            }
          }
        },
        global: {
          useUTC: false
        }
      },
    }),
    created () {
      
      // this.chartOptions.rangeSelector.buttons =  [{
      //     type: 'week',
      //     count: 1,
      //     text: ('1 '+ this.$t('week'))
      // }, {
      //     type: 'month',
      //     count: 1,
      //     text: ('1 '+ this.$t('month'))
      // }, {
      //     type: 'month',
      //     count: 2,
      //     text: ('2 '+ this.$t('month'))
      // }, {
      //     type: 'month',
      //     count: 6,
      //     text: ('6 '+ this.$t('month'))
      // }, {
      //     type: 'year',
      //     count: 1,
      //     text: ('1 '+ this.$t('year'))
      // }, {
      //     type: 'all',
      //     text: this.$t('all')
      // }]
    },
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
        }
      },
      open(uuid) {
        this.$root.$refs.master.disabledScroll()
        if(this.$vuetify.theme.dark){
          this.chartOptions.chart.backgroundColor = '#232425'
          //this.chartOptions.title.style.color = '#FFF'
          this.chartOptions.xAxis.labels.style.color = '#FFF'
          this.chartOptions.yAxis.labels.style.color = '#FFF'
          this.chartOptions.rangeSelector.buttonTheme.style.color = '#FFF'
          this.chartOptions.rangeSelector.inputStyle.color = '#FFF'
        }
        else{
          this.chartOptions.chart.backgroundColor = '#FFF'
          //this.chartOptions.title.style.color = '#000'
          this.chartOptions.xAxis.labels.style.color = '#000'
          this.chartOptions.yAxis.labels.style.color = '#000'
          this.chartOptions.rangeSelector.buttonTheme.style.color = '#000'
          this.chartOptions.rangeSelector.inputStyle.color = '#000'
        }


          let w = this.$vuetify.breakpoint.width > 500 ? 50 : 100
          this.chartOptions.chart.events = {
            load: function() {
              // Draw labels
              var chart = this;
              // Assuming 'data' is an array of points and you want to label the last point
              var lastPoint = chart.series[0].data[chart.series[0].data.length - 1];

              // Find the y-coordinate for the last data point's Y value
              var yPos = chart.yAxis[0].toPixels(lastPoint.y, true);

              // Adding custom label for last price
              chart.renderer.label(lastPoint.y, chart.plotWidth, yPos + w, 'rect', chart.plotWidth, yPos)
                .attr({
                  fill: '#000000',
                  padding: 8,
                  r: 3, // rounded corners
                  zIndex: 7
                })
                .css({
                  color: 'white',
                  fontSize: '11px',
                  fontWeight: '500'
                })
                .add();

                if (this.navigator && this.navigator.yAxis && this.navigator.group) {
                  const navigatorY = this.navigator.yAxis.top; // Using a valid property, such as 'top'
                  this.navigator.group.attr({
                    translateY: - navigatorY
                  });
                }
            }
          }
        
        this.dialog = true
        this.uuid = uuid
        this.tab_rank = 'all'
        this.has_tab = true
        this.has_all = true
        this.has_rank_group = true
        this.has_rank_absolute = true
        this.has_rank_group_domain = true
        this.has_rank_absolute_domain = true

        if(this.$auth.user().json_disable_column && this.$auth.user().json_disable_column.keyword){
          let column_keyword = this.$auth.user().json_disable_column.keyword
  
          let count = 5
          if(column_keyword.indexOf("rank_absolute_domain_url") != -1){
            this.has_rank_absolute_domain = false
            count--
          }
          else{
            this.tab_rank = 'rank_absolute_domain'
          }

          if(column_keyword.indexOf("rank_group_domain_url") != -1){
            this.has_rank_group_domain = false
            count--
          }else{
            this.tab_rank = 'rank_group_domain'
          }

          if(column_keyword.indexOf("rank_absolute_url") != -1){
            this.has_rank_absolute = false
            count--
          }
          else{
            this.tab_rank = 'rank_absolute'
          }

          if(column_keyword.indexOf("rank_group_url") != -1){
            this.has_rank_group = false
            count--
          }
          else{
            this.tab_rank = 'rank_group'
          }

          if(column_keyword.indexOf("best_ranking") != -1){
            this.has_all = false
            count--
          }
          else{
            this.tab_rank = 'all'
          }
          if(count <= 1){
            this.has_tab = false
          }
        }
        
        this.getData(this.tab_rank)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      getData(rank){
        this.best_ranking = null
        this.loading = true
        this.axios.post('/tracking/keyword/report', {
          uuid: this.uuid,
          rank: rank,
          enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : ''),
        })
        .then(res => {
          if (res.data.status === 'success') {
            //console.log(res.data)
            this.initChart(res.data.ranking,res.data.keyword)
            let that = this
            setTimeout(function(){
              that.$nextTick(() => {
                that.checkForScrollbar();
              });
            },100)
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading = false)
      },
      initChart(data,keyword){
        this.keyword = keyword
        this.chartOptions.yAxis.title.text = this.$t('ranking_date')
        //console.log(data)
        let array = []
        
        for(let e of data){
          //console.log(e.date)
          //console.log(this.moment(e.date).unix()*1000)
          let sub = [];
          //sub.push(parseFloat(e.date + '000'));
          sub.push(this.moment(e.date_created).unix()*1000);
          sub.push(e.ranking);
          if(!this.best_ranking){
            this.best_ranking = e.ranking
          }
          else if(this.best_ranking > parseInt(e.ranking)){
            this.best_ranking = e.ranking
          }
          array.push(sub);
        }

        this.chartOptions.series = [
          {
            name: keyword,
            color: (this.$vuetify.theme.dark ? '#FFF': '#000000'),
            data: array
          }
        ]
      },
      agree() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: true
        })
        this.dialog = false
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
          text: this.text
        })
        this.dialog = false
      }
    }
  }
</script>
<style>
.title_loading .v-skeleton-loader__button{
  width: 200px;
}
.chart_loading .v-skeleton-loader__image{
  height: 395px;
}

</style>