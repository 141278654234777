'use strict';

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueAuth from '@websanova/vue-auth'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import axios from './plugins/axios'
import i18n from '@/plugins/i18n'
import auth from '@/plugins/auth'
import '@/plugins/moment'
import '@/plugins/lodash'
import '@/components/components'
//import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import './assets/css/global.css';
import promptparse from  'promptparse'
// import promptpay from  'promptpay-js'
//require('dotenv').config()
// Global Vue config
Vue.config.productionTip = false

// Global variables
Vue.prototype.$init = window.init

// Vue.prototype.$promptpay = promptpay
Vue.prototype.$promptparse = promptparse


import HighchartsVue from "highcharts-vue";

Vue.use(HighchartsVue);

// Auth
Vue.router = router
Vue.use(VueRouter)
Vue.use(VueAxios, axios)
Vue.use(VueAuth, auth)
// Toggle .dark class on html tag
let dark = store.state.app.dark
var root = document.documentElement
if (dark) {
  root.classList.add('dark')
} else {
  root.classList.remove('dark')
}

// console.log(process.env.VUE_APP_PUSHER_APP_KEY)
// console.log(process.env.VUE_APP_PUSHER_APP_CLUSTER)
Vue.use(require('vue-pusher'), {
  api_key: process.env.VUE_APP_PUSHER_APP_KEY,
  options: {
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      encrypted: true,
  }
});

// Check installation
// axios
//   .post('/ping')
//   .then(response => {
//     if (typeof response.data.redirect !== 'undefined') {
//       router.push({ name: response.data.redirect })
//     }
//   })

new Vue({
  router,
  store,
  vuetify,
  axios,
  i18n,
  auth,
  render: h => h(App)
}).$mount('#app')
