<template>
  <v-dialog 
  scrollable 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  transition="dialog-bottom-transition"
  >
    <v-card rounded class="d-flex flex-column dialog-card">
      <v-card-title class="headline d-flex justify-space-between">
        <div>
          {{ $t('report_backlink') }}
        </div>
        <div>
          <!-- <v-btn
            color="grey"
            text
            class="no-caps"
            @click="CopyBacklink()"
          >
            <v-icon>mdi-content-copy</v-icon>
            {{ $t('copy') }}
          </v-btn> -->

          <!-- <v-btn
            v-if="report_link"
            color="grey"
            text
            class="no-caps"
            @click="DownloadZip()"
          >
            <v-icon>mdi-zip-box</v-icon>
            {{ $t('report_order') }}
          </v-btn> -->

          <v-tooltip top class="">
            <template v-slot:activator="{ on }">
              <v-btn
                @click.stop="CopyBacklink()" 
                v-on="on"
                fab 
                small 
                text
                color="grey"
                class="no-caps border-radius-circle"
              >
                <v-icon size="20">mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('copy') }}</span>
          </v-tooltip>

          <v-tooltip top class="" v-if="report_link">
            <template v-slot:activator="{ on }">
              <v-btn
                @click.stop="DownloadZip()" 
                v-on="on"
                fab 
                small 
                text
                color="grey"
                class="no-caps border-radius-circle ml-2"
              >
                <v-icon size="20">mdi-zip-box</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('report_order') }}</span>
          </v-tooltip>

          <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
            <v-icon size="24">mdi-close</v-icon>
          </v-btn>
        </div>
        
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>

        <x-text-area
          v-model="backlink"
          :label="$t('url')"
          filled
          rounded
          persistent-placeholder
          persistent-hint
          label-placement="on-top"
          class="custom-text-field mt-4 text-area-wrap"
        ></x-text-area>

      </v-card-text>
      <!-- <v-card-actions class="">
        <v-spacer></v-spacer>
        
        <v-btn color="grey" text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify DialogViewBacklink Dialog component
   *
   * Insert component where you want to use it:
   * <DialogViewBacklink ref="DialogViewBacklink"></DialogViewBacklink>
   *
   * Call it:
   * this.$refs.DialogViewBacklink.open('Delete', 'Are you sure?', { color: 'red' }).then((DialogViewBacklink) => {})
   * Or use await:
   * if (await this.$refs.DialogViewBacklink.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$DialogViewBacklink
   * <template>
   *   <v-app>
   *     ...
   *     <DialogViewBacklink ref="DialogViewBacklink"></DialogViewBacklink>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$DialogViewBacklink = this.$refs.DialogViewBacklink.open
   * }
   */

  // import { copyStringToClipboard } from '@/utils/helpers';

  export default {
    data: () => ({
      loading: false,
      dialog: false,
      resolve: null,
      reject: null,
      backlink: null,
      report_link: null,
      options: {
        color: 'primary',
        width: 720,
        zIndex: 200
      }
    }),
    methods: {
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      copyText() {
        navigator.clipboard.writeText(this.backlink); 
        this.$root.$snackbar(this.$t('copied_to_clipboard'))
      },
      CopyBacklink(){
        this.copyText()
      },
      DownloadZip(){
        window.open(this.report_link, '_blank');
      },
      open(backlink) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.backlink = backlink.array_url.join('\n')
        this.report_link = backlink.report_link
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false
        })
        this.dialog = false
      }
    }
  }
</script>
<style>
.text-area-wrap textarea{
  overflow-x: auto;
  text-wrap: nowrap;
}
</style>