<template>
  <v-dialog 
  scrollable 
  :fullscreen="$vuetify.breakpoint.xsOnly"
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  transition="dialog-bottom-transition"
  >
    <ValidationObserver ref="validatePayment" v-slot="{ invalid }">
      <v-form 
        ref="formPayment" 
        @submit.prevent="SavePayment"
      >
        <v-card rounded class="dialog-card">
          <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
            <div>
            {{ $t('top_up') }}
            </div>
            <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
              <v-icon size="24">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider> 
          <v-card-text :class="'ma-0 pt-5 pb-5 pl-5 pr-5'">

            <x-text-field
              type="number"
              rules="required"
              v-model="form_payment.amount"
              :label="$t('amount_usd')"
              filled
            ></x-text-field>

            <div class="mb-2">
              <v-label :class="'input-label mb-2 '+label_cover">{{ $t('related_image') }}</v-label>
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-avatar
                    width="100%"
                    :height="250"
                    class="input-upload"
                  >
                    <v-img :src="form_payment.slip" class="" contain 
                    :style="{'width': '100%'}" >
                      <v-fade-transition>
                        <v-overlay
                          v-if="hover"
                          absolute
                        >
                            <v-btn @click="UploadSlip" small fab color="primary"><v-icon size="14">mdi-folder-open</v-icon></v-btn>
                            <v-btn v-if="form_payment.slip" @click="form_payment.slip=null; form_payment.upload_slip=null; $refs.fileupload.value=null;" class="ml-2" small fab color="error"><v-icon size="14">mdi-close</v-icon></v-btn>
                        </v-overlay>
                      </v-fade-transition>
                    </v-img>
                  </v-avatar>
                </template>
              </v-hover>
              <input
                type="file"
                style="display: none"
                id="slip"
                ref="fileupload"
                name="slip"
                accept="image/*"
                @change="onFilePicked"
              >
            </div>
            

          </v-card-text>
          <v-divider></v-divider> 
          <v-card-actions class="pa-5 ma-0">
            <v-spacer></v-spacer>
            <!-- <v-btn color="grey" text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn> -->
            <v-btn 
              type="submit" 
              color="primary" 
              depressed 
              class="no-caps"
              :loading="loading" :disabled="loading || invalid"
              >{{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify DialogCustomerTopUp Dialog component
   *
   * Insert component where you want to use it:
   * <DialogCustomerTopUp ref="DialogCustomerTopUp"></DialogCustomerTopUp>
   *
   * Call it:
   * this.$refs.DialogCustomerTopUp.open('Delete', 'Are you sure?', { color: 'red' }).then((DialogCustomerTopUp) => {})
   * Or use await:
   * if (await this.$refs.DialogCustomerTopUp.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$DialogCustomerTopUp
   * <template>
   *   <v-app>
   *     ...
   *     <DialogCustomerTopUp ref="DialogCustomerTopUp"></DialogCustomerTopUp>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$DialogCustomerTopUp = this.$refs.DialogCustomerTopUp.open
   * }
   */

  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      customer_uuid: null,
      loading: false,
      label_cover: '',
      locale: 'TH',
      form_payment: {
        amount: 0,
        currency: 'USD',
      },
      options: {
        color: 'primary',
        width: 720,
        zIndex: 200
      }
    }),
    methods: {
      open(customer_uuid) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        if(this.$refs.validatePayment){
         this.$refs.validatePayment.reset();
        }
        this.customer_uuid = customer_uuid
        this.form_payment.amount = null
        this.form_payment.slip=null; 
        this.form_payment.upload_slip=null; 
        //console.log(this.form_payment.currency)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      formatCurrency (number,currency) {
        return currency ? new Intl.NumberFormat('th', {style: 'currency', currency: currency}).format(number) : '-'
      },
      UploadSlip () {
        document.getElementById('slip').click()
      },
      onFilePicked (e) {
        const files = e.target.files
        //console.log(files)
        if(files[0] !== undefined) {
          if(files[0].name.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader ()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.form_payment.slip = fr.result
            this.form_payment.upload_slip = files[0]
          })
        }
      },
      
      SavePayment() {
        // if(!this.form_payment.upload_slip){
        //   this.label_cover = 'error--text'
        //   this.$root.$snackbar(this.$t('enter_img'))
        //   return false
        // }
        this.label_cover = ''

        this.$root.$confirm(this.$t('confirm'), this.$t('confirm_create_topup'),{
          color: 'primary',
          width: 360,
          zIndex: 200
        },false)
        .then((confirm) => {
          if (confirm.confirm) {
            this.loading = true

            let settings = { headers: { 'content-type': 'multipart/form-data' } }
            let formData = new FormData()
            formData.append('image', this.form_payment.upload_slip)
            formData.append('amount', this.form_payment.amount)
            formData.append('customer_uuid', this.customer_uuid)
            let role = this.$auth.user().role == 1 ? 'admin' : 'sales'
            this.axios.post('/'+role+'/billing/add/topup', formData, settings)
            .then(res => {
              if (res.data.status === 'success') {
                this.$root.$refs.master.enabledScroll()
                this.resolve({
                  confirm: true
                })
                this.dialog = false
              }
            })
            .catch(err => {
              if (err.response.data.status === 'error') {
                this.$root.$snackbar(err.response.data.msg)
              }
            })
            .finally(() => this.loading = false)
          }
        })
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false
        })
        this.dialog = false
      },
    },
  }
</script>