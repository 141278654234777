<template>
  <v-dialog 
  scrollable 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  transition="dialog-bottom-transition"
  >
    <v-card class="dialog-card">
      <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
        <div>
          {{ $t('setting_menu') }}
        </div>
        
        <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="ma-0 pa-5">
        <div class="mt-2">
          <v-switch
            @change="changePageKeyword"
            v-model="page_keyword"
            ref="page_keyword"
            id="page_keyword"
            :label="$t('keyword')"
            inset
            dense
            :ripple="false"
            name="page_keyword"
            :persistent-hint="true"
          />
        </div>
        <div class="mt-2">
          <v-switch
            @change="changePageSubKeyword"
            v-model="page_dashboard"
            ref="page_dashboard"
            id="page_dashboard"
            :label="$t('dashboard')"
            inset
            dense
            :ripple="false"
            name="page_dashboard"
            :persistent-hint="true"
          />
        </div>
        <div class="mt-2">
          <v-switch
            @change="changePageSubKeyword"
            v-model="page_package"
            ref="page_package"
            id="page_package"
            :label="$t('package')"
            inset
            dense
            :ripple="false"
            name="page_package"
            :persistent-hint="true"
          />
        </div>
        <div class="mt-2">
          <v-switch
            v-model="page_order"
            ref="page_order"
            id="page_order"
            :label="$t('order')"
            inset
            dense
            :ripple="false"
            name="page_order"
            :persistent-hint="true"
          />
        </div>
        <div class="mt-2">
          <v-switch
            v-model="page_lighthouse"
            ref="page_lighthouse"
            id="page_lighthouse"
            :label="$t('lighthouse')"
            inset
            dense
            :ripple="false"
            name="page_lighthouse"
            :persistent-hint="true"
          />
        </div>
        <div class="mt-2">
          <v-switch
            v-model="page_payment"
            ref="page_payment"
            id="page_payment"
            :label="$t('payment')"
            inset
            dense
            :ripple="false"
            name="page_payment"
            :persistent-hint="true"
          />
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="ma-0 pa-5">
        <v-spacer></v-spacer>
        <!-- <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn> -->
        <v-btn color="primary" rounded depressed @click.native="updateSetting" :loading="loading" :disabled="loading" class="no-caps">{{ $t('save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>  
<script>
  
  export default {
    data: () => ({
      dialog: false,
      loading: false,
      resolve: null,
      reject: null,
      uuid: null,
      page_keyword: false,
      page_order: false,
      page_payment: false,
      page_dashboard: false,
      page_package: false,
      page_lighthouse: false,
      options: {
        color: 'primary',
        width: 360,
        zIndex: 200
      } 
    }),
    methods: {
      open(uuid,setting) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.uuid = uuid
        this.page_keyword = setting.page_keyword ? true : false
        this.page_order = setting.page_order ? true : false
        this.page_payment = setting.page_payment ? true : false
        this.page_dashboard = setting.page_dashboard ? true : false
        this.page_package = setting.page_package ? true : false
        this.page_lighthouse = setting.page_lighthouse ? true : false
        
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      changePageKeyword(){
        if(!this.page_keyword){
          this.page_dashboard = false
          this.page_package = false
        }
      },
      changePageSubKeyword(){
        this.page_keyword = true
      },
      updateSetting(){

        this.loading = true
        this.axios.post('/admin/user/update/setting-menu', {
          uuid: this.uuid,
          page_keyword: this.page_keyword ? 1 : 0,
          page_order: this.page_order ? 1 : 0,
          page_payment: this.page_payment ? 1 : 0,
          page_dashboard: this.page_dashboard ? 1 : 0,
          page_package: this.page_package ? 1 : 0,
          page_lighthouse: this.page_lighthouse ? 1 : 0,
        })
        .then(res => {
          if (res.data.status === 'success') {
            this.$root.$refs.master.enabledScroll()
            this.resolve({
              confirm: true,
            })
            this.dialog = false
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading = false)
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>