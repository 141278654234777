<template>
  <v-dialog 
  scrollable 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }" 
  transition="dialog-bottom-transition"
  >
    <v-card class="dialog-card">
      <v-card-title class="pt-3 pl-5 pr-5 pb-2 ma-0 d-flex justify-space-between">
        <div>
          {{ $t('select_package') }}
        </div>
        <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text rounded :class="'ma-0 pl-5 pb-5 pt-5 ' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">
                  {{ $t('select') }}
                </th>
                <th class="text-center">
                  {{ $t('end_date') }}
                </th>
                <th class="text-center">
                  {{ $t('date_update_ranking') }}
                </th>
                <th class="text-center">
                  {{ $t('depth') }}
                </th>
                <th class="text-center">
                  {{ $t('limit_active_keyword') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in item_package"
                :key="item.id"
                @click="selectPackageTr(item.id)"
              >
                <td class="text-center d-flex justify-center">
                  <v-radio-group v-model="package_uuid" :ref="'select_' + item.id" @change="selectPackage">
                    <v-radio :value="item.id" />
                  </v-radio-group>
                </td>
                <td class="text-center">{{ moment(item.package_date_expired).tz($auth.user().timezone).format('DD/MM/YYYY') }}</td>
                <td class="text-center">{{ item.date }} {{ $t(item.format) }}</td>
                <td class="text-center">{{ item.depth }}</td>
                <td class="text-center">
                  <span class="blue--text">{{ item.count_acive }} <span class="green--text">(+{{ count }})</span></span> / <span class="red--text">{{ item.limit_active }}</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pl-5 pr-5 pb-5 pt-5 ma-0 ">
        <v-spacer></v-spacer>
        <!-- <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn> -->
        <v-btn 
          color="primary" 
          rounded depressed 
          @click.native="agree" 
          class="no-caps"
          :disabled="disabled"
          >
          {{ $t('select') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify SelectDateActiveKeyword Dialog component
   *
   * Insert component where you want to use it:
   * <SelectDateActiveKeyword ref="SelectDateActiveKeyword"></SelectDateActiveKeyword>
   *
   * Call it:
   * this.$refs.SelectDateActiveKeyword.open('Delete', 'Are you sure?', { color: 'red' }).then((SelectDateActiveKeyword) => {})
   * Or use await:
   * if (await this.$refs.SelectDateActiveKeyword.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$SelectDateActiveKeyword
   * <template>
   *   <v-app>
   *     ...
   *     <SelectDateActiveKeyword ref="SelectDateActiveKeyword"></SelectDateActiveKeyword>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$SelectDateActiveKeyword = this.$refs.SelectDateActiveKeyword.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      disabled: false,
      count: null,
      item_package: [],
      package_uuid: null,
      has_scrollbar: false,
      options: {
        color: 'primary',
        width: 900,
        zIndex: 200
      }
    }),
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
        }
      },
      open(item_package,count) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.count = count
        this.disabled = false
        this.item_package = item_package
        this.package_uuid = item_package[0].id
        this.selectPackage()
        let that = this
        setTimeout(function(){
          that.$nextTick(() => {
            that.checkForScrollbar();
          });
        },100)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      SelectTextDate(e){
        return e.date+' '+this.$t(e.format) + ' '+ this.$t('end_date') +': '+this.moment(e.package_date_expired).tz(this.$auth.user().timezone).format('DD/MM/YYYY')+' ( '+e.count_acive+' / '+e.limit_active+' )'
      },
      selectPackageTr(id){
        //console.log(id)
        this.package_uuid = id
        this.selectPackage()
      },
      selectPackage(){
        // console.log(this.item_package)
        // console.log(this.package_uuid)
        let data_package = this.item_package.find(e => e.id == this.package_uuid)
        // console.log(data_package)
        if(data_package){
          if(this.count + data_package.count_acive > data_package.limit_active){
            this.disabled = true
          }
          else{
            this.disabled = false
          }
        }
      },
      agree() {
        let data_package = this.item_package.find(e => e.id == this.package_uuid)
        if(data_package){
          this.$root.$refs.master.enabledScroll()
          this.resolve({
            confirm: true,
            data: data_package,
          })
          this.dialog = false
        }
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>