<template>
  <v-dialog 
  scrollable 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  transition="dialog-bottom-transition"
  >
    <v-card rounded class="d-flex flex-column dialog-card">
      <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
        <div>
          {{ $t('table_serpclick') }}
        </div>
        <!-- <v-btn
          color="grey"
          text
          class="no-caps"
          @click="CopyBacklink()"
        >
          <v-icon>mdi-content-copy</v-icon>
          {{ $t('copy') }}
        </v-btn>  -->

        <div>
          <v-tooltip top class="">
            <template v-slot:activator="{ on }">
              <v-btn
                @click.stop="CopyBacklink()" 
                v-on="on"
                fab 
                small 
                text
                color="grey"
                class="no-caps border-radius-circle"
              >
                <v-icon size="20">mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('copy') }}</span>
          </v-tooltip>

          <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
            <v-icon size="24">mdi-close</v-icon>
          </v-btn>
        </div>

      </v-card-title>
      <v-divider></v-divider>
      <v-card-text rounded :class="'ma-0 pl-5 pt-5 pb-5 ' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">

        <v-tabs 
          v-if="type == 'keyword'"
          v-model="tab_order"
          :color="($store.state.app.dark) ? 'white' : 'black'"
          class="content-tabs mb-2"
          background-color="transparent"
          fixed-tabs
          show-arrows
        > 
          <v-tab 
          v-for="item in item_order_serpclick"
          :key="item.uuid"
          @click="selectOrder(item.serpclick)" 
          >
            Order ID: {{ item.uuid.substr(item.uuid.length - 5) }}
          </v-tab>

        </v-tabs>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">{{ $t('serpclick') }}</th>
                <th class="text-center">{{ $t('url') }}</th>
                <th class="text-center">{{ $t('keyword') }}</th>
                <th class="text-center">{{ $t('search_engine') }}</th>
                <th class="text-center">{{ $t('locale') }}</th>
                <th class="text-center">{{ $t('language') }}</th>
                <th class="text-center">{{ $t('device') }}</th>
                <th class="text-center">{{ $t('os') }}</th>
                <th class="text-center">{{ $t('created_at') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item,index) in item_serpclick"
                :key="index"
              >
                <td class="text-center">{{ item['count'] }}</td>
                <td class="text-left td-chip">
                  <v-chip
                    v-for="(url, index_url) in item['urls']" :key="'chip_url_' + index_url"
                    class="ma-2 url-in-table"
                    color="primary"
                  >
                    {{ encodeDecodeURL(url) }}
                  </v-chip>
                </td>
                <td class="text-left td-chip">
                  <v-chip
                    v-for="(keyword, index_keyword) in item['keywords']" :key="'chip_keyword_' + index_keyword"
                    class="ma-2"
                    color="primary"
                  >
                    {{ keyword }}
                  </v-chip>
                </td>
                <td class="text-center">{{ item['search_engine'] }}</td>
                <td class="text-center">{{ item['locale'] }}</td>
                <td class="text-center">{{ item['language'] }}</td>
                <td class="text-center">{{ item['device'] }}</td>
                <td class="text-center">{{ item['os'] }}</td>
                <td class="text-center">{{ moment(item['created_at']).tz($auth.user().timezone).format('DD/MM/YYYY') }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

      </v-card-text>
      <!-- <v-card-actions class="pl-5 pr-5 pb-5 pt-0 ma-0 ">
        <v-spacer></v-spacer>
        
        <v-btn color="grey" text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>
<script>
 
  export default {
    data: () => ({
      loading: false,
      dialog: false,
      resolve: null,
      reject: null,
      type: null,
      tab_order: null,
      item_serpclick: [],
      item_order_serpclick: [],
      keyword: null,
      keywords: [],
      links: [],
      has_scrollbar: false,
      options: {
        color: 'primary',
        width: 1024,
        zIndex: 200
      }
    }),
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
        }
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      selectOrder(serpclick){
        //this.item_serpclick = serpclick
        this.initTable(serpclick)
      },
      encodeDecodeURL(url) {
        if(!url){
          return ''
        }
        const parts = url.split('/');
        const decodedParts = parts.map(part => {
          try {
            // พยายามดีโค้ดแต่ละส่วนของ URL
            return decodeURIComponent(part);
          } catch {
            // หากเกิดข้อผิดพลาด, ส่งคืนส่วนนั้นๆ ออกมาเป็นเอนโค้ด
            return part;
          }
        });
        // รวมกลับเป็น URL ที่ดีโค้ดได้บางส่วนและเอนโค้ดบางส่วน
        return decodedParts.join('/');
      },
      copyText() {
        let array_serpclick = []
        //console.log(this.item_serpclick)
        let th = this.$t('serpclick') + '\t'
        th += this.$t('url') + '\t'
        th += this.$t('keyword') + '\t'
        th += this.$t('search_engine') + '\t'
        th += this.$t('locale') + '\t'
        th += this.$t('language') + '\t'
        th += this.$t('device') + '\t'
        th += this.$t('os') + '\t'
        th += this.$t('created_at') + '\t'
        array_serpclick.push(th + '\n')
        for(let e of this.item_serpclick){
          let td = e.count + '\t'
          td += e.urls.join(',') + '\t'
          td += e.keywords.join(',') + '\t'
          td += e.search_engine ? e.search_engine : '-' + '\t'
          td += e.locale ? e.locale : '-' + '\t'
          td += e.language ? e.language : '-' + '\t'
          td += e.device ? e.device : '-' + '\t'
          td += e.os ? e.os : '-' + '\t'
          td += e.created_at ? e.created_at : '-' + '\t'
          array_serpclick.push(td + '\n')
        } 
        //console.log(array_serpclick)
        navigator.clipboard.writeText(array_serpclick.join('\n')); 
        this.$root.$snackbar(this.$t('copied_to_clipboard'))
      },
      initTable(item_serpclick){
        let body = []
        if(item_serpclick.length){
          let urls = this.links
          let keywords = this.keywords
          let search_engine = this.keyword ? this.keyword.search_engine : '-'
          let locale = this.keyword ? this.keyword.locale_name : '-'
          let language = this.keyword ? this.keyword.language_name : '-'
          let device = this.keyword ? this.keyword.device : '-'
          let os = this.keyword ? this.keyword.os : '-'
          for(let e of item_serpclick){
            body.push({
              urls: urls,
              keywords: keywords,
              count: e.count_backlink,
              created_at: e.created_at,
              search_engine: search_engine,
              locale: locale,
              language: language,
              device: device,
              os: os,
            })
          }
        }
        //console.group(body)
        this.item_serpclick = body
        let that = this
        setTimeout(function(){
          that.$nextTick(() => {
            that.checkForScrollbar();
          });
        },100)
      },
      CopyBacklink(){
        this.copyText()
      },
      open(item_serpclick,keyword,keywords,links,type) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.type = type
        this.keyword = keyword
        this.keywords = JSON.parse(keywords)
        this.links = JSON.parse(links)
        //console.log(item_serpclick)
        if(this.type == 'keyword'){
          this.item_order_serpclick = item_serpclick
          this.tab_order = this.item_order_serpclick[0].uuid
          this.selectOrder(this.item_order_serpclick[0].serpclick)
        }
        else{
          this.initTable(item_serpclick)
          //this.item_serpclick = item_serpclick
        }
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false
        })
        this.dialog = false
      }
    }
  }
</script>
<style>
.url-in-table{
  max-width: 360px;
  overflow-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.td-chip{
  max-width: 360px;
}
</style>