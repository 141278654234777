<template>
  <v-card>
    <v-skeleton-loader
      v-if="loading" 
      class="mx-auto"
      type="table-thead, table-tbody"
    ></v-skeleton-loader>

    <v-simple-table v-if="!loading" >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">{{ $t('provider') }}</th>
            <th class="text-center">{{ $t('code') }}</th>
            <th class="text-center">{{ $t('description') }}</th>
            <th class="text-center">{{ $t('min_qty') }}</th>
            <th class="text-center">{{ $t('cost') }}</th>
            <th class="text-center">{{ $t('price') }}</th>
            <th class="text-center">{{ $t('profit') }}</th>
            <th class="text-center">{{ $t('percent_profit') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item,index) in items"
            :key="index"
          >
            <td class="text-center">{{ item.providers_name }}</td>
            <td class="text-center">{{ item.code }}</td>
            <td class="text-center">{{ item.description }}</td>
            <td class="text-center">{{ formatNumber(item.min_qty) }}</td>
            <td class="text-center red--text">{{ item.cost }}</td>
            <td class="text-center blue--text">{{ item.price }}</td>
            <td class="text-center success--text">{{ item.profit }}</td>
            <td class="text-center warning--text">{{ formatNumber(item.percent_profit) }} %</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>
<script>
  export default {
    data: () => ({
      loading: true,
      locale: 'th',
      search_items: '',
      headers: [],
      items: []
    }),
    created () {
      this.$store.dispatch('setClassExpandContainer', 'expand_container')
      this.getAllData()
    },
    methods: {
      formatNumber (number) {
        return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
      },
      getAllData(){
        this.loading = true
        this.axios.get('/admin/service/all-data')  
        .then(res => {
          if (res.data.status === 'success') {
            let data = res.data.data
            this.items = data
          }   
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading = false)  
      }
    }
  }
</script>