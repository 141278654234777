<template>
  <v-dialog 
  scrollable 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  fullscreen
  hide-overlay
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }" 
  transition="dialog-bottom-transition"
  >
    <v-card class="dialog-card">
      <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
        <div>
        {{ site }}
        </div>

        <div>
          <v-tooltip left class="">
            <template v-slot:activator="{ on }">
              <v-btn
                @click.stop="openLink()" 
                v-on="on"
                fab 
                small 
                text
                color="grey"
                class="no-caps border-radius-circle"
              >
                <v-icon size="20">mdi-web</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('link') }}</span>
          </v-tooltip>

          <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
            <v-icon size="24">mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 pb-0 pl-5 pr-5 ma-0 ">
        <iframe
          width="100%"
          height="97%"
          :src="url"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
 
  export default {
    data: () => ({
      dialog: false,
      loading: false,
      resolve: null,
      reject: null,
      site: null,
      url: null,
      options: {
        color: 'primary',
        width: 500,
        zIndex: 200
      }
    }),
    methods: {
      openLink(){
        window.open(this.url, '_blank').focus();
      },
      open(url,site) {
        this.$root.$refs.master.disabledScroll()
        
        this.url = url
        this.site = site
        let that = this
        setTimeout(()=> {
          that.dialog = true
        },500)
        

        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>
<style>
.no-scroll {
  overflow: hidden !important;
}
</style>