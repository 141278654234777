<template>
  <v-dialog 
  scrollable 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }" 
  transition="dialog-bottom-transition"
  >
    <v-card class="dialog-card">
      <v-card-title class="d-flex justify-space-between">
        <div>
        {{ $t('confirm_promptpay') }}
        </div>
        <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="view_div">
          <v-text-field
              type="number"
              v-model="amount"
              :label="$t('msg_confirm_promptpay')"
              @keyup="CalAmountUSD"
          ></v-text-field>
        </div>

        <div class="view_div">
          <div class="text-body-1">{{$t('amount_thb')}}</div>
          <div class="text-h6">{{ formatNumber(amount_thb) }} THB</div>
        </div>

        <div class="view_div">
          <div class="text-body-1">{{$t('service_fee')}}</div>
          <div class="text-h6">{{ formatNumber(service_fee) }} THB ({{ percent_service_fee }}%)</div>
        </div>
        
        <div class="view_div">
          <div class="text-body-1">{{$t('fee')}}</div>
          <div class="text-h6">{{ formatNumber(fee) }} THB ({{ percent_fee }}%)</div>
        </div>
        <div class="view_div">
          <div class="text-body-1">{{$t('total_amount')}}</div>
          <div class="text-h6">{{ formatNumber(total_amount) }} THB</div>
        </div>
      </v-card-text>
      
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn> -->
        <v-btn color="primary" rounded depressed @click.native="agree" class="no-caps">{{ $t('save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify ConfirmPromptpay Dialog component
   *
   * Insert component where you want to use it:
   * <ConfirmPromptpay ref="ConfirmPromptpay"></ConfirmPromptpay>
   *
   * Call it:
   * this.$refs.ConfirmPromptpay.open('Delete', 'Are you sure?', { color: 'red' }).then((ConfirmPromptpay) => {})
   * Or use await:
   * if (await this.$refs.ConfirmPromptpay.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$ConfirmPromptpay
   * <template>
   *   <v-app>
   *     ...
   *     <ConfirmPromptpay ref="ConfirmPromptpay"></ConfirmPromptpay>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$ConfirmPromptpay = this.$refs.ConfirmPromptpay.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      rate_thb:  1,
      amount:  0,
      amount_thb:  0,
      service_fee:  0,
      percent_service_fee:  0,
      fee:  0,
      percent_fee:  0,
      total_amount:  0,
      options: {
        color: 'primary',
        width: 360,
        zIndex: 200
      }
    }),
    methods: {
      open(percent_fee,percent_service_fee,rate_thb) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.amount = 0
        this.amount_thb = 0
        this.rate_thb = rate_thb
        this.percent_fee = percent_fee
        this.percent_service_fee = percent_service_fee
        
        this.CalAmountUSD()
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      formatCurrency (number,currency) {
        return currency ? new Intl.NumberFormat('th', {style: 'currency', currency: currency}).format(number) : '-'
      },
      CalAmountUSD(){
        if(this.amount){
          this.amount_thb = parseFloat(this.amount)*this.rate_thb
          this.service_fee = parseFloat(this.amount_thb) + (this.amount_thb*this.percent_service_fee/100)
          this.fee = parseFloat(this.amount_thb) + (this.amount_thb*this.percent_fee/100)
          this.total_amount = parseFloat(this.amount_thb) + (this.amount_thb*this.percent_service_fee/100)  + (this.amount_thb*this.percent_fee/100)
        }
        else{
          this.service_fee = 0
          this.fee = 0
          this.total_amount = 0
          this.amount_thb = 0
        }
      },
      agree() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: true,
          amount: this.amount
        })
        this.dialog = false
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>