<template>
  <v-card>

    
    <v-row class="pt-2">
      <v-col cols="12" md="6" sm="12" class="mt-0 mb-0 pt-0 pb-0">
        <x-select
          v-model="package_search_engine"
          :items="item_package_search_engine"
          item-text="providers_name"
          item-value="uuid"
          :label="$t('search_engine')"
          @change="selectPackageSearchEngine()"
          filled
        ></x-select>
      </v-col>
      <v-col cols="12" md="6" sm="12" class="mt-0 mb-0 pt-0 pb-0">
        <x-select
          v-model="package_top_ranking"
          :items="item_package_top_ranking"
          item-text="top_ranking"
          item-value="uuid"
          :label="$t('top_ranking')"
          @change="getAllData()"
          filled
        ></x-select>
      </v-col>
    </v-row>

    <v-skeleton-loader
      v-if="loading" 
      class="mx-auto"
      type="table-thead, table-tbody"
    ></v-skeleton-loader>

    <v-simple-table v-if="!loading" >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">{{ $t('search_engine') }}</th>
            <th class="text-center">{{ $t('top_ranking') }}</th>
            <th class="text-center">{{ $t('package_date') }}</th>
            <th class="text-center">{{ $t('total_day') }}</th>
            <th class="text-center">{{ $t('cost') }}</th>
            <th class="text-center">{{ $t('cost_per_keyword') }}</th>
            <th class="text-center">{{ $t('multiple_factor') }}</th>
            <th class="text-center">{{ $t('price_per_keyword') }}</th>
            <th class="text-center">{{ $t('percent_discount') }}</th>
            <th class="text-center">{{ $t('price_per_keyword_with_discount') }}</th>
            <th class="text-center">{{ $t('limit_active_keyword') }}</th>
            <th class="text-center">{{ $t('total_cost') }}</th>
            <th class="text-center">{{ $t('price') }}</th>
            <th class="text-center">{{ $t('profit') }}</th>
            <th class="text-center">{{ $t('percent_profit') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item,index) in items"
            :key="index"
          >
            <td class="text-center">{{ item.search_engine }}</td>
            <td class="text-center">{{ item.top_ranking }}</td>
            <td class="text-center">{{ item.package_date }}</td>
            <td class="text-center">{{ formatNumber(item.total_day) }}</td>
            <td class="text-center">{{ formatNumber(item.cost) }}</td>
            <td class="text-center">{{ formatNumber(item.cost_per_keyword) }}</td>
            <td class="text-center">{{ item.multiple_factor }}</td>
            <td class="text-center">{{ formatNumber(item.price_per_keyword) }}</td>
            <td class="text-center">{{ item.percent_discount }} %</td>
            <td class="text-center">{{ formatNumber(item.price_per_keyword_with_discount) }}</td>
            <td class="text-center">{{ item.limit_boost_keyword }}</td>
            <td class="text-center red--text">{{ formatNumber(item.total_cost) }}</td>
            <td class="text-center blue--text">{{ formatNumber(item.price) }}</td>
            <td class="text-center success--text">{{ formatNumber(item.profit) }}</td>
            <td class="text-center warning--text">{{ formatNumber(item.percent_profit) }} %</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>
<script>
  export default {
    data: () => ({
      loading: true,
      locale: 'th',
      search_items: '',
      package_search_engine: null,
      item_package_search_engine: [],
      package_top_ranking: null,
      item_package_top_ranking: [],
      headers: [],
      items: []
    }),
    created () {
      this.$store.dispatch('setClassExpandContainer', 'expand_container')
      this.setData()
      //this.getAllData()
      
    },
    methods: {
      formatNumber (number) {
        return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
      },
      SelectTextFrequency(e){
        return e.frequency + ' ' + this.$t(e.format)
      },
      setData() {
        //console.log(data)
        this.axios.get('/admin/package/boost-keyword/get-all')
        .then(res => {
          if (res.data.status === 'success') {
            this.item_package_search_engine = res.data.package_keyword.serp_provider
            this.item_package_top_ranking = res.data.package_keyword.serp_top_ranking_provider
            this.package_search_engine = this.item_package_search_engine[0].uuid
            this.package_top_ranking = this.item_package_top_ranking[0].uuid
            this.getAllData()
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
      },
      selectPackageSearchEngine(){
        this.loading = true
        this.axios.post('/admin/package/boost-keyword/get-data-top-ranking',{
          serp_provider_uuid: this.package_search_engine
        })
        .then(res => {
          if (res.data.status === 'success') {
            this.item_package_top_ranking = res.data.serp_top_ranking_provider
            this.package_top_ranking = this.item_package_top_ranking[0].uuid
            this.getAllData()
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading = false)
      },
      getAllData(){
        this.loading = true
        this.axios.post('/admin/package/boost-keyword/all-data',{
          top_ranking_uuid: this.package_top_ranking,
        })
        .then(res => {
          if (res.data.status === 'success') {
            let data = res.data.data
            this.items = data
          }   
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading = false)  
      }
    }
  }
</script>