<template>
  <v-dialog 
  scrollable 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  transition="dialog-bottom-transition"
  >
    <v-card class="dialog-card">
      <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
        <div>
          {{ $t('show_hide_column') }}
        </div>
        
        <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text rounded :class="'ma-0 pb-5 pt-5 pl-5 ' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">
        <v-tabs 
            v-model="tab"
            :color="($store.state.app.dark) ? 'white' : 'black'"
            class="content-tabs mb-3"
            background-color="transparent"
            fixed-tabs
            show-arrows
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab class="no-caps" :href="'#keyword'" :ripple="false">
              {{ $t('keyword') }}
            </v-tab>
            <v-tab class="no-caps" :href="'#order'" :ripple="false">
              {{ $t('order') }}
            </v-tab>
            <v-tab class="no-caps" :href="'#lighthouse'" :ripple="false">
              {{ $t('lighthouse') }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" :touchless="false">
            <v-tab-item :transition="false" :reverse-transition="false" :value="'keyword'">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">
                        {{ $t('show_hide') }}
                      </th>
                      <th class="text-center">
                        {{ $t('column') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in column_keyword"
                      :key="item.value"
                    >
                      <td class="text-center">
                        <div class="d-flex justify-center">
                          <v-switch
                            v-model="item.active"
                            :ref="'ref_'+item.value"
                            :id="'id_'+ item.value"
                            inset
                            dense
                            :ripple="false"
                            :name="item.value"
                            :persistent-hint="true"
                          />
                        </div>
                      </td>
                      <td class="text-center">
                        {{ $t(item.text) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-tab-item>
            <v-tab-item :transition="false" :reverse-transition="false" :value="'order'">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">
                        {{ $t('show_hide') }}
                      </th>
                      <th class="text-center">
                        {{ $t('column') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in column_order"
                      :key="item.value"
                    >
                      <td class="text-center">
                        <div class="d-flex justify-center">
                          <v-switch
                            v-model="item.active"
                            :ref="'ref_'+item.value"
                            :id="'id_'+ item.value"
                            inset
                            dense
                            :ripple="false"
                            :name="item.value"
                            :persistent-hint="true"
                          />
                        </div>
                      </td>
                      <td class="text-center">
                        {{ $t(item.text) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-tab-item>
            <v-tab-item :transition="false" :reverse-transition="false" :value="'lighthouse'">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">
                        {{ $t('show_hide') }}
                      </th>
                      <th class="text-center">
                        {{ $t('column') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in column_lighthouse"
                      :key="item.value"
                    >
                      <td class="text-center">
                        <div class="d-flex justify-center">
                          <v-switch
                            v-model="item.active"
                            :ref="'ref_'+item.value"
                            :id="'id_'+ item.value"
                            inset
                            dense
                            :ripple="false"
                            :name="item.value"
                            :persistent-hint="true"
                          />
                        </div>
                      </td>
                      <td class="text-center">
                        {{ $t(item.text) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-tab-item>
          </v-tabs-items>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-5 ma-0 ">
        <v-spacer></v-spacer>
        <!-- <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn> -->
        <v-btn color="primary" rounded depressed @click.native="updateSetting" :loading="loading" :disabled="loading" class="no-caps">{{ $t('save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>  
<script>
  
  export default {
    data: () => ({
      dialog: false,
      loading: false,
      resolve: null,
      reject: null,
      tab: 'keyword',
      uuid: null,
      column_keyword: [],
      column_order: [],
      column_lighthouse: [],
      has_scrollbar: false,
      options: {
        color: 'primary',
        width: 1024,
        zIndex: 200
      } 
    }),
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
        }
      },
      open(uuid,column) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.uuid = uuid
        this.column_keyword = column.keyword
        this.column_order = column.order
        this.column_lighthouse = column.lighthouse
        let that = this
        setTimeout(function(){          
          that.$nextTick(() => {
            that.checkForScrollbar();
          });
        },100)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      updateSetting(){
        this.loading = true
        let disable_column_keyword = []
        for(let e of this.column_keyword){
          if(!e.active){
            disable_column_keyword.push(e.value)
          }
        }
        let disable_column_order = []
        for(let e of this.column_order){
          if(!e.active){
            disable_column_order.push(e.value)
          }
        }
        let disable_column_lighthouse = []
        for(let e of this.column_lighthouse){
          if(!e.active){
            disable_column_lighthouse.push(e.value)
          }
        }
        this.axios.post('/admin/user/update/column', {
          uuid: this.uuid,
          disable_column_keyword: JSON.stringify(disable_column_keyword),
          disable_column_order: JSON.stringify(disable_column_order),
          disable_column_lighthouse: JSON.stringify(disable_column_lighthouse),
        })
        .then(res => {
          if (res.data.status === 'success') {
            //console.log(res.data)
            this.$root.$refs.master.enabledScroll()
            this.resolve({
              confirm: true,
            })
            this.dialog = false
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading = false)
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>