'use strict';

import Cookies from 'js-cookie'

let language = Cookies.get('language') || (navigator.language || navigator.userLanguage)
// language = language.substr(0, 2)

const app = {
  state: {
    loading: true,
    init: window.init,
    dark: (Cookies.get('dark') === 'true') ? true : false || false,
    showCookieConsent: (Cookies.get('showCookieConsent') === 'false') ? false : true || true,
    language: language,
    dashboardDrawer: false,
    classExpandContainer: '',
    data_order: {
      lang_service: null,
      service: null,
      extras: null,
      formOrder: null,
    },
    data_keyword: {
      formKeyword: null,
    },
    data_package: {
      formPackage: null,
    },
    data_package_boost_keyword: {
      formPackage: null,
    },
    data_payment: {
      tap: null,
      transfer: null,
      credit_card: null,
      promptpay: null,
    },
    data_lighthouse:{
      formLighthouse: null,
    }
  },
  mutations: {
    SET_LOADING: (state, loading) => {
      state.loading = loading
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language
      Cookies.set('language', language)
    },
    SET_CLASS_EXPAND_CONTAINER: (state, classExpandContainer) => {
      state.classExpandContainer = classExpandContainer
    },
    SET_DATA_ORDER: (state, data_order) => {
      state.data_order = data_order
    },
    SET_DATA_KEYWORD: (state, data_keyword) => {
      state.data_keyword = data_keyword
    },
    SET_DATA_PACKAGE: (state, data_package) => {
      state.data_package = data_package
    },
    SET_DATA_PACKAGE_BOOST_KEYWORD: (state, data_package_boost_keyword) => {
      state.data_package_boost_keyword = data_package_boost_keyword
    },
    SET_DATA_PAYMENT: (state, data_payment) => {
      state.data_payment = data_payment
    },
    SET_DATA_LIGHTHOUSE: (state, data_lighthouse) => {
      state.data_lighthouse = data_lighthouse
    },
    SET_COOKIE_CONSENT: (state, showCookieConsent) => {
      state.showCookieConsent = showCookieConsent
      Cookies.set('showCookieConsent', showCookieConsent, { expires: 360, path: '/' })
    },
    SET_DARK_THEME: (state, dark) => {
      state.dark = dark
      // Toggle .dark class on html tag
      var root = document.documentElement
      if (dark) {
        root.classList.add('dark')
      } else {
        root.classList.remove('dark')
      }
      Cookies.set('dark', dark, { expires: 360, path: '/' })
    },
    SET_DASHBOARD_DRAWER: (state, dashboardDrawer) => {
      state.dashboardDrawer = dashboardDrawer
    }
  },
  actions: {
    setLoading({ commit }, loading) {
      commit('SET_LOADING', loading)
    },
    setLanguage({ commit }, language) {
      commit('SET_LANGUAGE', language)
    },
    setClassExpandContainer({ commit }, classExpandContainer) {
      commit('SET_CLASS_EXPAND_CONTAINER', classExpandContainer)
    },
    setDataOrder({ commit }, data_order) {
      commit('SET_DATA_ORDER', data_order)
    },
    setDataKeyword({ commit }, data_keyword) {
      commit('SET_DATA_KEYWORD', data_keyword)
    },
    setDataPackage({ commit }, data_package) {
      commit('SET_DATA_PACKAGE', data_package)
    },
    setDataPackageBoostKeyword({ commit }, data_package_boost_keyword) {
      commit('SET_DATA_PACKAGE_BOOST_KEYWORD', data_package_boost_keyword)
    },
    setDataPayment({ commit }, data_payment) {
      commit('SET_DATA_PAYMENT', data_payment)
    },
    setDataLighthouse({ commit }, data_lighthouse) {
      commit('SET_DATA_LIGHTHOUSE', data_lighthouse)
    },
    setCookieConsent({ commit }, showCookieConsent) {
      commit('SET_COOKIE_CONSENT', showCookieConsent)
    },
    setDark({ commit }, dark) {
      commit('SET_DARK_THEME', dark)
    },
    setDashboardDrawer({ commit }, dashboardDrawer) {
      commit('SET_DASHBOARD_DRAWER', dashboardDrawer)
    }
  }
}

export default app