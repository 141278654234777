<template>
  <v-dialog 
  scrollable 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }" 
  transition="dialog-bottom-transition"
  >
    <v-card class="dialog-card">
      <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
        <div>
          {{ $t('filters') }}
        </div>
        
        <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text rounded :class="'ma-0 pl-5 pt-5 pb-5 ' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">
        <v-card v-if="type == 'chart_ranking'" outlined class="mb-2 pa-3 card_chart_ranking">
          <v-row class="d-flex justify-end" >
              <v-col class="text-left" cols="8" sm="8" md="8" lg="8">
                <div class="">
                  <div><h3><b>{{ $t('ranking_avg') }}</b></h3></div>
                  <div>{{ $t('ranking_avg_msg') }}</div>
                </div>
              </v-col>
              <v-col class="d-flex justify-end ma-auto " cols="4" sm="4" md="4" lg="4">
                <v-switch
                  v-model="ranking_avg"
                  class="mr-1"
                  :ref="'ref_chart_ranking'"
                  :id="'id_chart_ranking'"
                  inset
                  dense
                  :ripple="false"
                  :persistent-hint="true"
                />    
              </v-col>
          </v-row>
          
        </v-card>

        <v-card v-if="type != 'table_keyword_rank'" outlined class="mb-2 pa-3 card_chart_ranking">
          <div>
            <h3><b>{{ $t('historical_data') }}</b></h3>
            <div>
              <v-radio-group v-model="historical_data" @change="changeHistoricalData" row class="ma-auto historical_data">  
                <v-radio
                  class="ma-2"
                  :label="1 + ' ' + $t('month')"
                  :value="1"
                ></v-radio>
                <v-radio
                  class="ma-2"
                  :label="2 + ' ' + $t('month')"
                  :value="2"
                ></v-radio>
                <v-radio
                  class="ma-2"
                  :label="3 + ' ' + $t('month')"
                  :value="3"
                ></v-radio>
                <v-radio
                  class="ma-2"
                  :label="6 + ' ' + $t('month')"
                  :value="6"
                ></v-radio>
                <v-radio
                  class="ma-2"
                  :label="1 + ' ' + $t('year')"
                  :value="12"
                ></v-radio>
                <v-radio
                  class="ma-2"
                  :label="2 + ' ' + $t('year')"
                  :value="24"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>
          <div v-if="(type == 'keyword_performance' || type == 'table_keyword_performance_historical')">
            <h3><b>{{ $t('format') }}</b></h3>
            <div>        
              <v-radio-group v-model="week_date" row class="ma-auto historical_data">  
                <v-radio
                  v-if="historical_data <= 3"
                  class="ma-2"
                  :label="$t('week')"
                  value="week"
                ></v-radio>
                <v-radio
                  class="ma-2"
                  :label="$t('month')"
                  value="month"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>
        </v-card>

        <v-tabs 
          v-model="tab_filters"
          :color="($store.state.app.dark) ? 'white' : 'black'"
          class="content-tabs"
          background-color="transparent"
          fixed-tabs
          show-arrows
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab v-for="(item, index) in filters"  @click="initScrollbar" :key="index" class="no-caps" :href="'#'+ index" :ripple="false">
            {{ item.text }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab_filters" :touchless="false">
          <v-tab-item v-for="(item, index) in filters" :key="index" :transition="false" :reverse-transition="false" :value="index">
            <div class="d-flex justify-end mt-3">
              <x-text-field
                @input="searchData(index)"
                class="input-search"
                v-model="item.search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
              ></x-text-field>
            </div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left text-td">
                      {{ $t('name') }}
                    </th>
                    <th class="text-center" style="width: 50px;">
                      <div class="d-flex justify-center">
                        <v-switch
                          v-model="item.active"
                          :ref="'ref_'+index"
                          :id="'id_'+ index"
                          inset
                          dense
                          :ripple="false"
                          :name="index"
                          :persistent-hint="true"
                          @click="resetFilters(item.active,index)"
                        />
                      </div>
                     
                    </th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="filter in item.items"
                    :key="filter.value"
                    :style="'display: ' +(filter.show ? '' : 'none')+';'"
                  >
                    <td class="text-left text-td">
                      {{ filter.text }}
                    </td>
                    <td class="text-center" style="width: 50px;">
                      <div class="d-flex justify-center">
                        <v-switch
                          v-model="filter.active"
                          :ref="'ref_'+filter.value"
                          :id="'id_'+ filter.value"
                          inset
                          dense
                          :ripple="false"
                          :name="filter.value"
                          :persistent-hint="true"
                        />
                      </div>
                    </td>
                    
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
        </v-tabs-items> 
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pl-5 pr-5 pb-5 pt-5 ma-0 ">
        <v-spacer></v-spacer>
        <!-- <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn> -->
        <v-btn color="primary" rounded depressed @click.native="agree" class="no-caps">{{ $t('confirm') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
 
export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      filters: [],
      tab_filters: null,
      type: null,
      ranking_avg: true,
      historical_data: 1,
      week_date: 'month',
      has_scrollbar: false,
      options: {
        color: 'primary',
        width: 550,
        zIndex: 200
      }
    }),
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      initScrollbar() {
        let that = this
        setTimeout(function(){
          that.$nextTick(() => {
            that.checkForScrollbar();
          });
        },300)
      },
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
          //console.log(this.has_scrollbar)
        }
      },
      changeHistoricalData(){
        if(this.historical_data > 3){     
          this.week_date = 'month'
        }
      },
      resetFilters (active,name) {
        //console.log(this.filters[name].items)
        this.filters[name].items = this.filters[name].items.filter((e) => {
          e.active = active
          return e
        })
        this.initScrollbar()
      },
      searchData(name){
        //console.log(this.filters[name].search)
        this.filters[name].items = this.filters[name].items.filter((e) => {
          if(this.filters[name].search){
            if(e.text.includes(this.filters[name].search)){
              e.show = true
            }
            else{
              e.show = false
            }
          }
          else{
            e.show = true
          }
          return e
        })
        this.initScrollbar()
      },
      open(filters,type,ranking_avg,historical_data,week_date) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        //console.log(filters)
        this.filters = filters
        this.type = type
        this.ranking_avg = ranking_avg
        this.historical_data = historical_data
        this.week_date = week_date
        this.initScrollbar()
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        for(let i in this.filters){
            let chk = false
            for(let e of this.filters[i].items){
              if(e.active){
                chk = true
              }
            }
            if(!chk){
              this.tab_filters = i
              this.$root.$snackbar(this.$t('not_found'))
              return false
            }
        }
        //console.log(this.filters)
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: true,
          filters: this.filters,
          ranking_avg: this.ranking_avg,
          historical_data:  this.historical_data,
          week_date:  this.week_date,
        })
        this.dialog = false
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>
<style >
@media (max-width: 600px) {
  .input-search{
    width: 100%;
  }
}
.v-dialog .card_chart_ranking{
  border-radius: 10px !important;
}
.text-td{
  max-width: 250px;
  width: 250px;
  overflow-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (max-width: 370px) {
  .text-td{
    max-width: 100px;
    width: 100px;
  }
}
.historical_data .v-messages{
  display: none;
}
.historical_data .v-input__slot{
  margin: 0;
}
</style>


