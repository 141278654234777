<template>
  <v-dialog 
  scrollable 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }" 
  transition="dialog-bottom-transition"
  >
    <v-card class="dialog-card">
      <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
        <div>
          {{ $t('edit_column') }}
        </div>
        <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text rounded :class="'ma-0 pt-0 pb-0 pl-5 ' + (has_scrollbar ? 'pr-3' : 'pr-5')" ref="body_dialog">
        <v-simple-table class="ma-0 pa-0">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left td-text">
                  {{ $t('column') }}
                </th>
                <th class="text-right">
                  <!-- {{ $t('show_hide') }} -->
                  <div class="d-flex justify-end">
                    <v-switch
                      v-model="active_all"
                      :ref="'ref_active_all'"
                      :id="'id_active_all'"
                      inset
                      dense
                      :ripple="false"
                      :persistent-hint="true"
                      @click="resetColumn"
                    />
                  </div>
                </th>
                
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in columns"
                :key="item.value"
              >
                <td class="text-left td-text">
                  {{ item.text }}
                </td>
                <td class="text-right">
                  <div class="d-flex justify-end">
                    <v-switch
                      v-model="item.active"
                      :ref="'ref_'+item.value"
                      :id="'id_'+ item.value"
                      inset
                      dense
                      :ripple="false"
                      :name="item.value"
                      :persistent-hint="true"
                    />
                  </div>
                </td>
                
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pl-5 pr-5 pb-5 pt-5 ma-0 ">
        <v-spacer></v-spacer>
        <!-- <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn> -->
        <v-btn color="primary" rounded depressed @click.native="agree" class="no-caps">{{ $t('confirm') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify CustomColumn Dialog component
   *
   * Insert component where you want to use it:
   * <CustomColumn ref="CustomColumn"></CustomColumn>
   *
   * Call it:
   * this.$refs.CustomColumn.open('Delete', 'Are you sure?', { color: 'red' }).then((CustomColumn) => {})
   * Or use await:
   * if (await this.$refs.CustomColumn.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$CustomColumn
   * <template>
   *   <v-app>
   *     ...
   *     <CustomColumn ref="CustomColumn"></CustomColumn>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$CustomColumn = this.$refs.CustomColumn.open
   * }
   */
  
  import Cookies from 'js-cookie'
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      columns: [],
      model: null,
      has_scrollbar: false,
      active_all: true,
      options: {
        color: 'primary',
        width: 430,
        zIndex: 200
      }
    }),
    mounted() {
      this.checkForScrollbar();
    },
    methods: {
      checkForScrollbar() {
        const myDiv = this.$refs.body_dialog;
        //console.log(myDiv)
        if(myDiv){
          const hasVerticalScrollbar = myDiv.scrollHeight > myDiv.clientHeight;
          if(hasVerticalScrollbar){
            this.has_scrollbar = true
          }
          else{
            this.has_scrollbar = false
          }
        }
      },
      resetColumn () { 
        
        let columns = JSON.parse(JSON.stringify(this.columns))
        //console.log(columns)
        let active = this.active_all
        this.columns = columns.filter((e) => {
          e.active = active
          return e
        })
        //console.log(this.columns)
      },
      open(columns,model) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.model = model
        let new_columns = JSON.parse(JSON.stringify(columns))
        //console.log(columns)
        let disable_columns = []
        if(Cookies.get('hide_columns')){
          let hide_columns = JSON.parse(Cookies.get('hide_columns'))
          if(hide_columns[this.model]){
            disable_columns = hide_columns[this.model]
          }
        }
        //console.log(disable_columns)
        this.columns = new_columns.filter((item) => {
          if(disable_columns.find(e => e == item.value)){
            item.active = false
          }
          else{
            item.active = true
          }
          return item
        })

        let that = this
        setTimeout(function(){
          that.$nextTick(() => {
            that.checkForScrollbar();
          });
        },100)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        let hide_columns = {}
        if(Cookies.get('hide_columns')){
          hide_columns = JSON.parse(Cookies.get('hide_columns'))
        }
        //console.log(this.columns)
        let disable_columns = []
        for(let e of this.columns){
          if(!e.active){
            disable_columns.push(e.value)
          }
        }
        //console.log(disable_columns)
        hide_columns[this.model] = disable_columns
        Cookies.set('hide_columns', JSON.stringify(hide_columns))

        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: true,
          disable_columns: disable_columns
        })
        this.dialog = false
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>
<style>
.td-text{
  max-width: 200px!important;
}
</style>