<template>
  <v-dialog 
  scrollable 
  @click:outside="cancel" 
  v-model="dialog" 
  :max-width="options.width" 
  :fullscreen="$vuetify.breakpoint.xsOnly" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  transition="dialog-bottom-transition"
  >
    <v-card class="dialog-card">
      <v-card-title class="pt-3 pl-5 pr-5 pb-3 ma-0 d-flex justify-space-between">
        <div>
          {{ $t('setting_payment') }}
        </div>
        
        <v-btn fab small text @click.native="cancel" color="grey" class="no-caps border-radius-circle ml-2">
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="ma-0 pa-5">
        <div class="mt-2">
          <v-switch
            @change="changeStatusPayment"
            v-model="status_payment_transfer"
            ref="status_payment_transfer"
            id="status_payment_transfer"
            :label="$t('transfer')"
            inset
            dense
            :ripple="false"
            name="status_payment_transfer"
            :persistent-hint="true"
          />
        </div>
        <div class="mt-2">
          <v-switch
            @change="changeStatusPayment"
            v-model="status_payment_credit_card"
            ref="status_payment_credit_card"
            id="status_payment_credit_card"
            :label="$t('credit_card')"
            inset
            dense
            :ripple="false"
            name="status_payment_credit_card"
            :persistent-hint="true"
          />
        </div>
        <div class="mt-2">
          <v-switch
            @change="changeStatusPayment"
            v-model="status_payment_promptpay"
            ref="status_payment_promptpay"
            id="status_payment_promptpay"
            :label="$t('promptpay')"
            inset
            dense
            :ripple="false"
            name="status_payment_promptpay"
            :persistent-hint="true"
          />
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="ma-0 pa-5">
        <v-spacer></v-spacer>
        <!-- <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn> -->
        <v-btn color="primary" rounded depressed @click.native="updateSetting" :loading="loading" :disabled="loading" class="no-caps">{{ $t('save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>  
<script>
  
  export default {
    data: () => ({
      dialog: false,
      loading: false,
      resolve: null,
      reject: null,
      uuid: null,
      status_payment_transfer: false,
      status_payment_credit_card: false,
      status_payment_promptpay: false,
      options: {
        color: 'primary',
        width: 360,
        zIndex: 200
      } 
    }),
    methods: {
      open(uuid,setting) {
        this.$root.$refs.master.disabledScroll()
        this.dialog = true
        this.uuid = uuid
        this.status_payment_transfer = setting.payment_transfer ? true : false
        this.status_payment_credit_card = setting.payment_credit_card ? true : false
        this.status_payment_promptpay = setting.payment_promptpay ? true : false
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      changeStatusPayment(){
        if(!this.status_payment_transfer && !this.status_payment_credit_card && !this.status_payment_promptpay){
          this.status_payment_transfer = true
        }
      },
      updateSetting(){
        if(!this.status_payment_transfer && !this.status_payment_credit_card && !this.status_payment_promptpay){
          this.$root.$snackbar(this.$t('not_found'))
          return false
        }
        this.loading = true
        this.axios.post('/admin/billing/update/setting-payment', {
          uuid: this.uuid,
          payment_transfer: this.status_payment_transfer ? 1 : 0,
          payment_credit_card: this.status_payment_credit_card ? 1 : 0,
          payment_promptpay: this.status_payment_promptpay ? 1 : 0,
        })
        .then(res => {
          if (res.data.status === 'success') {
            this.$root.$refs.master.enabledScroll()
            this.resolve({
              confirm: true,
            })
            this.dialog = false
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading = false)
      },
      cancel() {
        this.$root.$refs.master.enabledScroll()
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>