<template>
  <div class="" >
        <panel_create
        v-if="($auth.user().role != 2 && $route.query && $route.query.enterprise_uuid) || ($auth.user().role == 2 && !$auth.user().status_enterprise)"
        :title="$t('package_tracking_keyword')"
        :msg="$t('msg_title_create_package')"
        :create_item="$t('buy_package')"
        :redirect="'tracking-package-keyword-add'"
        ></panel_create>

        <v-card rounded outlined class="mt-5 pa-5" v-if="body_package.length">
          <v-skeleton-loader
            v-if="loading" 
            class="mx-auto"
            type="table-thead, table-tbody"
          ></v-skeleton-loader>

          <v-simple-table v-if="!loading" class="">
            <template v-slot:default>
              <thead>
                <tr>
                  <th :class="'text-center pakage-th'">
                    {{ $t('search_engine') }} 
                  </th>
                  <th :class="'text-center pakage-th'">
                    {{ $t('depth') }} 
                  </th>
                  <th :class="'text-center pakage-th'">
                    {{ $t('date_update_ranking') }} 
                  </th>
                  <th :class="'text-center pakage-th'">
                    {{ $t('limit_active_keyword') }} 
                  </th>
                  <th :class="'text-center pakage-th'"
                  v-for="item in head_package"
                  :key="item.date"
                  >
                    {{ item.month }}
                  </th>
                  
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in body_package"
                  :key="item.uuid"
                >
                <!-- <td v-if="item.has_text_search_engine" :rowspan="item.rowspan_search_engine" :class="'text-center pakage-td'"></td> -->
                  <td :class="'text-center pakage-td'">
                    {{ item.search_engine }}
                  </td>
                  <!-- <td v-if="item.has_text_frequency" :rowspan="item.rowspan_frequency" :class="'text-center pakage-td'"></td> -->
                  <td :class="'text-center pakage-td'">
                    {{ item.depth }}
                  </td>
                  <td :class="'text-center pakage-td'">
                  {{ item.frequency_date }} {{ $t(item.frequency_date_format) }}
                  </td>
                  <td :class="'text-center pakage-td'">
                    {{ item.limit_active_keyword }} {{ $t('keyword') }}
                  </td>
                  
                  <v-tooltip top
                    v-for="body in item.month_body"
                    :key="body.m" 
                  >
                    <template v-slot:activator="{ on }">
                      <td 
                        v-if="!body.expired"  
                        :class="'text-center '+ body.class"
                        v-on="on"
                        >
                        {{ body.limit_active_keyword }}
                      </td>
                      <td 
                        v-else
                        :class="'text-center '+ body.class"
                        v-on="on"
                        >
                        {{ body.limit_active_keyword }}
                      </td>
                    </template>
                    <div>{{ $t('search_engine') }}: {{ body.search_engine }}</div>
                    <div>{{ $t('depth') }}: {{ body.depth }}</div>
                    <div>{{ $t('update_ranking_every') }}: {{ item.frequency_date }} {{ $t(item.frequency_date_format) }}</div>
                    <div>{{ $t('limit_active_keyword') }}: {{ body.limit_active_keyword }}</div>
                    <div>{{ $t('end_date') }}: {{ body.expired_date }}</div>
                  </v-tooltip>
                  
                </tr>
                <tr>
                  <td colspan="4" :class="'text-center pakage-th'">{{ $t('total') }}</td>
                  <td 
                    v-for="item in footer_package"
                    :key="item.month"
                    :class="'text-center pakage-th'">
                    {{ item.limit_active_keyword }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        

      <div class="mt-5">
        <data-table
          model="App\Models\PackageKeyword"
          :enterprise="enterprise"
          :user_uuid="enterprise_uuid"
        >
          <template v-slot:empty-head>
            <v-icon size="72">mdi-folder-multiple</v-icon>
          </template>
          <template v-slot:empty-text>
          </template>
        </data-table>
      </div>
  </div>
</template>
<script>
  
  export default {
    data: () => ({
      loading: false,
      loading_main: false,
      head_package: [],
      body_package: [],
      footer_package: [],
      enterprise: false,
      enterprise_uuid: null,
    }),
    created () {
      // if(!this.$auth.user().page_package){
      //   this.$router.push({
      //     name: 'settings.profile',
      //   })
      // }

      if(this.$route.query && this.$route.query.enterprise_uuid){
        this.enterprise = true
        this.enterprise_uuid = this.$route.query.enterprise_uuid
        this.$root.$refs.master.getCredit()
      }
      this.open()
    },
    methods: {
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      
      open() { 
        this.setData()
      },
      setData() {
        this.loading = true
        this.head_package = []
        this.body_package = []
        this.footer_package = []
        this.axios.post('/package/keyword/get-all-active',{
          enterprise_uuid: (this.$route.query && this.$route.query.enterprise_uuid ? this.$route.query.enterprise_uuid : '')
        })
        .then(res => {
          if (res.data.status === 'success') {
            //console.log(res.data)
            let package_keyword = res.data.package_keyword
            if(package_keyword.length){ 
              let max_package_date_expired = package_keyword[(package_keyword.length-1)].package_date_expired
              // package_keyword = package_keyword.sort(function(a, b) {
              //     return a.keyword_package_1_id - b.keyword_package_1_id;
              // })
              // package_keyword = package_keyword.sort(function(a, b) {
              //     return a.serp_provider_id - b.serp_provider_id;
              // })
              // package_keyword = package_keyword.sort(function(a, b) {
              //     return a.package_date_expired - b.package_date_expired;
              // })
              let month = Math.round(this.moment(max_package_date_expired).diff(this.moment(), 'months', true)) + 1
              for(let m = 0; m < month; m++){
                //console.log(this.moment().add(m, 'month').tz(this.$auth.user().timezone).format('MM-YYYY'))
                this.head_package.push({
                  date: this.moment().add(m, 'month').tz(this.$auth.user().timezone).unix(),
                  month: this.moment().add(m, 'month').tz(this.$auth.user().timezone).format('MM-YYYY')
                })
                let limit_active_keyword = 0
                for(let e of package_keyword){
                  //let diff_month = Math.round(this.moment(e.package_date_expired).diff(this.moment(), 'months', true))
                  let package_date_expired = this.moment(e.package_date_expired).startOf('month')
                  let diff_month = Math.round(package_date_expired.diff(this.moment().startOf('month'), 'months', true))
                  if(diff_month >= m){
                    limit_active_keyword += parseInt(e.limit_active_keyword)
                  }
                }
                this.footer_package.push({
                  month: m,
                  limit_active_keyword: limit_active_keyword
                })
              }
              // //console.log(this.footer_package)
              // let frequency_date_id_now = package_keyword[0].keyword_package_1_id
              // let serp_provider_id_now = package_keyword[0].serp_provider_id
              let count = 0
              let body_package = []
              for(let e of package_keyword){
                let package_date_expired = this.moment(e.package_date_expired).startOf('month')
                let diff_month = Math.round(package_date_expired.diff(this.moment().startOf('month'), 'months', true))
                //console.log(e.package_date_expired)
                //console.log(diff_month)
                let month_body = []
                for(let m = 0; m < month; m++){
                  let class_td = diff_month+1 > m ? 'pakage-td' : 'pakage-td-active'
                  let expired = diff_month+1 > m ? false : true
                  month_body.push({
                    m: m,
                    month: this.moment().add(m, 'month').tz(this.$auth.user().timezone).format('MM-YYYY'),
                    class: class_td,
                    expired: expired,
                    expired_date: this.moment(e.package_date_expired).tz(this.$auth.user().timezone).format('DD/MM/YYYY H:mm'),
                    limit_active_keyword: (!expired ? e.limit_active_keyword : ''),
                    depth: e.serp_depth_provider_depth,
                    search_engine: e.serp_provider_name,
                  })
                }

              //   let rowspan_search_engine = 1
              //   let rowspan_frequency = 1
              //   let has_text_search_engine = true
              //   let has_text_frequency = true
              //   if(count){
              //     if(frequency_date_id_now != e.keyword_package_1_id){
              //       frequency_date_id_now = e.keyword_package_1_id
              //     }
              //     else{
              //       has_text_frequency = false
              //       let data_find = body_package.find(el => el.keyword_package_1_id == frequency_date_id_now)
              //       //console.log(data_find)
              //       if(data_find){
              //         body_package[data_find.count].rowspan_frequency += 1
              //       }
              //     }


              //     if(serp_provider_id_now != e.serp_provider_id){
              //       serp_provider_id_now = e.serp_provider_id
              //     }
              //     else{
              //       has_text_search_engine = false
              //       let data_find = body_package.find(el => el.serp_provider_id == serp_provider_id_now)
              //       //console.log(data_find)
              //       if(data_find){
              //         body_package[data_find.count].rowspan_search_engine += 1
              //       }
              //     }

              //   }

                let body = {
                  uuid: e.uuid, 
                  count: count, 
                  limit_active_keyword: e.limit_active_keyword,
                  keyword_package_1_id: e.keyword_package_1_id,
                  serp_provider_id: e.serp_provider_id,
                  // rowspan_frequency: rowspan_frequency,  
                  // rowspan_search_engine: rowspan_search_engine,  
                  // has_text_frequency: has_text_frequency,  
                  // has_text_search_engine: has_text_search_engine,  
                  frequency_date: e.frequency_date, 
                  depth: e.serp_depth_provider_depth,
                  search_engine: e.serp_provider_name,
                  frequency_date_format: e.frequency_date_format, 
                  package_date_expired: this.moment(e.package_date_expired).tz(this.$auth.user().timezone).format('DD/MM/YYYY H:mm'), 
                  month_body: month_body, 
                }
                
                body_package.push(body)
                count++
              }
              //console.log(body_package)
              this.body_package = body_package
            }
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading = false)
      },
    }
  }
</script>
<style>
.theme--light .pakage-th,.theme--light .pakage-td {
  border: 1px solid #d4d3d3; /* เส้นขอบด้านล่างสำหรับแต่ละ cell */
}
.theme--dark .pakage-th,.theme--dark .pakage-td {
  border: 1px solid #ffffff; /* เส้นขอบด้านล่างสำหรับแต่ละ cell */
}
.theme--light .pakage-td-active {
  background-color: #616161;
}
.theme--dark .pakage-td-active {
  background-color: #ffffff;
}
.theme--light .pakage-th {
  background-color: #E0E0E0;
}
.theme--dark .pakage-th {
  background-color: #424242;
}
.v-data-table__wrapper{
  border-radius: 15px !important;
}
</style>